import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import * as moment from 'moment';

declare var $:any;

@Component({
  selector: 'app-brand-car-list',
  templateUrl: './brand-car-list.component.html',
  styleUrls: ['./brand-car-list.component.scss']
})
export class BrandCarListComponent implements OnInit {
  public yearList:any = [];
  public filterForm!: FormGroup;
  public carModels:any;
  public user : any;
  public brandId:any;
  public stateData: any;
  public allMakes: any = []
  public modelList: any = [];
  public priceRange = [
    { id: 1, price: '1-10000' ,label :  '$1-$10000'},
    { id: 2, price: '10001-20000',label: '$10001-$20000' },
    { id: 3, price: '20001-30000',label : '$20001-$30000' },
    { id: 4, price: '30001-40000' ,label : '$30001-$40000'},
    { id: 5, price: '40001-50000',label : '$40001-$50000' },
    { id: 6, price: 'Above 50000',label : 'Above $50000' }
  ]
  public colorsArray = [
    { value: "White", label: "White" },
    { value: "Black", label: "Black" },
    { value: "Gray", label: "Gray" },
    { value: "Silver", label: "Silver" },
    { value: "Blue", label: "Blue" },
    { value: "Red", label: "Red" },
    { value: "Brown", label: "Brown" },
    { value: "Green", label: "Green" },
    { value: "Orange", label: "Orange" },
    { value: "Gold", label: "Gold" },
    { value: "Yellow", label: "Yellow" },
    { value: "Purple", label: "Purple" }
  ];
  public cityData:any;
  constructor(private service: CommonService,private activatedRoute:ActivatedRoute, private fb: FormBuilder, private renderer: Renderer2) {
    this.intiliazeFilterForm();
  

    if(this.service.isBrowserPlateformId()){
      this.user = JSON.parse(`${localStorage.getItem('user')}`)
    }
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1960; i--) {
      this.yearList.push({ id: i, year: i });
    }
    this.activatedRoute.params.subscribe((res:any)=>{
      this.brandId = res.id;
      let data = {
        brandName : this.brandId
      }
      this.getAllCarModel({});

      this.getAllMake();
      this.getStates();
    })
  }

  ngOnInit(): void {
    if(this.service.isBrowserPlateformId()){
   
       // Dynamically load JS
       const script2 = this.renderer.createElement('script');
       script2.src = 'https://cdn.jsdelivr.net/momentjs/latest/moment.min.js';
       script2.onload = () => {
         console.log('Swiper script loaded');
        
       };
       this.renderer.appendChild(document.body, script2);
      // Dynamically load CSS
      const link = this.renderer.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css';
      this.renderer.appendChild(document.head, link);
  
     
      // Dynamically load JS
      const script = this.renderer.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js';
      script.onload = () => {
        console.log('Swiper script loaded');
        this.getCalendar();
      };
      this.renderer.appendChild(document.body, script);
    }
   
  }

  intiliazeFilterForm() {
    this.filterForm = this.fb.group({
      make: [''],
      model: [''],
      year: [''],
      price: [''],
      color:[''],
      state:[''],
      city:['']
    });
  }
  getCalendar(){
    $(() => {
      $('input[name="daterange"]').daterangepicker({
        opens: 'center',
        // singleDatePicker: true,
        // autoUpdateInput: false,
        alwaysShowCalendars:true,
        showCustomRangeLabel:false,
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
       },
        locale: {
          format: 'DD MMM YYYY'
        }
      }, (start:any, end:any, label:any) => {
      
      });


      $('input[name="daterange"]').on('apply.daterangepicker', (ev: any, picker:any) => {

        let data = {
          startDate: picker.startDate.format('YYYY-MM-DD'),
          endDate: picker.endDate.format('YYYY-MM-DD'),
          brandName : this.brandId
        }
        this.getAllCarModel(data)
        $('input[name="daterange"]').val(picker.startDate.format('DD-MMM-YYYY') + ' - ' + picker.endDate.format('DD-MMM-YYYY'));
      });
  
      $('input[name="daterange"]').on('cancel.daterangepicker', function(ev:any, picker:any) {
          $('input[name="daterange"]').val('');
      });
    });
  }

  resetCarModelData(){
    let data = {
      brandName : this.brandId
    }
    this.getAllCarModel(data)
    const $input = $('input[name="daterange"]');
    $input.val('')
    const daterangepickerInstance = $input.data('daterangepicker');
    if (daterangepickerInstance) {
      daterangepickerInstance.setStartDate(moment());
      daterangepickerInstance.setEndDate(moment());
    }
  }

  getAllCarModel(data:any){
    this.service.showSpinner();
    this.service.postApi('cars/vehicle-insights',data).subscribe((res:any)=>{
      if(res.status ==200){
        this.carModels =res.cars
        this.service.hideSpinner();
      }
      else{
        this.service.hideSpinner()
      }
    },(error)=>{
      this.service.hideSpinner();
    })
  }
  
  filterCar() {
    const formValues = this.filterForm.value;
    const filters = {
      make: formValues.make,
      model: formValues.model,
      year: formValues.year,
      price: formValues.price,
      color: formValues.color,
      state: formValues.state,
      city:formValues.city
    }
    this.getAllCarModel(filters)
  }

  resetFilter() {
    this.filterForm.reset();
    let data = {
      brandName : this.brandId
    }
    this.getAllCarModel(data);
  }

  // update filter when remove/clear any option from dropdown filter
  removeMake() {
    this.filterCar();
    this.getModel();
  }
  clearMake() {
    this.modelList = [];
    this.filterCar();
  }
  updateFilter() {
    this.filterCar();
  }
  removeState() {
    this.filterCar();
    this.getCities();
  }
  clearState() {
    this.cityData = [];
    this.filterCar();
  }
  getModel() {
    let event = this.filterForm.value.make
    const tempModelList: any[] = [];
    if (Array.isArray(event) && event?.length > 0) {
      this.service.showSpinner();
      event.forEach((makeName: any) => {
        this.service.getModelByMakeName(`${makeName}`).subscribe((res: any) => {
          if (res && res.data) {
            res.data.forEach((element: any) => {
              element.make_name = makeName
              tempModelList.push(element)
            })
            this.modelList = [...tempModelList];
            this.service.hideSpinner();
          }
        }, (error) => {
          this.modelList = []
          this.service.hideSpinner();
        })
      });
    }
  }

  

  // get all car brand
  getAllMake() {
    this.allMakes = [];
    this.service.showSpinner();
    this.service.getAllMakeApi().subscribe(
      (res: any) => {
        if (res && res.data) {
          this.allMakes = [...res.data];;
          this.service.hideSpinner();
        }
      },
      (error) => {
        console.log(error);
        this.service.hideSpinner();
      }
    );
  }
  

  // get state list according country selected
  getStates() {
    this.service.apiFunction(`https://api.countrystatecity.in/v1/countries/us/states`).subscribe(
      (res: any) => {
        this.stateData = res;
        this.stateData = this.stateData.sort((a: any, b: any) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }

    // get city list according state selected
    getCities() {
      let event = this.filterForm.value.state
      const tempModelList: any[] = [];
      if (Array.isArray(event) && event?.length > 0) {
        this.service.showSpinner();
        event.forEach((element: any) => {
          this.service.apiFunction(`https://api.countrystatecity.in/v1/countries/us/states/${element}/cities`).subscribe(
            (res: any) => {
              // this.cityData = res
              res.forEach((element: any) => {
                tempModelList.push(element)
              })
              this.cityData = [...tempModelList];
              this.service.hideSpinner();
            },
            (error) => {
              this.service.hideSpinner();
              console.error('API Error:', error);
            }
          );
        })
      }
    }
}
