import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-validate-token',
  templateUrl: './validate-token.component.html',
  styleUrls: ['./validate-token.component.scss']
})
export class ValidateTokenComponent {
  public userId:any
  public token  : any
  constructor(private servive: CommonService,private router: Router,private activateRoute:ActivatedRoute){
    this.activateRoute.params.subscribe((res:any)=>{      
      this.token = res.token;
      this.userId = res.user_id
    })
    this.checkToken();
  }

  checkToken(){
    let url = 'users/check-token'
    let data = {
      userId : this.userId,
      token:this.token
    }
    this.servive.showSpinner()
    this.servive.postApi(url,data).subscribe((res:any)=>{
      if(res['status']==200){
        console.log("inside true condition",data);
        
        this.servive.hideSpinner()
        // this.servive.showSuccess(res['message'])
        this.router.navigate([`/reset-password/${this.userId}`])
      }
      else{
        this.servive.hideSpinner();
        this.servive.showError(res['message']);
        this.router.navigate(['/'])

      }
    })
  }

}
