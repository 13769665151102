<div class="w-full bg-white border border-[#E3E3E3] p-3 mb-12 hidden md:block">
    <form class="form flex flex-wrap justify-end " [formGroup]="filterForm">
        <div class="label flex-1 mr-4">
            <input type="text" class="shadow-none focus:shadow-none	focus:border-0 focus:outline-0 focus:ring-0 border-0 placeholder:text-[#777777] text-base h-9 pl-10 bg-[url('assets/img/search-icon.png')] bg-no-repeat	bg-[center_left_1rem]" placeholder="Search for Car" formControlName="carName">
        </div>
        <div class="group inline-flex flex-wrap space-x-3 mr-6">
            
            <div class="label">
                <select class="select lg:w-36 w-full h-9 border border-[#E3E3E3] rounded-md text-sm text-[#141827]ring-0 focus:shadow-none outline-0 focus:outline-0 focus:ring-0 focus:border-[#E3E3E3] bg-white custom-select-filter" formControlName="modelName">
                    <option value="" selected disabled>Make/Model</option>
                </select>
            </div>
            
            <div class="label">
                <select class="select lg:w-28 w-full h-9 border border-[#E3E3E3] rounded-md text-sm text-[#141827]ring-0 focus:shadow-none outline-0 focus:outline-0 focus:ring-0 focus:border-[#E3E3E3] bg-white custom-select-filter">
                    <option selected>Price</option>
                </select>
            </div>
            <div class="label">
                <select class="select lg:w-28 w-full h-9 border border-[#E3E3E3] rounded-md text-sm text-[#141827]ring-0 focus:shadow-none outline-0 focus:outline-0 focus:ring-0 focus:border-[#E3E3E3] bg-white custom-select-filter" formControlName="modelYear">
                    <option value="" selected disabled>Year</option>
                    <option  *ngFor="let item of yearList" [value]="item">{{item}}</option>
    
                </select>
            </div>
            <div class="label">
                <select class="select lg:w-32 w-full h-9 border border-[#E3E3E3] rounded-md text-sm text-[#141827] ring-0 focus:shadow-none outline-0 focus:outline-0 focus:ring-0 focus:border-[#E3E3E3] bg-white custom-select-filter">
                    <option selected>Distance</option>
                </select>
            </div>
            <div class="label">
                <select class="select lg:w-28 w-full h-9 border border-[#E3E3E3] rounded-md text-sm text-[#141827]ring-0 focus:shadow-none outline-0 focus:outline-0 focus:ring-0 focus:border-[#E3E3E3] bg-white custom-select-filter" formControlName="mileage">
                    <option value="" selected>Mileage</option>
                </select>
            </div>
        </div>
        <div class="right-btn mr-8">
            <button class="px-4 py-2 rounded-md bg_primary text-white text-sm outline-0" (click)="filterCar()">Search Cars </button>
        </div>
        <div class="mode inline-flex">
            <a href="" class="list mode mr-4">
                <span class="block w-8 h-8 grey_text_500 hover:text-black">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                    <g>
                        <path d="M92.3,6.6c0.6,0.4,1.4,0.8,1.9,1.3c0.5,0.7,0.9,1.6,0.9,2.4c0.1,5,0,10,0,15.1c0,2.2-1.6,3.8-3.8,3.8c-17.5,0-35,0-52.5,0
                            c-2.3,0-3.8-1.5-3.9-3.9c0-2.3,0-4.6,0-7c0-2.3,0.1-4.6,0-7c-0.1-2.3,0.7-3.9,2.9-4.7C56,6.6,74.1,6.6,92.3,6.6z"/>
                        <path d="M24.6,6.6c0.2,0.1,0.4,0.2,0.6,0.3c1.4,0.7,2.2,1.8,2.2,3.4c0,5,0,10.1,0,15.1c0,2.1-1.6,3.7-3.7,3.7c-5,0-10.1,0-15.1,0
                            c-1.6,0-2.7-0.8-3.4-2.2c-0.1-0.2-0.2-0.4-0.3-0.6c0-5.6,0-11.3,0-16.9C5.4,8,6.3,7.1,7.7,6.6C13.3,6.6,19,6.6,24.6,6.6z"/>
                        <path d="M4.9,43.2C5,43,5.1,42.8,5.2,42.6c0.7-1.4,1.8-2.2,3.4-2.2c5,0,10.1,0,15.1,0c2.1,0,3.7,1.6,3.7,3.7c0,5,0,10.1,0,15.1
                            c0,2.1-1.6,3.7-3.7,3.7c-5,0-10.1,0-15.1,0c-1.6,0-2.7-0.8-3.4-2.2c-0.1-0.2-0.2-0.4-0.3-0.6C4.9,54.5,4.9,48.9,4.9,43.2z"/>
                        <path d="M4.9,77.1c0.1-0.2,0.2-0.4,0.3-0.6c0.7-1.4,1.8-2.2,3.4-2.2c5,0,10.1,0,15.1,0c2.1,0,3.7,1.6,3.7,3.7c0,5,0,10.1,0,15.1
                            c0,2.1-1.6,3.7-3.7,3.7c-5,0-10.1,0-15.1,0c-1.6,0-2.7-0.8-3.4-2.2C5.1,94.4,5,94.2,4.9,94C4.9,88.4,4.9,82.7,4.9,77.1z"/>
                        <path d="M65.1,40.4c8.6,0,17.3,0,25.9,0c2.6,0,4.1,1.5,4.1,4.1c0,4.8,0,9.6,0,14.4c0,2.5-1.5,4-4,4c-17.4,0-34.8,0-52.2,0
                            c-2.4,0-4-1.5-4-3.8c0-5,0-9.9,0-14.9c0-2.4,1.6-3.8,4-3.8C47.7,40.4,56.4,40.4,65.1,40.4z"/>
                        <path d="M65.1,74.3c8.7,0,17.3,0,26,0c2.5,0,4.1,1.5,4.1,4c0,4.8,0,9.7,0,14.5c0,2.4-1.6,4-3.9,4c-17.4,0-34.8,0-52.2,0
                            c-2.4,0-4-1.5-4-3.9c0-4.9,0-9.9,0-14.8c0-2.4,1.6-3.9,4-3.9C47.7,74.3,56.4,74.3,65.1,74.3z"/>
                    </g>
                    </svg>
    
                </span>
            </a>
            <a href="" class="grid-mode mode ">
                <span class="block w-8 h-8 grey_text_500 hover:text-black">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                <g>
                    <path d="M2.3,23.7c0-5.4,0-10.7,0-16.1c0-3.4,1.8-5.3,5.2-5.3c10.8,0,21.7,0,32.5,0c3.4,0,5.2,1.9,5.2,5.3c0,10.8,0,21.6,0,32.3
                        c0,3.5-1.9,5.3-5.4,5.3c-10.7,0-21.5,0-32.2,0c-3.5,0-5.4-1.8-5.4-5.4C2.3,34.4,2.3,29.1,2.3,23.7z"/>
                    <path d="M97.7,23.9c0,5.4,0,10.7,0,16.1c0,3.4-1.9,5.3-5.2,5.3c-10.8,0-21.7,0-32.5,0c-3.3,0-5.2-1.9-5.2-5.3c0-10.8,0-21.6,0-32.3
                        c0-3.5,1.8-5.3,5.3-5.3c10.7,0,21.5,0,32.2,0c3.6,0,5.4,1.8,5.4,5.4C97.7,13.1,97.7,18.5,97.7,23.9z"/>
                    <path d="M2.3,76.1c0-5.4,0-10.7,0-16.1c0-3.4,1.8-5.3,5.2-5.3c10.8,0,21.7,0,32.5,0c3.4,0,5.2,1.9,5.2,5.3c0,10.8,0,21.6,0,32.3
                        c0,3.5-1.9,5.3-5.4,5.3c-10.7,0-21.5,0-32.2,0c-3.5,0-5.4-1.8-5.4-5.4C2.3,86.9,2.3,81.5,2.3,76.1z"/>
                    <path d="M76.1,97.7c-5.4,0-10.7,0-16.1,0c-3.3,0-5.2-1.9-5.2-5.1c0-10.9,0-21.7,0-32.6c0-3.3,1.9-5.1,5.2-5.1c10.8,0,21.7,0,32.5,0
                        c3.4,0,5.2,1.9,5.2,5.3c0,10.8,0,21.6,0,32.3c0,3.5-1.9,5.3-5.4,5.3C86.9,97.7,81.5,97.7,76.1,97.7z"/>
                </g>
                </svg>
                </span>
            </a>
        </div>
    </form>
  
</div>