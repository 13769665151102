<header class=" w-full top-0 left-0 bg-transparent z-20 md:py-6 py-4  ease-in-out duration-300"
    [ngClass]="{ 'lg:py-4': (isScrolled  || isVerificationPage), 'fixed transition-all bg_primary ' : !isHomePage ,'absolute' : isHomePage  }">
    <div class="container max-w-screen-xl mx-auto">
        <div class="flex flex-wrap items-center justify-between px-4">
            <div class="logo w-4/12 md:mx-[0]  xl:w-4/12 pad:w-3/12	 transition-all ease-in-out duration-500 cursor-pointer"
                (click)="routeToHomePage()">
                <img height="100%" width="100%" loading="lazy" alt="head1"  srcset="assets/icons/logo.svg">
            </div>
            <div class="flex items-center lg:order-2 lg:mr-0 mr-10">
                <a [ngClass]="{'button-white lg:px-10': isScrolled}"
                    class="rounded-full bg-white md:py-2 py-1 md:px-14 px-6 font-semibold xl:text-lg sm:text-base text-xs text_primary_color cursor-pointer border border-transparent transition-all ease-in-out duration-300"
                    routerLink="/login" *ngIf="!user && showHeader">Sign In</a>
                <div *ngIf="user" class="relative">
                    <!-- (focusout)="dropDownClick()" -->
                    <button
                        class="rounded-full bg-white text_primary_color  hover:text-white hover:bg-blue-800  focus:outline-none focus:ring-blue-300 font-semibold xl:text-lg sm:text-base text-xs px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        type="button" (click)="dropDownClick()">
                        Hi {{firstName}}!
                        <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 1 4 4 4-4" />
                        </svg>
                    </button>
                    <div class="z-10 md:mt-2 mt-1 absolute right-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                        id="dropdownInformation" [ngClass]="{'block': isOpen, 'hidden': !isOpen}">
                        <div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
                            <div class="font-medium truncate">{{user.username}}</div>
                            <!-- <div class="font-medium truncate">{{user.email}}</div> -->
                        </div>
                        <div class="py-2">
                            <a class="block px-4 py-2 cursor-pointer text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                (click)="routeToCarList()">Vehicle listing
                            </a>
                        </div>

                        <div class="py-2">
                            <a class="block px-4 py-2 cursor-pointer text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                (click)="logOut()">Sign out
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="flex lg:hidden">
                <input type="checkbox" class="openSidebarMenu" id="openSidebarMenu">
                <label for="openSidebarMenu" class="sidebarIconToggle">
                    <div class="spinner diagonal part-1"></div>
                    <div class="spinner horizontal"></div>
                    <div class="spinner diagonal part-2"></div>
                </label>
                <div class=" absolute z-2 xl:w-3/12 min-[993px]:w-5/12 w-full px-4 mb-10 min-[992px]:mb-0"
                    id="sidebar_menu">
                    <app-sidebar></app-sidebar>
                </div>
            </div>          -->
        </div>
    </div>
</header>