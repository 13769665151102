<label class="block md:mb-4 mb-4 md:w-9/12 mx-auto">
    <input *ngIf="selectedValue === '0'"
           type="text"
           placeholder="VIN Number"
           name="vin_number"
           [(ngModel)]="vin_number"
           class="xl:h-14 h-12 rounded-full text-center md:px-8 px-6 sm:w-3/5 w-full outline-0 input-login-shadow md:text-lg text-sm font-normal placeholder:text-black border-0 focus:outline-0 focus:border-0" />
  
    <div *ngIf="selectedValue === '1'"
         class="flex flex-wrap md:flex-nowrap justify-center gap-4">
      <input type="text"
             placeholder="License Plate"
             name="license_number"
             [(ngModel)]="licenseNumber"
             class="xl:h-14 h-12 rounded-full text-center md:px-8 px-6 sm:w-1/2 w-full outline-0 input-login-shadow xl:text-lg md:text-base text-sm font-normal placeholder:text-black border-0 focus:outline-0 focus:border-0" />
  
      <select class="xl:h-14 h-12 rounded-full md:px-8 px-6 sm:w-1/2 w-full outline-0 input-login-shadow xl:text-lg md:text-base text-sm font-normal border-0 focus:outline-0 focus:border-0 text-center"
              [(ngModel)]="selectedState">
        <option value="" disabled>Select State</option>
        <option *ngFor="let state of stateData" [value]="state.iso2">{{ state.name }}</option>
      </select>
    </div>
  </label>
  
  <label class="block justify-center">
    <div class="mt-2 md:px-6">

        <label class="inline-flex items-center jus text-gray-600 dark:text-gray-400">
            <div class="custom-radio">
                <input type="radio"
                    class="form-radio focus:outline-none focus:shadow-outline-purple text-black"
                    name="accountType" value="1" [(ngModel)]="selectedValue">
                <span class="custom-label ml-2 text-white font-semibold xl:text-xl text-base">License
                    Plate</span>
            </div>
        </label>
        <label class="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400">
            <div class="custom-radio">
                <input type="radio"
                    class="text-purple-600 form-radio focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray "
                    name="accountType" value="0" [(ngModel)]="selectedValue">
                <span class="custom-label ml-2 text-white font-semibold xl:text-xl	text-base">VIN
                    Number</span>
            </div>
        </label>
    </div>
</label>
  
  <div class="block text-center xl:mt-12 mt-8">
    <button class="rounded-full bg-white md:py-3 py-2 md:px-20 px-12 font-semibold xl:text-xl text-base text_primary_color cursor-pointer border border-transparent hover:border-white hover:bg-transparent hover:text-white"
            (click)="listYourCar()">List Your Vehicle</button>
  </div>
  
  <div class="mx-auto text-center md:text-sm text-xs font-semibold text-white">
    <p class="md:mt-8 mt-6">Here’s how to list and sell your Vehicle in 3 simple steps</p>
    <div class="flex justify-center w-full my-4 custom-scrollbar">
      <table class="text-left">
        <tbody>
          <tr>
            <td class="font-bold underline whitespace-nowrap" valign="top">Step 1:</td>
            <td class="pl-2">Enter Your License Plate, select your state and click on List Your Vehicle</td>
          </tr>
          <tr>
            <td class="font-bold underline whitespace-nowrap" valign="top">Step 2:</td>
            <td class="pl-2">Answer a few questions and upload pictures of your Vehicle</td>
          </tr>
          <tr>
            <td class="font-bold underline whitespace-nowrap" valign="top">Step 3:</td>
            <td class="pl-2">Enter and verify your details</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="mt-1">Now you can start receiving offers for your Vehicle from verified dealers.</p>
    <p class="mt-1">It’s that easy to sell your vehicle on Kargone™</p>
  </div>
  