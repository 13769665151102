<app-sub-header></app-sub-header>

<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4">
            <app-responsive-sidebar></app-responsive-sidebar>
            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center">
                    <h2 class="lg:text-5xl text-4xl  font-bold leading-none text_primary_color">Edit Your Profile</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>
                <form class="md:px-7 px-2 md:flex md:flex-wrap" [formGroup]="registrationForm">
                    <div class="mx-auto w-full text-center  mb-4 grid justify-center relative" *ngIf="!isSellerProfile">
                        <div class="w-44 h-44 relative">
                            <!-- Image Preview -->
                            <img *ngIf="imagePreview" [src]="imagePreview"
                                class="w-44 h-44 rounded-full absolute object-cover" alt="Uploaded Image">
                            <img *ngIf="!imagePreview" src="../../../assets/img/avatar-profile.png"
                                class="w-44 h-44 rounded-full absolute object-cover" alt="Uploaded Image">
                            <!-- File Input Trigger -->
                            <label for="dropzone-file"
                                class="w-44 h-44 group hover:bg-gray-200 opacity-60  rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500">
                                <!-- Image Icon (replace with your own icon) -->
                                <img src="https://www.svgrepo.com/show/33565/upload.svg" alt="Upload Icon"
                                    class="hidden w-12 group-hover:block" *ngIf="!imagePreview">
                                <!-- File Input (hidden) -->
                                <input id="dropzone-file" type="file" class="hidden"
                                    (change)="handleImageUpload($event)">
                            </label>
                        </div>

                    </div>
                    <label class="block mb-6 lg:w-6/12 w-full md:px-4">
                        <input type="text" name="firstName" placeholder="First Name"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 "
                            formControlName="firstName" />
                        <div *ngIf="(f['firstName'].invalid && (f['firstName'].dirty || f['firstName'].touched)) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['firstName'].errors?.['required']">*First Name is required</div>
                            <div *ngIf="f['firstName'].errors?.['pattern']">*Invalid first name format</div>
                        </div>
                    </label>
                    <label class="block mb-6 lg:w-6/12 w-full md:px-4">
                        <input type="text" name="lastName" placeholder="Last Name"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="lastName" />
                        <div *ngIf="f['lastName'].invalid && (f['lastName'].dirty || f['lastName'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['lastName'].errors?.['required']">*Last Name is required</div>
                            <div *ngIf="f['lastName'].errors?.['pattern']">*Invalid last name format</div>
                        </div>
                    </label>
                    <label class="block mb-6 lg:w-6/12 w-full md:px-4" *ngIf="!isSellerProfile">
                        <input type="text" name="dealershipName" placeholder="Dealership Name"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="dealershipName" />
                        <div *ngIf="f['dealershipName'].invalid && (f['dealershipName'].dirty || f['dealershipName'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['dealershipName'].errors?.['required']">*Dealership Name is required</div>
                            <div *ngIf="f['dealershipName'].errors?.['pattern']">*Invalid Dealership Name format</div>
                        </div>
                    </label>
                    <label class="block mb-6 lg:w-6/12 w-full md:px-4" *ngIf="!isSellerProfile">
                        <input type="text" name="companyName" placeholder="Company Name"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="companyName" />
                        <div *ngIf="f['companyName'].invalid && (f['companyName'].dirty || f['companyName'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['companyName'].errors?.['required']">*Company Name is required</div>
                            <div *ngIf="f['companyName'].errors?.['pattern']">*Invalid Company Name format</div>
                        </div>
                    </label>
                    <div class="flex mb-6  w-full md:px-4">
                        <label class="block  w-2/5  text-center" *ngIf="!isSellerProfile">
                            <input type="file" accept="image/*,.pdf" style="display: none;"
                                (change)="uploadLicense($event)" />
                            <span
                                class="mt-1 md:h-14 h-12 block w-full bg-[#014BAE] md:px-8 px-4 md:text-base text-sm input-shadow text-[#ffffff] rounded-l-md md:py-4 py-3 license"
                                style="cursor: pointer;">
                                Upload
                            </span>
                        </label>
                        <label class="block  w-3/5 " *ngIf="!isSellerProfile">
                            <input type="text" name="carMonth" placeholder="License Certificate"
                                class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-r-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                                readonly [(ngModel)]="licenseName" [ngModelOptions]="{standalone: true}" />
                        </label>
                    </div>
                    <label class="block mb-6  w-full md:px-4" *ngIf="!isSellerProfile">
                        <input type="text" name="carMonth" placeholder="License Number"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="licenseNumber" />
                        <div *ngIf="f['licenseNumber'].invalid && (f['licenseNumber'].dirty || f['licenseNumber'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['licenseNumber'].errors?.['required']">*Please enter license number</div>
                        </div>
                    </label>
                    <label class="block mb-6  w-full md:px-4" *ngIf="!isSellerProfile">
                        <input type="text" name="carMonth" placeholder="How many used cars do you buy in a month?"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            (keypress)="numberOnly($event)" formControlName="carBuyMonthly" />
                        <div *ngIf="f['carBuyMonthly'].invalid && (f['carBuyMonthly'].dirty || f['carBuyMonthly'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['carBuyMonthly'].errors?.['required']">*Number of used car in a month is
                                required</div>
                        </div>
                    </label>
                    <label class="block mb-6 md:w-full md:px-4" *ngIf="!isSellerProfile">

                        <select
                            class="mt-1 md:h-14 h-12 block text-black w-full bg-white  rounded-md placeholder:text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="dealerType" (change)="selectGroup($event)">
                            <option selected value="" disabled>What is your dealership type?</option>
                            <option value="group">Group</option>
                            <option value="independent">Independent</option>
                        </select>

                    </label>
                    <label class="block mb-6 md:w-full md:px-4" *ngIf="isGroupSelected">
                        <input type="text" name="carMonth" placeholder="How many dealership are there in your group?"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            (keypress)="numberOnly($event)" formControlName="dealerGroup" />

                    </label>
                    <label class="block mb-6 md:w-full md:px-4">
                        <input type="text" name="addressLine1" placeholder="Address Line 1"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="addressLine1" />
                        <div *ngIf="f['addressLine1'].invalid && (f['addressLine1'].dirty || f['addressLine1'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['addressLine1'].errors?.['required']">*Address Line 1 is required</div>
                        </div>
                    </label>
                    <label class="block mb-6 md:px-4"
                        [ngClass]="{'lg:w-6/12 w-full': !isSellerProfile, 'md:w-full': isSellerProfile}">
                        <input type="text" name="addressLine2" placeholder="Address Line 2"
                            class="mt-1 md:h-14 h-12 block text-black w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="addressLine2" />
                    </label>
                    <label class="block mb-6 lg:w-6/12 w-full md:px-4">
                        <input type="text" name="firstName"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="country" readonly />
                        <div *ngIf="f['country'].invalid && (f['country'].dirty || f['country'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['country'].errors?.['required']">*Please select country</div>
                        </div>
                    </label>
                    <label class="block mb-6 lg:w-6/12 w-full md:px-4">
                        <input type="text" name="zipCode" placeholder="Zip Code"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="zipCode" (input)="getStateCity($event)" />
                        <div *ngIf="f['zipCode'].invalid && (f['zipCode'].dirty || f['zipCode'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['zipCode'].errors?.['required']">*Zip code is required</div>
                        </div>
                    </label>
                    <label class="block mb-6 lg:w-6/12 w-full md:px-4">
                        <input type="text" name="state" placeholder="State" [(ngModel)]="selectedState"
                            [ngModelOptions]="{standalone: true}"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            readonly />
                        <div *ngIf="f['state'].invalid && (f['state'].dirty || f['state'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['state'].errors?.['required']">*Please select state</div>
                        </div>
                    </label>

                    <label class="block mb-6 lg:w-6/12 w-full md:px-4">
                        <input type="text" name="city" placeholder="City"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="city" readonly />
                        <div *ngIf="f['city'].invalid && (f['city'].dirty || f['city'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['city'].errors?.['required']">*Please select city</div>
                        </div>
                    </label>
                    <label class="block mb-6 lg:w-6/12 w-full md:px-4" *ngIf="!isSellerProfile">
                        <input type="text" name="regNumber" placeholder="Tax Id"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="regNumber" />
                        <div *ngIf="f['regNumber'].invalid && (f['regNumber'].dirty || f['regNumber'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['regNumber'].errors?.['required']">*Registration number is required</div>
                        </div>
                    </label>
                    <label class="block mb-6 lg:w-6/12 w-full md:px-4">
                        <input type="email" name="email" placeholder="Email Address"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            formControlName="email"/>
                        <div *ngIf="f['email'].invalid && (f['email'].dirty || f['email'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['email'].errors?.['required']">*Email is required</div>
                            <div *ngIf="f['email'].errors?.['email']">*Invalid email format</div>
                        </div>
                    </label>
                    <label class="block mb-6 lg:w-6/12 w-full md:px-4">
                        <input type="text" name="phone" placeholder="Mobile Number"
                            class="mt-1 md:h-14 h-12 block text-black w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0"
                            (keypress)="numberOnly($event)" (input)="onInputChange($event,0)" formControlName="phone"
                            minlength="14"/>
                        <div *ngIf="f['phone'].invalid && (f['phone'].dirty || f['phone'].touched)"
                            class="error-message md:ml-4">
                            <div *ngIf="f['phone'].errors?.['required']">*Phone number is required</div>
                        </div>
                    </label>
                </form>
                <div class="block text-center mt-4 ">
                    <button
                        class="rounded-full bg_primary pt-3 pb-3 lg:px-32 px-20  font-medium text-xl text-white cursor-pointer border border-transparent hover:border-[#004AAD] hover:bg-white hover:text-[#004AAD] "
                        (click)="submitForm()" [disabled]="!registrationForm.valid">Update</button>
                </div>
            </div>
        </div>
    </div>
</section>