import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.scss']
})
export class TermConditionComponent {
  public termConditionData:any;
  constructor(private service:CommonService,@Inject(PLATFORM_ID) private platformId: any) {
  
    if (isPlatformBrowser(this.platformId)){
      this.getData()
    }
   
    this.service.scrollToTop()
  }
  getData(){
    this.service.showSpinner();
    this.service.postApi('term-conditions/getTermCondition',{isWebsite:true}).subscribe((res: any) => {
      if(res.status ==200){
        this.termConditionData = res.termCondition
        this.service.hideSpinner();
      }
      else{
        this.service.showError(res.message)
        this.service.hideSpinner();
      }
  
    }, (error) => {
      this.service.hideSpinner();
    })
  }
}
