<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name"
            class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center">
        <div class="container mx-auto  ">
            <div class="max-w-full mx-auto z-50 px-6 ">
                <div class="block text-center">
                    <h1 class="md:text-6xl text-4xl text-white font-bold ">Frequently Asked Questions</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="relative lg:py-16 py-10 bg-[#F5F5F5]">
    <div class="max-w-screen-xl	 mx-auto">
        <div class="md:flex md:flex-wrap">
            <input type="checkbox"  class="openSidebarMenu" id="openSidebarMenu">
            <label for="openSidebarMenu" class="sidebarIconToggle">
                <div class="spinner diagonal part-1"></div>
                <div class="spinner horizontal"></div>
                <div class="spinner diagonal part-2"></div>
            </label>
            <div class="z-2 xl:w-3/12 min-[993px]:w-5/12 w-full px-4 mb-10 min-[992px]:mb-0 hidden lg:block" id="sidebar_menu">
            <app-sidebar *ngIf="user?.id>0"></app-sidebar>
            </div>
            <div class="px-4  w-full" [ngClass]="{'xl:w-9/12 md:w-7/12': user}">

                <div id="accordion-collapse" data-accordion="collapse" >

                    <div *ngFor="let faq of faqArray" class="mb-6 p-4 rounded-lg bg-white">
                        <h2 [id]="'accordion-collapse-heading-' + faq.id">
                            <button type="button"
                                class="flex items-center justify-between w-full py-2 pt-4 px-4 text-xl font-medium text-left text-black"
                                [class.text-[#004AAD]]="isAccordionOpen(faq.id)" (click)="toggleAccordion(faq.id)"
                                [attr.aria-expanded]="isAccordionOpen(faq.id)"
                                [attr.aria-controls]="'accordion-collapse-body-' + faq.id">
                                <span>{{ faq?.question }}</span>
                                <svg data-accordion-icon class="w-3 h-3 shrink-0 " aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"
                                    [class.rotate-180]="!isAccordionOpen(faq.id)">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M9 5 5 1 1 5" />
                                </svg>
                            </button>
                        </h2>
                        <div [id]="'accordion-collapse-body-' + faq.id" [class.hidden]="!isAccordionOpen(faq.id)"
                            [attr.aria-labelledby]="'accordion-collapse-heading-' + faq.id">
                            <div class="p-5">
                                <p class="text-[#777] text-base font-medium" [innerHTML]="faq?.answer"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>