<div class="main-section container flex flex-wrap bg-cover min-h-screen align-middle lg:items-center justify-center min-w-full" style="background-image: url('../../../assets/img/home-banner1.avif');">
    <div class=" z-50 flex-1 px-6 mt-20 pad:mt-10 xl:mt-10 md:mb-0 mb-14 flex ">
            <div class="block text-center md:max-w-screen-sm sm:max-w-md m-auto">
                <h1 class="md:text-5xl text-3xl text-white font-bold mb-3">Forgot Your Password </h1>
                <p class="md:text-lg text-sm text-white font-medium">Enter the email address for your Kargone account, and we’ll send a 
                    password rest link to reset your password.</p>
                <div class="form xl:mt-10 mt-6">
                    <form  [formGroup]="forgotForm">
                        <label class="block md:mb-8 mb-6 md:max-w-[460px] max-w-xs  mx-auto">
                            <input type="text" placeholder="Enter Email" name="user_name" class="md:h-14 h-12 rounded-full md:px-8 px-6 w-full outline-0 input-login-shadow md:text-lg text-sm font-normal  border-0" formControlName="email">
                            <div *ngIf="f['email'].invalid && f['email'].touched" class="text-white bg-red-600  text-center inline-block px-2 py-1 mt-2 rounded-md ">
                                <div *ngIf="f['email'].hasError('required')" >
                                  *Email address is required.
                                </div>
                                <div *ngIf="f.email.hasError('pattern')">
                                  *Please enter valid email address.
                                </div>
                              </div>
                        </label>                      
                        <div class="block text-center md:mt-6 mt-8">
                            <button class="rounded-full bg-white md:py-3 py-2 md:px-20 px-12 font-semibold md:text-xl taxt-base text_primary_color cursor-pointer border border-transparent hover:border-white hover:bg-transparent hover:text-white" (click)="sendEmail()">Submit</button>
                            <p  class="md:mt-6 mt-4 md:text-lg text-xs font-medium text-white">Already have an account ?<a href="" class="underline text-white hover:no-underline" routerLink="/login"> Sign In </a></p>
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
