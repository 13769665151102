<app-sub-header></app-sub-header>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap justify-center -mx-4">
            <!-- <app-sidebar class="z-2 xl:w-3/12 md:w-5/12 w-full px-4 mb-10 md:mb-0 hidden md:block"
                *ngIf="userId || isSubDealer"></app-sidebar> -->
            <app-responsive-sidebar *ngIf="userId || isSubDealer" ></app-responsive-sidebar>
            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center">
                    <h2 class="lg:text-5xl text-4xl  font-bold leading-none text_primary_color">Dealer Preference</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>
                <div class="right-block">

                    <form [formGroup]="dealerPreferenceForm" class="md:flex md:flex-wrap">
                        <!-- Car Pick At Customer's Location -->
                        <div class="md:w-6/12 md:px-4">
                            <label class="block mb-2">Car Pick At Customer's Location</label>
                            <ng-select
                                class="mt-1 h-auto block w-full bg-white rounded-md grey_text_500 placeholder-[#777777] md:text-base text-sm input-shadow outline-0 border-0"
                                placeholder="Car Pick At Customer's Location" [searchable]="false"
                                formControlName="carLocation">
                                <ng-option value="1">Yes</ng-option>
                                <ng-option value="0">No</ng-option>
                            </ng-select>

                            <!-- Error message for Car Pick At Customer's Location -->
                            <div *ngIf="f.carLocation.hasError('required') && (f.carLocation.touched || f.carLocation.dirty || isSubmit) "
                                class="text-red-500 text-sm mt-2">This field is required.</div>
                        </div>

                        <!-- Price Payment -->
                        <div class="md:w-6/12 md:px-4">
                            <label class="block mb-2">Price Range($)</label>
                            <ng-select [items]="priceRange" [multiple]="true" bindLabel="price" bindValue="price"
                                [closeOnSelect]="false" [searchable]="false"
                                class="mt-1 h-auto block w-full bg-white rounded-md grey_text_500 placeholder-[#777777] md:text-base text-sm input-shadow outline-0 border-0"
                                placeholder="Price Range($)" formControlName="sameDayPayment">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                        [ngModelOptions]="{standalone: true}" /> {{item.price | uppercase}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                        [ngModelOptions]="{standalone: true}" /> {{item.price}}
                                </ng-template>
                            </ng-select>

                            <!-- Error message for price -->
                            <div *ngIf="f.sameDayPayment.hasError('required') && (f.sameDayPayment.touched || f.sameDayPayment.dirty || isSubmit)"
                                class="text-red-500 text-sm mt-2">This field is required.</div>
                        </div>

                        <!-- Finance Settlement -->
                        <div class="md:w-6/12 md:px-4">
                            <label class="block mt-6">Finance Settlement</label>
                            <ng-select
                                class="mt-1 h-auto block w-full bg-white rounded-md grey_text_500 placeholder-[#777777] md:text-base text-sm input-shadow outline-0 border-0"
                                placeholder="Finance Settlement" [searchable]="false"
                                formControlName="financeSettlement">
                                <ng-option value="1">Yes</ng-option>
                                <ng-option value="0">No</ng-option>
                            </ng-select>

                            <!-- Error message for Finance Settlement -->
                            <div *ngIf="f.financeSettlement.hasError('required') && (f.financeSettlement.touched || f.financeSettlement.dirty || isSubmit) "
                                class="text-red-500 text-sm mt-2">This field is required.</div>
                        </div>

                        <!-- Lease Settlement -->
                        <div class="md:w-6/12 md:px-4">
                            <label class="block mt-6">Lease Settlement</label>
                            <ng-select
                                class="mt-1 h-auto block w-full bg-white rounded-md grey_text_500 placeholder-[#777777] md:text-base text-sm input-shadow outline-0 border-0"
                                placeholder="Lease Settlement" [searchable]="false" formControlName="leaseSettlement">
                                <ng-option value="1">Yes</ng-option>
                                <ng-option value="0">No</ng-option>
                            </ng-select>

                            <!-- Error message for Lease Settlement -->
                            <div *ngIf="f.leaseSettlement.hasError('required') && (f.leaseSettlement.touched || f.leaseSettlement.dirty || isSubmit)"
                                class="text-red-500 text-sm mt-2">This field is required.</div>
                        </div>

                        <!-- Pincode With Radius In Miles -->
                        <label
                            class="flex flex-wrap justify-center md:justify-normal mt-6 w-full items-center md:mx-4 bg-white rounded-md input-shadow outline-0 border-0">
                            <p
                                class="lg:w-[62%] block placeholder-[#777777] md:px-3.5 px-4 md:text-base text-sm  text-black">
                                Pincode With Radius In Miles You Would Want To Buy Cars From</p>
                            <label class="block lg:w-[38%] px-4 pb-6 w-full relative pr-14">
                                <div class="relative">
                                    <input type="range" min="0" max="100" value="{{rangeValue}}"
                                        class="range  transition-all duration-300 ease-linear mt-3"
                                        [(ngModel)]="rangeValue" [ngModelOptions]="{standalone: true}" />
                                    <div class="w-[70px] flex justify-between text-xs transition-all duration-300 ease-linear absolute
                                    " [style.left.%]="rangeValue-(rangeValue/20)">
                                        <div>{{rangeValue}} miles</div>
                                    </div>
                                </div>
                            </label>
                        </label>
                        <!-- Add a label for this input field if needed -->

                        <!-- Car Make -->
                        <div class="md:w-6/12 md:px-4">
                            <label class="block mt-6">Select Make</label>
                            <ng-select [items]="allMakes" [multiple]="true" bindLabel="name" [loading]="isLoading"
                                [closeOnSelect]="false" [clearSearchOnAdd]="true" bindValue="name"
                                class="mt-1 h-auto block w-full bg-white rounded-md grey_text_500 placeholder-[#777777] md:text-base text-sm input-shadow outline-0 border-0"
                                placeholder="Select Make" formControlName="carMake" (close)="getModel()"
                                (remove)="getModel()" (clear)="clearMake()">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type=checkbox [ngModel]="item$.selected"
                                        [ngModelOptions]="{standalone: true}" /> {{item.name | uppercase}}
                                </ng-template>+
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type=checkbox [ngModel]="item$.selected"
                                        [ngModelOptions]="{standalone: true}" /> {{item.name}}
                                </ng-template>
                            </ng-select>
                        </div>

                        <!-- Car Model -->
                        <div class="md:w-6/12 md:px-4">
                            <label class="block mt-6">Select Model</label>
                            <ng-select [items]="modelList" [multiple]="true" bindLabel="name" groupBy="make_name"
                                [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false"
                                [clearSearchOnAdd]="true" bindValue="name"
                                class="mt-1 h-auto block w-full bg-white rounded-md grey_text_500 placeholder-[#777777] md:text-base text-sm input-shadow outline-0 border-0"
                                placeholder="Select Model" formControlName="carModel">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type=checkbox [ngModel]="item$.selected"
                                        [ngModelOptions]="{standalone: true}" /> {{item.make_name | uppercase}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type=checkbox [ngModel]="item$.selected"
                                        [ngModelOptions]="{standalone: true}" /> {{item.name}}
                                </ng-template>
                            </ng-select>
                        </div>

                        <!-- Mile Range -->
                        <div class="md:w-6/12 md:px-4">
                            <label class="block mt-6">Select Mile Range</label>
                            <ng-select [items]="mileage" [multiple]="true" bindLabel="mileage" bindValue="mileage"
                                [closeOnSelect]="false" [clearSearchOnAdd]="true"
                                class="mt-1 h-auto block w-full bg-white rounded-md grey_text_500 placeholder-[#777777] md:text-base text-sm input-shadow outline-0 border-0"
                                placeholder="Select Mile Range" formControlName="mileRange">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type=checkbox [ngModel]="item$.selected"
                                        [ngModelOptions]="{standalone: true}" /> {{item.mileage | uppercase}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type=checkbox [ngModel]="item$.selected"
                                        [ngModelOptions]="{standalone: true}" /> {{item.mileage}}
                                </ng-template>
                            </ng-select>
                        </div>

                        <!-- Model Year -->
                        <div class="md:w-6/12 md:px-4">
                            <label class="block mt-6">Select Model Year</label>
                            <ng-select [items]="modelYear" [multiple]="true" bindLabel="year" bindValue="year"
                                [closeOnSelect]="false" [clearSearchOnAdd]="true"
                                class="mt-1 h-auto block w-full bg-white rounded-md grey_text_500 placeholder-[#777777] md:text-base text-sm input-shadow outline-0 border-0"
                                placeholder="Select Model Year" formControlName="carAge">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type=checkbox [ngModel]="item$.selected"
                                        [ngModelOptions]="{standalone: true}" /> {{item.year | uppercase}}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type=checkbox [ngModel]="item$.selected"
                                        [ngModelOptions]="{standalone: true}" /> {{item.year}}
                                </ng-template>
                            </ng-select>
                        </div>
                    </form>

                    <div class="block text-center mt-8 ">
                        <button
                            class="rounded-full bg_primary pt-3 pb-3 lg:px-32 px-20  font-medium text-xl text-white  border border-transparent hover:border-[#004AAD] hover:bg-white hover:text-[#004AAD] "
                            (click)="submitForm()">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>