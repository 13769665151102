<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name" class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center" >
        <div class="container mx-auto  ">
            <div class="max-w-screen-sm	mx-auto z-50  flex-1 px-6 ">
                <div class="block text-center">
                    <h1 class="md:text-5xl text-4xl text-white font-bold ">Dashboard</h1>       
                </div>
            </div>
        </div>
    </div>
</div>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4 justify-between">
            <div class="px-4 w-full flex justify-end mb-8">
                <!-- <div class="bg-white rounded-md border border-primary-color md:px-6 md:py-4 px-4 py-3 text-[#3A3F4D] md:text-lg text-base inline-flex items-center"> -->
                 
                     <!-- <span>      
                         <input type="text" name="daterange" class="w-full px-4 md:pl-14 pl-10 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/date-icon-black.png)] bg-no-repeat	bg-[center_left_1rem] md:bg-[length:24px] bg-[length:18px]	!shadow-none outline-none cursor-pointer focus:ring-0 focus:ring-offset-0" value="" >
                    </span> -->
                    <span class="flex justify-between items-center gap-2">
                        <input type="text" name="daterange" placeholder="Select Date Range"
                            class="w-full px-4 md:pl-14 pl-10 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/date-icon-black.png)] bg-no-repeat	bg-[center_left_1rem] md:bg-[length:24px] bg-[length:18px]	!shadow-none outline-none cursor-pointer focus:ring-0 focus:ring-offset-0"
                            value="">
                            <button (click)="resetCarModelData()" title="reset" class="btn bg-blue-800 hover:bg-blue-700  btn-circle "> <i class="fa fa-refresh text-lg text-white" aria-hidden="true"></i> </button>
                    </span>
    
                <!-- </div> -->
            </div>   

            
            <app-responsive-sidebar></app-responsive-sidebar> 
            <div class="px-4 xl:w-9/12 lg:w-8/12 md:w-full w-full">
                <div class="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
                    <div class="relative bg-white p-6 rounded-md overflow-hidden shadow-3xl  bg-blend-multiply !bg-[url('assets/img/bg-pattern2.png')] bg-no-repeat bg-cover text-center" *ngFor="let item of subDealer">
                        <div class="profile rounded-full w-28 h-28 border-4 border-primary-color mx-auto overflow-hidden">
                            <img src="{{item.userImage ? item?.userImage  : 'assets/img/user_image_placeholder.png'}}" class="h-28 object-cover">
                        </div>
                        <div class="profile-name md:text-2xl text-xl font-semibold text-black my-2">{{item?.username}}</div>
                        <!-- <div class="profile-id text-[#3A3F4D] font-medium text-base mb-2">Id No. : 1526 8346 9933 4110</div> -->
                        <div class="user-rating flex items-center space-x-1 justify-center mb-2" >
                            <span *ngFor="let _ of [].constructor(5); let i = index">
                                <!-- <svg class="w-5 h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" [class]="calculateStarClass(i,item?.star)"/>
                                </svg> -->
                                <i [class]="calculateStarClass(i,item?.star)" aria-hidden="true"></i>

                            </span>
                        </div>
                        <div class="space-y-1 text-base text-gray-500 font-medium my-4">
                            <p> <span class="text-black"> Offers Made :</span> {{item?.offerMade}}</p>
                            <p> <span class="text-black"> Offers Won  :</span>{{item?.offerWon}}</p>
                            <p> <span class="text-black"> Offers Lost :</span> {{item?.offerLost}}</p>
                        </div>   
                    </div>
                </div> 
            </div> 
                  
        </div>
    </div>
</section>