<div class="grid xl:grid-cols-2  grid-cols-1 gap-x-8 gap-y-14">
    <div class="car-data-box sold relative" *ngFor="let item of cars">
        <div
        class="close-deal-badge bg-[url('assets/img/yellow-ribbon.png')] bg-no-repeat w-48 h-14 bg-contain absolute  top-10 -left-[10px] z-10 text-base text-black font-medium px-6 py-1.5 leading-normal	" *ngIf="currentRoute == 'offers' && item?.bidId == user?.id && item?.bidAmount < item?.maxBidAmount">
        Losing your Deal
      </div>
      <div class="close-deal-badge bg-[url('assets/img/red-ribbon.png')] bg-no-repeat w-48 h-14 bg-contain absolute  top-10 -left-[7px] z-10 text-lg text-white font-medium px-6 py-1.5"
      *ngIf="currentRoute == 'offers' && item?.bidId == user?.id && item?.bidAmount == item?.maxBidAmount && item?.timeUnit  == 'minutes'">
        About to close
    </div>
        <div
            class="data-img text-center rounded-md overflow-hidden relative after after:absolute after:w-full after:h-full after:top-0 after:left-0 after:opacity-20 after:bg-black md:grid md:place-items-center md:max-h-80 md:min-h-[20rem]">
            <img width="600" height="400" *ngIf="item?.imageUrl" src="{{ item?.imageUrl }}" alt="Car Name" class="inline-block w-auto h-[320px]" alt="car-no-name">
            <img  width="600" height="400" *ngIf="!item.imageUrl" src="../../../assets/img/car_palceholder.png" alt="Car Name"
                class="inline-block w-full">
        </div>
        <div class="md:px-4 px-4 -mt-14 relative z-10">
            <div class="car-data-details bg-white p-4 pb-8 shadow-xl rounded-lg">
                <div class="car-data-info sm:flex sm:flex-wrap sm:justify-between mb-3">
                    <div class="data-left sm:flex-1 pr-2">
                        <h3 class="text-xl font-medium mb-1 text-black">{{item?.modelYear}} {{item?.carName}} {{item?.modelName}}
                        </h3>
                        <div class="flex flex-wrap items-center text-base grey_text_500 font-medium ">
                            <span class="pr-2">{{item?.mileage}} Miles</span>
                            <span
                                class="relative pl-3 pr-2 inline-block before:absolute before:w-1 before:h-1 before:rounded-full before:bg-[#777777] before:left-0 before:top-[10px]">{{item?.color}}</span>
                            <span
                                class="relative pl-3 pr-2 inline-block before:absolute before:w-1 before:h-1 before:rounded-full before:bg-[#777777] before:left-0 before:top-[10px]">{{item?.fuelType}}</span>
                        </div>
                        <div class="location-details mt-1">
                            <p class="text-base grey_text_500 flex items-center"><img   alt="loading..."
                                    src="assets/img/location-small.png" class="inline-block mr-2">{{item?.city}} ,
                                {{item?.state}}</p>
                        </div>
                    </div>
                    <div
                        class="data-right sm:text-right text-left py-3 sm:py-0 border border-y-2 border-x-0 sm:border-0 sm:mt-0 mt-3">
                        <div class="price text-green md:text-2xl text-xl font-semibold leading-none">
                            ${{item?.sellingPrice}}</div>
                        <div class="timer-data text-lg font-semibold  sm:leading-none"
                            [ngClass]="{'text-red': item?.timeUnit == 'minutes', 'text-green': item?.timeUnit == 'hours'}">
                            <span *ngIf="!item?.bidWinnerId && item?.allImageTypesExist">
                                {{item?.remainingTime}} {{item?.timeUnit}} left</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isDealer">
                    <div class="dealers-detail-data text-base grey_text_500 font-medium mb-2 space-y-2">
                        <div class="dealer-name" *ngIf="item?.purchased"><span class="text-black"> Dealership Name
                                :</span> {{ item?.dealershipName }}
                            <span class="rating"><img src="assets/img/rating.png"
                                   alt="loading..." class="inline-block w-16 verticle-top leading-none"></span>
                        </div>
                        <div class="employer-name" *ngIf="item?.purchased"><span class="text-black"> Employee Name
                                :</span>
                            <span>{{ item?.employeeName }}</span>
                        </div>
                    </div>
                    <div class="status sold text-base font-medium text-black flex items-center">Status :
                        <span *ngIf="!item?.allImageTypesExist">
                            <span class="text-red-600 text-lg font-semibold px-2">Vehicle not listed </span>
                        </span>
                      
                        <span *ngIf="item?.allImageTypesExist">
                            <span class="text-green text-lg font-bold px-2" *ngIf="item.purchased == 1; else checkRejected" >PURCHASED </span>
                            <ng-template #checkRejected>
                              <span class="text-green text-lg font-bold px-2" *ngIf="item.rejected == 1; else checkBidWinner">REJECTED </span>
                            </ng-template> 
                            <ng-template #checkBidWinner>
                              <span class="text-green text-lg font-bold px-2" *ngIf="item.bidWinnerId > 0; else unSold">SOLD </span>
                            </ng-template>
                            <ng-template #unSold>
                              <span class="text-green text-lg font-bold px-2" *ngIf="item.isRelist==1 && !item?.bidWinnerId; else relisted">UNSOLD </span>
                            </ng-template>
                            <ng-template #relisted>
                              <ng-container *ngIf="(item.isRelist==0 && item.relistStatus==1); else inProgress ">RELISTED</ng-container>
                            </ng-template>
                            <ng-template #inProgress>
                              <span class="text-green text-lg font-bold px-2">In Progress</span>
                            </ng-template>
                        </span>
                          

                    </div>
                    <div class="car-update-data space-y-2 mt-2">
                        <div class="text-base text-black font-medium flex items-center">Car Images Updated :
                            <span class="checked inline-block ml-1" *ngIf="item?.allImageTypesExist">
                                <i class="fa-solid fa-square-check" style="color: green;font-size: 25px;"></i>
                            </span>
                            <span class="checked inline-block ml-1" *ngIf="!item?.allImageTypesExist" style="
                            color: red;">
                                <i class="fa fa-square-xmark mr-1" style="font-size: 25px;vertical-align: middle;"></i>
                                <!-- <span style="font-size: 14px;">Please upload all images</span> -->
                            </span>
                        </div>
                        <div class="text-base text-black font-medium flex items-center">Car Listed Successfully :
                            <span class="checked inline-block ml-1">
                                <i class="fa-solid fa-square-check" style="color: green; font-size: 25px;" *ngIf="!item.isRelist"></i>
                                <i class="fa fa-square-xmark mr-1" style="font-size: 25px; color: red;" *ngIf="item.isRelist"></i>
                              </span>
                        </div>
                        <div *ngIf="!item.allImageTypesExist" class="text-red-600">To successfully list your vehicle and start receiving offers, kindly upload all required images by clicking on the "Edit" button
                        </div>
                    </div>
                    <div class="action-button space-y-4 mt-6">
                        <div class="btn-box flex flex-wrap gap-4">
                            <a *ngIf="item.purchased"
                                class="bg-green rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer" (click)="showCustomer(item?.dealerId,item?.parentUserId)">View
                                Dealer Info</a>
                            <a class="bg-yellow rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4  text-center cursor-pointer"
                                *ngIf="!item.bidWinnerId" routerLink="/seller/edit-car/{{item?.id}}"><span
                                    class="mr-4"><i class="fa fa-pencil"></i></span>Edit </a>
                            <a routerLink="/dealer/view-car-info/{{item?.id}}" *ngIf="item?.purchased"
                                class="bg-green rounded-full border border-transparent text-base text-white block  py-2 flex-1 whitespace-nowrap px-4 text-center">View
                                Vehicle Info</a>
                            <a *ngIf="!item.rejected && !item.purchased && item?.allImageTypesExist" routerLink="/seller/offer/{{item?.id}}"
                                class="bg_primary rounded-full border border-transparent text-base text-white block  py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer">View
                                Vehicles Offers</a>

                            <a *ngIf="item?.bidWinnerId && !item.rejected && !item.purchased && item?.scheduleDate"
                                class="bg_primary rounded-full  border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer"
                                (click)="showSummary(item)">
                                {{item?.pickup>0 ? 'Pick-Up' : 'Drop-Off'}} Info</a>
                                <!-- <a routerLink="/dealer/view-car-info/{{item?.id}}"
                                class="bg-green rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center" *ngIf="item?.rejected == 1">View
                                Vehicle Info</a> -->
                        </div>
                        <div class="btn-box flex flex-wrap gap-4">
                            <a *ngIf="item.purchased"
                                class="bg_primary rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer"
                                (click)="showSummary(item)">{{item?.pickup>0 ? 'Pick-Up' : 'Drop-Off'}} Info</a>
                                <a *ngIf="item?.allImageTypesExist && item?.rejected!=0" routerLink="/seller/offer/{{item?.id}}"
                                class="bg_primary rounded-full border border-transparent text-base text-white block  py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer">View
                                Vehicles Offers</a>
                            <a *ngIf="item.purchased" routerLink="/seller/rating/{{item?.dealerId}}"
                                class="bg-yellow rounded-full border border-transparent text-base text-black block  py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer">Rate
                                the Dealer</a>
                            <a class="bg-green rounded-full border border-transparent text-base text-white block  py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer"
                                (click)="reslistCar(item?.id,item?.isRelist)" *ngIf="item?.remainingTime == 0 && !item?.bidWinnerId && item?.allImageTypesExist">Relist
                                your vehicle</a>
                                <a *ngIf="item.bidWinnerId && !item.purchased && item?.rejected == 0"
                                class="bg-green rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer" (click)="showCustomer(item?.dealerId,item?.parentUserId)">View
                                Dealer Info</a>
                                <a routerLink="/seller/view-car-info/{{item?.id}}"
                                class="bg-green rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center" *ngIf="item?.rejected == 1">View
                                Vehicle Info</a>
                                <a *ngIf="item?.rejected == 1"
                                class="bg_primary rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer"
                                (click)="showRejected(item)">Rejection Information</a>

                        </div>
                    </div>
                </div>
               
                    <div class="text-base grey_text_500 w-full inline-block" *ngIf="(item.rejectionRequest==1 || item.rejectionStatus==1) && isDealer">
                        <span class="text-black mr-2">Status:</span>
                          <span class="text-red-600 text-lg font-semibold px-2">Rejection {{item.rejectionRequest==1 ? 'Pending' :'Disapproved'}} </span>
                        </div>
                <div class="text-base grey_text_500 md:w-6/12  w-full inline-block">
                    <span *ngIf="isDealer && item?.maxBidAmount">

                        <span class="text-black mr-2">Highest Offer: </span><span class="text-green font-semibold">
                            ${{item?.maxBidAmount}}</span>
                    </span>
                </div>
                <div class="text-base grey_text_500 md:w-6/12 w-full inline-block" *ngIf="isDealer && item?.maxBidAmount && currentRoute!='car-list-new' && currentRoute!= 'dealer-preferred-list'">
                    <span class="text-black mr-2">My Offer: </span>
                    <span class="text-green font-semibold">${{ item?.bidAmount }}</span>
                </div>

                <div class="text-base grey_text_500 md:w-6/12 w-full inline-block"
                    *ngIf="item?.purchaseAmount && currentRoute == 'car-purchased'">
                    <span class="text-black">Purchased Amount:</span>
                    <span>${{ item?.purchaseAmount }}</span>
                </div>
                <!-- <div class="text-base grey_text_500  w-full" *ngIf="isDealer && item?.sellingPrice > 0">
                    <span class="text-black">Customer's Expected Offer : </span>
                    <span class="text-green font-semibold">${{ item?.sellingPrice }}</span>
                  </div> -->
                  <div *ngIf="((currentRoute != 'car-list-new' && currentRoute != 'dealer-preferred-list') || (!isDealer && item.purchased)) && item?.dealershipName">
                    <div class="text-base grey_text_500 w-full mt-1">
                        <span class="text-black ">Dealership Name: </span>
                        <span class="text-green font-semibold">{{ item?.dealershipName }}</span>
                    </div>
                    <div class="text-base grey_text_500  w-full mt-1">
                        <span >
                            <span class="text-black">Employee Name: </span><span class="text-green font-semibold">
                                {{item?.employeeName}}</span>
                        </span>
                    </div>
                </div>
                <div class="action-button space-y-4 mt-6"
                    *ngIf="isDealer && !schedulePage && currentRoute != 'car-purchased'">
                    <div class="btn-box flex flex-wrap gap-4">
                        <a routerLink="/dealer/car-history/{{item?.id}}"
                            class="bg-orange rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center"
                            *ngIf="currentRoute == 'offers' || currentRoute == 'won-offers'">History</a>
                        <a routerLink="/dealer/view-car-info/{{item?.id}}"
                            class="bg-green rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center" *ngIf="currentRoute != 'car-rejected' && item?.rejected !=1">View
                            Vehicle Info</a>
                            <a class="bg-green rounded-full py-2 text-white text-center block cursor-pointer flex-1 whitespace-nowrap px-4"
                            (click)="showCustomer(item?.userId,0)" *ngIf="currentRoute== 'won-offers'">View Customer Info</a>
                        <a class="bg_primary rounded-full border border-transparent text-base text-white block  py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer"
                            *ngIf="currentRoute != 'won-offers' && !item?.bidCompleted"
                            (click)="showBidModel(item)">{{item?.dealerId == user?.id ? 'Update Offer' : 'Offer Now'}}</a>
                    </div>

                </div>
                <div class="action-button space-y-4 mt-6"
                    *ngIf="isDealer && !schedulePage && currentRoute != 'car-purchased'">
                    <div class="btn-box flex flex-wrap gap-4">

                        <a *ngIf="item?.bidWinnerId && currentRoute != 'car-rejected'"
                            class="bg_primary rounded-full  border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer"
                            (click)="showPickDropModel(item)">Schedule
                            pick/drop-off</a>
                            <a routerLink="/dealer/view-car-info/{{item?.id}}"
                            class="bg-green rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center" *ngIf="item?.rejected == 1">View
                            Vehicle Info</a>
                        <a *ngIf="currentRoute == 'car-rejected'"
                            class="bg_primary rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer"
                            (click)="showRejected(item)">Rejection Information</a>
                    </div>

                </div>
                <div class="action-button space-y-4 mt-6" *ngIf="isDealer && schedulePage">
                    <div class="btn-box flex flex-wrap  gap-4">
                        <a routerLink="/dealer/car-history/{{item?.id}}"
                            class="bg-orange rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center">History</a>
                        <a routerLink="/dealer/view-car-info/{{item?.id}}"
                            class="bg-green rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center">View
                            Vehicle Info</a>
                    </div>
                    <div
                        class="btn-box flex flex-wrap gap-4 cursor-pointer">
                        <a class="bg-green rounded-full border border-transparent text-base text-white block py-2  flex-1 whitespace-nowrap px-4 text-center"
                            (click)="showCustomer(item?.userId,0)">View Customer Info</a>
                        <a *ngIf="currentRoute == 'schedule-car-pickup'  || currentRoute == 'schedule-car-dropoff'"
                            (click)="showSummary(item)"
                            class="bg_primary rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center">Vehicles
                            {{currentRoute == 'schedule-car-pickup' ? 'Pick-Up' : 'Drop-Up'}} Info</a>
                        <a class="bg_primary rounded-full py-2 text-white block cursor-pointer flex-1 whitespace-nowrap px-4 text-center"
                            (click)="showPickDropModel(item)" *ngIf="!item.rejectionStatus">Update Vehicle {{item.pickup==1 ? 'Pick-Up' : 'Drop-Up'}} Info</a>
                        <button class="bg_primary rounded-full border border-transparent text-base text-white block py-2  flex-1 whitespace-nowrap px-4 text-center"
                        [disabled]="item?.rejected"
                            *ngIf="(currentRoute == 'car-overview') && !item.rejectionStatus" (click)="updateStatus(item)">Update Vehicle Status</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-bid></app-bid>
<app-popup></app-popup>
<app-user-info></app-user-info>
<app-update-car-status></app-update-car-status>
<app-schedule-pick-drop ></app-schedule-pick-drop>