import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, catchError, of, throwError } from 'rxjs';
import { apiUrl } from './global.variables';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public user = new Subject<any>()
  public vinCarData = new Subject<any>()
  public CarData = new Subject<any>();
  public paymentType = new Subject<any>();
  public bidData = new Subject<any>();
  public walletData =new Subject<any>();
  public pickDrop = new Subject<any>();
  public customerData  = new Subject<any>();
  public summary = new Subject<any>();
  public carStatus = new Subject<any>();
  public showMessage = new Subject<any>();

  public webUrl = apiUrl.replace('api/', '');

  constructor(private http: HttpClient, public spinner: NgxSpinnerService, private toastr: ToastrService,  @Inject(PLATFORM_ID) private platformId: any) { }


  isBrowserPlateformId(){
    return isPlatformBrowser(this.platformId) ? true :false;
  }
  //post api function
  postApi(endPointUrl: any, data: any) {
  
    var httpHeaders;
    httpHeaders = {
      headers: new HttpHeaders({
        // 'Content-Type' : 'application/x-www-form-urlencoded',
        // 'enctype':'multipart/form-data',
        authorization: `Bearer ${this.isBrowserPlateformId() ? localStorage.getItem('token'): ''}`,
      })
    }
    return this.http.post(apiUrl + endPointUrl, data, httpHeaders);
  }

  //get api function
  getApi(endPointUrl: any) {
    var httpHeaders;
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': `Bearer ${this.isBrowserPlateformId() ? localStorage.getItem('token'): ''}`
      })
    }
    return this.http.get(apiUrl + endPointUrl, httpHeaders)

  }

  licenseApi(endPointUrl: any) {
    var httpHeaders;
    httpHeaders = {
      headers: {
        'X-RapidAPI-Key': 'b0c263d578msh486214ea62efab5p17b68djsn935061312c35',
        'X-RapidAPI-Host': 'apibroker-license-plate-search-v1.p.rapidapi.com'
      }

    }
    return this.http.get(endPointUrl, httpHeaders)
  }
  vinApi(endPointUrl: any) {

    return this.http.get(endPointUrl)
  }

  //to upload files/image
  uploadfiles(endPointUrl: any, data: any) {
    var httpHeaders;

    httpHeaders = {
      headers: new HttpHeaders({
        'authorization': `Bearer ${this.isBrowserPlateformId() ? localStorage.getItem('token'): ''}`
      })
    }
    return this.http.post(apiUrl + endPointUrl, data, httpHeaders)
  }
  apiFunction(path: any) {
    const headers = new HttpHeaders().set('X-CSCAPI-KEY', 'aEFoZkU1R3l0QlpmNUpmRllqVU9WZ0JDV09XaWQ0WjZJTW9td0hhUw==')
    return this.http.get(path, { headers })
  }
  showSpinner() {
    this.spinner.show();
  }
  hideSpinner() {
    this.spinner.hide();
  }
  showSuccess(message: string) {
    let data = {
      status:1,
      message : message
    }
    this.showMessage.next(data)
    // this.toastr.success(message, undefined, {
    //   positionClass: 'toast-top-center',
    //   closeButton: true,
    // });
  }
  showConfirmModal(message:string){
        let data = {
      status:1,
      message : message
    }
    this.showMessage.next(data)
  }
  showError(message: string) {
    let data = {
      status:0,
      message : message
    }
    this.showMessage.next(data)
   
  }
  tempErrormessage(message:string){
     this.toastr.error(message, undefined, {
      positionClass: 'toast-top-center',
      enableHtml: true,
    })
  }

  public isLoggedIn() {
    console.log("token found ",localStorage.getItem('token') ? true : false);
    return localStorage.getItem('token') ? true : false
  }
  //accept only numbers in input
  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  // getAllMakeApi() {
  //   return this.http.get('https://vpic.nhtsa.dot.gov/api/vehicles/GetMakesForVehicleType/car?format=json')
  // }
  getAllMakeApi() {
    var httpHeaders;
    httpHeaders = {
      headers: {
        'X-RapidAPI-Key': 'b0c263d578msh486214ea62efab5p17b68djsn935061312c35',
        'X-RapidAPI-Host': 'car-api2.p.rapidapi.com'
      }

    }
    return this.http.get(`https://car-api2.p.rapidapi.com/api/makes?direction=asc&sort=name`, httpHeaders)
  }

  //get model by make Id 
  getModelByMakeName(make:string) {
    var httpHeaders;
    httpHeaders = {
      headers: {
        'X-RapidAPI-Key': 'b0c263d578msh486214ea62efab5p17b68djsn935061312c35',
        'X-RapidAPI-Host': 'car-api2.p.rapidapi.com'
      }

    }
    return this.http.get(`https://car-api2.p.rapidapi.com/api/models?make=${make}&sort=name&direction=asc`,httpHeaders)
  }

  //get model by make and year
  getModelByMake(make:string,year:number) {

    return this.http.get(`https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeYear/make/${make}/modelyear/${year}?format=json`)
  }

  zipCodeApi(city:string,state:string){
    return this.http.get(`https://www.zipcodeapi.com/rest/js-B6J36oAta21Ur1xPoesiu7cTGBeVakqQ3d29H8eJpavXwefpORJqoIq3ICTs6O86/city-zips.json/${city}/${state}`)
  }

  getStateCityByZipCode(zipCode:number){
    return this.http.get(`https://www.zipcodeapi.com/rest/js-B6J36oAta21Ur1xPoesiu7cTGBeVakqQ3d29H8eJpavXwefpORJqoIq3ICTs6O86//info.json/${zipCode}/degrees`)
  }

  //scrollToTop
  scrollToTop(){
    if (isPlatformBrowser(this.platformId)){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
}




}