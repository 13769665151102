import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  public showEye = false
  public resetForm: any
  public userId: any
  public token: any
  public userData: any;
  private regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-.\/:;\\<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-.\/:;\\<=>?@[\]^_`{|}~]{8,}$/

  constructor(private fb: FormBuilder, private service: CommonService, private router: Router, private activateRoute: ActivatedRoute) {
    this.activateRoute.params.subscribe((res: any) => {
      this.userId = res.user_id;
      this.getUserById();
    })
  }

  getUserById() {
    this.service.showSpinner();
    this.service.getApi(`users/get-user/${this.userId}`).subscribe((res: any) => {
      if (res.status == 200) {
        this.userData = res.user;
        this.service.hideSpinner()
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }


  ngOnInit(): void {
    this.resetForm = this.fb.group({
      password: ['', [Validators.required, Validators.pattern(this.regx), Validators.compose([
        this.lowercaseValidator,
        this.uppercaseValidator,
        this.digitValidator,
        this.specialCharacterValidator,
        this.lengthValidator,
      ])]],
      confirm_password: ['', [Validators.maxLength(255), Validators.minLength(8)]]
    })
  }

  get f() {
    return this.resetForm.controls
  }
  resetPassword() {
    let url = 'users/reset-password'
    let data = {
      userId: this.userId,
      password: this.resetForm.value.password,
      oldPassword: this.userData.password
    }
    this.service.showSpinner()
    this.service.postApi(url, data).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.service.user.next(res.user)
        let localUserData = {
          id: res.user.id,
          parentUserId: res.user.parentUserId,
          username: res.user.username,
          email: res.user.email,
          phone:res.user.phone,
          city: res.user.city,
          state: res.user.state,
          country: res.user.country,
          zipCode: res.user.zipCode,
          isAdminLoggedIn: 0,
          role:res.user.role
        }
        if(this.service.isBrowserPlateformId()){
          localStorage.setItem('user', `${JSON.stringify(localUserData)}`)
          localStorage.setItem('token', res.user.token);
        }
        this.service.hideSpinner()
        this.service.showSuccess(res['message'])
        if (!this.userData.password) {
          this.router.navigate(['dealer/dealer-performance'])
        }
        else {
          this.router.navigate(['dealer/car-list-new'])
        }
      }
      else {
        this.service.hideSpinner()
        this.service.showError(res['message'])
      }
    })
  }


  showHidePassword() {
    this.showEye = !this.showEye
  }

  // Custom validator for at least one lowercase letter
  lowercaseValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !/[a-z]/.test(value)) {
      return { 'lowercase': true };
    }
    return null;
  }

  // Custom validator for at least one uppercase letter
  uppercaseValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !/[A-Z]/.test(value)) {
      return { 'uppercase': true };
    }
    return null;
  }

  // Custom validator for at least one digit
  digitValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !/[0-9]/.test(value)) {
      return { 'digit': true };
    }
    return null;
  }

  // Custom validator for at least one special character
  specialCharacterValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !/[#?!@$%^&*-]/.test(value)) {
      return { 'special': true };
    }
    return null;
  }

  // Custom validator for password length between 7 and 16 characters
  lengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && (value.length < 7 || value.length > 16)) {
      return { 'length': true };
    }
    return null;
  }
}
