import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public sell_car_promo_message = '';
  public trusted_sellers_count = '';
  public lazyLoadBackground = false;

  constructor(private service: CommonService) {}

  ngOnInit(): void {
    this.service.scrollToTop();
    this.loadHomePageData();
  }

  private loadHomePageData(): void {
    this.service.showSpinner();
    this.service.getApi('homes/getHome').subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.sell_car_promo_message = res.home[0].sell_car_promo_message;
          this.trusted_sellers_count = res.home[0].trusted_sellers_count;
        }
        this.service.hideSpinner();
      },
      error: () => {
        this.service.hideSpinner();
      }
    });
  }
}
