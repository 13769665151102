<input type="checkbox" class="openSidebarMenu" id="openSidebarMenu">
<label for="openSidebarMenu" class="sidebarIconToggle">
    <div class="spinner diagonal part-1"></div>
    <div class="spinner horizontal"></div>
    <div class="spinner diagonal part-2"></div>
</label>
<!-- <div class="z-2  w-full px-4 mb-10 md:mb-0 hidden md:block"
    id="sidebar_menu"> -->
    <div class="z-2 xl:w-3/12 min-[993px]:w-5/12 w-full px-4 mb-10 min-[992px]:mb-0 hidden lg:contents"
    id="sidebar_menu">
    <!-- -->
    <app-sidebar></app-sidebar>
</div>