<div id="schedule-way" tabindex="-1" aria-hidden="true" class="justify-center items-center	hidden  fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75" [ngStyle]="{display: showModal}">
    <div class="relative w-full max-w-md max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow">
            <!-- Modal header -->
            <div class="flex items-start justify-center p-4 rounded-t-lg bg_primary">
                <h3 class="text-2xl font-medium text-white text-center secondary-font ">
                    Select Your Schedule Way
                </h3>
            </div>
            <!-- Modal body -->
            <div class="p-10 flex items-center justify-center">
                <div class="flex items-center custom-radio-blue mr-8">
                  <input id="default-radio-2" type="radio" value="0" name="default-radio" [(ngModel)]="selectedOption">
                  <label for="default-radio-2" class="ml-2 text-lg font-medium text-black custom-label cursor-pointer">Pick Up</label>
                </div>
                <div class="flex items-center custom-radio-blue">
                  <input id="default-radio-1" type="radio" value="1" name="default-radio" [(ngModel)]="selectedOption">
                  <label for="default-radio-1" class="ml-2 text-lg font-medium text-black custom-label cursor-pointer">Drop Off</label>
                </div>
              </div>

            <!-- Modal footer -->
            <div class="flex justify-center  pb-8 ">
                <button type="button" class="text-black  font-medium rounded-full  md:text-xl text-lg px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD] mr-2" (click)="hideModal()" >Cancel</button> 
                <button type="button" class="text-white bg_primary font-medium rounded-full  text-xl px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD]" (click)="showpickDropModal()">Submit</button>                
            </div>
        </div>
    </div>
</div>


<div id="schedule-pickdrop" tabindex="-1" aria-hidden="true" class="justify-center items-center hidden  fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75" [ngStyle]="{display: showDropOffModal}">
    <div class="relative w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow  bg-popup secondary-font">
            <!-- Modal header -->
            <div class="flex items-start justify-center p-4 rounded-t-lg bg_primary">
                <h3 class="text-2xl	 font-medium text-white text-center secondary-font">
                    Schedule Summary
                </h3>
            </div>
            <!-- Modal body -->
            <div class="p-6">
                <div class="agency-head flex items-center justify-between mb-4">
                    <div class="agency-info-pickup">
                        <h3 class="dealer-agency-name font-semibold	text-xl leading-none m-0 secondary-font">{{user?.companyName}}</h3>
                        <p class="agency-owner-name font-medium  text-base">{{user?.dealershipName}}</p>
                    </div>
                    <div class="right">
                        <div class="rounded-full md:px-8 px-4 py-2 bg-[#979797] text-white text-lg cursor-pointer	">Drop Off</div>
                    </div>
                </div>
                <div class="schedule-detail">
                    <p class="flex items-center mb-4 text-base text-[#3A3F4D]"> <img alt="car image" src="assets/img/date-icon.png" class="mr-3"> {{scheduleDate | date: 'd MMMM y'}}</p>
                    <p class="flex items-center mb-4 text-base text-[#3A3F4D]"> <img alt="car image" src="assets/img/time-icon.png" class="mr-3"> {{scheduleTime | date: 'h mm a'}}</p>
                    <p class="flex items-center text-base text-[#3A3F4D]"> <img alt="car image" src="assets/img/location-icon.png" class="mr-3">{{user?.city}}, {{user?.state}}, United State, {{user?.zipCode}}</p>
                </div>
            </div>
            <!-- Modal footer -->
            <div class="flex justify-center  pb-8 ">
                <button type="button" class="text-black  font-medium rounded-full  md:text-xl text-lg px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD] mr-2" (click)="hideModal()" >Cancel</button> 
                <button type="button" class="text-white bg_primary font-medium rounded-full  text-xl px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD]" (click)="onSubmit()">Confirm</button>                
            </div>
        </div>
    </div>
</div>


<!-- Pickdrop Location Auto -->
<div id="autolocation-pickdrop" tabindex="-1" aria-hidden="true" class="justify-center items-center hidden  fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75" [ngStyle]="{display: showLocationModal}">
    <div class="relative w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow bg-popup secondary-font">           
            <!-- Modal body -->
            <div class="p-6">
                <div class="agency-head flex items-center justify-end mb-4">                    
                    <div class="right">
                        <div class="rounded-full md:px-8 px-4 py-2 bg-[#979797] text-white text-lg cursor-pointer	">{{selectedOption ? 'Pickup' :'Drop Off' }}</div>
                    </div>
                </div>
                <div class="location-auto-genrate text-center -mt-10 mb-0">
                    <div class="icon text-center mb-6"><img alt="car image" src="assets/img/auto-location.png" class="inline-block"></div>
                    <div class="location-info">
                        <p class="text-[#626262] text-sm">{{locationAddress}}</p>
                    </div>
                </div>
            </div>
            <!-- Modal footer -->
            <!-- <div class="flex justify-center mb-8">
                <button type="button" class="text-white  font-medium rounded-full  md:text-xl text-lg px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD]" >Cancel</button>                
            </div>    -->
            <div class="flex justify-center  pb-8 ">
                <button type="button" class="text-black  font-medium rounded-full  md:text-xl text-lg px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD] mr-2" (click)="hideModal()" >Cancel</button> 
                <button type="button" class="text-white bg_primary font-medium rounded-full  text-xl px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD]" (click)="onSubmit()">Confirm</button>                
            </div>
        </div>
    </div>
</div>

<!-- calendar modal -->
<div id="calendar-modal" tabindex="-1" class="flex justify-center items-center  fixed top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75" [ngClass]="{'flex': showCalendar, 'hidden': !showCalendar, 'z-50': increaseZ, '-z-10': !increaseZ}">
    <div class="relative w-full max-w-6xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow  secondary-font lg:min-w-[55rem]">           
            <!-- Modal body -->
            <div class="p-6">
                <div class="agency-head flex items-center justify-end mb-4">                    
                    <div class="right">
                        <div class="rounded-full md:px-10 px-8 py-2 bg-[#979797] text-white text-lg cursor-pointer	">{{selectedOption == '0' ? 'Pickup' :'Drop Off' }}</div>
                    </div>
                </div>
                <div class="md:flex">
                    <div class="md:w-6/12 md:pr-6 pb-8 md:border-r-2 md:border-b-0 border-b-2 border-[#F1F5FD]">
                        <div class="agency-info-head py-4 px-2 border-b-2 mb-4 border-[#F1F5FD]">
                            <h3 class="font-semibold text-xl text-primary">{{selectedCar?.dealershipName }}</h3>
                            <p class="text-base font-medium text-black uppercase">{{selectedCar?.employeeName }}</p>
                        </div>
                        <div class="grid md:grid-cols-2 sm:grid-cols-3 grid-cols-2 gap-x-6 gap-y-4 text-center overflow-y-auto max-h-[336px]">
                            <div
                              *ngFor="let timeSlot of timeSlots; let i = index"
                              [class.active]="i == selectedTimeSlot"
                              class="border border-theme rounded-lg p-3 hover:border-hover hover:border-dashed text-dark md:text-lg text-base hover:text-primary"
                              (click)="selectTimeSlot(i,timeSlot)"

                            >
                              {{ timeSlot | date:'h:mm a' }}
                            </div>
                          </div>
                    </div>
                    <div class="md:w-6/12">
                       <div class="text-xl px-4 font-semibold text-[#062E50]">Select a Date & Time</div>
                        <full-calendar [options]="calendarOptions"></full-calendar>         
                        <div class="flex justify-end mb-8">
                            <button type="button" class="text-black  font-medium rounded-full  md:text-xl text-lg px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD] mr-2" (click)="hideModal()" >Cancel</button> 
                            <button type="button" class="text-white bg_primary font-medium rounded-full  md:text-xl text-lg px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD]" (click)="showLocation()">Submit</button>                
                        </div>   
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- <full-calendar  [options]="calendarOptions"></full-calendar>             -->
