<div class="grid lg:grid-cols-2 lg:grid-flow-col grid-flow-row ">
    <section class="main-section bg-cover overflow-hidden pt-6 md:pt-0 md:h-screen " style="background-image: url('../../../assets/img/home-banner1.avif');">
        <div class="container  mx-auto">
            <div class="flex flex-wrap relative z-10">
                <div class=" max-w-full md:pt-6 md:pb-6 ">
                    <div class="logo mx-4 w-6/12 lg:w-3/5 sm:w-6/12 px-4 cursor-pointer" [routerLink]="['/']" >
                        <img height="100" width="100" loading="lazy" src="assets/icons/logo.svg" alt="logo">
                    </div>
                    <div class="left-block flex flex-wrap justify-center md:py-0 py-36  md:h-screen">
                        <div class="signup-block md:px-20 px-4 m-auto">
                            <div class="switch-connect md:mb-10 mb-10">
                                <ul class="flex items-center justify-center">
                                    <li class="rounded-full mt-0 pt-1 pb-1 pl-10 pr-10 ml-1 text-xl font-semibold	text-white cursor-pointer hover:bg-white hover:text-[#004AAD]"
                                        (click)="toggle(true)"
                                        [ngClass]="{'bg-white text_primary_color': is_dealer== false}" >
                                        Seller</li>
                                    <li class="rounded-full hover:bg-white hover:text-[#004AAD]  pt-1 pb-1 pl-10 pr-10  font-semibold  text-white text-xl  cursor-pointer mx-5 "
                                        [ngClass]="{'bg-white text_primary_color': is_dealer== true}"
                                        (click)="toggle(false)">
                                        Dealer</li>
                                </ul>
                            </div>
                            <h2 class="text-center text-white md:text-5xl text-4xl mb-7">Come Join Us</h2>
                            <p class="text-center text-white md:text-lg text-sm" *ngIf="!is_dealer">Your vehicle deserves
                                the best buyer – join Kargone to find them! Our platform offers a seamless way to sell
                                your vehicle online. Register today, list your vehicle, and connect with potential buyers.
                                Your journey to a successful sale begins here!</p>
                            <p class="text-center text-white md:text-lg text-sm" *ngIf="is_dealer">Calling all Auto
                                Dealers! Expand your inventory effortlessly by registering on Kargone. Unlock a diverse
                                selection of pre-owned cars from private sellers waiting to be part of your showroom.
                                Don't miss out – sign up today and revolutionize your sourcing!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="">
        <div class="bg-white max-w-full py-8 p-6 scrollable-content right-block-signup">
            <div class="right-block">
                <div class="heading text-center mb-8">
                    <h1 class="md:text-5xl text-4xl font-bold text_primary_color">Create an Account</h1>
                    <p class="md:text-lg text-sm font-semibold text_primary_color">To continue, create a Kargone
                        account.</p>
                </div>

                <form class="md:px-7 px-2 md:flex md:flex-wrap" [formGroup]="registrationForm">
                    <div class="mx-auto w-full text-center  mb-4 grid justify-center relative" *ngIf="is_dealer">
                        <div class="w-44 h-44 relative">
                            <!-- Image Preview -->
                            <img *ngIf="imagePreview" [src]="imagePreview" class="w-44 h-44 rounded-full absolute"
                                alt="Uploaded Image">
                            <img *ngIf="!imagePreview" src="../../../assets/img/avatar-profile.png"
                                class="w-44 h-44 rounded-full absolute" alt="Uploaded Image">
                            <!-- File Input Trigger -->
                            <label for="dropzone-file"
                                class="w-44 h-44 group   rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500">
                                <!-- Image Icon (replace with your own icon) -->
                                <img src="https://www.svgrepo.com/show/33565/upload.svg" alt="Upload Icon"
                                    class="hidden w-12 group-hover:block" *ngIf="!imagePreview">
                                <!-- File Input (hidden) -->
                                <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/gif, image/jpeg"
                                    (change)="handleImageUpload($event)">
                            </label>
                        </div>

                    </div>
                    <label class="block mb-6 md:w-6/12 md:px-4">
                        <input type="text" name="firstName" placeholder="First Name"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="firstName" />
                        <div *ngIf="f['firstName'].invalid && (f['firstName'].dirty || f['firstName'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['firstName'].errors?.['required']">*First Name is required</div>
                            <div *ngIf="f['firstName'].errors?.['pattern']">*Invalid first name format</div>
                        </div>
                    </label>
                    <label class="block mb-6 md:w-6/12 md:px-4">
                        <input type="text" name="lastName" placeholder="Last Name"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="lastName" />
                        <div *ngIf="f['lastName'].invalid && (f['lastName'].dirty || f['lastName'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['lastName'].errors?.['required']">*Last Name is required</div>
                            <div *ngIf="f['lastName'].errors?.['pattern']">*Invalid last name format</div>
                        </div>
                    </label>
                    <label class="block mb-6 md:w-6/12 md:px-4" *ngIf="is_dealer ">
                        <input type="text" name="dealershipName" placeholder="Dealership Name"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="dealershipName" />
                        <div *ngIf="f['dealershipName'].invalid && (f['dealershipName'].dirty || f['dealershipName'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['dealershipName'].errors?.['required']">*Dealership Name is required</div>
                            <div *ngIf="f['dealershipName'].errors?.['pattern']">*Invalid Dealership Name format</div>
                        </div>
                    </label>
                    <label class="block mb-6 md:w-6/12 md:px-4" *ngIf="is_dealer ">
                        <input type="text" name="companyName" placeholder="Company Name"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="companyName" />
                        <div *ngIf="f['companyName'].invalid && (f['companyName'].dirty || f['companyName'].touched) || isSubmit"
                            class="error-message">
                            <div *ngIf="f['companyName'].errors?.['required']">*Company Name is required</div>
                            <div *ngIf="f['companyName'].errors?.['pattern']">*Invalid Company Name format</div>
                        </div>
                    </label>
                    <label class="block md:w-2/5 md:pl-4 text-center" *ngIf="is_dealer ">
                        <input type="file" accept="image/*,.pdf" style="display: none;"
                            (change)="uploadLicense($event)" />
                        <span
                            class="mt-1 md:h-14 h-12 block w-full bg-[#014BAE] md:px-8 px-4 md:text-base text-sm input-shadow text-[#ffffff] md:py-4 py-4 license"
                            style="cursor: pointer;">
                            Upload
                        </span>

                    </label>
                    <label class="block  md:w-3/5 md:pr-4" *ngIf="is_dealer ">
                        <input type="text" name="carMonth" placeholder="License Certificate"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-r-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            readonly [(ngModel)]="licenseName" [ngModelOptions]="{standalone: true}" />
                    </label>
                    <label class="block  md:w-3/5 md:pr-4 md:ml-4" *ngIf="is_dealer ">
                        <div *ngIf="(f['license'].invalid && (f['license'].dirty || f['license'].touched || isSubmit))"
                            class="error-message">
                            *License certificate is required
                        </div>
                    </label>
                    <label class="block mt-6 mb-6 w-full md:px-4" *ngIf="is_dealer ">
                        <input type="text" name="carMonth" placeholder="License Number"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="licenseNumber" />
                        <div *ngIf="f['licenseNumber'].invalid && (f['licenseNumber'].dirty || f['licenseNumber'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['licenseNumber'].errors?.['required']">*Please enter license number</div>
                        </div>
                    </label>
                    <label class="block mb-6 w-full md:px-4" *ngIf="is_dealer ">
                        <input type="text" name="carMonth" placeholder="How many used vehicles do you buy in a month?"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            (keypress)="numberOnly($event)" formControlName="carBuyMonthly" />
                        <div *ngIf="f['carBuyMonthly'].invalid && (f['carBuyMonthly'].dirty || f['carBuyMonthly'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['carBuyMonthly'].errors?.['required']">*Number of used car in a month is
                                required</div>
                        </div>
                    </label>
                    <label class="block mb-6 md:w-full md:px-4" *ngIf="is_dealer ">
                        <!-- <input type="text" name="carMonth" placeholder="What is your dealership type?"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="dealerType" /> -->

                        <select
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  grey_text_500 placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="dealerType" (change)="selectGroup($event)">
                            <option selected value="" disabled>What is your dealership type?</option>
                            <option value="group">Group</option>
                            <option value="independent">Independent</option>
                        </select>

                    </label>
                    <label class="block mb-6 md:w-full md:px-4" *ngIf="isGroupSelected">
                        <input type="text" name="carMonth" placeholder="How many dealership are there in your group?"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            (keypress)="numberOnly($event)" formControlName="dealerGroup" />

                    </label>
                    <label class="block mb-6 md:w-full md:px-4">
                        <input type="text" name="addressLine1" placeholder="Address Line 1"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="addressLine1" />
                        <div *ngIf="f['addressLine1'].invalid && (f['addressLine1'].dirty || f['addressLine1'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['addressLine1'].errors?.['required']">*Address Line 1 is required</div>
                        </div>
                    </label>
                    <label class="block mb-6 md:px-4" [ngClass]="{'md:w-6/12': is_dealer, 'md:w-full': !is_dealer}">
                        <input type="text" name="addressLine2" placeholder="Address Line 2"
                            class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="addressLine2" />
                    </label>
                    <label class="block mb-6 md:w-6/12 md:px-4">
                        <!-- <select
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md grey_text_500 placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="country" >
                            <option selected value="" disabled>Country</option>
                            <option *ngFor="let item of countryData" [value]="item?.iso2
                                ">{{item?.name}}</option>

                        </select> -->
                        <input type="text" name="firstName"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="country" readonly />
                        <div *ngIf="f['country'].invalid && (f['country'].dirty || f['country'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['country'].errors?.['required']">*Please select country</div>
                        </div>
                    </label>
                    <label class="block mb-6 md:w-6/12 md:px-4">
                        <input type="text" name="zipCode" placeholder="Zip Code"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="zipCode" (input)="getStateCity($event)" />
                        <!-- <select
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md grey_text_500 placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="zipCode">
                            <option selected value="" disabled>Zip Code</option>
                            <option *ngFor="let item of zipCode" [value]="item
                                ">{{item}}</option>

                        </select> -->
                        <div *ngIf="f['zipCode'].invalid && (f['zipCode'].dirty || f['zipCode'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['zipCode'].errors?.['required']">*Zip code is required</div>
                        </div>
                    </label>
                    <label class="block mb-6 md:w-6/12 md:px-4">
                        <!-- <select
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  grey_text_500 placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="state" >
                            <option selected value="" disabled>State</option>
                            <option *ngFor="let item of stateData" [value]="item?.iso2">{{item.name}}</option>

                        </select> -->
                        <input type="text" name="state" placeholder="State" [(ngModel)]="selectedState" [ngModelOptions]="{standalone: true}"
                        class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                         readonly />
                        <div *ngIf="f['state'].invalid && (f['state'].dirty || f['state'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['state'].errors?.['required']">*Please select state</div>
                        </div>
                    </label>

                    <label class="block mb-6 md:w-6/12 md:px-4">
                        <!-- <select
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  grey_text_500 placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="city" (change)="getZipCode($event)">
                            <option selected value="" disabled>City</option>
                            <option *ngFor="let item of cityData" [value]="item?.name">{{item.name}}</option>

                        </select> -->
                        <input type="text" name="city" placeholder="City"
                        class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                        formControlName="city" readonly/>


                        <div *ngIf="f['city'].invalid && (f['city'].dirty || f['city'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['city'].errors?.['required']">*Please select city</div>
                        </div>
                    </label>
                  
                    <label class="block mb-6 md:w-6/12 md:px-4" *ngIf="is_dealer">
                        <input type="text" name="regNumber" placeholder="Tax Id"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="regNumber" />
                        <div *ngIf="f['regNumber'].invalid && (f['regNumber'].dirty || f['regNumber'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['regNumber'].errors?.['required']">*Tax Id is required</div>
                        </div>
                    </label>
                    <label class="block mb-6 md:w-6/12 md:px-4">
                        <input type="email" name="email" placeholder="Email Address"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="email" />
                        <div *ngIf="f['email'].invalid && (f['email'].dirty || f['email'].touched || isSubmit)"
                            class="error-message">
                            <div *ngIf="f['email'].errors?.['required']">*Email is required</div>
                            <div *ngIf="f['email'].errors?.pattern">*Invalid email format</div>
                        </div>
                    </label>
                    <label class="block mb-6 md:w-6/12 md:px-4">
                        <input type="text" name="phone" placeholder="Mobile Number"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            (keypress)="numberOnly($event)" (input)="onInputChange($event)" formControlName="phone" />
                        <div *ngIf="f['phone'].invalid && (f['phone'].dirty || f['phone'].touched || isSubmit)"
                            class="error-message md:ml-4">
                            <div *ngIf="f['phone'].errors?.['required']">*Phone number is required</div>
                           

                            <!-- <div *ngIf="f['phone'].errors?.['pattern']">*Please enter valid mobile number</div> -->
                        </div>
                        <div *ngIf="isMinLength || isMaxLength" class="error-message md:ml-4">
                            <div *ngIf="isMinLength">* Phone number must be at least 10 digit</div>
                            <div *ngIf="isMaxLength">* Phone number cannot exceed 12 digit</div>
                        </div>
                    </label>
                    <label class="block mb-10 md:w-6/12 md:px-4">
                        <input [type]="showEye ? 'text' : 'password'" name="password" placeholder="Password"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="password" />
                        <i class="fa  eye-icon" aria-hidden="true"
                            [ngClass]="{'fa-eye-slash': !showEye,'fa-eye': showEye}" (click)="showHidePassword()"></i>
                        <div *ngIf="f['password'].invalid && (f['password'].dirty || f['password'].touched || isSubmit)"
                            class="error-message error-message1">
                            <div *ngIf="f['password'].errors?.['required']">*Password is required</div>
                            <div *ngIf="f['password'].hasError('pattern')">
                                *Password must meet the following requirements:
                                <ul>
                                    <li *ngIf="f['password'].hasError('lowercase')">At least one lowercase letter</li>
                                    <li *ngIf="f['password'].hasError('uppercase')">At least one uppercase letter</li>
                                    <li *ngIf="f['password'].hasError('digit')">At least one digit</li>
                                    <li *ngIf="f['password'].hasError('special')">At least one special character</li>
                                    <li *ngIf="f['password'].hasError('length')">Between 7 and 16 characters</li>
                                </ul>
                            </div>
                        </div>
                    </label>
                    <label class="block mb-10 md:w-6/12 md:px-4">
                        <input [type]="showEye ? 'text' : 'password'" name="confirmPassword"
                            placeholder="Confirm Password"
                            class="mt-1 md:h-14 h-12 block w-full  bg-white  rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-black text-sm input-shadow outline-0 border-0"
                            formControlName="confirmPassword" />
                        <i class="fa eye-icon" aria-hidden="true"
                            [ngClass]="{'fa-eye-slash': !showEye,'fa-eye': showEye}" (click)="showHidePassword()"></i>
                        <div *ngIf="((f['password'].value != f['confirmPassword'].value))  && (f['confirmPassword'].dirty || isSubmit)"
                            class="error-message error-message1">
                            *Confirm Password does not match with the password.
                        </div>
                    </label>

                    <label class="block mt-4 md:px-6 w-full h-auto checkbox border-none">
                        <div class="checkbox-custom flex flex-wrap gap-y-2.5 md:inline-block">
                            <input type="checkbox" (click)="handleCheckboxClick($event)" [value]="1"
                                class="checked:bg-blue-500 mr-2 border-[#004AAD]" />
                            <span class="checkbox-box"></span>
                            <span class="md:text-lg text-sm font-medium text-[#4f4f4f] "
                                [ngClass]="{'text-red-500': !isTermConditon && isSubmit}">By clicking Sign Up, you agree
                                to our </span><a [routerLink]="['/term-condition']"
                                class="underline text-[#1054b0] md:text-lg text-sm font-medium  hover:no-underline  ">
                                Terms & Conditions</a><span class="md:text-lg text-sm font-medium text-[#4f4f4f]"
                                [ngClass]="{'text-red-500': !isTermConditon && isSubmit}"> and </span><a [routerLink]="['/privacy-policy']"
                                class="underline text-[#1054b0] md:text-lg text-sm font-medium  hover:no-underline ">
                                Privacy Policy</a>
                        </div>
                    </label>
                    <!-- SMS Checkbox -->
                    <label class="block mt-4 md:px-6 w-full h-auto checkbox border-none">
                        <div class="checkbox-custom">
                            <input type="checkbox" (click)="checkSmsOptIn($event)"
                                class="checked:bg-blue-500 mr-2 border-[#004AAD]" />
                            <span class="checkbox-box"></span>
                            <span class="md:text-lg text-sm font-medium text-[#4f4f4f]">Please check the box if you
                                would like to opt-in for our promotional content via SMS </span>
                        </div>
                    </label>

                    <!-- Email Checkbox -->
                    <label class="block mt-4 md:px-6 w-full h-auto checkbox border-none">
                        <div class="checkbox-custom">
                            <input type="checkbox" (click)="checkEmailOptIn($event)"
                                class="checked:bg-blue-500 mr-2 border-[#004AAD]" />
                            <span class="checkbox-box"></span>
                            <span class="md:text-lg text-sm font-medium text-[#4f4f4f]">Please check the box if you
                                would like to opt-in for our promotional content via Email</span>
                        </div>
                    </label>

                </form>
                <div class="block text-center mt-4 md:w-full">
                    <button
                        class="rounded-full bg_primary pt-3 pb-3 pl-14 pr-14  font-medium text-xl text-white  border border-transparent hover:border-[#004AAD] hover:bg-white hover:text-[#004AAD] disabled:opacity-75 disabled:hover:bg-[#004AAD] disabled:hover:text-white cursor-pointer"
                        (click)="submitForm()">Sign Up
                    </button>
                    <p class="mt-6 md:text-lg text-sm font-medium text-[#4f4f4f]">Already have an account? <a href=""
                            class="underline text-[#1054b0] hover:no-underline" routerLink="/login">Sign In</a></p>
                </div>
            </div>
        </div>

    </section>
</div>