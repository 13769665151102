<app-sub-header></app-sub-header>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4">
            <!-- <app-sidebar class="z-2 xl:w-3/12 md:w-5/12 w-full px-4 mb-10 md:mb-0 hidden md:block"></app-sidebar> -->
            <app-responsive-sidebar></app-responsive-sidebar>

            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center">
                    <h2 class="lg:text-5xl text-4xl  font-bold leading-none text_primary_color">Change Password</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>
                <form [formGroup]="passwordForm">
                    <div class="w-full mb-6 relative">
                      <input [type]="showEye ? 'text' : 'password'" formControlName="currentPassword" placeholder="Current Password"
                        class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-black"  />
                        <i class="fa eye-icon" aria-hidden="true"
                        [ngClass]="{'fa-eye-slash': !showEye,'fa-eye': showEye}" (click)="showHidePassword()"></i>
                        <div *ngIf="f['currentPassword'].invalid && (f['currentPassword'].dirty || f['currentPassword'].touched)" class="error-message">
                            <div *ngIf="f['currentPassword'].errors?.['required']">*Current password is required</div>
                        </div>
                    </div>
                    <div class="w-full mb-6 relative">
                      <input [type]="showEye ? 'text' : 'password'" formControlName="newPassword" placeholder="New Password"
                        class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-black" pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[\w#?!@$%^&*-]{7,12}$" />
                        <i class="fa eye-icon " aria-hidden="true"
                        [ngClass]="{'fa-eye-slash': !showEye,'fa-eye': showEye}" (click)="showHidePassword()"></i>
                        <div *ngIf="f['newPassword'].invalid && (f['newPassword'].dirty || f['newPassword'].touched)" class="error-message">                
                            <div *ngIf="f['newPassword'].errors?.['required']">*Password is required</div>
                            <div *ngIf="f['newPassword'].hasError('pattern')">
                              *Password must meet the following requirements:
                              <ul>
                                <li *ngIf="f['newPassword'].hasError('lowercase')">At least one lowercase letter</li>
                                <li *ngIf="f['newPassword'].hasError('uppercase')">At least one uppercase letter</li>
                                <li *ngIf="f['newPassword'].hasError('digit')">At least one digit</li>
                                <li *ngIf="f['newPassword'].hasError('special')">At least one special character</li>
                                <li *ngIf="f['newPassword'].hasError('length')">Between 7 and 16 characters</li>
                              </ul>
                            </div>
                        </div>
                    </div>
                    <div class="w-full mb-6 relative">
                      <input [type]="showEye ? 'text' : 'password'" formControlName="confirmPassword" placeholder="Confirm New Password"
                        class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0" />
                        <i class="fa eye-icon" aria-hidden="true"
                        [ngClass]="{'fa-eye-slash': !showEye,'fa-eye': showEye}" (click)="showHidePassword()"></i>
                        <div *ngIf="(f['newPassword'].value != f['confirmPassword'].value) && f['confirmPassword'].dirty"
                        class="error-message">
                        *Confirm Password does not match with the password.
                    </div>
                    </div>
                    <div class="block text-center mt-4">
                      <button [disabled]="passwordForm.invalid" class="rounded-full bg_primary pt-3 pb-3 md:px-20 px-16 font-medium text-xl text-white border border-transparent hover:border-[#004AAD] hover:bg-white hover:text-[#004AAD]" (click)="onSubmit()" [disabled]="!passwordForm.valid">Update Password</button>
                    </div>
                  </form>
                  
                  
            </div>
        </div>
    </div>
</section>