import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { interval } from 'rxjs';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public role: boolean = false;
  public user: any;
  public carCounts: any;
  public initialarray: any = [{ id: 1 }];
  public modifyArray: any = [{ id: 2 }];
  public notification: number = 0
  isAdminLoggedIn: number=0;
  constructor(private service: CommonService) {
    const localUser = this.service.isBrowserPlateformId()? localStorage.getItem('user') : null
    if (localUser) {
      this.user = JSON.parse(localUser)
      this.isAdminLoggedIn = this.user.isAdminLoggedIn
      this.role = this.user.role
      // if (!this.role) {
      // this.getNewCarCount();
      this.getUserById(this.user.id)
      // }

    }
  }

  ngOnInit(): void {
    this.service.CarData.subscribe(res => {
      if (res) {
        this.getNewCarCount();

      }
    })
    this.subscribeLiveData()

  }


  public dataSubcribed: any
  private subscribeLiveData(): void {

    this.dataSubcribed = interval(60000)
      .subscribe(() => this.refreshLiveData());
  }
  private refreshLiveData(): void {
    if (this.user.role == 0) {
      this.getNewCarCount();
    }
    else{
      this.sellerNotification();
    }
  }

  getUserById(id: any) {
    this.service.showSpinner();
    this.service.getApi(`users/get-user/${id}`).subscribe((res: any) => {
      if (res.status == 200) {
        this.user = res.user;
        if (this.user.role == 0) {
          this.getNewCarCount();
        }
        else {
          this.sellerNotification();
        }
        this.service.hideSpinner()
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }

  sellerNotification() {
    let data = {
      lastViewedCar: this.user.lastViewedCar,
      userId: this.user.id,
    }
    // this.service.showSpinner();
    this.service.postApi('cars/seller-notification', data).subscribe((res: any) => {
      this.notification = res.newNotication;
      // this.service.hideSpinner()
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  getNewCarCount() {
    this.role = this.user.role == '1' ? true : false;

    
    let data = {
      lastViewedCar: this.user.lastViewedCar,
      userId: this.user.id,
      parentUserId: this.user.parentUserId,
      userTimeZone:'Asia/Calcutta'
    }

    if(this.service.isBrowserPlateformId()){
      data.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ;
      console.log("userTimeZone", data.userTimeZone)
    } 

    // this.service.showSpinner();
    this.service.postApi('cars/init-car', data).subscribe((res: any) => {
      this.carCounts = res;
      this.initialarray = res;
      // this.service.CarData.next(res)
      this.service.hideSpinner()
    }, (error) => {
      this.service.hideSpinner();
    })
  }
  toggleDropdown(id: string) {
    if(this.service.isBrowserPlateformId()){
    const dropdownElement = document.getElementById(id);
    if (dropdownElement) {
      dropdownElement.style.display = dropdownElement.style.display === 'block' ? 'none' : 'block';

    }
  }
  }
  ngOnDestroy(): void {
    this.dataSubcribed.unsubscribe();
  }

  udateLastViewDate() {
    this.service.showSpinner();
    let data = {
      userId: this.user.id,
      lastViewedCar: new Date()
    }
    this.service.postApi(`users/update-lastviewdate`, data).subscribe((res: any) => {
      let localUserData = {
        id: res.user.id,
        parentUserId:res.user.parentUserId,
        username: res.user.username,
        email:res.user.email,
        phone:res.user.phone,
        city: res.user.city,
        state: res.user.state,
        country: res.user.country,
        zipCode: res.user.zipCode,
        role: res.user.role,
        isAdminLoggedIn : this.isAdminLoggedIn,
      }
      if(this.service.isBrowserPlateformId()){

        localStorage.setItem('user',`${JSON.stringify(localUserData)}`)
      }
      this.getUserById(this.user.id);
      this.service.hideSpinner();
    }, (error) => {
      this.service.hideSpinner();
    })
  }
}