import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent {
  public brands:any;
  constructor(private service: CommonService, private router:Router) {
    this.getAllBrands();
  }
  getAllBrands(){
    this.service.showSpinner();
    this.service.getApi('brands/getBrand').subscribe((res:any)=>{
      if(res.status ==200){
        this.brands =res.brands;
        this.service.hideSpinner();
      }
    },(error:any)=>{
      this.service.hideSpinner();
    })
  }
}
