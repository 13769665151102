<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name"
            class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center">
        <div class="container mx-auto  ">
            <div class="max-w-screen-sm	mx-auto z-50  flex-1 px-6 ">
                <div class="block text-center">
                    <h1 class="md:text-5xl text-4xl text-white font-bold ">Dashboard</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="xl:py-20 py-8 px-4 lg:px-0 bg-[#EBEBEB]">
    
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
    <!-- Uppper filter start -->
        <div class="w-full bg-white border border-[#E3E3E3] p-3 mb-12">
            <form class="form " [formGroup]="filterForm">
                <!-- <div class="label flex-1 mr-4">
                    <input type="text" class="shadow-none focus:shadow-none	focus:border-0 focus:outline-0 focus:ring-0 border-0 placeholder:text-[#777777] text-base h-9 pl-10 bg-[url('assets/img/search-icon.png')] bg-no-repeat	bg-[center_left_1rem]" placeholder="Search for Car" formControlName="carName">
                </div> -->
                <div class="group grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
                    <div class="label make">
                        <ng-select [items]="allMakes" [multiple]="true" bindLabel="name" [closeOnSelect]="false"
                            [clearSearchOnAdd]="true" bindValue="name" class="  w-full" placeholder="Make"
                            formControlName="make" (close)="getModel()" (remove)="removeMake()" (clear)="clearMake()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name | uppercase}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name | uppercase}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label model">
                        <ng-select [items]="modelList" [multiple]="true" bindLabel="name" groupBy="make_name"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false"
                            [clearSearchOnAdd]="true" bindValue="name" class=" w-full" placeholder="Model"
                            formControlName="model" (clear)="updateFilter()" (remove)="updateFilter()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.make_name}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name | uppercase}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label year">
                        <ng-select [items]="yearList" [multiple]="true" bindLabel="year" bindValue="year"
                            [closeOnSelect]="false" [clearSearchOnAdd]="true" class=" w-full" placeholder="Year"
                            formControlName="year" (clear)="updateFilter()" (remove)="updateFilter()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.year | uppercase}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.year}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label price">
                        <ng-select [items]="priceRange" [multiple]="true" bindLabel="label" bindValue="price"
                            [closeOnSelect]="false" [searchable]="false" class=" w-full" placeholder="Price($)"
                            formControlName="price" (clear)="updateFilter()" (remove)="updateFilter()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.label}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.label}}
                            </ng-template>
                        </ng-select>
                    </div>

                    <div class="label color">
                        <ng-select [items]="colorsArray" [multiple]="true" bindLabel="label" bindValue="value"
                            [closeOnSelect]="false" [searchable]="false" class="w-full" placeholder="Color"
                            formControlName="color" (clear)="updateFilter()" (remove)="updateFilter()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.label}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.label}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label state">
                        <ng-select [items]="stateData" [multiple]="true" bindLabel="name" bindValue="iso2"
                            [closeOnSelect]="false" [clearSearchOnAdd]="true" class="w-full"
                            placeholder="State" (close)="getCities()" (remove)="removeState()" (clear)="clearState()"
                            formControlName="state" [disabled]="true">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label city">
                        <ng-select [items]="cityData" [multiple]="true" bindLabel="name" bindValue="name"
                            groupBy="state" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            [closeOnSelect]="false" [clearSearchOnAdd]="true" class="w-full" placeholder="City"
                            formControlName="city" (clear)="updateFilter()" (remove)="updateFilter()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name}}
                            </ng-template>
                        </ng-select>
                    </div>                    
                </div>
                <!-- <div class="right-btn mx-4 mt-2">
                    <button class="px-4 py-2 h-12 w-40 text-base rounded-md bg_primary text-white outline-0"
                    (click)="filterCar()">Search Vehicles</button>
                <button class="px-4 py-2 h-12 w-40 text-base rounded-md bg_primary text-white outline-0 ml-2"
                    (click)="resetFilter()">Reset Filter</button>
                </div> -->
                <div class="flex gap-2 flex-wrap justify-end mt-4">
                    <button class="px-4 py-2 h-12 w-40 text-base rounded-md bg_primary text-white outline-0"
                        (click)="filterCar()">Search Vehicles</button>
                    <button class="px-4 py-2 h-12 w-40 text-base rounded-md bg_primary text-white outline-0"
                        (click)="resetFilter()">Reset Filter</button>    
                </div>
            </form>

        </div>
    <!-- Uppper filter end -->
        <div class="md:flex md:flex-wrap -mx-4 justify-between">
            <div class="px-4 w-full flex justify-end mb-8">
                <!-- <div class="bg-white rounded-md border border-primary-color md:px-6 md:py-4 px-4 py-3 text-[#3A3F4D] md:text-lg text-base inline-flex items-center"> -->
                <span class="flex justify-between items-center gap-2">
                    <input type="text" name="daterange" placeholder="Select Date Range"
                        class="w-full px-4 md:pl-14 pl-10 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/date-icon-black.png)] bg-no-repeat	bg-[center_left_1rem] md:bg-[length:24px] bg-[length:18px]	!shadow-none outline-none cursor-pointer focus:ring-0 focus:ring-offset-0"
                        value="">
                        <button (click)="resetCarModelData()" title="reset" class="btn bg-blue-800 hover:bg-blue-700  btn-circle "> <i class="fa fa-refresh text-lg text-white" aria-hidden="true"></i> </button>
                </span>

                <!-- <span >
                    <input type="text" name="daterange"
                        class="w-full px-4 md:pl-14 pl-10 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/date-icon-black.png)] bg-no-repeat	bg-[center_left_1rem] md:bg-[length:24px] bg-[length:18px]	!shadow-none outline-none cursor-pointer focus:ring-0 focus:ring-offset-0"
                        value="">
                        
                </span> -->

                <!-- </div> -->
            </div>
            <app-responsive-sidebar></app-responsive-sidebar> 
            <!-- <app-sidebar class="z-2 xl:w-3/12 md:w-5/12 w-full px-4 mb-10 md:mb-0 hidden md:block"></app-sidebar> -->
            <div class="px-4 xl:w-9/12 lg:w-8/12 md:w-full w-full">
                <div class="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
                    <div class="bg-white" *ngFor="let item of carModels">
                        <div class="block-img flex justify-center min-h-[250px]">
                            <img [src]="item?.imageUrl" alt="car image" class="max-w-full max-h-[250px] ">
                        </div>
                        <div class="py-4 px-6">
                            <div class="model-name text-xl text-black font-medium mb-1">{{item?.carFullName}}</div>
                            <div class="model-info space-y-1 pb-4  mt-4 font-medium text-gray-500">
                                <div class="text-black">Color : {{item?.color}}</div>
                                <div class="text-black">Mileage : {{item?.mileage}}</div>
                                <div><span class="text-black">Purchased Amount: </span>
                                    <span class="text-green-600 font-semibold"> ${{item?.purchaseAmount}}</span></div>
                                <div class="text-black">Purchase Date:
                                    {{item?.carPuchaseDate | date:'d MMMM y h:mm a'}}</div>
                            </div>
                            <hr>
                            <div class="text-center mt-2">
                                <a routerLink="/dealer/view-car-info/{{item?.id}}" class="bg-green rounded-full py-2 text-white block flex-1 whitespace-nowrap px-4">View Vehicle Info</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>