import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-how-we-work',
  templateUrl: './how-we-work.component.html',
  styleUrls: ['./how-we-work.component.scss']
})
export class HowWeWorkComponent {
  public howWeWork:any;
  constructor(private service:CommonService, @Inject(PLATFORM_ID) private platformId: any,
  private sanitizer: DomSanitizer){
    this.service.scrollToTop()
    if (isPlatformBrowser(this.platformId)){
      this.getData()
    }
  }
  getData(){
    this.service.showSpinner();
    this.service.postApi('howWeWorks/getHowWeWork',{isWebsite:true}).subscribe((res: any) => {
      if(res.status ==200){
        this.howWeWork = res.howWeWork
        this.howWeWork.forEach((element:any) => {
          element.fileUrl = this.sanitizeUrl(element.fileUrl)
        });
        this.service.hideSpinner();
      }
      else{
        this.service.showError(res.message)
        this.service.hideSpinner();
      }
  
    }, (error) => {
      this.service.hideSpinner();
    })
  }
   sanitizeUrl(url: string): SafeResourceUrl {
    console.log(url)
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
