<app-sub-header></app-sub-header>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="w-full bg-white border border-[#E3E3E3] p-3 mb-8 block rounded-md" *ngIf="user.role == 0">
            <form class="form grid gap-3" [formGroup]="filterForm">
                <div class="group grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                    <div class="label car-name" >
                        <input type="text"
                            class="w-full shadow-none border-[#ccc] focus:border-[#ccc] rounded-md focus:shadow-none focus:outline-0 focus:ring-0 placeholder:text-[#777777] text-base h-14 pl-10 bg-[url('assets/img/search-icon.png')] bg-no-repeat	bg-[center_left_1rem]"
                            placeholder="Search for Vehicle" formControlName="carName">
                    </div>
                    <div class="label employee-name" *ngIf="!isCarListPage">
                        <ng-select [items]="dealerList" [multiple]="true" bindLabel="username" bindValue="id"
                            [closeOnSelect]="false" [searchable]="false" class=" w-full" placeholder="Employee Name"
                            formControlName="empId">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.username}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.username}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label make">
                        <ng-select [items]="allMakes" [multiple]="true" bindLabel="name" [closeOnSelect]="false" [clearSearchOnAdd] = "true"
                            bindValue="name" class=" w-full" placeholder="Make" formControlName="make"
                            (close)="getModel()" (remove)="removeMake()" (clear)="clearMake()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name | uppercase}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name | uppercase}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label model">
                        <ng-select [items]="modelList" [multiple]="true" bindLabel="name" groupBy="make_name"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false" [clearSearchOnAdd] = "true" 
                            bindValue="name" class=" w-full" placeholder="Model" formControlName="model" 
                            (clear)="updateFilter()" (remove)="updateFilter()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.make_name | uppercase}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name | uppercase}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label price">
                        <ng-select [items]="priceRange" [multiple]="true" bindLabel="label" bindValue="price"
                            [closeOnSelect]="false" [searchable]="false" class=" w-full" placeholder="Price($)"
                            formControlName="price" (clear)="updateFilter()" (remove)="updateFilter()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.label}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.label}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label year">
                        <ng-select [items]="modelYear" [multiple]="true" bindLabel="year" bindValue="year"
                            [closeOnSelect]="false" [clearSearchOnAdd] = "true" class=" w-full" placeholder="Year" formControlName="year" (clear)="updateFilter()" (remove)="updateFilter()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.year | uppercase}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.year}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label distance">
                        <ng-select [items]="distance" [readonly]="f.state.value?.length > 0" [multiple]="true" bindLabel="distance" bindValue="distance"
                            [closeOnSelect]="false" [clearSearchOnAdd] = "true" class=" w-full" placeholder="Distance" formControlName="distance" (clear)="updateFilter()" (remove)="updateFilter()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.distance}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.distance}} miles
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label mileage">

                        <ng-select [items]="mileage" [multiple]="true" bindLabel="mileage" bindValue="mileage"
                            [closeOnSelect]="false" [clearSearchOnAdd] = "true" class=" w-full" placeholder="Mileage" formControlName="mileage" (clear)="updateFilter()" (remove)="updateFilter()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.mileage}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.mileage}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label state">
                        <ng-select [items]="stateData" [multiple]="true" bindLabel="name" bindValue="iso2"
                            [closeOnSelect]="false" [clearSearchOnAdd] = "true" class=" w-full" placeholder="State" formControlName="state"
                            (close)="getCities()" [readonly]="f.distance.value?.length > 0" (remove)="removeState()" (clear)="clearState()" [disabled]="true">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label city">
                        <ng-select [items]="cityData" [multiple]="true" bindLabel="name" bindValue="name"
                            groupBy="state" [readonly]="f.distance.value?.length > 0" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            [closeOnSelect]="false" [clearSearchOnAdd] = "true" class=" w-full" placeholder="City" formControlName="city" (clear)="updateFilter()" (remove)="updateFilter()">
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                    [ngModelOptions]="{standalone: true}" /> {{item.name}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="label filter-name"  *ngIf="!myOffer && !wonOffers">
                        <input type="text"
                            class="w-full shadow-none border-[#ccc] focus:border-[#ccc] rounded-md focus:shadow-none focus:outline-0 focus:ring-0 placeholder:text-[#777777] text-base h-14 pl-10"
                            placeholder="Filter Name" formControlName="filterName">
                    </div>
                </div>
                <div class="relative grid items-center px-1">
                    <div class="flex gap-2 flex-wrap w-[86%]">
                        <button class="px-4 py-2 h-12 w-40 text-base rounded-md bg_primary text-white outline-0"
                            (click)="filterCar()">Search Vehicles</button>
                        <button class="px-4 py-2 h-12 w-40 text-base rounded-md bg_primary text-white outline-0"
                            (click)="resetFilter()">Reset Filter</button>
                        <button class="px-4 py-2 h-12 w-40 text-base rounded-md bg_primary text-white outline-0"
                            (click)="saveSearch()" *ngIf="!myOffer && !wonOffers">Save Search</button>
                    </div>
                    <div class="absolute right-0 bottom-[7px] grid grid-cols-2 gap-2 align-middle">
                        <a class="list cursor-pointer" (click)="listView = true">
                            <span class="block w-8 h-8  hover:text-black "
                                [ngClass]="{ 'text-black': listView , 'grey_text_500' : !listView}">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path
                                            d="M92.3,6.6c0.6,0.4,1.4,0.8,1.9,1.3c0.5,0.7,0.9,1.6,0.9,2.4c0.1,5,0,10,0,15.1c0,2.2-1.6,3.8-3.8,3.8c-17.5,0-35,0-52.5,0
                                                c-2.3,0-3.8-1.5-3.9-3.9c0-2.3,0-4.6,0-7c0-2.3,0.1-4.6,0-7c-0.1-2.3,0.7-3.9,2.9-4.7C56,6.6,74.1,6.6,92.3,6.6z" />
                                        <path
                                            d="M24.6,6.6c0.2,0.1,0.4,0.2,0.6,0.3c1.4,0.7,2.2,1.8,2.2,3.4c0,5,0,10.1,0,15.1c0,2.1-1.6,3.7-3.7,3.7c-5,0-10.1,0-15.1,0
                                                c-1.6,0-2.7-0.8-3.4-2.2c-0.1-0.2-0.2-0.4-0.3-0.6c0-5.6,0-11.3,0-16.9C5.4,8,6.3,7.1,7.7,6.6C13.3,6.6,19,6.6,24.6,6.6z" />
                                        <path
                                            d="M4.9,43.2C5,43,5.1,42.8,5.2,42.6c0.7-1.4,1.8-2.2,3.4-2.2c5,0,10.1,0,15.1,0c2.1,0,3.7,1.6,3.7,3.7c0,5,0,10.1,0,15.1
                                                c0,2.1-1.6,3.7-3.7,3.7c-5,0-10.1,0-15.1,0c-1.6,0-2.7-0.8-3.4-2.2c-0.1-0.2-0.2-0.4-0.3-0.6C4.9,54.5,4.9,48.9,4.9,43.2z" />
                                        <path
                                            d="M4.9,77.1c0.1-0.2,0.2-0.4,0.3-0.6c0.7-1.4,1.8-2.2,3.4-2.2c5,0,10.1,0,15.1,0c2.1,0,3.7,1.6,3.7,3.7c0,5,0,10.1,0,15.1
                                                c0,2.1-1.6,3.7-3.7,3.7c-5,0-10.1,0-15.1,0c-1.6,0-2.7-0.8-3.4-2.2C5.1,94.4,5,94.2,4.9,94C4.9,88.4,4.9,82.7,4.9,77.1z" />
                                        <path
                                            d="M65.1,40.4c8.6,0,17.3,0,25.9,0c2.6,0,4.1,1.5,4.1,4.1c0,4.8,0,9.6,0,14.4c0,2.5-1.5,4-4,4c-17.4,0-34.8,0-52.2,0
                                                c-2.4,0-4-1.5-4-3.8c0-5,0-9.9,0-14.9c0-2.4,1.6-3.8,4-3.8C47.7,40.4,56.4,40.4,65.1,40.4z" />
                                        <path
                                            d="M65.1,74.3c8.7,0,17.3,0,26,0c2.5,0,4.1,1.5,4.1,4c0,4.8,0,9.7,0,14.5c0,2.4-1.6,4-3.9,4c-17.4,0-34.8,0-52.2,0
                                                c-2.4,0-4-1.5-4-3.9c0-4.9,0-9.9,0-14.8c0-2.4,1.6-3.9,4-3.9C47.7,74.3,56.4,74.3,65.1,74.3z" />
                                    </g>
                                </svg>
                            </span>
                        </a>
                        <a class="grid-mode mode cursor-pointer" (click)="listView = false">
                            <span class="block w-8 h-8 grey_text_500 hover:text-black"
                                [ngClass]="{ 'text-black': !listView , 'grey_text_500' : listView}">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path
                                            d="M2.3,23.7c0-5.4,0-10.7,0-16.1c0-3.4,1.8-5.3,5.2-5.3c10.8,0,21.7,0,32.5,0c3.4,0,5.2,1.9,5.2,5.3c0,10.8,0,21.6,0,32.3
                                            c0,3.5-1.9,5.3-5.4,5.3c-10.7,0-21.5,0-32.2,0c-3.5,0-5.4-1.8-5.4-5.4C2.3,34.4,2.3,29.1,2.3,23.7z" />
                                        <path
                                            d="M97.7,23.9c0,5.4,0,10.7,0,16.1c0,3.4-1.9,5.3-5.2,5.3c-10.8,0-21.7,0-32.5,0c-3.3,0-5.2-1.9-5.2-5.3c0-10.8,0-21.6,0-32.3
                                            c0-3.5,1.8-5.3,5.3-5.3c10.7,0,21.5,0,32.2,0c3.6,0,5.4,1.8,5.4,5.4C97.7,13.1,97.7,18.5,97.7,23.9z" />
                                        <path
                                            d="M2.3,76.1c0-5.4,0-10.7,0-16.1c0-3.4,1.8-5.3,5.2-5.3c10.8,0,21.7,0,32.5,0c3.4,0,5.2,1.9,5.2,5.3c0,10.8,0,21.6,0,32.3
                                            c0,3.5-1.9,5.3-5.4,5.3c-10.7,0-21.5,0-32.2,0c-3.5,0-5.4-1.8-5.4-5.4C2.3,86.9,2.3,81.5,2.3,76.1z" />
                                        <path
                                            d="M76.1,97.7c-5.4,0-10.7,0-16.1,0c-3.3,0-5.2-1.9-5.2-5.1c0-10.9,0-21.7,0-32.6c0-3.3,1.9-5.1,5.2-5.1c10.8,0,21.7,0,32.5,0
                                            c3.4,0,5.2,1.9,5.2,5.3c0,10.8,0,21.6,0,32.3c0,3.5-1.9,5.3-5.4,5.3C86.9,97.7,81.5,97.7,76.1,97.7z" />
                                    </g>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
            </form>
        </div>
        <div class="flex items-center justify-end mb-4 gap-2" *ngIf="(schedulePickPage || scheduleDropPage || purchasedCar ||rejectedCar )">
            <input type="text" name="daterange" class="w-1/4 px-4 md:pl-14 pl-10 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/date-icon-black.png)] bg-no-repeat	bg-[center_left_1rem] md:bg-[length:24px] bg-[length:18px]	!shadow-none outline-none cursor-pointer focus:ring-0 focus:ring-offset-0" value="" >
            <button (click)="resetCarModelData()" title="reset" class="btn bg-blue-800 hover:bg-blue-700  btn-circle "> <i class="fa fa-refresh text-lg text-white" aria-hidden="true"></i> </button>
        </div>
        <div *ngIf="user.role==1">

            <div class="w-full">
                <div class="toolbar flex flex-wrap justify-end mb-4">
                    <div class="mode inline-flex items-center">
                        <a class="list mode mr-4 cursor-pointer" (click)="listView = true">
                            <span class="block w-8 h-8 grey_text_500 hover:text-black"
                                [ngClass]="{ 'text-black': listView , 'grey_text_500' : !listView}">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path
                                            d="M92.3,6.6c0.6,0.4,1.4,0.8,1.9,1.3c0.5,0.7,0.9,1.6,0.9,2.4c0.1,5,0,10,0,15.1c0,2.2-1.6,3.8-3.8,3.8c-17.5,0-35,0-52.5,0
                                                        c-2.3,0-3.8-1.5-3.9-3.9c0-2.3,0-4.6,0-7c0-2.3,0.1-4.6,0-7c-0.1-2.3,0.7-3.9,2.9-4.7C56,6.6,74.1,6.6,92.3,6.6z" />
                                        <path
                                            d="M24.6,6.6c0.2,0.1,0.4,0.2,0.6,0.3c1.4,0.7,2.2,1.8,2.2,3.4c0,5,0,10.1,0,15.1c0,2.1-1.6,3.7-3.7,3.7c-5,0-10.1,0-15.1,0
                                                        c-1.6,0-2.7-0.8-3.4-2.2c-0.1-0.2-0.2-0.4-0.3-0.6c0-5.6,0-11.3,0-16.9C5.4,8,6.3,7.1,7.7,6.6C13.3,6.6,19,6.6,24.6,6.6z" />
                                        <path
                                            d="M4.9,43.2C5,43,5.1,42.8,5.2,42.6c0.7-1.4,1.8-2.2,3.4-2.2c5,0,10.1,0,15.1,0c2.1,0,3.7,1.6,3.7,3.7c0,5,0,10.1,0,15.1
                                                        c0,2.1-1.6,3.7-3.7,3.7c-5,0-10.1,0-15.1,0c-1.6,0-2.7-0.8-3.4-2.2c-0.1-0.2-0.2-0.4-0.3-0.6C4.9,54.5,4.9,48.9,4.9,43.2z" />
                                        <path
                                            d="M4.9,77.1c0.1-0.2,0.2-0.4,0.3-0.6c0.7-1.4,1.8-2.2,3.4-2.2c5,0,10.1,0,15.1,0c2.1,0,3.7,1.6,3.7,3.7c0,5,0,10.1,0,15.1
                                                        c0,2.1-1.6,3.7-3.7,3.7c-5,0-10.1,0-15.1,0c-1.6,0-2.7-0.8-3.4-2.2C5.1,94.4,5,94.2,4.9,94C4.9,88.4,4.9,82.7,4.9,77.1z" />
                                        <path
                                            d="M65.1,40.4c8.6,0,17.3,0,25.9,0c2.6,0,4.1,1.5,4.1,4.1c0,4.8,0,9.6,0,14.4c0,2.5-1.5,4-4,4c-17.4,0-34.8,0-52.2,0
                                                        c-2.4,0-4-1.5-4-3.8c0-5,0-9.9,0-14.9c0-2.4,1.6-3.8,4-3.8C47.7,40.4,56.4,40.4,65.1,40.4z" />
                                        <path
                                            d="M65.1,74.3c8.7,0,17.3,0,26,0c2.5,0,4.1,1.5,4.1,4c0,4.8,0,9.7,0,14.5c0,2.4-1.6,4-3.9,4c-17.4,0-34.8,0-52.2,0
                                                        c-2.4,0-4-1.5-4-3.9c0-4.9,0-9.9,0-14.8c0-2.4,1.6-3.9,4-3.9C47.7,74.3,56.4,74.3,65.1,74.3z" />
                                    </g>
                                </svg>

                            </span>
                        </a>
                        <a class="grid-mode mode cursor-pointer" (click)="listView = false ">
                            <span class="block w-8 h-8 grey_text_500 hover:text-black"
                                [ngClass]="{ 'text-black': !listView , 'grey_text_500' : listView}">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path
                                            d="M2.3,23.7c0-5.4,0-10.7,0-16.1c0-3.4,1.8-5.3,5.2-5.3c10.8,0,21.7,0,32.5,0c3.4,0,5.2,1.9,5.2,5.3c0,10.8,0,21.6,0,32.3
                                                    c0,3.5-1.9,5.3-5.4,5.3c-10.7,0-21.5,0-32.2,0c-3.5,0-5.4-1.8-5.4-5.4C2.3,34.4,2.3,29.1,2.3,23.7z" />
                                        <path
                                            d="M97.7,23.9c0,5.4,0,10.7,0,16.1c0,3.4-1.9,5.3-5.2,5.3c-10.8,0-21.7,0-32.5,0c-3.3,0-5.2-1.9-5.2-5.3c0-10.8,0-21.6,0-32.3
                                                    c0-3.5,1.8-5.3,5.3-5.3c10.7,0,21.5,0,32.2,0c3.6,0,5.4,1.8,5.4,5.4C97.7,13.1,97.7,18.5,97.7,23.9z" />
                                        <path
                                            d="M2.3,76.1c0-5.4,0-10.7,0-16.1c0-3.4,1.8-5.3,5.2-5.3c10.8,0,21.7,0,32.5,0c3.4,0,5.2,1.9,5.2,5.3c0,10.8,0,21.6,0,32.3
                                                    c0,3.5-1.9,5.3-5.4,5.3c-10.7,0-21.5,0-32.2,0c-3.5,0-5.4-1.8-5.4-5.4C2.3,86.9,2.3,81.5,2.3,76.1z" />
                                        <path
                                            d="M76.1,97.7c-5.4,0-10.7,0-16.1,0c-3.3,0-5.2-1.9-5.2-5.1c0-10.9,0-21.7,0-32.6c0-3.3,1.9-5.1,5.2-5.1c10.8,0,21.7,0,32.5,0
                                                    c3.4,0,5.2,1.9,5.2,5.3c0,10.8,0,21.6,0,32.3c0,3.5-1.9,5.3-5.4,5.3C86.9,97.7,81.5,97.7,76.1,97.7z" />
                                    </g>
                                </svg>
                            </span>
                        </a>
                        <button class="bg-[#00449E] text-white border-slate-300 border-2 px-8 py-2 rounded-md ml-2"
                            data-modal-target="staticModal" data-modal-toggle="staticModal" (click)="showModal()">Add
                            Vehicle</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="md:flex md:flex-wrap -mx-4">
            <input type="checkbox"  class="openSidebarMenu" id="openSidebarMenu">
            <label for="openSidebarMenu" class="sidebarIconToggle">
                <div class="spinner diagonal part-1"></div>
                <div class="spinner horizontal"></div>
                <div class="spinner diagonal part-2"></div>
            </label>
            <div class="z-2 xl:w-3/12 min-[993px]:w-5/12 w-full px-4 mb-10 min-[992px]:mb-0 hidden lg:block" id="sidebar_menu">

                <app-sidebar></app-sidebar>
                <div class="p-1 overflow-y-auto bg-gray-50 rounded-lg mt-8" *ngIf="isFilterPage">
                    <ul class="px-4">
                      <li class="my-3">
                        <div>Saved Searches</div>
                      </li>
                      <li *ngFor="let item of filterData">
                        <label class="relative flex items-center mb-4 cursor-pointer justify-between">
                          <span class="text-lg leading-none font-medium text-[#3A3F4D] uppercase">{{ item?.filterName }} <span class="text-sm font-normal text-[#67696d]">({{item?.totalCar}})</span> </span>
                          <input type="checkbox" [(ngModel)]="item.checked" class="sr-only peer" (change)="filterByCheckbox(item)">
                          <div
                            class="w-11 relative h-6 bg-[#605C5C] rounded-[0.175rem] peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-[0.175rem] after:h-5 after:w-5 after:transition-all peer-checked:bg-[#175BB5]">
                          </div>
                        </label>
                      </li>
                    </ul>
                  </div>
                  
            </div>

            <div class="px-4 xl:w-9/12 min-[992px]:w-7/12 w-full">
                <div class="grid grid-cols-1 gap-x-8 gap-y-10" *ngIf="cars?.length==0 && isLoading">
                    <div class="car-data-box bg-white shadow-md p-4 rounded-md  text-center text-black">
                        No Vehicles Found
                    </div>
                </div>
                <app-data-list [cars]="cars" *ngIf="listView"></app-data-list>
                <app-data-grid [cars]="cars" *ngIf="!listView"></app-data-grid>
            </div>
        </div>
    </div>
</section>


<!-- Add car pop up -->
<div id="staticModal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden justify-center items-center overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
    [ngStyle]="{display: displayStyle}">
    <div class="relative w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h2 class="text-xl text-black">
                    Add New Vehicle
                </h2>
                <button type="button"
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="staticModal" (click)="hideModal()">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6 bg-[#1659B3] text-center pt-12">
                <app-search-car></app-search-car>

            </div>
            <!-- Modal footer -->
            <div class=" items-center p-3  border-t border-gray-200 rounded-b dark:border-gray-600 text-right">

                <button data-modal-hide="staticModal" type="button"
                    class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 "
                    (click)="hideModal()">Close</button>
            </div>
        </div>
    </div>
</div>