import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent {
  public userData: any[] = [];
  public total_items = 0;
  public item_per_page = 10;
  public current_page = 1;
  public displayStyle = 'none'
  public userForm!: FormGroup;
  public isSubmit: boolean = false;
  public user: any = '';
  public imagePreview:any;
  public deletedUserid:any;
  public showDeleteUserModal :string = 'none'
  constructor(private fb: FormBuilder, private service: CommonService,private router:Router) {
    if(this.service.isBrowserPlateformId()){
     this.service.scrollToTop() 
     this.user = JSON.parse(`${localStorage.getItem('user')}`);
    }
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.inilizeForm();
    this.getAllUser();
  }

  // inializing user form
  inilizeForm() {
    this.userForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // userImage: [''],
      role: [0]
    }
    ,{
      updateOn: 'blur'
    }
    )
  }

  // get form controls
  get f() {
    return this.userForm.controls;
  }

  // get all sub users
  getAllUser() {
    this.service.showSpinner();
    this.service.postApi(`users/list`,{parentUserId:this.user.id}).subscribe((res: any) => {
      if(res.status ==200){
        this.userData = res.users
        this.total_items = res.totalItems
        this.service.hideSpinner()
      }
      else{
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  // manage pagination
  pageChanged(event: any) {
    this.current_page = event;
    this.getAllUser();
  }

  // show add new user modal
  showModal() {    
    this.displayStyle = 'flex'
  }
  // hide add new user modal
  hideModal() {
    this.displayStyle = 'none'
  }

  // add new user function
  submitForm() {
    if (this.userForm.invalid) {      
      this.isSubmit = true;
      return;
    }
    const phoneNumber = this.userForm.get('phone')?.value;
    const cleanedPhoneNumber = phoneNumber.replace(/\s/g, "").replace(/[()\-+]/g, "");
    this.userForm.get('phone')?.setValue(cleanedPhoneNumber)

    if (this.userForm && this.userForm.valid) {
      const formData = new FormData()
      const formGroup: FormGroup = this.userForm as FormGroup;
      for (const field in formGroup.controls) {
        if (Object.prototype.hasOwnProperty.call(formGroup.controls, field)) {
          const control = formGroup.get(field);
          if (control && control.value !== undefined) {
            formData.append(field, control.value);
          }
        }
      }      
      formData.append('parentUserId',this.user.id);
      formData.append('smsOptIn','0');
      formData.append('emailOptIn','0');
      formData.append('zipCode',this.user.zipCode);
      formData.append('isTempUser', '0');
      formData.append('webUrl',this.service.webUrl);
      this.service.showSpinner();
      this.service.postApi('auths/register', formData).subscribe((res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.service.showSuccess(res.message);
          sessionStorage.setItem('userPreferred','true')
          if(this.service.isBrowserPlateformId()){
      
            localStorage.setItem('tempUserId',`${res.newUser.id}`)
          }
          this.displayStyle = 'none';
          this.userForm.reset();
          this.imagePreview = ''
          this.getAllUser();
          // this.router.navigate(['/dealer/dealer-preferences']);
        }
        else if (res.status == 202) {
          this.service.showError(res.message);
        }
        else {
          this.service.showError(res.message);
        }
      }, (error) => {
        this.service.hideSpinner()
      })
    }

  }

  // formatting the mobile number input
  formatPhoneNumber(input: string) {
    // Remove non-numeric characters
    const numericValue = input.replace(/\D/g, '');
    // Format the numeric value as (XXX) XXX-XXXX
    let formattedValue = '';
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 0) {
        formattedValue += '(' + numericValue[i];
      } else if (i === 3) {
        formattedValue += ') ' + numericValue[i];
      } else if (i === 6) {
        formattedValue += '-' + numericValue[i];
      } else {
        formattedValue += numericValue[i];
      }
    }

    return formattedValue;
  }

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const newValue = inputElement.value;
    const formattedValue = this.formatPhoneNumber(newValue);

    // Update the input value with the formatted value
    inputElement.value = formattedValue;
  }
  //accept only numbers in input
  numberOnly(event: any) {
    return this.service.numberOnly(event);
  }

  // upload user profile pic 
  // handleImageUpload(event: Event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   const fileList: FileList | null = inputElement.files;

  //   if (fileList && fileList.length > 0) {
  //     const file = fileList[0];
  //     const reader = new FileReader();
  //     reader.onload = () => {        
  //       this.userForm.get('userImage')?.setValue(fileList[0]);
  //       this.imagePreview  = reader.result as string;
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // }

  deleteUser(){
    this.service.showSpinner();
    this.service.getApi(`users/delete-user/${this.deletedUserid}`).subscribe((res: any) => {
      if(res.status ==200){
        this.service.showSuccess(res.message);
        this.showDeleteUserModal = 'none'
        this.getAllUser();
        this.service.hideSpinner();
      }
      else{
        this.service.showError(res.message)
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }

  showDeleteModal(id:number){
    this.deletedUserid = id;
    this.showDeleteUserModal = 'flex' 
  }
  hideDeleteModal(){
    this.showDeleteUserModal = 'none'
  }
  
}
