import { environment } from "src/environments/environment";

// add all api url in this object
const apiUrl_credentials = {
	// url_local: 'http://localhost:5003/api/',
	// url_local:`http://cmpstaging.softdemonew.info:5003/api/`
	url_local: environment.url_local
};

let apiUrls = apiUrl_credentials.url_local;
// the api url of the server
export let apiUrl = apiUrls;