import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent {
  @Input() cars: any = [];
  public isDealer = false;
  public user:any;
  public currentRoute:string='';
  public schedulePage:boolean = false;

  constructor(private router: Router,private service:CommonService) {
    if(this.service.isBrowserPlateformId()){

    }
    const localUser = this.service.isBrowserPlateformId()? localStorage.getItem('user') : null
    if(localUser){
      this.user = JSON.parse(localUser)
    }
    let currentPath =  this.router.url.split('/')[1]
    this.currentRoute = this.router.url ? this.router.url.split('/')[2] : ''

    if (currentPath == 'dealer') {
      this.isDealer = true
    }
    if (this.currentRoute == 'schedule-car-pickup' || this.currentRoute == 'schedule-car-dropoff' || this.currentRoute == 'car-overview' ) {
      this.schedulePage = true
    }
    
  }
  showBidModel(car:any){
    this.service.bidData.next(car)
  }

  showPickDropModel(car:any){
      this.service.pickDrop.next(car)
  }
  showCustomer(id:number,parentUserId:number){
    this.service.customerData.next({id:id,parentUserId:parentUserId});
  }
  showSummary(summary:any){    
    let data = {
      type : summary.pickup,
      scheduleDate: summary.scheduleDate ?  summary.scheduleDate.split(' ')[0]:'',
      scheduleTime:summary.scheduleDate ? summary.scheduleDate.split(' ')[1]: '',
      location: summary.location
    }    
    this.service.summary.next(data);
  }

  showRejected(car:any){
    car.isRejectDetails = true;
    this.service.carStatus.next(car);
  }
  updateStatus(car:any){
    this.service.carStatus.next(car);
  }

  reslistCar(carId:number,isRelist:number) {
    console.log(isRelist);
    
    if(!isRelist){
      this.service.showError('This car is already listed');
      return
    }
    this.service.showSpinner();
    this.service.postApi('cars/relist-car',  {carId :carId,isAdminLoggedIn:this.user.isAdminLoggedIn }).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.CarData.next(carId)
        this.service.showSuccess(res.message);
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }
}
