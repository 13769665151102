import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent {

  public licenseName: any = '';
  public licenseImage: any;
  public registrationForm!: FormGroup;
  public stateData: any = [];
  public isSellerProfile = false;
  public userId: any;
  public imagePreview: any;
  public isSubmit: boolean = false;
  public selectedState: string = ''
  public isoCode: string = ''
  public isAdminLoggedIn : number =0;

  @Output() formDataEmitter = new EventEmitter<FormGroup>();

  constructor(private fb: FormBuilder, private http: HttpClient, private route: ActivatedRoute, private service: CommonService) {
    let user = this.service.isBrowserPlateformId()? JSON.parse(`${localStorage.getItem('user')}`) : null
    this.isAdminLoggedIn = user ? user.isAdminLoggedIn : 0
    if (user) {
      this.userId = user.id
      if (user.role == '1') {
        this.isSellerProfile = true;
      }
      this.getUserById();
    }
    this.intializeForm()
  }

  ngOnInit(): void {
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.service.scrollToTop()
    this.service.user.subscribe(res => {
      this.getUserById();
    })
  }

  // doucument of license
  uploadLicense(event: Event): void {
    this.licenseImage = ''
    const inputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = inputElement.files;
    if (fileList && fileList.length > 0) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const fileBuffer = e.target.result; // This will be a buffer
        this.licenseImage = fileBuffer;
        this.licenseName = fileList[0].name;
        this.registrationForm.get('license')?.setValue(fileList[0]);
      };
      reader.readAsArrayBuffer(fileList[0]);
    }
  }

  // upload user profile pic 
  handleImageUpload(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = inputElement.files;

    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.registrationForm.get('userImage')?.setValue(fileList[0]);
        this.imagePreview = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  // initiazing registration form
  intializeForm() {
    this.registrationForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      dealershipName: [''],
      companyName: [''],
      license: [''],
      licenseNumber: [''],
      carBuyMonthly: [''],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      country: ["United States", Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: ['', Validators.required],
      regNumber: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      dealerType: [''],
      dealerGroup: [''],
      userImage: [''],
      role: ['1'],
    },
      {
        updateOn: 'blur'
      }
    );

    if (!this.isSellerProfile) {
      this.registrationForm.get('dealershipName')?.setValidators([
        Validators.required
        
      ]);
      this.registrationForm.get('companyName')?.setValidators([
        Validators.required
      ]);
      this.registrationForm.get('license')?.setValidators([Validators.required]);
      this.registrationForm.get('licenseNumber')?.setValidators([Validators.required]);
    } else {
      this.registrationForm.get('dealershipName')?.clearValidators();
      this.registrationForm.get('companyName')?.clearValidators();
      this.registrationForm.get('license')?.clearValidators();
    }
    // Update the form controls to reflect the new validators
    this.registrationForm.get('dealershipName')?.updateValueAndValidity();
    this.registrationForm.get('companyName')?.updateValueAndValidity();
    this.registrationForm.get('license')?.updateValueAndValidity();
    this.registrationForm.get('licenseNumber')?.updateValueAndValidity();

  }

  // get form controls
  get f() {
    return this.registrationForm.controls;
  }

  apiFunction(path: any) {
    const headers = new HttpHeaders().set('X-CSCAPI-KEY', 'aEFoZkU1R3l0QlpmNUpmRllqVU9WZ0JDV09XaWQ0WjZJTW9td0hhUw==')
    return this.http.get(path, { headers })
  }

  // get user details and patch form value
  getUserById() {
    this.service.showSpinner();
    this.service.getApi(`users/get-user/${this.userId}`).subscribe((res: any) => {
      let tempUser = res.user
      if (res.status == 200) {
        this.registrationForm.patchValue({
          firstName: tempUser.firstName,
          lastName: tempUser.lastName,
          dealershipName: tempUser.dealershipName != "null" ? tempUser.dealershipName : '',
          companyName: tempUser.companyName != "null" ? tempUser.companyName : '',
          license: tempUser.license,
          licenseNumber: tempUser.licenseNumber,
          carBuyMonthly: tempUser.carBuyMonthly,
          addressLine1: tempUser.addressLine1,
          addressLine2: tempUser.addressLine2 != "null" ? tempUser.addressLine2 : '',
          country: tempUser.country != "null" ? tempUser.country : 'United States',
          state: tempUser.state != "null" ? tempUser.state : '',
          city: tempUser.city ? tempUser.city : '',
          zipCode: tempUser.zipCode != "null" ? tempUser.zipCode : '',
          regNumber: tempUser.regNumber != "null" ? tempUser.regNumber : '',
          email: tempUser.email,
          password: tempUser,
          dealerType: tempUser.dealerType != "null" ? tempUser.dealerType : '',
          dealerGroup: tempUser.dealerGroup != "null" ? tempUser.dealerGroup : '',
          userImage: tempUser.userImage,
          role: tempUser.role,
        });
        if (tempUser.dealerType == 'group') {
          this.isGroupSelected = true;
        }
        this.imagePreview = res.user.userImage ? res.user.userImage : ''
        if (res.user.license) {
          const fileName = res.user.license.split('license/')[1];
          this.licenseName = fileName
        }
        this.onInputChange(tempUser.phone, 1)
        if (res.user.state) {
          this.getStates(res.user.state)
        }
        this.service.hideSpinner()
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }

  public isGroupSelected: boolean = false;
  selectGroup(event: any) {
    this.isGroupSelected = event.target.value == 'group' ? true : false;
  }

  // get state list according country selected
  getStates(isoCode: any) {
    this.service.apiFunction(`https://api.countrystatecity.in/v1/countries/us/states`).subscribe(
      (res: any) => {
        this.stateData = res;
        this.stateData.forEach((element: any) => {
          if (element.iso2 == isoCode) {
            this.registrationForm.get('state')?.setValue(isoCode);
            this.selectedState = element.name;
            if(this.service.isBrowserPlateformId()){
              localStorage.setItem('state', element.name);
            }
           
          }
        })
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }


  formatPhoneNumber(input: string) {
    // Remove non-numeric characters
    const numericValue = input.replace(/\D/g, '');
    if (numericValue.length == 0) {
      this.registrationForm.get('phone')?.setValue('')
    }
    // Format the numeric value as (XXX) XXX-XXXX
    let formattedValue = '';
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 0) {
        formattedValue += '(' + numericValue[i];
      } else if (i === 3) {
        formattedValue += ') ' + numericValue[i];
      } else if (i === 6) {
        formattedValue += '-' + numericValue[i];
      } else {
        formattedValue += numericValue[i];
      }
    }

    return formattedValue;
  }

  onInputChange(event: any, type: number) {
    let inputElement
    let formattedValue
    if (type == 1) {
      formattedValue = this.formatPhoneNumber(event);
    }
    else {
      inputElement = event.target as HTMLInputElement;
      const newValue = inputElement.value
      formattedValue = this.formatPhoneNumber(newValue);
      // Update the input value with the formatted value
      inputElement.value = formattedValue;
    }
    // Update the form control value
    const phoneNumberControl = this.registrationForm.get('phone');
    phoneNumberControl?.setValue(formattedValue);
  }

  //accept only numbers in input
  numberOnly(event: any) {
    return this.service.numberOnly(event);
  }

  // save profile data
  submitForm() {
    const formData = new FormData()
    const formGroup: FormGroup = this.registrationForm as FormGroup;
    if (formGroup.get('dealerType')?.value != 'group') {
      formGroup.get('dealerGroup')?.setValue('');
    }
    const phoneNumber = formGroup.get('phone')?.value;
    const cleanedPhoneNumber = phoneNumber.replace(/\s/g, "").replace(/[()\-+]/g, "");
    formGroup.get('phone')?.setValue(cleanedPhoneNumber)

    for (const field in formGroup.controls) {
      if (Object.prototype.hasOwnProperty.call(formGroup.controls, field)) {
        const control = formGroup.get(field);
        if (control && control.value !== undefined) {
          formData.append(field, control.value);
        }
      }
    }
    this.service.showSpinner();
    this.service.postApi(`users/update-user/${this.userId}`, formData).subscribe((res: any) => {
      this.service.user.next(res.userToUpdate)
      let localUserData = {
        id: res.userToUpdate.id,
        parentUserId:res.userToUpdate.parentUserId,
        username: res.userToUpdate.username,
        email:res.userToUpdate.email,
        phone:res.userToUpdate.phone,
        city: res.userToUpdate.city,
        state: res.userToUpdate.state,
        country: res.userToUpdate.country,
        zipCode: res.userToUpdate.zipCode,
        isAdminLoggedIn : this.isAdminLoggedIn,
        role : res.userToUpdate.role
      }
      if(this.service.isBrowserPlateformId()){

        localStorage.setItem('user', JSON.stringify(localUserData))
      }
      this.service.showSuccess(res.message);
      this.service.hideSpinner();
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  getStateCity(event: any) {
    if (event.target.value.length == 5) {
      this.service.getStateCityByZipCode(event.target.value).subscribe((res: any) => {
        this.registrationForm.patchValue({
          city: res.city,
          // state: res.state
        })
        this.isoCode = res.state;
        this.getStates(res.state)
      })
    }
    else {
      this.registrationForm.patchValue({
        city: '',
        state: ''
      })
      this.selectedState = '';
    }
  }

}

