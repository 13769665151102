import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isScrolled = false;
  public user: any;
  public routePath: any
  public isOpen = false;
  public isVerificationPage = false;
  public firstName :string=''

  @HostListener('window:scroll', [])
  onScroll(): void {
    if(this.service.isBrowserPlateformId()){
      this.isScrolled = window.scrollY > 0;
    }
  }
  public showHeader: Boolean = true;
  public isHomePage:boolean = false;

  constructor(private service: CommonService, private router: Router, private el: ElementRef) { }
  ngOnInit(): void {
    const localUser = this.service.isBrowserPlateformId()? localStorage.getItem('user') : null
    if (localUser) {
      this.user = JSON.parse(localUser)
      if(this.user && Object.keys(this.user).length > 0){
        this.firstName = this.user ? this.user.username.split(' ')[0]: ''
      }
    }

    this.service.user.subscribe(res => {
      this.user = res;
      if(this.user && Object.keys(this.user).length > 0){
        this.firstName = this.user ? this.user.username.split(' ')[0]: ''
      }
      else{
        this.user = null;
      }
    })
    if (this.user && this.user.role == 0) {
      this.routePath = 'dealer-profile'
    }
    else {
      this.routePath = 'seller-profile'
    }
    this.clickOutSideModal();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects; // Gets the current route's path
        const routeSegments = currentRoute.split('/');
        const lastRouteSegment = routeSegments[routeSegments.length - 1];
        const verificationRouteSegment = routeSegments[routeSegments.length - 2];

        if (lastRouteSegment == 'login' || verificationRouteSegment == 'verification') {
          this.showHeader = false;
          this.isHomePage = false;

        }
        else {
          this.showHeader = true
          this.isHomePage =false
        }
        if (verificationRouteSegment == 'verification') {
          this.isVerificationPage = true;
        }
        if (lastRouteSegment == '') {
          // i will do this later
          // this.isScrolled = false
          // this.isVerificationPage = false
          this.isHomePage = true;
        }
      }
    });
  }

  // manage open and close header dropdown
  dropDownClick() {
    this.isOpen = !this.isOpen;
    this.clickOutSideModal();
  }


  clickOutSideModal() {
    if(this.service.isBrowserPlateformId()){

      window.addEventListener('click', (event) => {
  
        if (!this.el.nativeElement.contains(event.target)) {
          // Click occurred outside the dropdown, so close it
          this.isOpen = false;
        }
      });
    }
  }

  // logout user functionality
  logOut() {
    this.service.showSpinner();
    this.service.getApi(`auths/logout/${this.user.id}`).subscribe((res: any) => {
      this.user = null;
     if(this.service.isBrowserPlateformId()){
       localStorage.clear();
       sessionStorage.clear();
       document.cookie = `user=user; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.localhost; path=/`;
     }
    
      this.isOpen = false;
      this.service.hideSpinner();
      this.router.navigate(['/'])
    }, (error) => {
      this.service.hideSpinner();
    })

  }

  routeToCarList() {
    if (this.service.isLoggedIn()) {
      if (this.user.role) {
        this.router.navigate(['seller/car-list-new'])
      }
      else {
        this.router.navigate(['dealer/car-list-new'])
      }
    }
    this.isOpen = false;
  }

  routeToHomePage(){
    if (this.service.isLoggedIn() && this.user.role ==0) {
        this.router.navigate(['dealer/car-list-new'])
    }
    else{
      this.router.navigate(['/'])
    }
      this.isOpen = false;
  }


}
