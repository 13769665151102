<app-sub-header></app-sub-header>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4">
            <app-sidebar class="z-2 xl:w-3/12 md:w-5/12 w-full px-4 mb-10 md:mb-0 hidden md:block"></app-sidebar>
            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="title-heading pb-1 lg-3 leading-none text-center mb-14 lg:text-center">
                    <h2 class="lg:text-5xl text-4xl  font-bold leading-none text_primary_color">Profile Management</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>
                <div class="flex flex-wrap -mx-4">
                    <div class="md:w-4/12 w-6/12 mx-auto px-4">
                        <div class="px-4 py-4 md:py-8 border-2 border-primary-color rounded-lg text-center md:mb-0 mb-40">
                          <!-- Display the uploaded image or a default image -->
                          <img *ngIf="imagePreview" [src]="imagePreview" alt="Uploaded Image" (click)="triggerFileInput()">
                          <img *ngIf="!imagePreview" src="assets/img/upload-user.png" alt="Uploaded Image" class="inline-block" (click)="triggerFileInput()">
                          
                          <!-- Input element (hidden) -->
                          <input id="dropzone-file" type="file" class="hidden" accept="image/png, image/gif, image/jpeg" (change)="handleImageUpload($event)">
                        </div>
                      </div>
                      
                      <div class="md:w-8/12 w-full px-4">
                        <form [formGroup]="userForm">
                          <label class="block mb-6 w-full md:px-4">
                            <input type="text" formControlName="firstName" placeholder="First Name" 
                                   class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0" />
                          </label>
                          <label class="block mb-6 w-full md:px-4">
                            <input type="text" formControlName="lastName" placeholder="Last Name" 
                                   class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0" />
                          </label>                  
                          <label class="block mb-6 w-full md:px-4">
                            <input type="email" formControlName="email" placeholder="Email" 
                                   class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0" />
                          </label>
                          <label class="block mb-6 w-full md:px-4">
                            <input type="text" formControlName="phone" placeholder="Mobile Number" 
                                   class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0" />
                          </label>
                          <label class="block mb-6 w-full md:px-4">
                            <input type="text" formControlName="empId" placeholder="Employee ID" 
                                   class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0" />
                          </label>
                          <!-- <label class="block mb-6 w-full md:px-4">
                            <input type="text" formControlName="userImage" placeholder="Upload Photo" 
                                   class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0" (change)="handleImageUpload($event)" />
                          </label> -->
                          <div class="block text-center mt-4 md:w-full">
                            <button type="submit" 
                                    class="rounded-full bg_primary pt-3 pb-3 pl-14 pr-14 font-medium text-xl text-white  border border-transparent hover:border-[#004AAD] hover:bg-white hover:text-[#004AAD]" (click)="submitForm()">Update</button>
                          </div>
                        </form>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>