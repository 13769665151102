<header class="fixed w-full top-0 left-0 bg_primary z-20 md:py-6 py-4 transition-all ease-in-out duration-300"
    >
    <div class="container max-w-screen-xl mx-auto">
        <div class="flex flex-wrap items-center justify-between px-4">
            <div class="logo w-4/12 md:mx-[0]  sm:w-4/12 transition-all ease-in-out duration-500 cursor-pointer"
                >
                <img src="assets/icons/logo.svg" alt="head1" srcset="">
            </div>
        </div>
    </div>
</header>
  <div class="relative flex min-screen-wrapper flex-col justify-center overflow-hidden bg-[#EBEBEB] pt-12 mt-8 px-4">
    <div class="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl mb-4">
        <div class="mx-auto flex w-full max-w-md flex-col space-y-16">
            <div class="flex flex-col items-center justify-center text-center space-y-2">
                <div class="title-heading pb-1 lg-3 leading-none text-center mb-2 lg:text-center">
                    <h2 class="lg:text-4xl text-2xl font-bold leading-none text_primary_color">Verification</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>
                <div *ngIf="!this.isRoleSeller" class="flex flex-row text-sm font-medium text-[#999]">
                    <p>We have sent a code to your email {{userData?.email}} and on mobile number {{userData?.phone}}</p>
                </div>
                <div  *ngIf="this.isRoleSeller" class="flex flex-row text-sm font-medium text-[#999]">
                    <p>Please enter the code sent to your registered mobile number {{userData?.phone}}</p>
                </div>
            </div>

                <form class="!mt-8">
                    <div class="flex flex-col space-y-8">
                        <div *ngIf="!this.isRoleSeller">
                          <label for="emailOTP" class="text-sm font-medium text-gray-700">Email OTP</label>
                          <div class="flex flex-row items-center justify-between mx-auto w-full gap-2 md:gap-0 mt-2">
                              <ng-container *ngFor="let index of [0, 1, 2, 3, 4, 5]">
                                  <div class="md:w-16 md:h-16 w-12 h-12">
                                      <input
                                        #otpInput
                                        class="w-full h-full flex flex-col items-center justify-center text-center  outline-none rounded-md border border-[#ccc] text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700 text-black"
                                        type="text"
                                        [name]="'otp' + index" 
                                        [id]="'otp' + index"   
                                        maxlength="1"
                                        [(ngModel)]="otpValues[index]"
                                        (input)="focusNext(otpInput, index + 1, index - 1, true)"
                                        (paste)="handleEmailOtpPaste($event, index)"
                                      >
                                  </div>                         
                              </ng-container>
                            </div>
                        </div>

                        <div>
                            <label for="smsOTP" class="text-sm font-medium text-gray-700">SMS OTP</label>
                            <!-- <input
                                id="smsOTP"
                                class="w-full mt-1 p-3 rounded-lg border border-gray-300 focus:ring focus:ring-blue-200 focus:outline-none"
                                type="text"
                                placeholder="Enter SMS OTP"
                            > -->
                            <div class="flex flex-row items-center justify-between mx-auto w-full  gap-2 md:gap-0 mt-2">
                              <ng-container *ngFor="let index of [0, 1, 2, 3, 4, 5]">
                                  <div class="md:w-16 md:h-16 w-12 h-12">
                                      <input
                                        #smsotpInput
                                        class="w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-md border border-[#ccc] text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700 text-black"
                                        type="text"
                                        [name]="'smsotp' + index" 
                                        [id]="'smsotp' + index"   
                                        maxlength="1"
                                        [(ngModel)]="smsotpValues[index]"
                                        (input)="focusNext(smsotpInput, index + 1, index - 1, false)"
                                        (paste)="handleSmsOtpPaste($event, index)"
                                      >
                                  </div>                         
                              </ng-container>
                            </div>
                        </div>

                        <div class="flex justify-center">
                            <button class="rounded-full bg_primary pt-3 pb-3 lg:px-32 md:px-20 px-14 font-medium md:text-xl text-lg text-white border border-transparent hover:border-[#004AAD] hover:bg-white hover:text-[#004AAD]" (click)="verifyOtp()">
                                Verify Account
                            </button>
                        </div>
                        <div class="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                            <p>Didn't receive the code?</p> <a class="flex flex-row items-center text-[bg_primary] cursor-pointer"  rel="noopener noreferrer" (click)="resendOtp()" >Resend</a>
                          </div>
                    </div>
                </form>
            </div>
    </div>
</div>

  