<!-- car pick up info -->
<div id="schedule-pickdrop1" tabindex="-1" aria-hidden="true" class="flex justify-center items-center  fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75" [ngClass]="{'flex': showSummaryModal,'hidden': !showSummaryModal}">
    <div class="relative w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow  bg-popup secondary-font">
            <!-- Modal header -->
            <div class="flex items-start justify-center p-4 rounded-t-lg bg_primary">
                <h3 class="text-2xl	 font-medium text-white text-center secondary-font">
                    Car {{pickupData?.type ? 'Pick-Up' : 'drop-Off'}} Summary
                </h3>
            </div>
            <button type="button" class="absolute top-3 end-2.5 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" (click)="hideModal()">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <!-- Modal body -->
            <div class="p-6">
                <div class="agency-head flex items-center justify-between mb-4">
                    <div class="agency-info-pickup">
                        <h3 class="dealer-agency-name font-semibold	text-xl leading-none m-0 secondary-font">{{pickupData?.companyName}}</h3>
                        <p class="agency-owner-name font-medium  text-base">{{pickupData?.dealershipName}}</p>
                    </div>
                </div>
                <div class="schedule-detail">
                    <p class="flex items-center mb-4 text-base text-[#3A3F4D]"> <img  alt="car image" src="assets/img/date-icon.png" class="mr-3"> {{pickupData?.scheduleDate | date: 'd MMMM y'}}</p>
                    <p class="flex items-center mb-4 text-base text-[#3A3F4D]"> <img alt="car image" src="assets/img/time-icon.png" class="mr-3"> {{scheduleTime}}</p>
                    <p class="flex items-center text-base text-[#3A3F4D]"> <img alt="car image" src="assets/img/location-icon.png" class="mr-3">{{pickupData?.location}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
