import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-dealer-list',
  templateUrl: './dealer-list.component.html',
  styleUrls: ['./dealer-list.component.scss']
})
export class DealerListComponent  implements OnInit{
  public carId:any;
  public displayStyle:string = 'none'
  public user:any;
  public bidOffers:any;
  public car:any;
  public dealer:any;
  public dealerId:any;
  public dealerBidAmount:number = 0;
  public isBidAccepted:boolean = false;
  public totalItems:number = 1;
  public userAddress:string = '';
  constructor(private fb: FormBuilder, private service: CommonService, private router: Router, private activateRaoute: ActivatedRoute) {
    if(this.service.isBrowserPlateformId()){

      this.user = JSON.parse(`${localStorage.getItem('user')}`)
    }
    this.activateRaoute.params.subscribe((res: any) => {
      this.carId = res.id;
      this.getCar();
    })
  }


  acceptBid(bidId:number, dealerId:number,parentUserId:number){
    this.service.showSpinner();
    let data = {
      bidId : bidId,
      dealerId : dealerId,
      sellerId: this.user.id,
      carId : this.carId,
      parentUserId:parentUserId,
      vinNumber:this.car.vinNumber,
      sellerName : this.user.username,
      sellerEmail: this.user.email,
      carName: this.car.carFullName,
      customerName: this.car.username,
      isAdminLoggedIn : this.user.isAdminLoggedIn
    }
    this.service.postApi(`bids/accept-bid`,data).subscribe((res: any) => {
      if(res.status ==200){
        this.getBidOffers();
        this.service.hideSpinner();
      }
      else{
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }

  getDealerDeatails(id:number,bidAmount:number,parentUserId:number) {
    this.service.showSpinner();
    this.dealerBidAmount = bidAmount
    let data = {
      userId:id,
      parentUserId :parentUserId > 0 ? parentUserId:0
    }
    this.service.postApi(`users/dealer-details`,data).subscribe((res: any) => {
      if(res.status ==200){
        this.dealer = res.user;
        this.userAddress = `${this.dealer?.addressLine1}, ${this.dealer?.addressLine2 ? this.dealer?.addressLine2 + ", " : ''} ${this.dealer?.city} , ${this.dealer?.state}, United State, ${this.dealer?.zipCode}`
        this.displayStyle = 'flex'
        this.service.hideSpinner();
      }
      else{
        this.totalItems =0;
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }

  

  getCar(): void {
    this.service.showSpinner();
    let data = {
      id: this.user.id,
      carId: this.carId,
      userTimeZone :'Asia/Calcutta'
    }
    if(this.service.isBrowserPlateformId()){
      data.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ;
      console.log("userTimeZone", data.userTimeZone)
    } 

    this.service.postApi(`cars/get-cars`, data).subscribe(
      (data: any) => {
        this.car = data.cars[0]; 
        this.getBidOffers();
        // this.service.hideSpinner();
      },
      error => {
        console.error('Error fetching cars:', error);
        this.service.hideSpinner(); 
      }
    );
  }

  ngOnInit(): void {
    if(this.service.isBrowserPlateformId()){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      window.addEventListener('click', (event) => {
        if (event.target === document.getElementById('agency-popup-deal')) {
          this.displayStyle = 'none'
        }
      });
    }
  }
  

  getBidOffers(){
    let data={
      carId : this.carId,
      sellerId: this.user.id,
      sellerZipCode : this.user.zipCode
    }
    this.bidOffers = []
    this.service.showSpinner();
    this.service.postApi(`bids/bid-offer`,data).subscribe((res: any) => {
      if (res.status == 200) {
        this.bidOffers = res.bidOffers;
        this.totalItems = res.bidOffers.length
        this.bidOffers.forEach((element:any)=>{          
          if(element.bidWinnerId){
            this.isBidAccepted = true;
          }
        })
        this.service.hideSpinner();
      }
      else {
        this.totalItems = 0
        this.service.hideSpinner();
        // this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }
  // showModal(id:number) { 
  //   this.dealerId = id
  //   this.displayStyle = 'flex'
  // }
  hideModal() {
    this.displayStyle = 'none'
  }
  calculateStarClass(i:number,averageRating:number): string {
    const integerPart = Math.floor(averageRating || 0);
    const fractionalPart = averageRating % 1;
  
    if (i + 1 <= integerPart) {
      return 'fa fa-star active text-xl';
    } else if (i + 0.5 === averageRating || (i + 1 === integerPart + 1 && fractionalPart > 0)) {
      return 'fas fa-star-half-alt active text-xl';
    } else {
      return 'inactive fa fa-star text-xl';
    }
  }
}
