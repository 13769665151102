<div class="inner-banner bg-cover z-0 relative" >
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name" class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center" >
        <div class="container mx-auto  ">
            <div class="max-w-screen-sm	mx-auto z-50  flex-1 px-6 ">
                <div class="block text-center">
                    <h1 class="md:text-5xl text-4xl text-white font-bold ">Dashboard</h1>       
                </div>
            </div>
        </div>
    </div>
</div>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4">    
            <input type="checkbox"  class="openSidebarMenu" id="openSidebarMenu">
            <label for="openSidebarMenu" class="sidebarIconToggle">
                <div class="spinner diagonal part-1"></div>
                <div class="spinner horizontal"></div>
                <div class="spinner diagonal part-2"></div>
            </label>
            <div class="z-2 xl:w-3/12 min-[993px]:w-5/12 w-full px-4 mb-10 min-[992px]:mb-0 hidden lg:block" id="sidebar_menu">
        <app-sidebar></app-sidebar>
    </div>
            <div class="px-4 xl:w-9/12 md:w-7/12 w-full" >
                <div class="grid grid-cols-2 gap-6 mb-8" *ngIf="accountStatement">
                    <div class="block-area border bg-white border-[#E3E3E3] rounded-lg overflow-hidden">
                        <div class="bg_primary px-4 py-4 text-white font-bold text-2xl text-center codec-font leading-none">Kargone Credits in Account</div>
                        <div class="p-16 flex items-center justify-center">
                            <div class="text_primary_color font-bold text-6xl">{{wallet?.amount}}</div>
                        </div>
                    </div>
                    <div class="block-area border bg-white border-[#E3E3E3] rounded-lg overflow-hidden">
                        <div class="bg_primary px-4 py-4 text-white font-bold text-2xl text-center codec-font leading-none"> Total Value Of Kargone Credits</div>
                        <div class="p-16 flex items-center justify-center">
                            <div class="text-green font-bold text-6xl">${{wallet?.totalCreditValue}}</div>
                        </div>
                    </div>
                </div>
                <div class="w-full flex justify-end mb-8">
                    <div class=" rounded-md  text-[#3A3F4D] md:text-lg text-base inline-flex items-center">
                         <!-- <span>
                            <input type="text" name="daterange" class="w-full px-4 md:pl-14 pl-10 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/date-icon-black.png)] bg-no-repeat	bg-[center_left_1rem] md:bg-[length:24px] bg-[length:18px]	!shadow-none outline-none cursor-pointer focus:ring-0 focus:ring-offset-0" value="">
                        </span> -->

                        <span class="flex justify-between items-center gap-2">
                            <input type="text" name="daterange" placeholder="Select Date Range"
                            class="w-full px-4 md:pl-14 pl-10 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/date-icon-black.png)] bg-no-repeat	bg-[center_left_1rem] md:bg-[length:24px] bg-[length:18px]	!shadow-none outline-none cursor-pointer focus:ring-0 focus:ring-offset-0"
                            value="">
                            <button (click)="resetCarModelData()" title="reset" class="btn bg-blue-800 hover:bg-blue-700  btn-circle "> <i class="fa fa-refresh text-lg text-white" aria-hidden="true"></i> </button>
                        </span>
                    </div>
                    <button class="border-0 outline-0 bg_primary rounded-md text-base text-white py-3 px-8 ml-3" *ngIf="!accountStatement" (click)="generatePdf(0)">Export Invoice </button>
                </div> 
                <div class="relative overflow-x-auto sm:rounded-lg custom-scrollbar " *ngIf="accountStatement">
                    <table class="w-full text-sm text-left grey_text_500">
                        <thead class="text-base  bg_primary text-white font-normal text-center">
                            <tr>
                                <th scope="col" class="px-4 py-6 font-semibold text-left">
                                    S.No.
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Date & Time
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Transaction Information 
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Credits Purchased
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap">
                                    Credits Used
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap">
                                    Amount  
                                </th>
                            </tr>
                        </thead>
                        <tbody class="text-center  font-medium text-sm">
                            <tr class="bg-white grey_text_500 text-sm " *ngFor="let item of creditLogs | paginate : {itemsPerPage: item_per_page,currentPage: current_page,totalItems: total_items}; let i=index">
                                <td scope="row" class="px-4 py-4">
                                    <span class="text-sm">{{(i+((current_page-1)*item_per_page))+1}}</span>
                                </td>
                                <td class="px-4 py-6 ">
                                    <p>{{ item?.createdAt | date:'d MMMM y h:mm a' }}</p>
                                </td>
                                <td class="px-4 py-6">
                                    {{item?.action}}
                                </td>
                                <td class="px-4 py-6">
                                    {{(item?.isCreditPurchased) ? item?.credit : '--' }}
                                </td>
                                <td class="px-4 py-6">
                                    {{ (item?.isCreditRemoved ) ? item?.credit : ((!item.isCreditPurchased && item?.amount>0) ? 1 : '--') }}
                                </td>
                                <td class="px-4 py-6 action">
                                    ${{item?.amount}} 
                                </td>
                            </tr>
                          
                        </tbody>
                    </table>
                </div>

                <div class="relative overflow-x-auto sm:rounded-lg custom-scrollbar " *ngIf="isCreditLogs">
                    <table class="w-full text-sm text-left grey_text_500">
                        <thead class="text-base  bg_primary text-white font-normal text-center">
                            <tr>
                                <th scope="col" class="px-4 py-6 font-semibold text-left">
                                    S.No.
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Date & Time
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Credit Purchased
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Amount Spent
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap">
                                    Payment Method
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap">
                                    Payment Status  
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap">
                                    Receipt  
                                </th>
                            </tr>
                        </thead>
                        <tbody class="text-center  font-medium text-sm">
                            <tr class="bg-white grey_text_500 text-sm " *ngFor="let item of creditLogs | paginate : {itemsPerPage: item_per_page,currentPage: current_page,totalItems: total_items}; let i=index">
                                <td scope="row" class="px-4 py-4">
                                    <span class="text-sm">{{(i+((current_page-1)*item_per_page))+1}}</span>
                                </td>
                                <td class="px-4 py-6 ">
                                    <p>{{ item?.createdAt | date:'d MMMM y h:mm a' }}</p>
                                </td>
                                <td class="px-4 py-6 action">
                                    {{(item?.isCreditPurchased) ? item?.credit : '--' }} 
                                </td>
                                <td class="px-4 py-6">
                                    ${{ item?.amount}}
                                </td>
                                <td class="px-4 py-6">
                                    {{ item?.paymentMethod}}
                                </td>
                                <td class="px-4 py-6">
                                    {{ item?.paymentStatus}} 
                                </td>
                                <td class="px-4 py-6 action cursor-pointer" (click)="generatePdf(item?.id)">
                                    <img alt="car image" src="assets/img/pdf-icon.svg" class="w-10">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="relative overflow-x-auto sm:rounded-lg custom-scrollbar " *ngIf="isBillingInvoice">
                    <table class="w-full text-sm text-left grey_text_500">
                        <thead class="text-base  bg_primary text-white font-normal text-center">
                            <tr>
                                <th scope="col" class="px-4 py-6 font-semibold text-left">
                                    S.No.
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Date & Time
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Car VIN No.
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Customer Name
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap">
                                    Car Info
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold ">
                                    Credits Used  
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold ">
                                    Payment Method
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap">
                                    Invoice  
                                </th>
                            </tr>
                        </thead>
                        <tbody class="text-center  font-medium text-sm">
                            <tr class="bg-white grey_text_500 text-sm " *ngFor="let item of creditLogs | paginate : {itemsPerPage: item_per_page,currentPage: current_page,totalItems: total_items}; let i=index">
                                <td scope="row" class="px-4 py-4">
                                    <span class="text-sm">{{(i+((current_page-1)*item_per_page))+1}}</span>
                                </td>
                                <td class="px-4 py-6 ">
                                    <p>{{ item?.createdAt | date:'d MMMM y h:mm a' }}</p>
                                </td>
                                <td class="px-4 py-6">
                                    {{item?.carNumber}}
                                </td>
                                <td class="px-4 py-6">
                                    {{item?.customer}}
                                </td>
                                <td class="px-4 py-6">
                                    {{item?.carName}} 
                                </td>
                                <td class="px-4 py-6 action">
                                    1 
                                </td>
                                <td class="px-4 py-6 action">
                                    {{item?.paymentMethod}}
                                </td>
                                <td class="px-4 py-6 action cursor-pointer" (click)="generatePdf(item?.id)">
                                    <img alt="car image" src="assets/img/pdf-icon.svg" class="w-10">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-4">
                    <pagination-controls class="pagination-align text-right" max="10" [autoHide]="true" [responsive]="true" (pageChange)="pageChanged($event)"></pagination-controls>   
                </div>
            </div>           
        </div>
    </div>
</section>
<!-- <div *ngIf="isBillingInvoice">
    <app-billing-invoice [creditLogs]="creditLogs"></app-billing-invoice>
</div>

<div *ngIf="isCreditLogs">
<app-credit-receipt [creditLogs]="creditLogs"></app-credit-receipt>
</div> -->