import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl } from '../../global.variables';
import { CommonService } from 'src/app/common.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private http: HttpClient, private service: CommonService) { }

  postApi(data: any) {
    var httpHeaders;
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': `Bearer ${this.service.isBrowserPlateformId()? localStorage.getItem('token'): ''}`
      })

    }
    return this.http.post(apiUrl + `wallets/get-wallet`, data, httpHeaders)
  }
  
}
