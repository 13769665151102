import { Component, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent {
  public otpValues: string[] = ['', '', '', '', '', ''];
  public smsotpValues: string[] = ['', '', '', '', '', ''];
  public isRoleSeller: boolean = false

  public userId: number=0
  @ViewChildren('otpInput') otpInput!: QueryList<ElementRef>;
  @ViewChildren('smsotpInput') smsotpInput!: QueryList<ElementRef>;
  // public userInfo:any;
  public userData:any;

  constructor(private service: CommonService, private router: Router,private activateRaout :ActivatedRoute) {
    this.activateRaout.params.subscribe((res:any)=>{
      this.userId = res.id;
      if(this.userId){
        this.getUserById();
      }
    })
    // this.userInfo = JSON.parse(`${localStorage.getItem('verificationData')}`)
   }
  // get user details and patch form value
  getUserById() {
    this.service.showSpinner();
    this.service.getApi(`users/get-user/${this.userId}`).subscribe((res: any) => {
      if(res.status ==200){
        this.userData = res.user
        this.isRoleSeller = res.user.role
        console.log("🚀 ~ file: verification.component.ts:35 ~ VerificationComponent ~ this.service.getApi ~ userData:", this.userData)
        this.service.hideSpinner()
      }
      else{
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }
  verifyOtp() {
    const otp = this.otpValues.join('');
    const smsotp = this.smsotpValues.join('');
    if(!this.isRoleSeller && !otp){
      this.service.showError('Please enter email otp');
      return
    }
   else if(!smsotp){
      this.service.showError('Please enter sms otp');
      return
    }
    let data= {
      userId : this.userId,
      emailOtp : otp,
      smsOtp : smsotp
    }
    if(this.service.isBrowserPlateformId()){

      localStorage.removeItem('user');
    }
    this.service.showSpinner();
    this.service.postApi('auths/verify-otp', data).subscribe((res: any) => {
      if (res.status == 200) {
        if (res.user.role == 1) {
        this.service.user.next(res.user)
        let localUserData = {
          id: res.user.id,
          parentUserId:res.user.parentUserId,
          username: res.user.username,
          email:res.user.email,
          phone:res.user.phone,
          city: res.user.city,
          state: res.user.state,
          country: res.user.country,
          zipCode: res.user.zipCode,
          role: res.user.role,
          isAdminLoggedIn : 0 
        }
        if(this.service.isBrowserPlateformId()){
          localStorage.setItem('user',`${JSON.stringify(localUserData)}`)
          localStorage.setItem('token', res.user.token);
          let userInfo = JSON.parse(`${localStorage.getItem('verificationData')}`) 
          if(userInfo && userInfo.isSeller){
            this.service.showSuccess('Congratulations on successfully creating your Kargone account! Please click on “Close” to continue.');
          }
          else{
            this.service.showSuccess(res.message);
          }
          localStorage.removeItem('signupData')
          localStorage.removeItem('signupData1')
          let tempcarId = localStorage.getItem('tempCarid');        
          if(tempcarId){
            this.addCar(Number(tempcarId));
          }
        }
        this.service.hideSpinner();
          this.router.navigate(['seller/car-list-new']);
        }
        else {
          this.service.hideSpinner();
          if(this.userData.parentUserId != this.userData.id){
            this.router.navigate([`/reset-password/${this.userId}`]);
          }
          else{
            this.service.showSuccess('Congratulations! Your account has been successfully created. Please be patient as our admin reviews and approves your account before you can login. Thank you for choosing our platform!')
            // this.router.navigate(['dealer/dealer-performance'])
            this.router.navigate(['login'])
          }
        }
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }

  addCar(carId:number){
    this.service.showSpinner();
    let data = {
      carId:carId,
      userId:this.userId,
      sellerEmail : this.userData.email,
      sellerName: this.userData.username,
      phone:this.userData.phone
    }
    this.service.postApi('tempcars/update-temp-car', data).subscribe((res: any) => {
      this.service.hideSpinner();
      if(this.service.isBrowserPlateformId()){

        localStorage.removeItem('tempCarid')
        localStorage.removeItem('carFormData')
        localStorage.removeItem('carData')
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }
  ngAfterViewInit() {
  }

  focusNext(currentInput: HTMLInputElement, nextIndex: number, prevIndex: number, isEmailOTP: boolean) {
    const prefix = isEmailOTP ? 'otp' : 'smsotp';
    if(this.service.isBrowserPlateformId()){
    if (nextIndex >= 0 && nextIndex <= 6) {
      if (currentInput.value.length === 1) {
        const nextInput = document.getElementById(`${prefix}${nextIndex}`) as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      } else if (currentInput.value.length === 0 && prevIndex >= 0) {
        const prevInput = document.getElementById(`${prefix}${prevIndex}`) as HTMLInputElement;
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
    }
}

// Handle paste for Email OTP
handleEmailOtpPaste(event: ClipboardEvent, currentIndex: number) {
  this.handlePaste(event, currentIndex, this.otpValues);
}

// Handle paste for SMS OTP
handleSmsOtpPaste(event: ClipboardEvent, currentIndex: number) {
  this.handlePaste(event, currentIndex, this.smsotpValues);
}

// Common paste handling function
handlePaste(event: ClipboardEvent, currentIndex: number, targetArray: string[]) {
  event.preventDefault();
  if(this.service.isBrowserPlateformId()){

    const clipboardData = event.clipboardData || (window as any).clipboardData;
    if (!clipboardData) {
        return;
    }
    const pastedText = clipboardData.getData('text');
    for (let i = 0; i < targetArray.length; i++) {
        if (i >= currentIndex && i < currentIndex + pastedText.length) {
            targetArray[i] = pastedText.charAt(i - currentIndex);
        }
    }
  }
  
  // Distribute the pasted value to the target array (either email OTP or SMS OTP)
}



  resendOtp() {
    this.service.showSpinner();
    this.service.postApi('auths/resend-otp',  {userId : this.userId,}).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.showSuccess(res.message);
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }
}
