import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  public privacyPolicyData:any;
  constructor(private service:CommonService, @Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)){
      this.getData()
    }
   
    this.service.scrollToTop()
    
  }
  getData(){
    this.service.showSpinner();
    this.service.postApi('privacy-policies/getPrivacyPolicy',{isWebsite:true}).subscribe((res: any) => {
      if(res.status ==200){
        this.privacyPolicyData = res.privacyPolicy
        this.service.hideSpinner();
      }
      else{
        this.service.showError(res.message)
        this.service.hideSpinner();
      }
  
    }, (error) => {
      this.service.hideSpinner();
    })
  }
}
