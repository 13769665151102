<header class="fixed w-full top-0 left-0 bg_primary z-20 md:py-6 py-4 transition-all ease-in-out duration-300"
    >
    <div class="container max-w-screen-xl mx-auto">
        <div class="flex flex-wrap items-center justify-between px-4">
            <div class="logo w-4/12 md:mx-[0]  sm:w-4/12 transition-all ease-in-out duration-500 cursor-pointer"
                >
                <img src="assets/icons/logo.svg" alt="head1" srcset="">
            </div>
        </div>
    </div>
</header>

<section class="bg-[#EBEBEB]">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">

        <div class="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center">
                <h2 class="lg:text-4xl text-2xl font-bold leading-none text_primary_color">Reset Password</h2>
                <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
            </div>
            <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5 " [formGroup]="resetForm">

                <div class="relative">   
                    <label for="password" class="block mb-2 text-base">New Password</label>
                    <input [type]="showEye ? 'text' : 'password'" name="password" id="password" placeholder="New Password" class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 ng-pristine ng-invalid ng-touched text-black"  formControlName="password">
                    <i class="fa eye-icon" aria-hidden="true"
                                [ngClass]="{'fa-eye-slash': !showEye,'fa-eye': showEye}" (click)="showHidePassword()"></i>
                    <div *ngIf="f['password'].invalid && (f['password'].dirty || f['password'].touched)" class="error-message">
                        <div *ngIf="f['password'].errors?.['required']">*Password is required</div>
                        <div *ngIf="f['password'].hasError('pattern')">
                          *Password must meet the following requirements:
                          <ul>
                            <li *ngIf="f['password'].hasError('lowercase')">At least one lowercase letter</li>
                            <li *ngIf="f['password'].hasError('uppercase')">At least one uppercase letter</li>
                            <li *ngIf="f['password'].hasError('digit')">At least one digit</li>
                            <li *ngIf="f['password'].hasError('special')">At least one special character</li>
                            <li *ngIf="f['password'].hasError('length')">Between 7 and 16 characters</li>
                          </ul>
                        </div>
                    </div>
                </div>
                <div class="relative">
                    <label for="confirm-password" class="block mb-2 text-base">Confirm password</label>
                    <input [type]="showEye ? 'text' : 'password'" name="confirm-password" id="confirm-password" placeholder="Confirm Password" class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 ng-pristine ng-invalid ng-touched text-black"  formControlName="confirm_password">
                    <i class="fa eye-icon" aria-hidden="true"
                                [ngClass]="{'fa-eye-slash': !showEye,'fa-eye': showEye}" (click)="showHidePassword()"></i>
                    <div *ngIf="(f.password.value != f.confirm_password.value) && f.confirm_password.dirty"
                    class="error-message">
                    *Confirm Password does not match with the password.
                </div>
                </div>

                <div class="flex justify-center">
                    <button class="rounded-full bg_primary pt-3 pb-3 lg:px-16 md:px-10 px-8 font-medium md:text-xl text-lg text-white border border-transparent hover:border-[#004AAD] hover:bg-white hover:text-[#004AAD] cursor-pointer" (click)="resetPassword()" [disabled]="!resetForm.valid">
                        Reset Password
                    </button>
                </div>
            </form>
        </div>
    </div>
  </section>