import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonService } from 'src/app/common.service';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  public aboutusData: any;
  // firstSectionVideoUrl:any;
  // thirdSectionVideoUrl:any;
  firstSectionVideoUrl: SafeResourceUrl | undefined;
  thirdSectionVideoUrl: SafeResourceUrl | undefined;

  constructor(private service: CommonService, private sanitizer: DomSanitizer, private meta: Meta, @Inject(PLATFORM_ID) private platformId: any) {
    this.service.scrollToTop()
    this.getAboutUsData();
  }


  // updateMetaTag(){
  //   this.meta.updateTag({ property: 'og:description', content: 'vishal' });
  // }

  // getAboutUsData() {
  //   this.service.showSpinner();
  //   this.service.getApi('aboutuses/getAbout').subscribe(
  //     (res: any) => {
  //       if (res.status === 200) {
  //         this.aboutusData = res.aboutUs[0];
  //         // Use the sanitizer to set the safe URL
  //         if (isPlatformBrowser(this.platformId)){
  //           this.firstSectionVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.aboutUs[0].firstSectionVideoUrl);
  //           this.thirdSectionVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.aboutUs[0].thirdSectionVideoUrl);
  //         }


  //         this.service.hideSpinner();
  //       } else {
  //         this.service.showError(res.message);
  //         this.service.hideSpinner();
  //       }
  //     },
  //     (error) => {
  //       this.service.hideSpinner();
  //     }
  //   );
  // }

  getAboutUsData() {
    this.service.showSpinner();
    this.service.getApi('aboutuses/getAbout').subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.aboutusData = res.aboutUs[0];
          // Use the sanitizer to set the safe URL
          // if (isPlatformBrowser(this.platformId)) {
          if (res.aboutUs[0].firstSectionFileType == 1) {
            this.firstSectionVideoUrl = this.sanitizeUrl(res.aboutUs[0].firstSectionVideoUrl);
          }
          if (res.aboutUs[0].thirdSectionFileType == 1) {
            this.thirdSectionVideoUrl = this.sanitizeUrl(res.aboutUs[0].thirdSectionVideoUrl);
          }
          this.service.hideSpinner();
        } else {
          this.service.showError(res.message);
          this.service.hideSpinner();
        }
      },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }

  private sanitizeUrl(url: string): SafeResourceUrl {
    console.log(url)
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}