<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name"
            class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center">
        <div class="container mx-auto  ">
            <div class="max-w-screen-sm	mx-auto z-50  flex-1 px-6 ">
                <div class="block text-center">
                    <h1 class="md:text-5xl text-4xl text-white font-bold ">Dashboard</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4 justify-between">
            <div class="px-4 w-full flex justify-end mb-8 ">
                <div
                    class="rounded-md  text-[#3A3F4D] md:text-lg text-base inline-flex items-center">
                    <!-- <span>
                        <input type="text" name="daterange" class="w-full px-4 md:pl-14 pl-10 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/date-icon-black.png)] bg-no-repeat	bg-[center_left_1rem] md:bg-[length:24px] bg-[length:18px]	!shadow-none outline-none cursor-pointer focus:ring-0 focus:ring-offset-0" value="" >
                    </span> -->
                    <span class="flex justify-between items-center gap-2">
                        <input type="text" name="daterange" placeholder="Select Date Range"
                            class="w-full px-4 md:pl-14 pl-10 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/date-icon-black.png)] bg-no-repeat	bg-[center_left_1rem] md:bg-[length:24px] bg-[length:18px]	!shadow-none outline-none cursor-pointer focus:ring-0 focus:ring-offset-0"
                            value="">
                        <button (click)="resetCarModelData()"  title="reset" class="btn bg-blue-800 hover:bg-blue-700  btn-circle "> <i class="fa fa-refresh text-lg text-white" aria-hidden="true"></i> </button>
                    </span>
                </div>
               
            </div>
            <app-responsive-sidebar></app-responsive-sidebar>
            <div class="px-4 xl:w-9/12 lg:w-8/12 md:full w-full">
                <div class="grid md:grid-cols-2  gap-8">
                    <div
                        class="relative bg_primary p-6 rounded-md overflow-hidden shadow-xl  !bg-[url('assets/img/blue-pattern.png')] bg-no-repeat bg-cover">
                        <div class="text-white text-2xl font-medium">Total Number Of Vehicles Purchased</div>
                        <div class="flex justify-between py-6">
                            <div class="purchase-no text-7xl font-semibold text-white leading-none">
                                {{dashboardCount?.purchasedCars}}
                            </div>
                            <div
                                class="absolute w-[216px] h-[110px] after:rounded-full after:-top-3 after:-right-5 after after:absolute after:w-full after:h-[216px] after:bg-white right-0 bottom-0">
                                <img  alt="car image" src="assets/img/car-thumb.png" class="absolute right-6 bottom-0 z-10">
                            </div>
                        </div>
                    </div>
                    <div
                        class="relative bg_primary p-6 rounded-md overflow-hidden shadow-xl  !bg-[url('assets/img/blue-pattern.png')] bg-no-repeat bg-cover">
                        <div class="text-white text-2xl font-medium">Total Number Of Offers Made</div>
                        <div class="flex justify-between py-6">
                            <div class="purchase-no text-7xl font-semibold text-white leading-none">
                                {{dashboardCount?.offers}}

                            </div>
                            <div
                                class="absolute w-[216px] h-[110px] after:rounded-full after:-top-3 after:-right-5 after after:absolute after:w-full after:h-[216px] after:bg-white right-0 bottom-0">
                                <img alt="car image" src="assets/img/car-thumb1.png" class="absolute right-6 bottom-0 z-10">
                            </div>
                        </div>
                    </div>
                    <div
                        class="relative bg_primary p-6 rounded-md overflow-hidden shadow-xl  !bg-[url('assets/img/blue-pattern.png')] bg-no-repeat bg-cover">
                        <div class="text-white text-2xl font-medium">Total Number Of Offers Won</div>
                        <div class="flex justify-between py-6">
                            <div class="purchase-no text-7xl font-semibold text-white leading-none">
                                {{dashboardCount?.wonOffers}}
                            </div>
                            <div
                                class="absolute w-[216px] h-[110px] after:rounded-full after:-top-3 after:-right-5 after after:absolute after:w-full after:h-[216px] after:bg-white right-0 bottom-0">
                                <img alt="car image" src="assets/img/car-thumb1.png" class="absolute right-6 bottom-0 z-10">
                            </div>
                        </div>
                    </div>
                    <div
                        class="relative bg_primary p-6 rounded-md overflow-hidden shadow-xl  !bg-[url('assets/img/blue-pattern.png')] bg-no-repeat bg-cover">
                        <div class="text-white text-2xl font-medium">Total Number Of Offers Lost</div>
                        <div class="flex justify-between py-6">
                            <div class="purchase-no text-7xl font-semibold text-white leading-none">
                                {{dashboardCount?.lostOffers}}
                            </div>
                            <div
                                class="absolute w-[216px] h-[110px] after:rounded-full after:-top-3 after:-right-5 after after:absolute after:w-full after:h-[216px] after:bg-white right-0 bottom-0">
                                <img alt="car image" src="assets/img/car-thumb.png" class="absolute right-6 bottom-0 z-10">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white p-6 rounded-md mt-12">
                    <div class="md:flex flex-wrap items-center">
                        <div class="md:w-4/12 text-center mb-10 md:mb-0">
                            <div class="review-number lg:text-7xl text-5xl font-semibold my-2">
                                {{starCounts?.averageRating}}</div>
                            <div class="user-rating space-x-1">
                                <span class="lg:w-5 lg:h-5 h-4 w-4 inline-block"
                                    *ngFor="let _ of [].constructor(5); let i = index">
                                    <i [class]="calculateStarClass(i)" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div class="rating-link">
                                
                                <a routerLink="/dealer/rating" class="md:text-lg text-base font-semibold text-[#004AAD]">{{totalRatings}} Review</a>
                            </div>
                        </div>
                        <div class="md:w-8/12">
                            <div class="review-heading text-xl text-black font-medium mb-4">
                               
                                My Reviews
                               
                            </div>
                            <div class="space-y-3">
                                <div *ngFor="let rating of starCounts?.totals"
                                    class="rating-range flex items-center text-base">
                                    <div class="star-block text-center">
                                        <span class="text-[#3A3F4D] text-base pr-10">{{ rating?.star }} Stars</span>
                                        <span class="font-medium text-black text-base">{{ rating?.total }}</span>
                                    </div>
                                    <div class="flex-1 bg-[#E9E9E9] rounded-full h-3.5 ml-10">
                                        <div class="bg_primary h-3.5 rounded-full"
                                            [style.width.%]="calculatePercentage(rating)"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
        </div>
    </div>
</section>