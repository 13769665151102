<!-- <div class="md:flex md:flex-wrap -mx-4">
    <input type="checkbox"  class="openSidebarMenu" id="openSidebarMenu">
    <label for="openSidebarMenu" class="sidebarIconToggle">
        <div class="spinner diagonal part-1"></div>
        <div class="spinner horizontal"></div>
        <div class="spinner diagonal part-2"></div>
    </label>
    <div class="z-2 xl:w-3/12 min-[993px]:w-5/12 w-full px-4 mb-10 min-[992px]:mb-0 hidden md:block" id="sidebar_menu">
</div>         -->
<aside id="default-sidebar" class="w-full " aria-label="Sidebar">
    <div class="py-2  bg-gray-50 rounded-lg max-h-96 overflow-y-auto md:max-h-none" *ngIf="!role">
        <ul class="text-base">
            <li>
                <button  type="button"
                    class="w-full  flex items-center p-4 pb-6 text-base  text-[#3A3F4D] hover:text-[#004AAD] hover:bg-[#EDEDED] group border-l-4 border-transparent hover:font-semibold hover:border-[#004AAD]"
                    aria-controls="dealer-analytics  active:text-[#004AAD] active:bg-[#EDEDED] active:border-[#004AAD]" data-collapse-toggle="dealer-analytics" (click)="toggleDropdown('dealer-analytics')">
                    <span class="inline-block w-6 h-6">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                            style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                            <g>
                            </g>
                            <g>
                                <path d="M95,16.2c-0.1,0.4-0.2,0.7-0.3,1.1c-1.4,4.4-6.3,6.5-10.4,4.5c-0.5-0.3-0.9-0.3-1.3,0c-4.1,2.5-8.2,4.9-12.3,7.3
                            c-0.4,0.3-0.6,0.5-0.6,1c0,3.6-2.7,6.7-6.2,7.3c-3.7,0.6-7.2-1.4-8.4-5c-0.2-0.6-0.5-0.9-1.1-1.2c-3.7-1.8-7.4-3.6-11.1-5.5
                            c-0.4-0.2-0.7-0.2-1.1,0.1c-2.3,1.8-4.9,2.2-7.6,1c-0.5-0.2-0.8-0.2-1.3,0.2c-4.4,3.3-8.8,6.6-13.2,9.9c-0.5,0.3-0.5,0.6-0.4,1.2
                            c1.1,4.2-1.4,8.3-5.7,9.3C10,48.3,6,45.5,5.1,41.2c-0.8-4,2.1-8,6.2-8.6c1.5-0.2,2.9-0.1,4.3,0.6c0.5,0.2,0.8,0.2,1.3-0.1
                            c4.3-3.3,8.7-6.5,13.1-9.8c0.4-0.3,0.6-0.6,0.4-1.1c-0.9-3-0.1-5.7,2.2-7.8c2.3-2.1,5-2.5,7.9-1.3c2.9,1.2,4.4,3.5,4.6,6.6
                            c0,0.2-0.1,0.5,0,0.7c0.1,0.3,0.2,0.7,0.4,0.8c3.5,1.8,7,3.5,10.5,5.2c1.3-2.1,3.1-3.5,5.6-3.8c2.5-0.3,4.6,0.5,6.4,2.3
                            c4.2-2.5,8.3-4.9,12.5-7.4c-0.6-2.1-0.5-4.1,0.5-6c1.5-2.8,4.4-4.3,7.7-3.8c3.1,0.4,5.1,2.3,6.1,5.2c0.1,0.3,0.2,0.6,0.3,0.9
                            C95,14.5,95,15.4,95,16.2z" />
                                <path
                                    d="M95,90.7c-0.7,1.4-1.7,1.9-3.3,1.8c-2.8-0.1-5.6-0.1-8.3,0C80.8,92.6,80,91.3,80,89c0-13.3,0-26.5,0-39.8
                            c0-2.9,0-5.8,0-8.7c0-2.2,0.9-3.1,3.1-3.1c2.9,0,5.8,0,8.7,0c1.5,0,2.6,0.5,3.2,1.9C95,56.5,95,73.6,95,90.7z" />
                                <path d="M30,67.4c0-7.3,0-14.5,0-21.8c0-2.2,0.9-3.1,3.1-3.1c3,0,6,0,9,0c2,0,2.9,1,2.9,3c0,14.7,0,29.3,0,44c0,2-0.9,3-2.9,3
                            c-3.1,0-6.1,0-9.2,0c-1.9,0-2.9-1-2.9-2.9C30,82.1,30,74.8,30,67.4z" />
                                <path d="M70,72.5c0,5.6,0,11.3,0,16.9c0,2.1-0.9,3-3,3c-3,0-6,0-9,0c-2,0-3-0.9-3-2.9c0-11.4,0-22.7,0-34.1c0-2,1-3,3-3
                            c3,0,6.1,0,9.1,0c2,0,3,1,3,3C70,61.2,70,66.8,70,72.5z" />
                                <path d="M5.1,77.4c0-4,0-8.1,0-12.1c0-1.8,0.9-2.9,2.8-2.9c3.1,0,6.3,0,9.4,0c1.9,0,2.8,1,2.8,2.8c0,8.1,0,16.2,0,24.3
                            c0,1.9-1,2.8-2.9,2.8c-3.1,0-6.1,0-9.2,0c-2,0-2.9-0.9-2.9-2.9C5,85.5,5.1,81.5,5.1,77.4z" />
                            </g>
                        </svg>
                    </span>
                    <span class="flex-1 ml-4 text-left whitespace-nowrap"
                        >Dealer Analytics</span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <ul id="dealer-analytics" class="p-2 hidden" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                    <li>
                        <a routerLink="/dealer/dealer-performance" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group text-base hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6 inline-block">
                                <svg version="1.1" id="dealer-performance" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path d="M49.9,7.3c13.6,0,27.1,0,40.7,0c1.5,0,2.1,0.5,2.1,2.1c0,0.9,0,1.8,0,2.8c0,0.8-0.1,1.6-0.7,2.2
                                    c-0.1,0.1-0.2,0.3-0.3,0.5c-1.5,1.8-1.9,3.8-1.9,6.2c0.1,23.2,0,46.3,0,69.5c0,1.6-0.5,2.1-2.1,2.1c-25.2,0-50.4,0-75.6,0
                                    c-1.4,0-1.9-0.5-1.9-2c0-24,0-48.1,0-72.1c0-0.5-0.2-1.1-0.4-1.5c-0.5-0.9-1.1-1.8-1.7-2.6c-0.6-0.7-0.7-1.4-0.7-2.3
                                    c0-1.2,0-2.4,0-3.6c0-0.8,0.5-1.3,1.3-1.4c0.2,0,0.5,0,0.7,0C22.9,7.3,36.4,7.3,49.9,7.3z M84.1,55.7c0,10.5,0,20.9,0,31.3
                                    c-5.2,0-10.4,0-15.5,0c0,1,0,1.9,0,2.8c6.2,0,12.3,0,18.4,0c0-23.7,0-47.4,0-71c-24.7,0-49.3,0-73.9,0c0,23.7,0,47.4,0,71
                                    c6.2,0,12.3,0,18.4,0c0-1,0-1.9,0-2.8c-5.2,0-10.4,0-15.5,0c0-10.5,0-20.8,0-31.2C38.7,55.7,61.3,55.7,84.1,55.7z M68.5,84.1
                                    c4.3,0,8.5,0,12.7,0c0-8.5,0-17,0-25.5c-20.9,0-41.7,0-62.5,0c0,8.5,0,17,0,25.5c4.3,0,8.5,0,12.7,0c0-4.7,0-9.4,0-14.2
                                    c-1.4,0-2.8,0-4.2,0c0-2.9,0-5.7,0-8.5c15.2,0,30.3,0,45.4,0c0,2.8,0,5.6,0,8.5c-1.4,0-2.8,0-4.2,0C68.5,74.7,68.5,79.4,68.5,84.1z
                                    M10.3,10.2c-0.4,1.7,0.1,3.1,1.1,4.3c0.1,0.2,0.3,0.4,0.4,0.6c0.3,0.6,0.8,0.8,1.5,0.8c24.1,0,48.3,0,72.4,0
                                    c1.4,0,2.2-0.3,2.9-1.5c0.8-1.3,1.6-2.6,1.1-4.1c-4.7,0-9.4,0-14.2,0c0,1,0,1.9,0,2.7c-1,0-1.9,0-2.8,0c0-1,0-1.9,0-2.7
                                    c-4.8,0-9.5,0-14.2,0c0,0.9,0,1.8,0,2.7c-0.9,0-1.8,0-2.8,0c0-0.9,0-1.8,0-2.8c-10.5,0-20.8,0-31.3,0c0,0.9,0,1.8,0,2.8
                                    c-1,0-1.9,0-2.8,0c0-0.9,0-1.8,0-2.8c-1.4,0-2.8,0-4.3,0c0,0.9,0,1.8,0,2.8c-1,0-1.9,0-2.8,0c0-0.9,0-1.8,0-2.8
                                    C13,10.2,11.6,10.2,10.3,10.2z M34.4,75.6c0,4.7,0,9.4,0,14.2c4.7,0,9.4,0,14.1,0c0-4.7,0-9.4,0-14.2
                                    C43.8,75.6,39.2,75.6,34.4,75.6z M65.6,89.8c0-4.8,0-9.5,0-14.2c-4.7,0-9.4,0-14.1,0c0,4.7,0,9.4,0,14.2
                                    C56.2,89.8,60.9,89.8,65.6,89.8z M30.1,67c13.3,0,26.5,0,39.8,0c0-1,0-1.9,0-2.7c-13.3,0-26.5,0-39.8,0
                                    C30.1,65.2,30.1,66.1,30.1,67z M65.6,70c-10.5,0-20.8,0-31.2,0c0,1,0,1.9,0,2.7c10.4,0,20.8,0,31.2,0C65.6,71.8,65.6,70.9,65.6,70z
                                    " />
                                        <path d="M15.9,21.6c22.7,0,45.4,0,68.2,0c0,10.4,0,20.8,0,31.2c-22.7,0-45.4,0-68.2,0C15.9,42.4,15.9,32,15.9,21.6z
                                    M32.9,47.1c0-2.9,0-5.7,0-8.4c0-0.3,0.2-0.6,0.4-0.8c1.7-1.8,3.5-3.6,5.2-5.4c0.7-0.7,1.3-1,2.4-1c4.4,0.1,8.9,0,13.3,0
                                    c0.4,0,0.9,0.1,1.3,0.4c1.3,1,2.5,2,3.8,3c0.5,0.4,1.1,0.9,1.7,1c2.9,0.6,5.9,1,8.8,1.6c0,3.2,0,6.4,0,9.7c-2.9,0-5.8,0-8.7,0
                                    c-0.8,1.5-1.3,2-3,2.8c7.7,0,15.4,0,23.1,0c0-7.8,0-15.6,0-23.5c-1.9,1.9-3.7,3.7-5.5,5.6c-0.4,0.4-0.7,0.5-1.1,0
                                    c-0.5-0.5-1-1-1.7-1.7c2-2,4-4,6-6c-1,0-2.2,0-3.3,0c-0.4,0-1,0.2-1.3,0.5c-2.8,2.8-5.6,5.5-8.4,8.4c-0.6,0.6-0.9,0.6-1.5,0
                                    c-0.4-0.5-0.9-0.9-1.5-1.5c2.5-2.5,5-5,7.4-7.4c-17.1,0-34.3,0-51.6,0c0,5,0,9.9,0,14.8c0.1,0,0.1,0.1,0.2,0.1
                                    c2.4-2.4,4.9-4.9,7.2-7.2c0.6,0.6,1.3,1.3,2,2c-0.2,0.1-0.4,0.3-0.6,0.5c-2.8,2.8-5.6,5.6-8.4,8.4c-0.2,0.2-0.4,0.5-0.5,0.8
                                    c0,1.3,0,2.6,0,4.1c2.1-2.1,4-4.1,5.9-5.9c0.7,0.7,1.4,1.3,2.2,2.1c-2,2-3.9,3.9-5.9,5.8c6.9,0,13.8,0,20.9,0
                                    c-1.3-0.5-2.3-1.1-2.8-2.2c-0.1-0.3-0.5-0.5-0.8-0.5C36.5,47.1,34.8,47.1,32.9,47.1z M67,39.9c-1.9-0.3-3.8-0.5-5.7-0.9
                                    c-0.9-0.2-1.9-0.2-2.7-0.9c-1-0.8-2-1.6-3-2.4c-1-0.9-2-1.4-3.5-1.4c-3.6,0.1-7.1,0-10.7,0.1c-0.4,0-0.9,0.2-1.2,0.5
                                    c-1.4,1.4-2.8,2.9-4.2,4.4c-0.1,0.2-0.3,0.4-0.3,0.6c0,1.5,0,2.9,0,4.5c1.9,0,3.8,0,5.6,0c0.2,1.1-0.4,2.8,1.4,2.8
                                    c1.7,0,1.5-1.6,1.6-2.7c3.8,0,7.6,0,11.3,0c0.1,1.1-0.4,2.7,1.3,2.7c1.8,0.1,1.6-1.6,1.7-2.8c2.9,0,5.7,0,8.5,0
                                    C67,42.8,67,41.4,67,39.9z M46.9,47.1c-0.5,1.5-1.6,2.3-3.1,2.8c4.2,0,8.2,0,12.2,0c-1.2-0.5-2.2-1.2-2.7-2.4
                                    c-0.1-0.2-0.4-0.4-0.7-0.4C50.6,47.1,48.8,47.1,46.9,47.1z" />
                                        <path
                                            d="M45.7,81.3c0,0.9,0,1.8,0,2.7c-0.9,0-1.8,0-2.8,0c0-0.9,0-1.8,0-2.7C43.8,81.3,44.7,81.3,45.7,81.3z" />
                                        <path
                                            d="M57.1,81.3c0,0.9,0,1.8,0,2.7c-0.9,0-1.8,0-2.8,0c0-0.9,0-1.8,0-2.7C55.2,81.3,56.1,81.3,57.1,81.3z" />
                                    </g>
                                </svg>
                            </span>
                            <span class="ml-4">Dealership Performance </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/employee-performance" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6 inline-block">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path d="M70.2,80.3c-13.5,0-26.9,0-40.4,0c0-0.3,0-0.5,0-0.8c0-5.1-0.1-10.3,0-15.4c0.1-3.2,1.3-6.1,2.9-8.8
                                    c1.3-2.1,3-3.9,4.9-5.5c1-0.8,2.1-0.9,3.3-0.5c2,0.7,4,1.5,6.2,2.3c0,4,0,8.1,0,12.3c0,0.6,0,1.1,0,1.7c0,1.8,1.2,3.2,2.7,3.2
                                    c1.8,0,3.1-1.3,3.1-3.2c0-4.6,0-9.2,0-13.8c0-0.2,0-0.4,0-0.3c2-0.7,3.9-1.4,5.8-2.1c1.7-0.7,3,0,4.3,1c3,2.6,5.2,5.7,6.3,9.4
                                    c0.5,1.7,0.9,3.6,0.9,5.4C70.3,70.2,70.2,75.2,70.2,80.3z" />
                                        <path d="M35.6,31.2C35.3,23.4,42,16.6,50,16.7c8.1,0.1,14.5,6.4,14.5,14.5c0,8-6.5,14.3-14.5,14.4
                                    C42.1,45.8,35.4,39,35.6,31.2z" />
                                        <path
                                            d="M27.2,53.2c-0.7,1.7-1.4,3.3-1.9,4.9c-0.8,2.4-1.3,4.9-1.2,7.5c0,4.8,0,9.7,0,14.5c0,0.1,0,0.1,0,0.3
                                    c-0.2,0-0.4,0.1-0.7,0.1c-4.6,0-9.3,0-13.9,0c-1.2,0-2.8-1.5-2.8-2.7c0-4.4-0.1-8.9,0.1-13.3c0.1-2.7,1.2-5.2,2.9-7.3
                                    c1-1.4,2.3-2.5,3.7-3.4c0.8-0.5,1.7-0.6,2.6-0.3c1.8,0.6,3.6,1.2,5.4,0.9c1.4-0.2,2.9-0.5,4.3-0.8C26.1,53.4,26.6,53.3,27.2,53.2z" />
                                        <path
                                            d="M72.9,53.1c0.9,0.3,1.8,0.6,2.7,0.8c2.5,0.6,5,0.6,7.4-0.3c2-0.8,3.6-0.3,5.2,1.1c2.4,2.1,4.2,4.6,4.8,7.8
                                    c0.2,1.1,0.4,2.2,0.4,3.3c0.1,3.7,0,7.5,0,11.2c0,2-1.2,3.4-3.4,3.4c-4.4-0.1-8.9,0-13.3,0c-0.2,0-0.4,0-0.7,0c0-1.4,0-2.8,0-4.1
                                    c0-3.1,0-6.2,0-9.3c0-4.6-0.9-9.1-3.1-13.2c-0.1-0.1-0.1-0.3-0.2-0.4C72.8,53.3,72.8,53.2,72.9,53.1z" />
                                        <path d="M9.5,39.8c-0.2-6,5.1-11.7,11.6-11.5c6.5,0.2,11.4,5,11.5,11.6c0,6.5-5.2,11.5-11.7,11.6
                                    C14.9,51.6,9.2,46,9.5,39.8z" />
                                        <path d="M67.4,39.9c-0.2-6,5.1-11.7,11.6-11.5c6.5,0.2,11.5,5,11.5,11.6c0,6.4-5.2,11.4-11.6,11.5
                                    C72.7,51.6,67.1,46,67.4,39.9z" />
                                    </g>
                                </svg>

                            </span>
                            <span class="ml-4">Employee Performance </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/vehicle-insights" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  pb-6 transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6 inline-block">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path d="M31.8,99.9c-1.9-0.7-3.6-1.7-5-3.2c-0.1-0.1-0.3-0.2-0.3-0.4c-0.6-1.5-1.8-1.5-3.2-1.5c-1.5,0-3,0.1-4.4-0.2
                                    c-1.5-0.3-2.4-1.5-2.7-3c-0.3-1.6,0.2-2.9,1.6-3.9c0.2-0.2,0.4-0.6,0.4-1c0-3.1,0-6.2,0-9.3c0-2.1,1.1-3.7,3-4.5
                                    c3.5-1.4,5.7-3.8,7.3-7.1c1-1.9,2.1-3.8,3.3-5.5c1.7-2.4,4.2-3.8,7.1-4c4.6-0.3,9.2-0.2,13.7-0.3c3.7,0,7.3,0,11,0c2,0,3.5,0.8,5,2
                                    c3.2,2.8,5.2,6.4,7.4,10c0.3,0.5,0.7,1.1,0.9,1.7c0.4,0.9,1.1,1.3,2.1,1.4c3.2,0.4,6.4,0.7,9.6,1.2c1.6,0.2,3.2,0.6,4.7,1.2
                                    c2.4,1.1,3.7,3.1,3.9,5.7c0.2,2.5,0.2,5.1,0.3,7.6c0,0.4,0.3,0.9,0.6,1.2c2.1,1.8,1.5,5.2-0.3,6.1C97,94.5,96,94.8,95,94.8
                                    c-1.9,0.1-3.8,0-5.7,0.1c-0.4,0-0.8,0.3-1.1,0.6c-1,1.3-2.2,2.5-3.7,3.2c-0.9,0.4-1.7,0.8-2.6,1.2c-1.1,0-2.1,0-3.2,0
                                    c-2.4-0.8-4.5-2.1-5.9-4.3c-0.4-0.6-0.8-0.7-1.4-0.7c-9.4,0-18.9,0-28.3,0c-0.9,0-1.7,0-2.2,1.1c-0.2,0.5-0.7,0.8-1.1,1.2
                                    c-1.4,1.3-3,2.3-4.9,2.8C33.9,99.9,32.9,99.9,31.8,99.9z M41.8,73.3c3.2,0,6.3,0,9.5,0c1.2,0,1.8-0.6,1.8-1.8c0-3.1,0-6.1,0-9.2
                                    c0-1.1-0.5-1.7-1.5-1.7c-3.9,0-7.8,0.1-11.6,0.2c-2.7,0.1-4.5,1.6-5.7,3.9c-1.1,2.2-2.2,4.3-3.3,6.5c-0.5,1.1,0.1,2.1,1.3,2.1
                                    C35.5,73.3,38.6,73.3,41.8,73.3z M64,73.3c2.3,0,4.7,0,7,0c1.5,0,2.1-1.2,1.3-2.4c-0.4-0.7-0.8-1.3-1.2-2c-1.4-2.1-2.8-4.2-4.3-6.3
                                    c-1-1.4-2.4-2.2-4.3-2.1c-1.7,0.2-3.5,0-5.3,0c-1.3,0-1.7,0.4-1.7,1.7c0,3.2,0,6.3,0,9.5c0,0.9,0.6,1.5,1.5,1.5
                                    C59.4,73.3,61.7,73.3,64,73.3z M80.5,84.2c-3.6-0.3-6.4,3-6.4,6.3c0,3.3,3,6.7,6.7,6.4c3.4-0.3,6.1-2.7,6.2-6.5
                                    C87,87,84.1,83.9,80.5,84.2z M33.4,96.9c3.4,0.1,6.8-2.9,6.6-6.7c-0.1-2.7-2.3-6.5-7.3-6c-3,0.3-5.5,2.9-5.5,6.3
                                    C27.1,93.7,29.6,96.8,33.4,96.9z M45.5,78.6C45.5,78.6,45.5,78.6,45.5,78.6c0.6,0,1.3,0,1.9,0c0.8-0.1,1.2-0.6,1.2-1.4
                                    c0-0.8-0.4-1.3-1.2-1.4c-1.2-0.1-2.4-0.1-3.6,0c-0.9,0-1.2,0.7-1.1,1.5c0.1,0.7,0.3,1.3,1.2,1.3C44.4,78.6,44.9,78.6,45.5,78.6z
                                        M62.7,75.9c-0.5,0-1.1,0-1.6,0c-0.8,0-1.5,0.6-1.5,1.4c0,0.7,0.7,1.3,1.5,1.3c1,0,2,0,3,0c1,0,1.5-0.4,1.5-1.3
                                    c0-1.1-0.3-1.4-1.5-1.4C63.6,75.9,63.2,75.9,62.7,75.9z" />
                                        <path
                                            d="M14,88c-0.7,1.7-0.9,3.4-0.2,5.3c-2.8-0.2-5.6,0-8.1-0.6c-3.1-0.7-4.6-3.2-5.2-6.2c-0.1-0.5,0.1-1,0.1-1.4
                                    c0-22.4,0-44.8,0-67.2c0-1.5,0.1-2.9,0.9-4.2c1.4-2.4,3.5-4,6.3-4c3.6-0.1,7.2,0,10.9,0c0,1.8,0,3.5,0,5.2c-0.3,0-0.7,0-1,0
                                    c-3.2,0-6.4,0-9.6,0c-1.6,0-2.4,0.8-2.4,2.4c0,22.8,0,45.6,0,68.4C5.6,87.2,6.4,88,8,88C10,88,11.9,88,14,88z" />
                                        <path
                                            d="M33.1,40.9c0.5,0.8,0.8,1.4,1.2,2c0.5,0.8,0.4,1.4-0.2,2.1c-0.8,0.8-1.5,1.5-2.3,2.3c-0.8,0.8-1.4,0.8-2.3,0.3
                                    c-0.6-0.4-1.3-0.7-2-1c-0.2,0.9-0.3,1.6-0.5,2.3c-0.2,0.8-0.7,1.3-1.6,1.3c-1.2,0-2.3-0.1-3.5,0c-0.9,0.1-1.3-0.5-1.6-1.1
                                    c-0.3-0.7-0.3-1.4-0.5-2.4c-0.5,0.2-1.1,0.4-1.5,0.7c-1.5,1-1.9,0.9-3.2-0.3c-0.7-0.7-1.3-1.4-2-2c-0.5-0.5-0.7-1-0.3-1.7
                                    c0.4-0.7,0.8-1.5,1.3-2.4c-0.8-0.2-1.4-0.3-2.1-0.5c-1.1-0.3-1.6-0.9-1.6-2.1c0-0.9,0-1.7,0-2.6c0-1.4,0.5-2,1.8-2.3
                                    c1.9-0.4,2.2-0.2,1-2.2c-1.1-1.8-1-1.8,0.4-3.2c0.6-0.6,1.2-1.2,1.8-1.7c0.7-0.7,1.4-0.8,2.3-0.3c0.6,0.4,1.3,0.7,2,1
                                    c0.2-0.9,0.4-1.6,0.6-2.3c0.2-0.8,0.6-1.1,1.5-1.1c1.2,0.1,2.4,0.1,3.6,0c1-0.1,1.5,0.3,1.7,1.3c0.1,0.7,0.3,1.3,0.6,2.1
                                    c0.7-0.4,1.4-0.8,2-1.2c0.8-0.5,1.6-0.4,2.2,0.3c0.8,0.8,1.6,1.6,2.4,2.4c0.6,0.6,0.7,1.3,0.2,2c-0.3,0.5-0.7,1-0.9,1.6
                                    c-0.2,0.5-0.3,0.8,0.5,1c3.3,0.9,2.7,0.3,2.7,3.4c0,0.6,0,1.2,0,1.8c0,1.2-0.3,1.6-1.5,1.9C34.5,40.5,33.9,40.7,33.1,40.9z
                                        M23.5,42.6c3.1,0.2,5.8-2.6,5.7-5.7c0-2.9-2.5-5.8-5.8-5.9c-3.1-0.1-5.7,3.2-5.7,5.9C17.8,39.8,20.2,42.8,23.5,42.6z" />
                                        <path d="M21.5,8.3c1.5,0,2.9,0,4.3,0c2,0,2.3-0.2,2.5-2.2c0.4-3.5,3.2-6,6.6-5.9c3.3,0.1,5.7,1.8,6.6,5.3
                                    c0.2,0.6,0.1,1.2,0.2,1.8c0.2,0.8,0.7,1.1,1.5,1c1.7,0,3.4,0,5,0c0,2.6,0.1,5.1,0,7.6c-0.1,1.3-1.3,2.2-2.7,2.3
                                    c-7,0.1-14.1,0-21.1,0c-1.6,0-2.8-1-2.9-2.6C21.4,13.1,21.5,10.8,21.5,8.3z M34.8,8.5c1.1,0,1.8-0.6,1.8-1.6c0-0.9-0.6-1.5-1.6-1.6
                                    c-1.1-0.1-1.7,0.5-1.8,1.6C33.2,7.8,33.9,8.4,34.8,8.5z" />
                                        <path d="M51.3,14.6c0-1.7,0-3.3,0-5.3c3.5,0.2,7,0.1,10.4,0.6c2.7,0.4,5.2,3.2,5.5,6.3c0.1,0.7,0.1,1.4,0.1,2.1
                                    c0,11.4,0,22.8,0,34.2c0,0.4,0,0.8,0,1c-1.7-0.1-3.3-0.3-4.9-0.4c-0.1,0-0.1-0.1-0.2-0.1c0-0.4,0-0.7,0-1.1c0-11.4,0-22.7,0-34.1
                                    c0-1.7-1.2-3-2.9-2.9c-2.6,0.1-5.2,0-7.8,0C51.4,14.7,51.4,14.6,51.3,14.6z" />
                                        <path
                                            d="M48.1,45.2c2.5,0,5,0,7.4,0c0.4,0,0.8,0,1,0.2c0.4,0.3,0.8,0.8,0.8,1.2c0,0.4-0.4,0.9-0.7,1.2
                                    c-0.2,0.2-0.6,0.3-0.9,0.3c-5.1,0-10.2,0-15.4,0c-1,0-1.6-0.5-1.6-1.3c0-1,0.5-1.5,1.7-1.6C43,45.2,45.6,45.2,48.1,45.2z" />
                                        <path
                                            d="M48.1,36.5c-2.6,0-5.2,0-7.8,0c-1.1,0-1.5-0.5-1.5-1.5c0-0.9,0.4-1.3,1.3-1.3c5.3,0,10.5,0,15.8,0
                                    c1,0,1.6,0.7,1.6,1.5c0,0.8-0.6,1.3-1.6,1.3C53.2,36.5,50.7,36.5,48.1,36.5C48.1,36.5,48.1,36.5,48.1,36.5z" />
                                        <path
                                            d="M48,39.4c2.5,0,5.1,0,7.6,0c0.5,0,1.1,0.2,1.5,0.6c0.7,0.7,0.4,1.8-0.6,2.1c-0.3,0.1-0.6,0.1-0.9,0.1
                                    c-5.1,0-10.1,0-15.2,0c-0.8,0-1.6-0.2-1.7-1.2c-0.1-0.9,0.5-1.7,1.5-1.7C42.8,39.4,45.4,39.4,48,39.4z" />
                                        <path d="M47.9,30.5c-2.5,0-5,0-7.5,0c-1.2,0-1.7-0.4-1.6-1.4c0-1,0.5-1.4,1.7-1.4c5,0,10.1,0,15.1,0c0.1,0,0.3,0,0.4,0
                                    c0.9,0.1,1.5,0.7,1.5,1.5c0,0.7-0.7,1.3-1.6,1.3C53.2,30.5,50.6,30.5,47.9,30.5z" />
                                        <path d="M51.3,14.6c0.1,0.1,0.1,0.1,0.2,0.2C51.4,14.7,51.4,14.6,51.3,14.6z" />
                                        <path d="M62.2,52.9c0.1,0,0.1,0.1,0.2,0.1C62.3,53,62.3,52.9,62.2,52.9z" />
                                        <path d="M80.4,94.9c-2.3,0.2-4.5-2.2-4.4-4.7c0.1-2,1.9-4.3,4.4-4.3c2.6,0,4.8,2.1,4.6,4.8
                                    C84.9,92.9,82.8,95.1,80.4,94.9z" />
                                        <path
                                            d="M38,90.5c0.3,2.5-2.4,4.4-4.5,4.5C31.3,95,29,92.6,29,90.4c0-2.1,1.8-4.5,4.3-4.5C36.1,86,38.2,88.1,38,90.5z" />
                                    </g>
                                </svg>
                            </span>
                            <span class="ml-4">Vehicle Insights </span>
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <button type="button"
                    class="relative w-full  flex items-center p-4 pb-6  text-base  text-[#3A3F4D] hover:text-[#004AAD] hover:bg-[#EDEDED] group border-l-4 border-transparent hover:font-semibold hover:border-[#004AAD]"
                    aria-controls="listing-example" data-collapse-toggle="listing-example" (click)="toggleDropdown('listing-example')">
                    <span class="w-8 h-6 inline-block">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                            style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                            <g>
                            </g>
                            <g>
                                <path d="M95.4,32.4c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0.1,0c-0.2,1.1-0.3,2.2-0.6,3.3c-0.3,1.1-0.6,2.2-1,3.2c-0.4,1.1-0.9,2.1-1.6,3
                            c-0.6,0.9-1.3,1.9-2,2.7c-0.7,0.8-1.6,1.6-2.4,2.4C87,47.4,86,48,85.1,48.6c-1,0.6-2,1.1-3.1,1.4c-1,0.4-2.1,0.7-3.2,0.9
                            c-1.1,0.2-2.2,0.4-3.4,0.4c-1.1,0-2.3,0-3.4-0.2C61,49.6,53.7,40.1,54.1,29.9c0.2-5.2,2.2-9.8,5.9-13.5c3.7-3.7,8.1-5.8,13.3-6.1
                            c-0.3,0-0.5-0.1-0.8-0.1c1.6,0,3.2,0,4.7,0c-0.3,0-0.6,0.1-0.9,0.1c5.1,0.4,9.5,2.4,13.1,6.1c3.6,3.6,5.6,8,5.9,13.1
                            c0-0.2,0.1-0.4,0.1-0.6C95.4,30,95.4,31.2,95.4,32.4z M70.8,45C70.7,45,70.7,45,70.8,45c0,0.5,0,1,0.1,1.5c0,0.2,0.2,0.3,0.4,0.4
                            c0.7,0.1,1.4,0.2,2.1,0.3c0.1,0,0.3-0.1,0.3-0.2c0.3-0.3,0.3-0.7,0.3-1.1c0-0.6,0-1.2,0-1.8c0-0.2,0.1-0.3,0.3-0.3
                            c0.9-0.2,1.8-0.5,2.5-1.1c2.6-1.9,3.7-4.4,3.3-7.6c-0.5-3.5-3.6-6.3-7.2-6.4c-0.4,0-0.9,0-1.3-0.1c-1.6-0.4-2.7-1.4-3.2-2.9
                            c-0.5-1.7-0.2-3.2,1.1-4.4c1.9-1.9,4.9-1.7,6.5,0.4c0.6,0.8,0.9,1.7,1,2.8c0.1,1.1,1.1,1.8,2.1,1.5c0.7-0.2,1.1-0.8,1.1-1.7
                            c-0.1-2.3-1-4.3-2.8-5.8c-0.9-0.8-2-1.3-3.2-1.5c-0.2,0-0.2-0.2-0.2-0.3c0-0.5,0-0.9,0-1.4c0-0.3,0-0.5,0-0.8
                            c-0.2-0.8-0.9-1.3-1.7-1.2c-0.8,0-1.4,0.6-1.5,1.4c-0.1,0.7,0,1.4,0,2.1c0,0.2,0,0.3-0.2,0.3c-3.3,0.8-5.5,3.9-5.6,6.9
                            c-0.1,3.2,1.2,5.6,4,7.2c1.2,0.7,2.6,0.9,3.9,1c2.3,0.1,3.7,1.7,4.1,3.7c0.3,1.9-0.8,3.9-2.6,4.7c-2.6,1.1-5.4-0.3-6-3.1
                            c-0.1-0.3-0.1-0.7-0.1-1.1c-0.1-0.8-0.8-1.5-1.6-1.5c-0.9,0-1.6,0.6-1.7,1.4c-0.1,1.5,0.3,2.9,1.1,4.2c1.1,1.7,2.5,2.8,4.5,3.3
                            c0.2,0.1,0.3,0.1,0.3,0.3C70.7,44.3,70.8,44.7,70.8,45z" />
                                <path
                                    d="M85.3,81.4c0,0.8,0,1.5,0,2.2c0,0.8-0.1,1.6-0.4,2.3c-0.3,0.9-0.8,1.8-1.6,2.4c-1.1,0.9-2.3,1.5-3.8,1.5
                            c-2.3,0-4.7,0.1-7,0c-1.8,0-3.2-1-4.3-2.4c-0.7-1-1.1-2.1-1.1-3.3c0-0.8,0-1.6,0-2.4c0-0.4,0-0.4-0.4-0.4c-8,0-16,0-24,0
                            c-2.5,0-5,0-7.6,0c-0.1,0-0.3,0-0.4,0c0,0.2,0,0.3,0,0.4c0,0.9,0,1.8-0.1,2.8c-0.1,1-0.4,1.8-0.9,2.6c-1.2,1.7-2.8,2.6-4.9,2.7
                            c-2.2,0-4.4,0-6.6,0c-2.8,0-5.5-2.4-5.7-5.3c-0.1-1-0.1-1.9-0.1-2.9c0-0.2-0.1-0.3-0.3-0.3C13.9,81,12.1,79,12,76.7
                            c0-0.5,0-1,0-1.5c0-4.6,0-9.2,0-13.8c0-2.4,0.7-4.6,2.4-6.4c1-1.1,1.9-2.2,2.9-3.3c0.1-0.1,0.1-0.2,0.2-0.3c-0.2,0-0.3,0-0.4,0
                            c-1.9,0-3.9,0-5.8,0c-0.8,0-1.5-0.1-2.1-0.6c-0.9-0.6-1.4-1.4-1.5-2.5c-0.1-2.6,1.5-5.1,4-6.1c0.8-0.3,1.6-0.5,2.4-0.5
                            c1.2,0,2.3,0,3.5,0c0.8,0,1.4,0.6,1.6,1.4c0.2,1-0.5,1.9-1.5,2c-1.1,0-2.2,0-3.3,0c-0.5,0-1,0.1-1.5,0.3c-1.1,0.5-1.7,1.4-1.9,2.5
                            c0,0,0,0.1,0,0.1c0.1,0,0.2,0,0.3,0c2.8,0,5.5,0,8.3,0c0.2,0,0.3-0.1,0.4-0.3c0.6-1.7,1.3-3.4,1.9-5.1c0.7-1.8,1.3-3.7,2.1-5.5
                            c1.2-2.8,3.4-4.5,6.4-5.1c0.4-0.1,0.9-0.1,1.3-0.1c5.8,0,11.6,0,17.4,0c1,0,1.6,0.6,1.8,1.6c0.1,0.5,0.1,1,0.2,1.5
                            c-0.1,0-0.3,0-0.4,0c-6.6,0-13.1,0-19.7,0c-2,0-3.5,1-4.2,2.8c-1,2.6-2.1,5.1-3.1,7.7c-0.4,1.1-0.9,2.1-1.3,3.2
                            c-0.7,1.8,0,3.4,1.9,3.9c0.6,0.2,1.3,0.3,1.9,0.3c12.9,0,25.8,0,38.8,0c0.3,0,0.7,0.1,1,0.2c2.1,0.8,4.2,1.3,6.4,1.6
                            c4.1,0.4,8.1-0.1,11.9-1.7c0.9-0.4,1.5-0.3,2.2,0.4c1,1.1,1.9,2.2,2.9,3.4c0.7,0.8,1,1.9,1,2.9c0,5.5,0,11.1,0,16.6
                            c0,2-0.9,3.6-2.8,4.6c-0.5,0.3-1.1,0.3-1.7,0.5C85.5,81.4,85.4,81.4,85.3,81.4z M51.1,73.8C51.1,73.8,51.1,73.8,51.1,73.8
                            c2.3,0,4.5,0,6.8,0c2.2,0,4.4,0,6.6,0c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.6-1.7-1.6-1.7c-0.2,0-0.4,0-0.6,0c-8.6,0-17.1,0-25.7,0
                            c-0.2,0-0.4,0-0.6,0c-0.8,0.1-1.4,0.8-1.4,1.7c0,0.8,0.6,1.5,1.4,1.7c0.2,0,0.5,0,0.7,0C42.5,73.8,46.8,73.8,51.1,73.8z M31.3,81.4
                            c-3.8,0-7.6,0-11.4,0c0,1,0,1.9,0,2.8c0,0.4,0.2,0.8,0.4,1.2c0.6,0.8,1.4,1.2,2.4,1.2c1.8,0,3.5,0,5.3,0c0.4,0,0.7,0,1.1,0
                            c1.1-0.1,2.1-1,2.2-2C31.3,83.5,31.3,82.5,31.3,81.4z M81.9,81.4c-3.8,0-7.6,0-11.4,0c0,1,0,1.9,0,2.8c0,0.4,0.2,0.8,0.4,1.2
                            c0.6,0.8,1.4,1.2,2.4,1.2c1.8,0,3.5,0,5.3,0c0.4,0,0.7,0,1.1,0c1.1-0.1,2.1-1,2.2-2C81.9,83.5,81.9,82.5,81.9,81.4z M71.8,65
                            C71.8,65,71.8,65,71.8,65c0.7,0,1.5,0,2.2,0c2.4-0.1,4.6-0.6,6.7-1.7c0.5-0.3,1.1-0.6,1.5-1c0.9-0.9,0.5-2.5-0.7-2.8
                            c-0.5-0.1-1,0-1.4,0.2c-0.7,0.4-1.4,0.8-2.1,1.1c-1.4,0.6-2.9,0.8-4.5,0.8c-1.7,0-3.3,0-5,0c-0.8,0-1.4,0.7-1.5,1.5
                            c-0.1,0.8,0.5,1.6,1.2,1.8c0.3,0.1,0.6,0.1,0.9,0.1C70,65,70.9,65,71.8,65z M31,65c0.9,0,1.9,0,2.9,0c0.2,0,0.5,0,0.7-0.1
                            c0.7-0.3,1.1-1.1,1-1.9c-0.2-0.8-0.8-1.3-1.6-1.3c-1.7,0-3.3,0-5,0c-1.8,0-3.5-0.4-5.1-1.2c-0.6-0.3-1.1-0.6-1.7-0.9
                            c-0.9-0.4-1.9,0-2.2,0.8c-0.4,0.9-0.1,1.7,0.8,2.2c1.8,1.1,3.7,1.9,5.8,2.2C27.9,65,29.4,65,31,65z" />
                            </g>
                        </svg>
                    </span>
                    <span class="flex-1 ml-4 text-left whitespace-nowrap"
                        >Vehicle Listing & Offer</span>
                    <span _ngcontent-wlm-c10=""
                        class="bg_primary absolute bottom-0.5 left-14 text-[10px] font-medium px-2.5 py-0.25 rounded-full text-white" *ngIf="carCounts?.newCarCount>0">{{carCounts?.newCarCount}}
                        New Updates</span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <ul id="listing-example" class="p-2 hidden" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                    <li>
                        <a routerLink="/dealer/car-list-new" routerLinkActive="text_primary_color font-medium bg-gray-100" (click)="udateLastViewDate()"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">

                                    <g>
                                        <path d="M16.2,96.6c0-3,0-6.1,0-9.1c0-0.4-0.2-0.8-0.4-1.1c-2.1-2.3-3.4-5-3.9-8c-0.2-1.3-0.4-2.5-0.4-3.8
                                    c0.1-3.4,0.9-6.7,2.9-9.6c1-1.5,2.3-2.8,3.9-3.6c0.8-0.4,1.1-1,1.2-1.8c0.6-4.3,2.1-8.4,4.8-11.8c2.5-3.2,5.7-5,10-5
                                    c10.5,0.1,21,0,31.5,0c4,0,7.2,1.6,9.7,4.8c2.8,3.5,4.3,7.4,5,11.8c0.2,1.1,0.7,1.7,1.6,2.3c2.3,1.4,3.9,3.5,5,5.9
                                    c1.3,2.9,1.7,6,1.4,9.2c-0.3,3.6-1.4,7-4.1,9.7c-0.2,0.2-0.4,0.6-0.4,0.8c0,3.1,0,6.2,0,9.3c0,0.1,0,0.1,0,0.2c-4.1,0-8.1,0-12.2,0
                                    c0-0.1,0-0.1,0-0.2c0-1.9,0-3.8,0-5.7c-14.5,0-28.8,0-43.2,0c0,1.9,0,3.8,0,5.7c0,0.1,0,0.1,0,0.2C24.3,96.6,20.3,96.6,16.2,96.6z
                                    M23.3,59.1c11.8,0,23.5,0,35.2,0c0.1-0.5,0.2-0.7,0.2-1c0.2-2.4,1.4-4.2,3.3-5.5c2.7-1.8,5.7-1.9,8.5-0.2c2,1.2,3.3,3,3.7,5.3
                                    c0.2,1.6,0.2,1.6,1.7,1.6c0.2,0,0.5,0,0.7,0c-0.8-3.6-2.1-7-4.5-9.9c-1.7-2-3.7-3-6.2-3c-10.8,0-21.6,0-32.5,0
                                    c-1.7,0-3.1,0.7-4.4,1.8C25.7,51,24.3,55,23.3,59.1z M75.1,69.8c-2.8-0.3-5,2.4-5,4.9c0,3,2.3,5.2,5.6,5.1c2.1,0,4.8-2.2,4.6-5.3
                                    C80.2,72.3,78.1,69.6,75.1,69.8z M24.8,69.8c-3-0.2-5.1,2.5-5.1,5c0,2.5,1.9,5.3,5.6,5c2.1-0.2,4.9-2.1,4.6-5.6
                                    C29.8,72.4,27.6,69.5,24.8,69.8z M50,72.1c-4.7,0-9.5,0-14.2,0c-1.4,0-1.9,0.4-2,1.2c-0.1,0.8,0.7,1.3,2,1.3c9.5,0,18.9,0,28.4,0
                                    c0.4,0,0.7,0,1.1-0.2c0.6-0.2,1-0.7,0.7-1.3c-0.2-0.4-0.7-0.7-1.1-0.9c-0.2-0.1-0.5,0-0.8,0C59.4,72.1,54.7,72.1,50,72.1z M50,79
                                    c4.7,0,9.5,0,14.2,0c0.3,0,0.7,0,1,0c0.6-0.1,1-0.5,0.9-1.1c0-0.8-0.6-1.2-1.8-1.2c-9.5,0-19,0-28.5,0c-0.3,0-0.7,0-1,0.1
                                    c-0.8,0.2-1.1,1-0.7,1.7c0.4,0.7,1,0.6,1.6,0.6C40.4,79,45.2,79,50,79z M61.3,58.9c3.5,0,7.1,0,10.6,0c0-2.8-2-5.1-5.4-5.3
                                    C63.7,53.5,61.3,55.9,61.3,58.9z" />
                                        <path
                                            d="M37,33.5c0-10.1,0-20.1,0-30.1c8.7,0,17.4,0,26.1,0c0,10,0,20,0,30.1C54.4,33.5,45.7,33.5,37,33.5z M41.9,11
                                    c-0.4,0.3-0.9,0.5-1,0.9c-0.1,0.4,0.1,1,0.3,1.3c1.9,1.8,3.9,3.6,5.9,5.3c0.1,0.1,0.6,0.1,0.7,0c1.1-0.9,2.1-1.8,3.1-2.7
                                    c1-0.9,2.1-1.8,3.1-2.7c1.6-1.4,3.2-2.7,4.8-4.1c0.6-0.5,0.7-1.1,0.2-1.7c-0.5-0.6-1.2-0.6-1.8-0.1c-2,1.7-3.9,3.4-5.9,5.2
                                    c-1.1,0.9-2.1,1.9-3.2,2.8c-0.4,0.3-0.7,0.4-1.2,0c-1.2-1.2-2.5-2.3-3.7-3.4C42.8,11.4,42.4,11.3,41.9,11z M52.1,24.6
                                    c1.2,0,2.4,0,3.6,0c1.1,0,2.3,0.1,3.4-0.1c0.4,0,0.9-0.5,1.1-0.9c0.2-0.6-0.2-1.1-0.8-1.2c-0.3-0.1-0.6-0.2-0.9-0.2
                                    c-4.3,0-8.6,0-12.9,0c-0.4,0-0.9,0.1-1.1,0.3c-0.3,0.3-0.5,0.9-0.4,1.2c0.1,0.3,0.6,0.6,1,0.8c0.3,0.1,0.6,0.1,0.9,0.1
                                    C48,24.6,50.1,24.6,52.1,24.6z M52.1,29.5C52.1,29.5,52.1,29.5,52.1,29.5c2.3,0,4.6,0,6.9,0c0.6,0,1.1-0.3,1.2-1
                                    c0.1-0.7-0.2-1.1-0.9-1.3c-0.3-0.1-0.6-0.2-0.9-0.2c-4.3,0-8.5,0-12.8,0c-0.9,0-1.6,0.5-1.6,1.2c0,0.8,0.5,1.2,1.5,1.2
                                    C47.7,29.5,49.9,29.5,52.1,29.5z M41.2,22.3c-0.2,0-0.4,0-0.6,0c-0.8-0.1-1,0.4-1,1c0,0.7,0.3,1.1,1,1.2c0.5,0,1,0.1,1.4,0
                                    c0.7-0.1,1.1-0.5,1-1.2c-0.1-0.6-0.4-1-1.1-0.9C41.6,22.3,41.4,22.3,41.2,22.3z M41.1,29.4c0.3,0,0.5,0,0.8,0
                                    c0.7-0.1,1.2-0.7,1-1.4c-0.4-1.2-1.4-0.7-2.2-0.8c-0.6-0.1-1.1,0-1.3,0.8C39.3,28.9,39.8,29.4,41.1,29.4z" />
                                    </g>
                                </svg>
                            </span>
                            <span class="ml-4">New Listing <span>({{carCounts?.totalNewCars}})</span> </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/dealer-preferred-list" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-5 h-5 inline-block">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">

                                    <path
                                        d="M4.6,40.6c0-0.5,0-0.9,0-1.4c0.5-1.1,1.3-1.9,2.6-2.1c9-1.4,18-2.7,26.9-4.1c0.6-0.1,0.9-0.3,1.2-0.9
                            c3.9-8.3,7.8-16.6,11.7-24.9c0.6-1.3,1.3-2.2,2.7-2.5c0.2,0,0.5,0,0.7,0C51.7,5,52.5,5.9,53,7.2c3.9,8.3,7.8,16.6,11.7,24.9
                            c0.2,0.5,0.5,0.8,1.1,0.9c1.7,0.2,3.4,0.5,5.1,0.7c7.4,1.1,14.7,2.2,22,3.4c1.3,0.2,2.3,1.2,2.4,2.5c0.1,1.1-0.4,1.9-1.1,2.7
                            c-6.5,6.6-12.9,13.2-19.4,19.9c-0.3,0.3-0.5,0.8-0.5,1.1c1.1,7.3,2.3,14.5,3.5,21.8c0.4,2.3,0.8,4.5,1.1,6.8c0.3,1.7-0.5,3.1-2,3.5
                            c-1,0.3-1.8-0.1-2.6-0.5c-7.8-4.3-15.6-8.6-23.4-13c-0.7-0.4-1.3-0.5-2.1,0c-7.8,4.4-15.7,8.7-23.5,13c-1.2,0.6-2.3,0.8-3.4,0
                            c-1.1-0.8-1.3-2-1.1-3.2c1.5-9.4,3.1-18.8,4.6-28.2c0.1-0.6-0.1-0.9-0.4-1.2c-6.3-6.5-12.7-13-19-19.5C5.6,42,5.1,41.3,4.6,40.6z" />
                                </svg>

                            </span>
                            <span class="ml-4">Preferred Listing <span >({{carCounts?.totalPreferredCars}})</span></span>
                        </a>
                    </li>
                    <!-- <li>
                        <a routerLink="/dealer/saved-search"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-5 h-5 inline-block">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"  x="0px" y="0px" style="enable-background:new 0 0 100 100;" xml:space="preserve"  fill="currentColor">
                                    <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/>
                                </svg>
                            </span>
                            <span class="ml-4">Saved Search </span>
                        </a>
                    </li> -->
                    <li>
                        <a routerLink="/dealer/offers" routerLinkActive="text_primary_color font-medium bg-gray-100" (click)="udateLastViewDate()"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6 inline-block"><svg version="1.1" id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;"
                                    xml:space="preserve" fill="currentColor">

                                    <g>
                                        <path
                                            d="M48.8,4.7c0.8,0,1.6,0,2.4,0C51.5,4.8,51.7,5,51.9,5c1.6,0.2,2.7,1.1,3.8,2.1c1.7,1.6,3.4,3.2,5.1,4.9
                           c0.7,0.7,1.4,1,2.3,0.8c2.2-0.3,4.4-0.5,6.5-0.9c2.3-0.4,4.5-0.3,6.4,1.1c1.4,1,2.6,2.2,3,3.9c0.5,2.4,1,4.9,1.4,7.3
                           c0.2,1.5,0.6,2.8,2.1,3.5c1.6,0.7,3.1,1.5,4.7,2.4c1.3,0.8,2.8,1.5,3.8,2.6c2.4,2.7,2.5,5.8,1,8.9c-1,2.1-2,4.2-3.1,6.3
                           c-0.5,0.9-0.5,1.8,0,2.7c1.1,2.2,2.2,4.3,3.2,6.5c1.8,3.9,0.7,8.2-3.2,10.3c-2,1.1-4.1,2.2-6.2,3.3c-1.1,0.5-1.8,1.3-2,2.4
                           c-0.3,1.7-0.6,3.5-0.9,5.2c-0.2,1.3-0.4,2.6-0.9,3.9c-0.7,1.8-2,3.1-3.7,3.8c-1.3,0.5-2.7,0.7-4,0.9c-0.8,0.1-1.6-0.2-2.4-0.3
                           c-1.5-0.2-2.9-0.4-4.4-0.7c-1.5-0.3-2.8-0.2-4,1.1c-1.7,1.8-3.5,3.4-5.4,5.1c-2.3,2-5.7,2.7-8.5,1c-1.2-0.7-2.3-1.7-3.4-2.7
                           c-1.2-1.1-2.4-2.4-3.6-3.6c-0.6-0.6-1.4-1.2-2.3-1.1c-1.9,0.2-3.8,0.5-5.7,0.7c-1.5,0.2-3,0.7-4.7,0.3c-3-0.7-5.1-2.4-5.9-5.3
                           c-0.7-2.5-1-5.1-1.4-7.7c-0.2-1.4-0.8-2.5-2.2-3.1c-1.4-0.7-2.9-1.4-4.3-2.2c-1.4-0.8-2.9-1.5-4-2.7c-1.7-2-2.7-5.1-1.5-7.7
                           c1.1-2.5,2.3-4.9,3.4-7.3c0.5-1,0.5-2,0-3.1c-1-2-2-4.1-3-6.1c-0.8-1.7-1.3-3.4-0.8-5.3c0.5-2,1.4-3.7,3.1-4.8
                           c2.4-1.4,4.8-2.7,7.2-4c0.8-0.4,1.5-1,1.7-2c0.3-1.7,0.6-3.5,0.9-5.3c0.3-1.6,0.5-3.3,1.4-4.8c1.4-2.3,3.3-3.9,6.1-3.8
                           c2.5,0.1,5,0.5,7.5,0.8c0.9,0.1,1.9,0.2,2.8,0c1.4-0.2,2.1-1.4,3-2.3C43.3,8.2,45.3,5.4,48.8,4.7z M51.8,67
                           c1.3-0.7,2.6-1.2,3.7-1.9c2.5-1.6,3.8-4.1,4.2-7c0.3-2.6-0.4-4.9-2-7c-1.8-2.2-4.1-3.4-6.9-3.7c-1.2-0.1-2.5-0.1-3.7-0.8
                           c-2.3-1.5-3.3-3.9-2.6-6.6c0.5-2,2.6-4,5.5-3.9c3.2,0,5.6,2.7,5.7,5.6c0,1,0.8,1.8,1.8,1.9c1.5,0.2,2.2-0.9,2.2-1.8
                           c0-0.8,0-1.7-0.2-2.5c-1-3.4-3.1-5.9-6.6-7c-0.9-0.3-1.4-0.7-1.2-1.7c0.1-0.7,0.2-1.4,0-2c-0.5-1.5-2.9-1.6-3.6-0.3
                           c-0.3,0.5-0.4,1.2-0.4,1.9c0,1.8,0,1.8-1.7,2.5c-0.1,0.1-0.3,0.1-0.4,0.2c-2.8,1.5-4.6,3.8-5.3,6.9c-0.5,2.5,0,4.9,1.3,7
                           c2,3.1,4.8,4.6,8.5,4.7c1.1,0,2.3,0.3,3.2,1.1c2,1.6,3,4.2,1.8,6.7c-1.4,2.9-3.9,3.9-6.8,3.2c-2.3-0.5-4.1-2.8-4-5.3
                           c0-1.1-0.8-2-1.8-2.1c-1.3-0.1-2.2,0.3-2.3,1.6c-0.1,1.1,0,2.3,0.3,3.3c1.1,3.2,3.2,5.5,6.5,6.5c0.4,0.1,0.6,0.3,0.5,0.7
                           c0,0.7,0,1.5,0,2.2c0,1,0.6,1.7,1.3,2c1.5,0.7,2.9-0.7,2.9-2.1C51.8,68.5,51.8,67.7,51.8,67z" />
                                    </g>
                                </svg>
                            </span>
                            <div class="grid gap-1 ml-4">
                                <span class=""> My Offers<span>({{carCounts?.offers}})</span> </span>
                                <span _ngcontent-wlm-c10=""
                                class="bg_primary -ml-1 bottom-0.5 text-[10px] font-medium px-2.5 py-0.25 rounded-full text-white" *ngIf="carCounts?.newOffers>0">{{carCounts?.newOffers}}
                                New Updates</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/won-offers" routerLinkActive="text_primary_color font-medium bg-gray-100" (click)="udateLastViewDate()"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6 inline-block">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path d="M19.5,12.1c-0.1-2.2,0.1-4.2,1.1-6c0.6-1.2,1.5-1.8,2.9-1.8c17.3,0,34.6,0.1,51.9,0c3.1,0,4,1.7,4.5,3.7
                                c0.3,1.3,0.4,2.7,0.6,4.1c1.2,0,2.4,0,3.7,0c1.9,0,3.8,0,5.7,0c3.2,0,4.2,1.1,4.2,4.3c0,3.7,0,7.3-1.3,10.8
                                c-2.8,7.3-8.1,11.7-15.7,13.3c-0.8,0.2-1.7,0.5-2.5,0.4c-0.9-0.1-1.2,0.5-1.4,1.1c-1.9,4.6-4.2,8.9-7.2,12.8
                                c-4,5.3-8.9,9.6-15.4,11.6c-0.4,0.1-0.8,0.2-1.2,0.1c-3.8-1.2-7.1-3.3-10.1-5.9c-6.1-5.4-9.9-12.3-13-19.6
                                c-4.8-0.2-9.1-1.7-12.8-4.7c-3.2-2.7-5.5-6.1-6.8-10.1C5.5,22.4,5.9,18.7,5.8,15c0-1.7,1.4-2.9,3.3-2.9c3.1,0,6.3,0,9.4,0
                                C18.8,12.1,19.1,12.1,19.5,12.1z M76.2,34.6C84.6,32.8,89,25.3,88.1,18c-2.5,0-5,0-7.5,0c-0.3,0-0.7,0.4-0.8,0.7
                                c-0.2,0.4-0.2,0.9-0.3,1.4c-0.8,3.5-1.6,7.1-2.5,10.6c-0.3,1.3-0.6,2.5-1,3.8c-0.1,0-0.1,0.1-0.2,0.1C76,34.6,76.1,34.6,76.2,34.6z
                                M57.6,38.3c-0.4-2.7-0.8-5.2-1.3-7.6c-0.2-0.7,0-1.1,0.4-1.6c1.8-1.8,3.6-3.5,5.4-5.3c-0.3-0.1-0.6-0.3-0.8-0.3
                                c-1.8-0.2-3.7-0.5-5.6-0.7c-1.3-0.1-2.2-0.4-2.8-1.8c-0.8-2.1-1.9-4-3-6.2c-0.3,0.5-0.5,0.8-0.7,1.1c-0.9,1.9-1.8,3.8-2.8,5.7
                                c-0.2,0.4-0.6,0.9-1,1c-1.8,0.3-3.6,0.5-5.4,0.8c-0.8,0.1-1.6,0.3-2.6,0.4c1.9,1.8,3.7,3.5,5.5,5.2c0.6,0.5,0.7,1.1,0.6,1.8
                                c-0.4,2.5-0.9,4.9-1.4,7.6c2.3-1.2,4.5-2.3,6.7-3.5c1-0.6,1.6-0.3,2.4,0.2C53.3,36,55.3,37.1,57.6,38.3z M23.7,34.6
                                c-1.4-5.5-2.7-11-4.1-16.6c-2.5,0-5.1,0-7.8,0C10.7,27.4,17.7,33.9,23.7,34.6z" />
                                        <path d="M83,95.8c-22,0-43.9,0-66,0c0-2.7,0-5.4,0-8.1c0-0.7,0.1-1.3,0.4-1.9c0.9-2.4,3.1-3.9,5.6-3.9
                                c17.9,0,35.7,0,53.6,0c3.9,0,6.3,2.7,6.3,6.3C83,90.7,83,93.2,83,95.8z" />
                                        <path
                                            d="M37.6,79.2c2.1-1.7,4.2-3.5,5.4-6c0.9-1.9,1.6-3.8,2.4-5.9c2.9,2,6.1,1.9,9.2-0.1c0.6,1.7,1.1,3.4,1.8,4.9
                                c1.2,2.9,3.2,5.2,5.8,7c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.1,0,0.2c-8.2,0-16.3,0-24.5,0C37.8,79.4,37.7,79.3,37.6,79.2z" />
                                        <path
                                            d="M37.6,79.2c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0-0.3,0-0.5,0C37.4,79.4,37.5,79.3,37.6,79.2z" />
                                        <path
                                            d="M62.4,79.6c0-0.1,0-0.1,0-0.2c0.1,0.1,0.1,0.1,0.2,0.2C62.5,79.5,62.4,79.5,62.4,79.6z" />
                                        <path
                                            d="M76.2,34.6c-0.1,0-0.2,0-0.3,0c0.1,0,0.1-0.1,0.2-0.1C76.1,34.5,76.2,34.6,76.2,34.6z" />
                                    </g>
                                </svg>
                            </span>

                            <div class="grid gap-1 ml-4">
                                <span> Offers Won <span>({{carCounts?.wonOffers}})</span></span>
                                <span
                                class="bg_primary -ml-1 bottom-0.5 text-[10px] font-medium px-2.5 py-0.25 rounded-full text-white" *ngIf="carCounts?.newWonOffers>0">{{carCounts?.newWonOffers}}
                                New Updates</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <button type="button"
                    class="relative w-full  flex items-center p-4 pb-6  text-base  text-[#3A3F4D] hover:text-[#004AAD] hover:bg-[#EDEDED] group border-l-4 border-transparent hover:font-semibold hover:border-[#004AAD]"
                    aria-controls="schedule-example" data-collapse-toggle="schedule-example" (click)="toggleDropdown('schedule-example')">
                    <span class="w-8 h-6 inline-block">
                        <svg id="std" data-name="std" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 68.65"
                            fill="currentColor">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill-rule: evenodd;
                                    }
                                </style>
                            </defs>
                            <title>drop-off</title>
                            <path class="cls-1"
                                d="M58.49,6.91c-4.79,0-7.23,4.14-8.69,8.71l-3.74,11.6-1.8-3.39c-8.09-.48-9,4.08-1,8.17h0c-9,5.81-7.72,16-6.84,27.24a2.77,2.77,0,0,0,0,.42v6.27a2.73,2.73,0,0,0,2.72,2.72h11.6a2.73,2.73,0,0,0,2.72-2.72V63.74h51.81v2.19A2.74,2.74,0,0,0,108,68.65H119.6a2.74,2.74,0,0,0,2.72-2.72V61.11c.9-14.69,1.44-20.62-4.34-26.57l-1.43-2.19c8.8-4.17,8.06-9-.26-8.52l-1.61,3-2.8-11.25c-1.16-4.64-3.92-8.7-8.7-8.7Zm-.93,20.45c1.2-.94,10.9-.64,12.12-.11a2.49,2.49,0,0,1,1.95,1.13,25.93,25.93,0,0,1,2.71,4.16h36.21l-2.81-14.43c-.77-3.55-3-6.63-6.64-6.63h-41A5.33,5.33,0,0,0,56.26,13a5.1,5.1,0,0,0-1.32,1.67,16,16,0,0,0-1.5,3.42l-3.5,14.43h3.57a26.59,26.59,0,0,1,2.7-4.16,2.52,2.52,0,0,1,1.35-1Zm6.1-13.24A5.92,5.92,0,1,1,57.75,20a5.92,5.92,0,0,1,5.91-5.91ZM16.84,0A7.58,7.58,0,1,1,9.26,7.58,7.58,7.58,0,0,1,16.84,0ZM6.93,19.15a3.2,3.2,0,0,1,2.52-1.44v-.1a36.57,36.57,0,0,1,15.21.13v.07a3.13,3.13,0,0,1,2.17,1.4,42.23,42.23,0,0,1,4.43,8.9,49,49,0,0,1,2.48,10A3.2,3.2,0,1,1,27.4,39a43.09,43.09,0,0,0-2.15-8.7c-.2-.53-.39-1-.6-1.54V63.88c0,4.14-6.82,4.44-6.82,0V42.81H16.27V63.88c0,4.44-6.82,4.14-6.82,0v-36c-.33.74-.64,1.52-.93,2.32a42.53,42.53,0,0,0-2.15,8.71A3.2,3.2,0,0,1,0,38.06a49.2,49.2,0,0,1,2.48-10,43,43,0,0,1,4.42-8.9ZM58.11,45.37,47.8,44.07c-2.43-.27-3.08.75-2.26,2.85l1.12,2.71a3.91,3.91,0,0,0,1.39,1.55,4.79,4.79,0,0,0,2.31.64l9.19.07c2.22,0,3.19-.89,2.49-2.93a5,5,0,0,0-3.93-3.59Zm42.5,0,10.31-1.3c2.43-.27,3.09.75,2.26,2.85l-1.12,2.71a3.91,3.91,0,0,1-1.39,1.55,4.79,4.79,0,0,1-2.31.64l-9.19.07C97,51.88,96,51,96.68,49a5,5,0,0,1,3.93-3.59Z" />
                        </svg>
                    </span>
                    <span class="flex-1 ml-4 text-left whitespace-nowrap"
                        >Schedule Pick & Drop 
                        <div 
                        class="bg_primary absolute left-14 text-[10px] font-medium px-2.5 py-0.25 rounded-full text-white"  *ngIf="carCounts?.newScheduleCount>0">{{carCounts?.newScheduleCount}}
                        New Updates</div></span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <ul id="schedule-example" class="p-2 hidden"  routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                    <li>
                        <a routerLink="/dealer/schedule-car-pickup" routerLinkActive="text_primary_color font-medium bg-gray-100" (click)="udateLastViewDate()"
                            class="flex items-center w-full p-4 pb-6 transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-5">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path d="M93.4,66.8c-1,0.6-2.1,0.2-3.2,0.3c-0.1,0-0.3-0.4-0.3-0.6c-0.8-3.6-3.4-5.8-6.8-6.3c-4.4-0.6-7.8,1.5-9.3,5.9
                                    c-0.1,0.3-0.2,0.5-0.2,0.8c-0.8,0-1.5,0-2.3,0c0-0.1-0.1-0.1-0.1,0c0-2.3,0-4.6,0-6.9c0-2.3,0-4.5,0-6.8c-0.9,0-1.7,0-2.6,0
                                    c0,4.5,0,9.1,0,13.7c-10.1,0-20.2,0-30.1,0c-0.6-1.2-1.1-2.5-1.9-3.5c-1.4-2.1-3.5-3.1-6-3.3c-1.9-0.1-3.7,0.2-5.3,1.3
                                    c-1.8,1.3-3,2.9-3.5,5.1c0,0.2-0.3,0.4-0.4,0.4c-1.8,0-3.6,0-5.5,0c0-2.6,0-5.2,0-7.7c0-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.4,0,0.7,0
                                    c13.1,0,26.2,0,39.3,0c0.3,0,0.7,0,1.1,0c0-0.4,0-0.7,0-1c0-7.5,0-15,0-22.5c0-1.3,0.3-2.4,1.6-2.9c0.2-0.1,0.5-0.2,0.8-0.2
                                    c2.6,0,5.1,0,7.7,0c0,0.1,0.1,0.1,0.1,0.2c0,4,0,8,0.1,12c0,1.9,0.9,3.4,2.3,4.7c1.1,1,2.4,1.6,3.8,1.8c0.3,0,0.6,0,0.9,0
                                    c5.2,0,10.3,0,15.5,0c1,2.6,2.1,5.1,3.1,7.7C93.4,61.4,93.4,64.1,93.4,66.8z M73.5,55.8c1.6,0,3.1,0,4.6,0c0-0.9,0-1.7,0-2.6
                                    c-1.6,0-3.1,0-4.6,0C73.5,54.1,73.5,54.9,73.5,55.8z" />
                                        <path d="M13.5,30.4c0,2.1,0,4,0,5.8c0,1.3-0.6,2-1.9,2.1c-1.1,0.1-1.6,0.8-2,1.7c-0.4,0.8-0.2,1.6,0.3,2.3
                                    c1.3,1.8,3.4,1.3,4.4-0.2c0.2-0.4,0.2-0.9,0.2-1.4c0.1-1.2,1.2-1.9,2.1-1.3c0.3,0.1,0.5,0.5,0.6,0.7c0.4,3.5-2,5.9-5.1,6
                                    c-2.6,0.1-4.7-1.9-5.3-4.2c-0.6-2.3,0.7-4.8,3-5.9c0.3-0.1,0.5-0.3,0.9-0.4c0-1.9,0-3.9,0-5.9c0-0.5,0.2-1,0.3-1.5
                                    c0-0.2,0.2-0.4,0.1-0.5c-0.3-0.8,0.2-1.4,0.7-1.6c0.5-0.2,1.4-0.2,1.9,0.1c5,3.6,9.9,7.4,14.9,11.1c4.9,3.7,9.9,7.3,14.9,11
                                    c1.1,0.8,2.1,1.6,3.2,2.5c-0.5,0.3-0.8,0.6-1.2,0.9c-1.4,0.9-2.2,2.2-2.6,3.8c-0.2,0.6-0.4,0.8-1.1,0.8c-0.7-0.1-1.5,0-2.2,0
                                    c-0.8-3.2-3.2-5.4-6.5-5.9c-0.2,0-0.5-0.2-0.6-0.3c-3.1-3.2-6.3-6.3-9.4-9.5c-1.1-1.1-2.2-2.3-3.3-3.4
                                    C17.7,34.9,15.7,32.7,13.5,30.4z" />
                                        <path
                                            d="M70.4,32.2c2.8-0.1,5.6-0.2,8.3,0.8c3.7,1.4,6.2,4,7.6,7.6c1,2.5,1.9,4.9,2.9,7.4c0,0.1,0,0.2,0,0.3
                                    c-0.3,0-0.6,0-0.8,0c-4.6,0-9.2,0-13.8,0c-2.2,0-4.1-1.9-4.1-4c-0.1-3.9,0-7.7,0-11.6C70.4,32.6,70.4,32.4,70.4,32.2z" />
                                        <path d="M29.9,62.9c3.2-0.2,5.7,2.6,5.6,5.6c-0.1,3.1-2.5,5.5-5.8,5.5c-2.8,0-5.4-2.6-5.4-5.7
                                    C24.3,65.8,26.7,62.5,29.9,62.9z" />
                                        <path d="M81.6,62.9c3.2-0.3,5.5,2.7,5.7,4.9c0.3,4.2-3.1,6.3-5.6,6.3c-3.1,0-5.5-2.5-5.6-5.6
                                    C76.1,65.7,78.6,62.6,81.6,62.9z" />
                                        <path
                                            d="M54.4,56.2c-3,0-6,0-9.1,0c0.3-1.7,2-3.3,3.6-3.3C52.1,52.9,53.9,53.9,54.4,56.2z" />
                                        <path
                                            d="M36.7,56.2c-3,0-5.9,0-8.9,0c0.3-1.6,2.1-3.2,3.7-3.3C34.2,52.8,36.1,54,36.7,56.2z" />
                                        <path d="M67.6,32.5c0-0.1-0.1-0.1-0.1-0.2C67.5,32.3,67.6,32.4,67.6,32.5z" />
                                        <path
                                            d="M71.1,67c0.1,0,0.1,0,0.1,0c0,0-0.1,0.1-0.1,0.1C71.1,67.1,71.1,67,71.1,67z" />
                                    </g>
                                </svg>

                            </span>
                            <div class="grid gap-1 ml-4">
                                <span >Pick Up <span>({{carCounts?.pickUpCar}})</span> </span>
                                <span _ngcontent-wlm-c10=""
                                class="bg_primary -ml-1 bottom-0.5 text-[10px] font-medium px-2.5 py-0.25 rounded-full text-white" *ngIf="carCounts?.newPickup>0">{{carCounts?.newPickup}}
                                New Updates</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/schedule-car-dropoff" routerLinkActive="text_primary_color font-medium bg-gray-100" (click)="udateLastViewDate()"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-5">
                                <svg version="1.1" id="drop-off" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">

                                    <g>
                                        <path
                                            d="M2.8,28.8c0.5-1.2,1-2.4,1.7-3.5c1.3-1.9,3.1-3.2,5.4-3.3c4.9-0.2,9.9-0.2,14.8-0.2c1.8,0,3.5,0,5.3,0
                                    c2.4,0.1,4.6,0.5,6.6,2c1.4,1.1,2.4,2.4,3.1,4c1.7,3.5,3.3,7.1,5,10.7c1.1,2.4,0,4.7-2.5,5.3c-4.2,0.9-8.5,1.8-12.7,2.8
                                    c-1.5,0.4-3,0.6-4.2-0.5c-1-0.9-1.6-1.9-1.3-3.2c0.2-1.3,0.9-2.5,2.4-2.9c2.6-0.7,5.3-1.2,7.9-1.8c0.7-0.2,1.3-0.3,2.2-0.4
                                    c-1.4-2.8-2.7-5.6-4.3-8.2c-1-1.7-2.9-2.4-5.3-1.6c-0.2,0.1-0.7,0-0.8-0.2c-1.5-2.4-4.2-2.7-6.6-1.2c-2,1.2-4,2.4-6.1,3.6
                                    c-0.6,0.3-1.1,0.8-1.8,1.2c0-0.4,0.1-0.7,0-0.9c-0.2-0.3-0.5-0.5-0.7-0.6c-0.1,0-0.5,0.4-0.6,0.6c-0.1,0.3,0,0.6,0,0.9
                                    c0,8.4,0,16.7,0,25.1c0,2.3-1.1,3.6-3.1,4.2c-1.6,0.5-3.6-0.9-4-2.2c0-0.1-0.1-0.2-0.2-0.4C2.8,48.2,2.8,38.5,2.8,28.8z" />
                                        <path d="M44.5,30.3c0.3,0,0.6,0,0.9,0c11.3,0,22.6,0,33.8,0c2.2,0,3.1,0.7,3.4,3c0.4,2.7,1,5.3,1.5,8
                                    c0.4,2.2,0.8,4.4,1.2,6.6c0.2,0.9,0.3,1.8,0.7,2.7c0.1-0.2,0.2-0.4,0.2-0.6c0-0.7,0-1.5,0-2.2c0-1.5,1-2.6,2.5-2.6
                                    c1.9,0,3.9,0,5.8,0c1.6,0,2.5,1.1,2.6,2.6c0,0.7,0,1.5,0,2.2c0,1.7-0.9,2.7-2.6,2.7c-1.3,0-2.7,0.1-4,0c-1.1-0.1-1.8,0.4-2.4,1.2
                                    c-0.4,0.4-0.7,0.9-1.1,1.4c1.4,1.2,2.5,2.7,3,4.5c0.4,1.3,0.6,2.7,0.6,4.1c0.1,3.8,0,7.6,0,11.4c0.1,3-2,5.1-4.8,4.8
                                    c-0.1,0-0.2,0-0.4,0c0,1.6,0.1,3.2,0,4.7c-0.1,1.2-0.3,2.4-0.8,3.5c-0.9,1.7-2.4,2.8-4.4,3c-3.5,0.5-5.9-1.8-6.7-4.7
                                    c-0.1-0.2,0-0.4,0-0.6c0-1.9,0-3.9,0-5.8c-1.8,0-3.5,0-5.3,0c-0.1,0.8-0.1,1.7-0.3,2.5c-0.1,0.8-0.9,1.5-1.8,1.5
                                    c-4.9,0-9.8,0-14.7,0c-1.1,0-1.8-1-1.9-2.5c0-0.5,0-1,0-1.5c-1.7,0-3.4,0-5.2,0c0,1.5,0,3,0,4.6c0,1.3-0.2,2.6-0.9,3.7
                                    c-1,1.6-2.4,2.6-4.3,2.9c-1.5,0.3-2.9-0.1-4.1-0.9c-0.3-0.2-0.6-0.6-0.6-0.9c0-6.4,0-12.7,0-19.2c1.2,1.2,2.6,1.6,4.1,1.5
                                    c1.6-0.1,2.8-0.9,3.7-2.1c1.4-1.8,1.5-4.6-0.4-6.6c-1.7-1.8-4.8-2.5-7.5-0.2c0-0.2,0-0.3,0-0.4c0-4.5,0-9,0-13.5
                                    c1-0.2,2-0.4,3.1-0.7c-0.2,1.5-0.5,2.8-0.7,4.3c0.4,0,0.7,0,0.9,0c7.3,0,14.7,0,22,0c0.8,0,1-0.3,1.1-1c0.5-2.8,2.7-5.6,5.8-6.4
                                    c3.1-0.8,5.7-0.1,8,1.9c1.4,1.2,2.3,2.9,2.5,4.8c0.1,0.6,0.3,0.8,0.9,0.7c0.9,0,1.9,0,2.9,0c-1-5.7-2.1-11.3-3.1-16.9
                                    c-4.5,0-9.1,0-13.7,0c0,0.4,0,0.7,0,1.1c0,1.4-0.7,2.2-2.2,2.2c-1.9,0-3.8-0.1-5.7,0c-2.1,0.1-3.1-1.4-2.4-3.4c-2.4,0-4.9,0-7.3,0
                                    c-0.7-1.6-1.4-3.2-2.1-4.8C44.5,30.5,44.5,30.4,44.5,30.3z M84.8,66.4c0.3-2.6-2.3-5.1-4.9-5.2c-3-0.2-5.6,2.6-5.4,5.6
                                    c0.2,2.2,2.3,5,5.4,4.8C82.4,71.5,85.2,69,84.8,66.4z M58.9,68.4c3,0,6,0,9,0c0.9,0,1.7-0.6,1.8-1.4c0.2-1.2-0.2-1.7-1.3-2.1
                                    c-0.3-0.1-0.7-0.2-1-0.2c-5.6,0-11.2,0-16.8,0c-0.2,0-0.4,0-0.6,0c-1.2,0.2-1.9,0.6-1.9,2c0,1,0.8,1.6,2,1.6
                                    C53.1,68.4,56,68.4,58.9,68.4z M58.9,75.6c2,0,3.9,0,5.9,0c0.9,0,1.5-0.5,1.7-1.3c0.2-0.8,0-1.7-0.8-2c-0.5-0.2-1-0.3-1.5-0.3
                                    c-3.5,0-7,0-10.5,0c-0.3,0-0.6,0-0.9,0.1c-1,0.2-1.6,1-1.5,2.1c0.1,0.8,0.8,1.5,1.8,1.5C55.1,75.6,57,75.6,58.9,75.6z M65.1,52.4
                                    c2.7,0,5.3,0,7.9,0c0-1.6-1.8-3.4-3.9-3.5C67.1,48.8,65.1,50.8,65.1,52.4z" />
                                        <path d="M11.6,36.4c2,3.5,4.1,6.9,6.1,10.4c1.3,2.2,2.9,2.9,5.4,2.3c0.2-0.1,0.5,0,0.8,0.1c0.7,0.3,1.4,0.8,2.1,0.8
                                    c1.3,0.1,2.7-0.2,4.1-0.2c0.2,0,0.4,0,0.7-0.1c0,0.3,0,0.5,0,0.8c0,13.5,0,26.9,0,40.4c0,2-0.7,3.6-2.7,4.4
                                    c-2.8,1.1-5.5-0.4-5.9-3.4c-0.1-0.5-0.1-1-0.1-1.6c0-10.8,0-21.7,0-32.5c0-0.3,0-0.7,0-1.1c-0.6,0-1.2,0-1.8,0c0,0.3,0,0.6,0,1
                                    c0,11,0,21.9,0,32.9c0,2.3-1.1,4.2-3,4.8c-3.1,0.9-5.8-0.9-5.8-4.2c0-18,0-35.9,0-53.9C11.5,36.9,11.5,36.6,11.6,36.4
                                    C11.5,36.4,11.6,36.4,11.6,36.4L11.6,36.4z" />
                                        <path d="M13.7,11.9c-0.2-4,3.3-7.4,7.1-7.5c4.4-0.1,8,3.2,7.9,7.7c-0.1,3.9-3.2,7.1-7.4,7.3
                                    C17.1,19.6,13.5,15.8,13.7,11.9z" />
                                        <path
                                            d="M31.9,35.1c-2.4,0.6-4.6,1-6.7,1.6c-0.9,0.3-1.7,1-2.5,1.6c-0.2,0.2-0.4,0.4-0.8,0.8c-0.6-1.1-1.3-2.1-1.7-3.2
                                    c-0.1-0.2,0.3-0.9,0.6-1.1c2.2-1.4,4.5-2.7,6.8-4.1c0.6-0.3,1.4-0.5,1.8,0.2C30.3,32.3,31,33.7,31.9,35.1z" />
                                        <path d="M20.8,46c-0.7-1.1-1.3-2.2-2-3.3c-1.5-2.5-2.9-5-4.4-7.6c-0.8-1.4-0.8-1.7,0.6-2.6c2-1.2,4.1-2.5,6.2-3.6
                                    c1.1-0.6,1.7-0.4,2.3,0.7c-1.4,0.8-2.7,1.7-4.1,2.5c-2.5,1.3-2.9,4.2-1.4,6.5c0.8,1.2,1.6,2.4,2.2,3.7c0.4,0.9,0.5,1.9,0.7,2.9
                                    c0.1,0.2,0.1,0.4,0.2,0.7C21,45.9,20.9,45.9,20.8,46z" />
                                        <path d="M44.5,30.3c0,0.1,0,0.2,0,0.3C44.5,30.5,44.5,30.4,44.5,30.3z" />
                                        <path
                                            d="M11.6,36.4L11.6,36.4C11.6,36.4,11.5,36.4,11.6,36.4c-0.1,0-0.1-0.1-0.1-0.1C11.6,36.3,11.6,36.3,11.6,36.4z" />
                                    </g>
                                </svg>
                            </span>
                            <div class="grid gap-1 ml-4">
                                <span >Drop Off <span >({{carCounts?.dropOffCar}})</span></span>
                                <span _ngcontent-wlm-c10=""
                                class="bg_primary -ml-1 bottom-0.5 text-[10px] font-medium px-2.5 py-0.25 rounded-full text-white" *ngIf="carCounts?.newDropOff>0">{{carCounts?.newDropOff}}
                                New Updates</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <button type="button"
                    class="w-full relative flex items-center p-4 pb-6  text-base  text-[#3A3F4D] hover:text-[#004AAD] hover:bg-[#EDEDED] group border-l-4 border-transparent hover:font-semibold hover:border-[#004AAD]"
                    aria-controls="car-rejected" data-collapse-toggle="car-rejected" (click)="toggleDropdown('car-rejected')">
                    <span class="w-7 h-7">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                            style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                            <g>
                            </g>
                            <g>
                                <path
                                    d="M86.8,66.1c-0.1-3.8-1.5-7-4.4-9.4c-2.2-1.8-4.7-2.8-7.6-2.9c-3.4,0-6.4,1.1-8.8,3.5c-2.4,2.4-3.5,5.3-3.7,8.7
                        c-8.7,0-17.3,0-26,0c-0.2-4.2-1.9-7.6-5.4-10.1c-2.5-1.7-5.2-2.4-8.2-2.1c-4.5,0.4-10.5,4-11.1,12.1c-1.7,0-3.4,0.1-5,0
                        c-1.5-0.1-2.5-1.5-2.6-3c0-5.3,0-10.5,0-15.8c0-1.6,1.1-2.9,2.8-3.1c4-0.5,8.1-1,12.1-1.5c2.4-0.3,4.8-0.5,7.1-0.9
                        c0.5-0.1,1.1-0.4,1.5-0.8c4.1-4.9,8.1-9.9,12.2-14.9c0.8-1,1.8-1.4,3-1.4c10,0,19.9,0,29.9,0c1.4,0,2.4,0.6,3.2,1.8
                        c3,4.8,6,9.5,9,14.2c0.4,0.6,0.8,0.9,1.5,0.8c2,0,4.1,0,6.1,0c2.2,0,3.5,1.3,3.5,3.5c0,5.9,0,11.8,0,17.7c0,2.2-1.3,3.4-3.4,3.4
                        C90.7,66.1,88.8,66.1,86.8,66.1z M35.2,41.5c14.3,0,28.5,0,42.8,0c-2.2-3.5-4.3-6.8-6.5-10.1c-0.2-0.3-0.7-0.4-1-0.4
                        c-8.7,0-17.3,0-26,0c-0.4,0-0.9,0.3-1.2,0.6c-1.3,1.5-2.5,3-3.7,4.5C38.1,37.8,36.7,39.6,35.2,41.5z" />
                                <path d="M74.5,56.9c5.1,0,9.3,4.2,9.2,9.3c-0.1,5.1-4.2,9.1-9.3,9.1c-5,0-9.1-4.2-9.1-9.3C65.3,61,69.5,56.9,74.5,56.9z M77.5,66.1
                        c0-1.7-1.3-3-3-3c-1.7,0-3,1.4-3,3.1c0,1.7,1.4,3.1,3,3C76.2,69.1,77.5,67.8,77.5,66.1z" />
                                <path d="M24,56.9c5.1,0,9.2,4.1,9.2,9.2c0,5.1-4.1,9.1-9.2,9.1c-5.1,0-9.2-4.1-9.2-9.2C14.8,61,18.9,56.9,24,56.9z M23.9,63.1
                        c-1.7,0-3,1.4-3,3.1c0,1.6,1.5,3,3.1,3c1.6,0,3-1.5,3-3.1C27,64.4,25.6,63.1,23.9,63.1z" />
                            </g>
                        </svg>
                    </span>
                    <span class="flex-1 ml-4 text-left whitespace-nowrap" >Vehicles
                        Status  
                        <div 
                        class="bg_primary absolute w-24 left-14 text-[10px] font-medium px-2.5 py-0.25 rounded-full text-white" *ngIf="carCounts?.newCarStatus>0">{{carCounts?.newCarStatus}}
                        New Updates</div>
                    </span>
                        
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <ul id="car-rejected" class="p-2 hidden"  routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                    <li>
                        <a routerLink="/dealer/car-overview" routerLinkActive="text_primary_color font-medium bg-gray-100" (click)="udateLastViewDate()"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path d="M75.5,83.6c5.5-0.4,11.1,0.5,16.5-0.7c0.2,2.5-2,5.5-4.8,5.9c-2.3,0.3-4.7,0.3-7,0
                                    C77.7,88.5,75.7,86.1,75.5,83.6z" />
                                        <g>
                                            <path
                                                d="M97.7,69.2c-0.1,0.5-0.3,1-0.4,1.5c-1.3,4.2-5.2,7.2-9.7,7.2c-15.8,0-31.5,0-47.3,0c-5.5,0-10-4.6-10-10
                                        c0-2.5,0-5.1,0-7.6c0-4,1.9-6.9,5.2-8.9c0.5-0.3,0.8-0.7,0.9-1.3c1.3-5.3,2.6-10.5,3.9-15.8c1.1-4.2,4.2-6.6,8.6-6.6
                                        c10,0,20,0,30.1,0c4.3,0,7.5,2.5,8.6,6.7c1.3,5.3,2.6,10.6,4,15.8c0.1,0.4,0.4,0.8,0.7,1c2.8,1.6,4.7,4,5.3,7.3
                                        c0,0.1,0.1,0.2,0.2,0.3C97.7,62.2,97.7,65.7,97.7,69.2z M42.3,49.9c14.5,0,28.9,0,43.3,0c0-0.2,0-0.2,0-0.3
                                        c-1.2-4.8-2.4-9.5-3.6-14.3c-0.3-1.3-1.3-2-2.7-2.1c-0.7,0-1.4,0-2,0c-9.4,0-18.7,0-28.1,0c-2,0-2.9,0.7-3.4,2.7
                                        c-0.4,1.7-0.9,3.4-1.3,5.1C43.7,44,43,46.9,42.3,49.9z M64,66.7c2.7,0,5.5,0,8.2,0c1.9,0,3.2-1.2,3.2-2.8c0-1.6-1.3-2.8-3.2-2.8
                                        c-5.5,0-10.9,0-16.4,0c-1.9,0-3.2,1.2-3.2,2.8c0,1.6,1.3,2.8,3.2,2.8C58.5,66.8,61.2,66.7,64,66.7z M44.3,61.2
                                        c-1.5,0-2.8,1.3-2.8,2.7c0,1.5,1.3,2.8,2.7,2.8c1.5,0,2.8-1.3,2.8-2.7C47.1,62.5,45.8,61.2,44.3,61.2z M83.7,66.7
                                        c1.5,0,2.8-1.3,2.8-2.8c0-1.5-1.3-2.8-2.8-2.8c-1.5,0-2.8,1.3-2.8,2.8C80.9,65.4,82.2,66.7,83.7,66.7z" />
                                            <path d="M60.4,22c-1.9,0-3.6,0-5.3,0c-0.2,0-0.5-0.3-0.6-0.5c-0.3-0.8-0.4-1.7-0.7-2.5c-0.5-1.8-1.4-2.5-3.4-2.5
                                        c-6.5,0-13,0-19.5,0c-3.3,0-6.5,0-9.8,0c-2,0-2.9,0.7-3.4,2.6c-1.1,4.5-2.3,9-3.4,13.5c0,0.2-0.1,0.4-0.1,0.6c6.8,0,13.6,0,20.5,0
                                        C34,37,33,40.7,32.1,44.4c-1.4,0-2.7,0-4.1,0c-2,0-3.4,1.1-3.4,2.8c0,1.6,1.3,2.8,3.3,2.8c0.1,0,0.2,0,0.5,0
                                        c-2.6,3.3-3.8,6.9-3.7,11.1c-0.2,0-0.4,0.1-0.6,0.1c-3.9,0-7.9,0-11.8,0c-5.3,0-9.9-4.6-9.9-10c0-2.6,0-5.2,0-7.7
                                        c0-4,1.9-6.9,5.2-9c0.4-0.2,0.8-0.7,0.9-1.2c1.4-5.3,2.6-10.6,4-15.8c1-4.2,4.2-6.6,8.5-6.6c9.9,0,19.9,0,29.8,0
                                        c4.2,0,7.4,2.4,8.5,6.4C59.6,18.8,60,20.4,60.4,22z M16.3,44.4c-1.5,0-2.8,1.3-2.8,2.8c0,1.5,1.3,2.8,2.8,2.8
                                        c1.5,0,2.8-1.3,2.8-2.8C19.1,45.8,17.8,44.4,16.3,44.4z" />
                                            <path d="M8,66c2.7,0.9,5.5,0.7,8.3,0.7c2.8,0,5.5,0,8.3,0c-0.1,2.4-2.1,5-4.7,5.3c-2.3,0.3-4.7,0.3-7,0
                                        C10,71.7,7.9,68.7,8,66z" />
                                            <path d="M35.9,82.9c5.5,1.2,11.1,0.3,16.6,0.7c-0.3,2.7-2.5,5.2-5.4,5.4c-2.1,0.2-4.2,0.2-6.3-0.2
                                        c-2.6-0.4-4.6-2.8-4.9-5.4C35.9,83.2,35.9,83.1,35.9,82.9z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                           
                            <div class="grid gap-1 ml-4">
                                <span >Overview  <span>({{carCounts?.overView}})</span></span>
                                <span _ngcontent-wlm-c10=""
                                class="bg_primary -ml-1 bottom-0.5 text-[10px] font-medium px-2.5 py-0.25 rounded-full text-white w-" *ngIf="carCounts?.newOverview>0">{{carCounts?.newOverview}}
                                New Updates</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/car-purchased" routerLinkActive="text_primary_color font-medium bg-gray-100" (click)="udateLastViewDate()"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path d="M4.2,35.1c6.8,0,13.5,0,20.3,0c0.4,0,0.7,0,1.1,0c0,18.9,0,37.7,0,56.7c-7.1,0-14.3,0-21.4,0
                                    C4.2,72.9,4.2,54,4.2,35.1z" />
                                        <path
                                            d="M66.1,36.9c0.4,0,0.7,0,1,0c6.4,0,12.9,0,19.3,0c4.7,0,8.4,3,9.2,7.7c0.6,3.2-0.5,6-2.9,8.2
                                    c-0.2,0.2-0.4,0.4-0.7,0.6c3,4.5,2,9.8-2,12.9c1.4,2,2,4.3,1.7,6.7c-0.4,2.5-1.6,4.5-3.5,5.9c0.5,1.3,1.1,2.4,1.4,3.7
                                    c1.2,4.8-2.2,9.9-7,10.8c-0.8,0.1-1.5,0.2-2.3,0.2c-9.2,0-18.3,0-27.5,0c-5.9,0-11.6-1.3-17-3.5c-1.3-0.5-2.7-1.1-4-1.5
                                    c-0.6-0.2-0.9-0.5-0.9-1.2c0-15.1,0-30.2,0-45.3c0-0.5,0.2-1,0.5-1.4c5.3-6,10.6-12,16-18c1.1-1.2,1.6-2.5,1.6-4.3
                                    c-0.1-2.6,0-5.3,0-8c0-2.7,1.7-4.3,4.4-4.2c8.5,0.2,15.2,7.3,15.5,16c0.1,4.1-1,7.9-2.1,11.7C66.6,35,66.4,35.9,66.1,36.9z" />
                                    </g>
                                </svg>
                            </span>
                           
                            <div class="grid gap-1 ml-4 justify-items-start">
                                <span>Purchased Vehicles<span>({{carCounts?.purchasedCars}})</span> </span>
                                <span _ngcontent-wlm-c10=""
                                class="bg_primary -ml-1 bottom-0.5 text-[10px] font-medium px-2.5 py-0.25 rounded-full text-white" *ngIf="carCounts?.newPurchashed>0">{{carCounts?.newPurchashed}}
                                New Updates</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/car-rejected" (click)="udateLastViewDate()" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path
                                            d="M94.1,40.7c0,0.9,0,1.8,0,2.8c-0.1,0.3-0.2,0.5-0.3,0.8c-0.6,2.2-1.9,3.9-3.8,5.2c0.2,0.3,0.3,0.6,0.5,0.9
                                c1.6,3.3,1.3,6.5-1,9.4C87.7,62,85.2,63,82.3,63c-6.6,0-13.3,0-19.9,0c-0.3,0-0.7,0-1.1,0c0.8,2,1.5,3.9,2.3,5.7
                                c1.6,3.9,2.8,8,2.9,12.3c0,4.6-2.7,8.7-7.1,10.2c-2.3,0.8-4.6,0.7-6.9,0.5c-2.5-0.3-4-1.9-4.5-4.4c-0.3-1.6-0.5-3.2-0.9-4.8
                                c-0.2-1-0.3-2.3-0.9-3c-5-5.9-10.1-11.8-15.2-17.7c-0.4-0.5-0.8-0.6-1.4-0.6c-1.3,0-2.6,0-3.9,0c0-0.3,0-0.4,0-0.6
                                c0-15.5,0-31,0-46.5c0-0.6,0.3-0.8,0.7-0.9c3.6-1.3,7.1-2.7,10.7-4C37.4,9,38,8.8,38.5,8.8c9.3,0,18.7,0,28,0c3.5,0,7,0,10.5,0
                                c3.9,0,7.2,2.4,8.4,6c0.8,2.3,0.5,4.6-0.5,6.8c5,2.2,7,7.7,4.4,12.6c2.3,1.6,3.3,2.6,4,4.2C93.6,39.1,93.8,39.9,94.1,40.7z" />
                                        <path
                                            d="M5.9,8.2c4.8,0,9.6,0,14.4,0c0,19.3,0,38.5,0,57.8c-4.8,0-9.5,0-14.4,0C5.9,46.7,5.9,27.5,5.9,8.2z" />
                                    </g>
                                </svg>
                            </span>
                          
                            <div class="grid gap-1 ml-4">
                                <span >Rejected Vehicles<span>({{carCounts?.rejectedCars}})</span> </span>
                                <span _ngcontent-wlm-c10=""
                                class="bg_primary -ml-1 bottom-0.5 text-[10px] font-medium px-2.5 py-0.25 rounded-full text-white" *ngIf="carCounts?.newRejected>0">{{carCounts?.newRejected}}
                                New Updates</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <button type="button"
                    class="w-full  flex items-center p-4 pb-6 text-base  text-[#3A3F4D] hover:text-[#004AAD] hover:bg-[#EDEDED] group border-l-4 border-transparent hover:font-semibold hover:border-[#004AAD]"
                    aria-controls="billing-invoice" data-collapse-toggle="billing-invoice" (click)="toggleDropdown('billing-invoice')">
                    <span class="w-7 h-7">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                            style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                            <g>
                            </g>
                            <g>
                                <path d="M1.9,79.9c4.7,0,9.3,0,14,0c11.1,0,22.2,0,33.3,0c0.4,0,0.7,0,1.2,0c0,1.6-0.1,3.2,0,4.7c0.1,1.4,0.1,2.8,0.4,4.2
                            c0.6,2.7,2.1,4.9,4.2,6.8c-0.4,0-0.6,0.1-0.8,0.1c-14.1,0-28.2,0-42.3,0c-5,0-8.9-3.2-10-8.1c0-0.1-0.1-0.2-0.2-0.3
                            C1.9,84.8,1.9,82.3,1.9,79.9z" />
                                <path d="M15.3,77c0.1-19.1,0.2-38,0.4-57c13.1,0,26,0,39.1,0c-1,5-0.5,9.7,1.6,14.5c-0.4,0-0.8,0-1.1,0c-4.8,0-9.5,0-14.3,0
                            c-0.4,0-0.8,0-1.2,0.2c-0.7,0.3-0.9,0.8-0.8,1.5c0.1,0.7,0.5,1.1,1.2,1.1c0.3,0,0.7,0,1,0c5.3,0,10.6,0,15.9,0c0.6,0,1,0.2,1.4,0.7
                            c3.4,5,8.1,8.2,14.1,9.6c0.5,0.1,0.7,0.3,0.7,0.9c0,12.5,0,25,0,37.5c0,5.2-4.2,9.4-9.4,9.7c-5.1,0.3-9.9-3.5-10.4-8.5
                            c-0.3-2.6-0.1-5.3-0.1-7.9c0-2-0.3-2.2-2.2-2.2c-11.5,0-23,0-34.5,0C16.2,77,15.8,77,15.3,77z M51.4,51.8c3.5,0,7,0,10.4,0
                            c0.4,0,0.8,0,1.1-0.1c0.7-0.2,1-0.7,1-1.4c0-0.7-0.4-1.2-1.1-1.3C62.5,49,62.2,49,61.9,49c-7,0-13.9,0-20.9,0
                            c-0.3,0-0.7-0.1-0.9,0.1c-0.4,0.3-0.9,0.8-1.1,1.2c-0.2,0.6,0.2,1.2,0.9,1.4c0.4,0.1,0.7,0.1,1.1,0.1C44.5,51.8,48,51.8,51.4,51.8z
                            M51.5,63.5c-3.6,0-7.3,0-10.9,0c-1,0-1.6,0.6-1.6,1.4c0,0.8,0.6,1.4,1.6,1.4c0.1,0,0.2,0,0.3,0c7.1,0,14.1,0,21.2,0
                            c1.2,0,1.8-0.5,1.8-1.4c0-1-0.6-1.5-1.8-1.5C58.6,63.5,55,63.5,51.5,63.5z M34.8,36c0-2.7-2.3-5-5.1-5c-2.8,0-5.1,2.3-5.1,5
                            c0,2.7,2.3,4.9,5,4.9C32.6,40.9,34.8,38.8,34.8,36z M34.8,64.8c0-2.8-2.2-4.9-5.1-4.9c-2.8,0-5.1,2.2-5.1,4.9
                            c0,2.7,2.3,4.9,5.1,4.9C32.6,69.8,34.8,67.6,34.8,64.8z M34.8,50.4c0-2.7-2.3-4.9-5.1-4.9c-2.7,0-5,2.2-5,4.9
                            c0,2.7,2.3,4.9,5.1,4.9C32.6,55.3,34.8,53.1,34.8,50.4z" />
                                <path
                                    d="M98.1,24.9c0,11.3-9.2,20.4-20.5,20.4c-11.2,0-20.4-9.2-20.4-20.5c0-11.3,9.2-20.5,20.5-20.5C89,4.4,98.1,13.5,98.1,24.9z
                            M73.7,19.4c0-1.9,1.3-3.4,3.1-3.8c2.5-0.5,4.3,1.1,4.7,3.8c0.1,0.9,0.7,1.5,1.5,1.4c0.8-0.1,1.4-0.8,1.3-1.7
                            c-0.2-2.6-1.3-4.6-3.7-5.7c-1.3-0.6-2.1-1.1-1.9-2.7c0.1-0.7-0.5-1.1-1.2-1.2c-0.7-0.1-1.3,0.2-1.5,0.9c-0.1,0.4-0.2,0.9-0.1,1.4
                            c0.1,0.7-0.2,1-0.9,1.3c-2.6,1-4.3,3.4-4.3,6.2c0,2.8,1.6,5.3,4.2,6.3c1,0.4,2.1,0.5,3.2,0.6c2.6,0.4,4.1,3.2,3,5.6
                            c-0.8,1.6-2.5,2.5-4.4,2.2c-1.6-0.3-2.9-1.7-3.1-3.5c-0.1-1.3-0.6-1.8-1.6-1.8c-0.9,0.1-1.4,0.8-1.3,2c0.3,2.8,1.8,4.7,4.3,5.7
                            c0.7,0.3,0.8,0.6,0.8,1.2c0,0.5,0,0.9,0.1,1.4c0.2,0.7,0.7,1.1,1.4,1.1c0.8,0,1.3-0.5,1.3-1.3c0-0.3,0.1-0.6,0-0.9
                            c-0.1-0.7,0.1-1.1,0.9-1.4c3.2-1,5-4.2,4.5-7.5c-0.6-3.4-3.1-5.5-6.9-5.7C75.2,23.2,73.7,21.6,73.7,19.4z" />
                                <path
                                    d="M29.7,38c-1.2,0-2.2-0.9-2.2-2c0-1.1,1-2.1,2.2-2c1.2,0,2.1,0.9,2.1,2C32,37.1,31,38,29.7,38z" />
                                <path
                                    d="M32,64.8c0,1.1-1,2.1-2.2,2.1c-1.2,0-2.2-0.9-2.2-2.1c0-1.1,1-2,2.1-2C31,62.8,31.9,63.6,32,64.8z" />
                                <path
                                    d="M32,50.4c0,1.1-1,2-2.2,2c-1.2,0-2.1-0.9-2.2-2c0-1.1,1-2.1,2.2-2.1C31,48.3,32,49.3,32,50.4z" />
                            </g>
                        </svg>
                    </span>
                    <span class="flex-1 ml-4 text-left whitespace-nowrap" >
                        Billing & Invoices</span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <ul id="billing-invoice" class="p-2 hidden"  routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                    <li>
                        <a routerLink="/dealer/kargone-wallet" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group  hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path d="M92.7,65.7c-0.3,0.6-0.6,1.3-1,1.9c-1,1.3-2.4,1.9-4,1.9c-9.4,0-18.8,0-28.2,0c-3,0-5-2.1-5-5.1c0-3.4,0-6.7,0-10.1
                           c0-3,2-5.1,5.1-5.1c9.3,0,18.5,0,27.8,0c2.8,0,4.2,1,5.2,3.6c0,0.1,0.1,0.1,0.1,0.2C92.7,57.2,92.7,61.4,92.7,65.7z M63,59.2
                           c0,2.1,0.5,3,1.9,3.3c0.8,0.1,1.7,0.1,2.6,0c1.1-0.2,1.8-1,1.9-2.2c0.1-1.4,0.3-3-1.3-3.8c-1.2-0.5-2.5-0.5-3.7,0
                           C63,57.1,62.9,58.2,63,59.2z" />
                                        <path
                                            d="M87.8,45.8c-0.4,0-0.8,0-1.1,0c-9,0-17.9,0-26.9,0c-3.5,0-6.2,1.5-7.8,4.7c-0.6,1.2-0.8,2.6-0.8,4c0,3.3,0,6.5,0,9.8
                           c0,5,3.5,8.6,8.5,8.6c9,0,18,0,27,0c0.3,0,0.7,0,1,0c0,0.4,0,0.6,0,0.9c0,4,0,8,0,11.9c0,3.4-2,5.4-5.4,5.4c-23.3,0-46.5,0-69.8,0
                           c-2.7,0-4.6-1.4-5.2-3.8c-0.1-0.5-0.1-0.9-0.1-1.4c0-17.6,0-35.1,0-52.7c0-3.1,2-5.1,5.2-5.1c11.1,0,22.2,0,33.3,0
                           c12.3,0,24.6,0,36.9,0c2.6,0,4.4,1.3,5,3.5c0.1,0.5,0.2,1,0.2,1.6c0,4.1,0,8.1,0,12.2C87.8,45.4,87.8,45.6,87.8,45.8z" />
                                        <path d="M38.4,24.5c1.8-0.5,3.4-0.9,5-1.4c8.9-2.4,17.9-4.9,26.8-7.3c3.1-0.9,5.6,0.5,6.5,3.6c0.5,1.7,0.9,3.3,1.4,5.1
                           C64.8,24.5,51.7,24.5,38.4,24.5z" />
                                        <path d="M22.8,24.5c0.5-0.2,0.8-0.4,1.2-0.6c9.5-4.7,19.1-9.5,28.6-14.2c2.8-1.4,5.4-0.7,6.9,1.9c0.6,1.2,1.2,2.4,1.8,3.6
                           c-0.2,0.1-0.4,0.2-0.5,0.2c-11,3-22.1,6.1-33.1,9C26.1,24.8,24.5,24.5,22.8,24.5z" />
                                    </g>
                                </svg></span>
                            <span class="ml-4">Kargone Wallet </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/billing-invoice/{{ user?.parentUserId ? user?.parentUserId : user?.id}}" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group  hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path
                                            d="M16.6,2.7c22.3,0,44.5,0,66.8,0c1.5,0.7,2.1,2,2,3.6c-0.1,4.9,0,9.7,0,14.6c0,21.6,0,43.1,0,64.7c0,1.1-0.2,2-1,2.8
                           c-2.6,2.6-5.3,5.3-7.9,7.9c-1.3,1.3-2.9,1.3-4.2-0.1c-1.3-1.3-2.6-2.6-3.9-3.9c-0.7-0.7-1.3-1.4-2.1-2.2c-0.3,0.4-0.5,0.6-0.7,0.9
                           c-1.7,1.7-3.4,3.4-5.2,5.2c-1.5,1.5-3,1.5-4.6,0c-0.7-0.7-1.4-1.4-2.2-2.2c-1.2-1.2-2.4-2.4-3.7-3.7c-0.3,0.3-0.5,0.5-0.7,0.7
                           c-1.7,1.7-3.4,3.4-5.1,5.1c-1.6,1.6-3.1,1.6-4.7,0c-1.1-1.1-2.3-2.3-3.4-3.4c-0.8-0.8-1.6-1.6-2.4-2.5c-2.1,2.1-4,4-5.9,5.9
                           c-1.5,1.5-3,1.5-4.6,0c-2.4-2.4-4.8-4.9-7.3-7.2c-1-1-1.4-2-1.4-3.4c0-26.4,0-52.7,0-79.1C14.6,4.7,15.1,3.5,16.6,2.7z M47.2,52.8
                           c0,1.1,0,2.1,0,3.1c0,1.6,1.3,2.8,2.8,2.8c1.5,0,2.7-1.2,2.7-2.8c0-0.7,0-1.5,0-2.2c-0.1-0.7,0.1-1,0.9-1.1c4-0.7,7-4.4,7-8.4
                           c0-4.1-2.9-7.8-6.9-8.5c-1.8-0.3-3.7-0.2-5.6-0.2c-1.4,0-2.6-0.8-3.1-2.1c-0.8-2.2,0.8-4.3,3.2-4.3c2.5,0,5,0,7.6,0
                           c1.1,0,1.9-0.4,2.5-1.3c1.1-1.8-0.1-4-2.2-4.1c-1.1-0.1-2.2,0-3.3,0c0-0.6,0-1,0-1.5c0-3.2-0.8-4.5-2.8-4.5c-1.9,0-2.7,1.3-2.7,4.4
                           c0,0.5,0,1,0,1.5c-0.5,0.1-0.9,0.2-1.2,0.3c-4,0.9-6.9,4.8-6.6,8.7c0.3,4.5,3.5,7.9,7.8,8.3c1.6,0.1,3.2,0.1,4.8,0.1
                           c1.9,0.1,3.2,1.4,3.2,3.2c0,1.8-1.4,3.1-3.3,3.1c-2.5,0-5,0-7.6,0c-1.7,0-3,1.2-3,2.8c0,1.6,1.2,2.7,3,2.8
                           C45.2,52.8,46.2,52.8,47.2,52.8z M50,73.5c1.2,0,2.5,0,3.7,0c3.7,0,7.4,0,11.1,0c1.7,0,2.9-1.3,2.8-2.9C67.5,69,66.3,68,64.4,68
                           c-9.1,0-18.2,0-27.3,0c-0.7,0-1.4,0-2.1,0c-1.1,0.1-1.9,0.6-2.3,1.7c-0.8,1.8,0.5,3.8,2.7,3.8C40.2,73.5,45.1,73.5,50,73.5z" />
                                    </g>
                                </svg>
                            </span>
                            <span class="ml-4">Vehicle Purchase Invoice </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/credit-reciept/{{user?.parentUserId ? user?.parentUserId : user?.id}}" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group  hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path
                                            d="M17.7,22.8c5.8,0,11.4,0,17,0c3.3,0,3.5-0.2,3.5-3.5c0-5.6,0-11.2,0-16.9c0.4,0,0.8,0,1.2,0c13.6,0,27.2,0,40.7,0
                           c1.8,0,2.3,0.5,2.3,2.3c0,30.2,0,60.5,0,90.7c0,1.8-0.5,2.3-2.3,2.3c-20,0-40.1,0-60.1,0c-1.8,0-2.3-0.5-2.3-2.3
                           c0-23.8,0-47.6,0-71.4C17.7,23.6,17.7,23.2,17.7,22.8z M63.6,90.8c3.3,0,6.5,0,9.8,0c1.5,0,2.1-0.6,2.1-2.1c0-2,0-4,0-5.9
                           c0-1.5-0.6-2.1-2.1-2.1c-6.5,0-13,0-19.5,0c-1.5,0-2.1,0.6-2.1,2.1c0,1.9,0,3.9,0,5.8c0,1.6,0.6,2.2,2.2,2.2
                           C57.2,90.9,60.4,90.8,63.6,90.8z M24.5,43.1c17,0,34,0,50.9,0c0-1.2,0-2.2,0-3.3c-17,0-34,0-50.9,0C24.5,40.9,24.5,42,24.5,43.1z
                            M75.5,50.1c-17,0-34,0-50.9,0c0,1.2,0,2.2,0,3.3c17,0,34,0,50.9,0C75.5,52.2,75.5,51.2,75.5,50.1z M75.5,63.6c0-1.2,0-2.2,0-3.3
                           c-17,0-34,0-50.9,0c0,1.1,0,2.2,0,3.3C41.5,63.6,58.4,63.6,75.5,63.6z M75.5,73.8c0-1.1,0-2.2,0-3.3c-17,0-34,0-50.9,0
                           c0,1.1,0,2.2,0,3.3C41.5,73.8,58.4,73.8,75.5,73.8z M70.5,36.3c-0.4-1.4,0.2-2,1.4-2.5c3-1.4,4.3-4.9,3.3-8
                           c-0.9-2.9-3.3-4.5-6.6-4.7c-1.9-0.1-3.3-1.7-3.2-3.6c0.1-1.8,1.6-3.3,3.5-3.1c0.7,0,1.5,0.2,1.9,0.7c0.7,0.7,1.2,1.7,1.8,2.7
                           c0.2,0,0.8,0,1.3,0c0.5,0,1.1,0,1.6,0c-0.1-2.7-1.4-4.9-3.6-5.9c-1.2-0.5-1.7-1.1-1.5-2.4c0,0-0.1-0.1-0.1-0.1c-1.1,0-2.2,0-3.4,0
                           c0.4,1.4-0.2,2-1.4,2.5c-2.9,1.4-4.3,4.8-3.3,7.9c0.9,2.9,3.3,4.6,6.6,4.8c1.9,0.1,3.3,1.7,3.2,3.6c-0.1,1.8-1.6,3.3-3.5,3.1
                           c-0.7,0-1.5-0.2-1.9-0.7c-0.7-0.7-1.2-1.7-1.8-2.6c-0.8,0-1.9,0-3,0c0.1,2.6,1.4,4.8,3.6,5.8c1.2,0.5,1.7,1.1,1.5,2.4
                           c0,0,0.1,0.1,0.1,0.2C68.2,36.3,69.3,36.3,70.5,36.3z M24.5,32.9c11.4,0,22.7,0,33.9,0c0-1.2,0-2.2,0-3.3c-11.3,0-22.6,0-33.9,0
                           C24.5,30.7,24.5,31.8,24.5,32.9z M45,9.2c0,1.1,0,2.2,0,3.3c4.6,0,9,0,13.5,0c0-1.1,0-2.2,0-3.3C54,9.2,49.5,9.2,45,9.2z
                            M58.4,22.7c0-1.2,0-2.2,0-3.3c-4.5,0-9,0-13.5,0c0,1.1,0,2.2,0,3.3C49.5,22.7,53.9,22.7,58.4,22.7z" />
                                        <path
                                            d="M34.7,5.4c0,4.4,0,9.1,0,13.9c-4.8,0-9.5,0-13.9,0C25.3,14.7,30,10,34.7,5.4z" />
                                        <path
                                            d="M72.1,87.4c-5.6,0-11.2,0-16.9,0c0-1.1,0-2.1,0-3.3c5.6,0,11.2,0,16.9,0C72.1,85.2,72.1,86.2,72.1,87.4z" />
                                    </g>
                                </svg></span>
                            <span class="ml-4">Kargone Credit Receipt </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/account-statement-invoice/{{user?.parentUserId ? user?.parentUserId : user?.id}}" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group  hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="#3a3f4d">
                                    <g>
                                        <path d="M2,61.4c2.2-0.7,4.4-1.3,6.6-2c6.1-1.9,12.2-3.8,18.3-5.7c0.5-0.2,1.1-0.2,1.6,0c8,2.5,16.1,5,24.1,7.5
                            c0.6,0.2,0.9,0.5,0.9,1.2c-0.1,1.5,0,3.1,0,4.8c-0.5,0-0.9,0-1.3,0c-16.3,0-32.6,0-49,0c-0.4,0-0.8,0.1-1.2,0.1
                            C2,65.3,2,63.4,2,61.4z M27.7,56.9c-1.9,0-3.4,1.5-3.4,3.3c0,1.9,1.5,3.5,3.4,3.5c1.9,0,3.4-1.5,3.4-3.4
                            C31.1,58.4,29.6,56.9,27.7,56.9z" />
                                        <path d="M98,87.1c-0.4,0.7-0.7,1.5-1.2,2.2c-0.9,1.2-2.2,1.8-4,1.8c1.8-2.3,1.8-4.9,1.8-7.4c0-21.7,0-43.5,0-65.2
                            c0-1.2-0.3-2.1-1.2-2.9c-4.3-4.2-8.5-8.5-12.8-12.7c-0.2-0.2-0.4-0.4-0.7-0.8c0.4,0,0.7-0.1,1-0.1c3.8,0,7.7,0,11.5,0
                            c2.8,0,4.5,1.2,5.4,3.9C97.9,6,98,6.1,98,6.2C98,33.1,98,60.1,98,87.1z" />
                                        <path d="M2,94.6c0.3,0,0.7-0.1,1-0.1c16.4,0,32.8,0,49.2,0c0.4,0,0.7,0,1.2,0c0,1.2,0,2.3,0,3.4C36.2,98,19.1,98,2,98
                            C2,96.8,2,95.7,2,94.6z" />
                                        <path d="M91.2,19.1c0,0.5,0,0.8,0,1.1c0,21.7,0,43.4,0,65.1c0,3.7-2.1,5.8-5.8,5.8c-10.3,0-20.6,0-30.9,0c-0.3,0-0.7,0-1.1,0
                            c0-6.9,0-13.7,0-20.6c0.5,0,1.1,0,1.6,0c1.2-0.1,1.8-0.7,1.8-1.9c0-2.7,0-5.5,0-8.2c0-1.1-0.4-1.5-1.6-1.9
                            c-8.5-2.6-17-5.3-25.4-7.9c-1.2-0.4-2.4-0.9-3.7,0c0-0.5,0-0.8,0-1.2c0-14,0-28,0-42C26,4.2,28.1,2,31.4,2C45.3,2,59.2,2,73,2
                            c0.3,0,0.6,0,1,0c0,0.3,0,0.6,0,0.9c0,2.4,0,4.8,0,7.2c0,5.3,3.7,8.9,9,8.9C85.7,19.1,88.4,19.1,91.2,19.1z M86,34.7
                            c-18.3,0-36.5,0-54.8,0c0,1.1,0,2.2,0,3.3c12,0,23.9,0,36,0c0,1.4-0.1,2.7,0,4c0.1,1-0.2,1.2-1.2,1.2c-11.2,0-22.4,0-33.6,0
                            c-0.4,0-0.8,0-1.2,0c0,1.2,0,2.2,0,3.4c12,0,24,0,35.9,0c0,1.8,0,3.4,0,5.1c-5.2,0-10.2,0-15.3,0c0,1.2,0,2.2,0,3.4
                            c5.2,0,10.2,0,15.3,0c0,1.8,0,3.4,0,5.2c-2.3,0-4.5,0-6.8,0c0,1.1,0,2.2,0,3.4c2.3,0,4.5,0,6.8,0c0,1.8,0,3.4,0,5.2
                            c-2.3,0-4.5,0-6.7,0c0,1.2,0,2.2,0,3.4c2.3,0,4.5,0,6.8,0c0,1.8,0,3.4,0,5.1c-3.4,0-6.8,0-10.2,0c0,1.2,0,2.2,0,3.3
                            c9.7,0,19.3,0,29,0c0-1.1,0-2.2,0-3.4c-5.1,0-10.2,0-15.3,0c0-1.7,0-3.4,0-5.1c5.1,0,10.2,0,15.3,0c0-1.2,0-2.2,0-3.4
                            c-5.1,0-10.2,0-15.3,0c0-1.8,0-3.4,0-5.2c5.2,0,10.2,0,15.3,0c0-1.2,0-2.2,0-3.4c-5.1,0-10.2,0-15.3,0c0-1.8,0-3.4,0-5.2
                            c5.2,0,10.2,0,15.3,0c0-1.2,0-2.2,0-3.4c-5.2,0-10.2,0-15.3,0c0-1.7,0-3.4,0-5.1c5.2,0,10.2,0,15.3,0c0-1.2,0-2.2,0-3.4
                            c-5.1,0-10.2,0-15.3,0c0-1.8,0-3.4,0-5.2c5.1,0,10.2,0,15.3,0C86,36.9,86,35.8,86,34.7z M36.3,7.3c0,1.1,0,2.2,0,3.3
                            c-0.3,0-0.5,0-0.6,0.1c-2.4,0.3-4.3,2.3-4.5,4.6c-0.2,3.1,1.8,5.4,5,5.6c1.2,0.1,2.3,0,3.5,0c1.1,0,1.8,0.7,1.8,1.7
                            c0,1-0.7,1.7-1.8,1.7c-0.7,0-1.3,0-2,0c-1.6,0-3.1,0-4.6,0c0,1.2,0,2.3,0,3.4c1.1,0,2.2,0,3.4,0c0,1.2,0,2.2,0,3.3
                            c1.1,0,2.2,0,3.4,0c0-1.1,0-2.2,0-3.3c0.3,0,0.5-0.1,0.7-0.1c2.7-0.5,4.5-2.6,4.4-5.3c-0.1-2.6-2-4.6-4.8-4.8
                            c-1.2-0.1-2.5,0-3.7-0.1c-1.1,0-1.7-0.7-1.7-1.7c0-1,0.7-1.6,1.8-1.7c1,0,2.1,0,3.1,0c1.2,0,2.4,0,3.6,0c0-1.2,0-2.3,0-3.4
                            c-1.1,0-2.2,0-3.3,0c0-1.1,0-2.2,0-3.4C38.5,7.3,37.5,7.3,36.3,7.3z M57,84.3c0,1.2,0,2.2,0,3.3c9.7,0,19.4,0,29,0
                            c0-1.1,0-2.2,0-3.3C76.3,84.3,66.7,84.3,57,84.3z M70.5,14c0-1.2,0-2.2,0-3.3c-6.9,0-13.7,0-20.4,0c0,1.1,0,2.2,0,3.3
                            C56.9,14,63.7,14,70.5,14z M50,27.7c6.9,0,13.7,0,20.5,0c0-1.1,0-2.2,0-3.3c-6.9,0-13.7,0-20.5,0C50,25.5,50,26.5,50,27.7z
                            M60.2,17.5c-3.4,0-6.8,0-10.2,0c0,1.1,0,2.2,0,3.3c3.4,0,6.8,0,10.2,0C60.2,19.7,60.2,18.6,60.2,17.5z M63.8,20.7
                            c0.1,0,0.2,0.1,0.3,0.1c1.3,0,2.6,0,3.9,0c2.8,0,2.7,0,2.6-2.8c0-0.5-0.2-0.7-0.7-0.7c-1.2,0-2.5,0-3.7,0c-0.8,0-1.6,0-2.4,0
                            C63.8,18.7,63.8,19.7,63.8,20.7z" />
                                        <path d="M32.8,70.6c1.2,0,2.3,0,3.4,0c0,6.8,0,13.6,0,20.5c-1,0-2,0-3,0c-0.2,0-0.4-0.3-0.4-0.6c-0.1-0.6,0-1.1,0-1.7
                            c0-5.7,0-11.4,0-17C32.8,71.5,32.8,71.1,32.8,70.6z" />
                                        <path d="M39.7,91.1c0-6.9,0-13.7,0-20.5c1.1,0,2.2,0,3.4,0c0,0.3,0,0.7,0,1c0,6.2,0,12.3,0,18.5c0,0.9-0.3,1.1-1.1,1.1
                            C41.3,91.1,40.5,91.1,39.7,91.1z" />
                                        <path
                                            d="M8.8,91.1c-1.2,0-2.3,0-3.4,0c0-6.8,0-13.6,0-20.5c1.1,0,2.2,0,3.4,0C8.8,77.4,8.8,84.2,8.8,91.1z" />
                                        <path
                                            d="M19.1,70.6c1.2,0,2.3,0,3.4,0c0,6.8,0,13.6,0,20.5c-1.1,0-2.3,0-3.4,0C19.1,84.2,19.1,77.4,19.1,70.6z" />
                                        <path
                                            d="M15.6,91.1c-1.1,0-2.2,0-3.3,0c0-6.8,0-13.6,0-20.5c1.1,0,2.1,0,3.3,0C15.6,77.4,15.6,84.2,15.6,91.1z" />
                                        <path
                                            d="M26,70.6c1.1,0,2.2,0,3.3,0c0,6.8,0,13.6,0,20.5c-1.1,0-2.2,0-3.3,0C26,84.3,26,77.5,26,70.6z" />
                                        <path
                                            d="M49.9,91.1c-1.1,0-2.2,0-3.3,0c0-6.8,0-13.6,0-20.5c1.1,0,2.2,0,3.3,0C49.9,77.4,49.9,84.2,49.9,91.1z" />
                                        <path
                                            d="M77.4,4.7c3.7,3.7,7.4,7.3,11,11c-2.3,0-4.6,0.2-6.9-0.1c-2.2-0.2-3.9-2.2-4.1-4.5C77.3,9,77.4,6.8,77.4,4.7z" />
                                    </g>
                                </svg>
                            </span>
                            <span class="ml-4">Dealer Account Statement </span>
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <button type="button"
                    class="w-full  flex items-center p-4 pb-6 text-base  text-[#3A3F4D] hover:text-[#004AAD] hover:bg-[#EDEDED] group border-l-4 border-transparent hover:font-semibold hover:border-[#004AAD]"
                    aria-controls="account-setting" data-collapse-toggle="account-setting" (click)="toggleDropdown('account-setting')">
                    <span class="w-6 currentColor">
                        <svg version="1.1" id="setting" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                            style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                            <g>
                                <path d="M20.1,74.1c0-6.1-4.5-11.2-10.5-11.9c-3.2-0.4-5-2.1-5.5-5.3c-0.9-5.2-0.7-10.4,0.3-15.6c0.5-2.6,2.5-4.4,5.2-4.7
                            c7.9-0.9,12.7-8.7,10.1-16.1c-1.1-3.2-0.5-5.4,2.2-7.5c3.6-2.8,7.5-4.9,11.8-6.6c2.7-1,5.1-0.4,6.9,1.8c5.1,6.1,13.7,6.1,18.9,0
                            c1.8-2.2,4.2-2.8,6.9-1.8c4.7,1.8,9,4.3,12.9,7.4c2.4,2,3,4.1,2,7.1c-2.5,7.2,2,14.7,9.5,16c2.7,0.5,4.4,2.1,5,4.9
                            c1,5.4,0.9,10.7-0.1,16c-0.5,2.4-2.4,4.2-4.8,4.5c-6.6,1-11,6.2-10.6,12.9c0.1,1.2,0.4,2.4,0.8,3.6c1,2.8,0.4,5.1-1.9,7
                            c-3.6,3-7.6,5.3-11.9,7.1c-2.6,1.1-5.2,0.4-6.9-1.8c-5.2-6.6-14.4-6.5-19.5,0.2c-1.8,2.4-4.3,3.1-7.1,2.1c-4.7-1.8-9.1-4.3-13-7.5
                            c-2.2-1.8-2.7-4.2-1.7-6.9c0.6-1.6,0.9-3.3,1.4-5C20.3,74.1,20.2,74.1,20.1,74.1z M65.5,50c0.1-8.4-6.9-15.5-15.3-15.5
                            c-8.5-0.1-15.6,6.9-15.7,15.3c-0.1,8.5,6.8,15.5,15.3,15.6C58.4,65.6,65.4,58.6,65.5,50z" />
                            </g>
                        </svg>
                    </span>
                    <span class="flex-1 ml-4 text-left whitespace-nowrap" >
                        Account Settings</span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <ul id="account-setting" class="p-2 hidden"  routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                    <li *ngIf="user?.parentUserId == user?.id">
                        <a routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4 cursor-pointer  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6 currentColor">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path d="M74.39,32.5c3.41,0,6.71-0.02,10.01,0.01c2.75,0.02,5.23,0.81,7.09,2.96c1.1,1.27,1.92,2.71,2.04,4.44
                                        c0.09,1.29,0.18,2.58,0.18,3.88c0.01,13.63,0.02,27.27,0,40.9c-0.01,4.33-2.52,7.53-6.61,8.78c-0.53,0.16-1.11,0.23-1.67,0.23
                                        c-23.8,0.01-47.61,0.02-71.41,0c-1.58,0-3-0.74-4.26-1.68c-1.82-1.35-2.91-3.16-3.38-5.36c-0.05-0.26-0.1-0.53-0.1-0.79
                                        c0-15.12-0.01-30.25,0-45.37c0-1.62,0.65-3.07,1.56-4.35c1.16-1.64,2.73-2.78,4.7-3.28c0.71-0.18,1.45-0.33,2.17-0.34
                                        c3.58-0.03,7.16-0.02,10.77-0.02c-0.42-8.36,2.61-15.29,8.88-20.67c4.37-3.76,9.63-5.59,15.41-5.54
                                        c7.02,0.06,13.14,2.51,17.98,7.68C72.59,19.12,74.7,25.34,74.39,32.5z M73.31,38.36c-0.05,0.05-0.1,0.1-0.15,0.15
                                        c-0.12,0.27-0.26,0.53-0.36,0.81c-1.74,4.89-4.89,8.7-9.11,11.62c-4.2,2.9-8.9,4.31-13.99,4.31c-1.54,0-3.08-0.26-4.6-0.53
                                        c-4.48-0.79-8.33-2.9-11.67-5.92c-3.02-2.74-5.18-6.1-6.57-9.94c-0.08-0.22-0.44-0.49-0.68-0.49c-3.73-0.03-7.47-0.05-11.2,0
                                        c-1.77,0.02-2.88,1.22-2.88,2.97c-0.01,14.54-0.01,29.08,0,43.63c0,1.7,1.24,2.92,2.92,2.92c23.19,0,46.38,0,69.57,0
                                        c0.32,0,0.65-0.05,0.96-0.12c1.39-0.33,2.34-1.55,2.33-3.37c-0.01-3.19,0-6.38,0-9.56c0-10.96-0.01-21.92,0.01-32.88
                                        c0-2.33-1.35-3.58-3.2-3.59C80.9,38.33,77.11,38.36,73.31,38.36z M65.4,40.87c0.16-0.16,0.3-0.25,0.37-0.38
                                        c2.59-4.31,3.41-8.92,2.32-13.86c-0.9-4.07-2.98-7.46-6.13-10.1c-4.58-3.84-9.98-5.2-15.78-4c-6.85,1.41-11.45,5.69-13.86,12.28
                                        c-1.04,2.84-1.13,5.77-0.73,8.73c0.32,2.41,1.2,4.62,2.38,6.73c0.28,0.5,0.57,0.59,1.05,0.22c1.15-0.89,2.25-1.85,3.5-2.57
                                        c3.15-1.83,6.59-2.91,10.24-2.99c1.89-0.04,3.81,0.23,5.69,0.51C58.66,36.07,62.21,38.09,65.4,40.87z M61.44,45.35
                                        c-6.3-6.48-17.99-5.61-22.91-0.05C43.74,49.72,52.86,51.69,61.44,45.35z" />
                                        <path
                                            d="M73.31,38.36c-0.05,0.05-0.1,0.1-0.15,0.15C73.21,38.46,73.26,38.41,73.31,38.36z" />
                                        <path d="M49.75,73.76c-6.37,0-12.74-0.02-19.12,0.01c-3.43,0.02-5.89-1.53-7.5-4.48c-1.55-2.85-0.86-6.75,1.38-9.04
                                        c1.7-1.73,3.72-2.5,6.08-2.5c12.9,0,25.79-0.01,38.69,0c2.25,0,4.29,0.69,5.89,2.28c1.41,1.41,2.26,3.12,2.35,5.22
                                        c0.1,2.35-0.55,4.38-2.15,6.03c-1.65,1.7-3.74,2.5-6.16,2.49C62.73,73.74,56.24,73.76,49.75,73.76z M49.8,67.87
                                        c3.97,0,7.95,0,11.92,0c2.61,0,5.22,0.01,7.83,0c1.01-0.01,1.99-0.75,2.13-1.57c0.21-1.19-0.18-2-1.3-2.45
                                        c-0.4-0.16-0.87-0.2-1.31-0.2c-12.71-0.01-25.42-0.01-38.13-0.01c-0.33,0-0.67-0.01-1,0.02c-1.24,0.14-2.07,1.52-1.64,2.68
                                        c0.42,1.14,0.96,1.52,2.2,1.52C36.93,67.87,43.36,67.87,49.8,67.87z" />
                                        <path class="st281"
                                            d="M49.91,82.97c-3.31,0-6.61,0-9.92,0c-1.43,0-2.67-1.31-2.77-2.72c-0.12-1.81,1.25-3.11,2.67-3.11
                                        c6.61,0,13.22-0.03,19.83,0c2.02,0.01,3.04,1.55,2.99,2.77c-0.07,1.69-0.96,3.02-3.07,3.05C56.4,82.99,53.16,82.97,49.91,82.97z" />
                                        <path d="M41.24,23.94c-0.2-4.3,3.76-8.89,9.14-8.58c4.54,0.26,8.41,3.93,8.36,8.82c-0.05,4.82-4.02,9.14-9.77,8.66
                                        C44.95,32.5,41.06,28.9,41.24,23.94z M47.07,24.14c-0.2,1.34,1.37,2.95,2.79,2.89c1.77-0.07,2.99-1.36,3.03-2.96
                                        c0.04-1.53-1.44-2.88-3-2.88C48.33,21.18,46.85,22.8,47.07,24.14z" />
                                    </g>
                                </svg>
                            </span>
                            <span class="ml-4" routerLink="/dealer/dealer-profile" routerLinkActive="text_primary_color font-medium bg-gray-100">Dealership Registration
                                Details</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/dealer-preferences/{{user?.id}}" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6 currentColor">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path
                                            d="M22.71,12.37c1.43-3.63,3.77-6.45,7.32-8.02c5.09-2.25,9.94-1.61,14.46,1.64c1.99,1.43,3.36,3.33,4.27,5.57
                                    c0.3,0.72,0.74,0.83,1.42,0.83c13.82-0.02,27.64-0.02,41.46,0c1.92,0,3.66,0.51,4.66,2.35c0.85,1.55,0.93,3.14,0.02,4.7
                                    c-0.86,1.47-2.13,2.37-3.86,2.37c-14.04,0.02-28.08,0.02-42.12-0.01c-0.81,0-1.26,0.16-1.62,0.99c-1.94,4.47-5.47,7.17-10.11,8.21
                                    c-4.1,0.92-7.9-0.13-11.26-2.73c-2.02-1.56-3.47-3.5-4.36-5.87c-0.2-0.54-0.56-0.6-1.08-0.6c-4.8,0.02-9.6,0.03-14.41,0
                                    c-2.06-0.01-3.36-1.16-4.13-3.01c-0.73-1.74-0.3-3.25,0.81-4.68c1.02-1.32,2.42-1.73,4-1.73C13.02,12.37,17.86,12.37,22.71,12.37z" />
                                        <path d="M22.69,87.54c-5.03,0-10.05,0.02-15.07-0.01c-2.49-0.01-4.57-2.09-4.58-4.75c-0.01-2.39,1.66-4.61,4.46-4.65
                                    c4.77-0.07,9.53-0.04,14.3,0c0.73,0,0.99-0.22,1.27-0.91c1.94-4.68,6.89-8.38,12.28-8.39c4.43-0.01,8.13,1.35,11.02,4.66
                                    c1,1.14,1.78,2.51,2.48,3.87c0.31,0.61,0.58,0.77,1.19,0.77c5.35-0.02,10.71-0.01,16.06-0.01c8.62,0,17.23-0.01,25.85,0.01
                                    c1.49,0,2.8,0.43,3.86,1.61c1.94,2.16,1.29,5.88-1.11,7.05c-0.9,0.44-1.96,0.73-2.96,0.74c-13.71,0.05-27.43,0.05-41.14,0
                                    c-1.14,0-1.61,0.39-2.07,1.35c-2.19,4.63-5.96,7.12-10.96,7.96c-3.21,0.54-6.12-0.32-8.79-1.89c-2.73-1.61-4.7-3.99-5.91-6.96
                                    C22.82,87.84,22.76,87.71,22.69,87.54z" />
                                        <path d="M50.76,54.68c-0.37,0-0.81,0-1.25,0c-13.67,0-27.34-0.03-41.01,0.02c-2.77,0.01-4.35-1.3-5.14-3.12
                                    c-1.2-2.75,1.15-6.17,3.77-6.26c0.59-0.02,1.17-0.08,1.75-0.08c13.56,0,27.12,0,40.68,0c0.4,0,0.81,0,1.21,0
                                    c-0.07-0.06-0.15-0.13-0.22-0.19c1.28-1.74,2.43-3.59,3.87-5.18c2-2.21,4.62-3.36,7.6-3.79c3.77-0.55,7.17,0.32,10.24,2.47
                                    c2.13,1.49,3.74,3.47,4.68,5.89c0.28,0.72,0.63,0.81,1.26,0.81c4.58-0.02,9.16-0.02,13.74-0.01c2.83,0.01,5.1,2.06,4.98,4.98
                                    c-0.09,2.12-1.89,4.24-4.07,4.37c-2.37,0.14-4.76,0.08-7.14,0.09c-2.38,0.01-4.77,0.04-7.15-0.02c-0.89-0.02-1.31,0.19-1.73,1.09
                                    c-1.94,4.21-5.22,7-9.75,7.99c-4.82,1.05-9.17-0.16-12.73-3.7c-0.76-0.76-1.46-1.6-2.06-2.49C51.71,56.67,51.29,55.68,50.76,54.68z
                                    " />
                                    </g>
                                </svg>

                            </span>
                            <span class="ml-4">Dealer Preference </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/rating" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6 currentColor">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path
                                            d="M51.29,4.28c1.12,0.24,2.23,0.47,3.35,0.71c3.39,0.7,6.3,2.32,8.87,4.62c2.68,2.38,4.57,5.26,5.67,8.66
                                c0.69,2.12,1.1,4.31,1.02,6.52c-0.17,5.02-1.72,9.54-5.24,13.24c-0.68,0.71-1.46,1.33-2.17,1.97c2.36,1.2,4.55,2.25,6.67,3.42
                                c2.31,1.27,4.35,2.9,6.31,4.69c2.14,1.95,3.98,4.12,5.59,6.5c1.3,1.94,1.03,3.87-0.64,5.06c-1.84,1.3-3.96,0.92-5.26-1.01
                                c-2.86-4.25-6.58-7.51-11.09-9.9c-3.12-1.65-6.41-2.85-9.95-3.19c-2.28-0.22-4.6-0.48-6.87-0.33c-6.18,0.42-11.77,2.51-16.68,6.35
                                c-2.64,2.06-4.88,4.46-6.76,7.23c-0.8,1.19-2.05,1.87-3.45,1.69c-1.47-0.19-2.61-1.06-3.04-2.61c-0.43-1.57,0.19-2.87,1.16-4.02
                                c1.61-1.91,3.15-3.91,4.95-5.63c3.42-3.27,7.34-5.82,11.79-7.52c0.48-0.18,0.95-0.4,1.3-0.55c-1.26-1.59-2.64-3.15-3.83-4.84
                                c-1.67-2.38-2.56-5.09-2.96-7.98c-0.77-5.54,0.56-10.55,3.85-15c2.69-3.63,6.34-6.04,10.69-7.3c1.13-0.33,2.34-0.4,3.51-0.61
                                c0.13-0.02,0.24-0.11,0.36-0.17C49.39,4.28,50.34,4.28,51.29,4.28z M49.72,37.51c7.46,0.23,13.32-5.76,13.33-12.92
                                c0-6.98-5.51-13.32-13.98-12.99c-5.85,0.23-12.68,5.6-12.06,14.14C37.43,31.6,42.84,37.7,49.72,37.51z" />
                                        <path
                                            d="M95.78,79.12c-2.05,1.6-4.09,3.2-6.16,4.78c-0.89,0.68-1.23,1.67-0.87,2.77c0.69,2.06,1.38,4.12,2.02,6.2
                                c0.32,1.03-0.08,1.9-0.96,2.46c-0.92,0.58-1.84,0.5-2.73-0.16c-1.6-1.19-3.24-2.34-4.84-3.54c-1-0.75-2.42-0.85-3.89,0.34
                                c-1.44,1.17-3,2.2-4.51,3.28c-0.61,0.43-2.07,0.58-2.89-0.14c-0.65-0.56-0.87-1.8-0.53-2.75c0.73-2.04,1.42-4.1,2.1-6.16
                                c0.27-0.83-0.26-1.77-1.19-2.43c-1.65-1.17-3.29-2.36-4.91-3.58c-0.94-0.71-1.27-1.77-0.96-2.77c0.32-1.03,1.16-1.61,2.44-1.62
                                c1.96-0.02,3.93,0,5.89-0.01c1.83-0.01,2.19-0.26,2.78-1.98c0.63-1.84,1.24-3.68,1.88-5.52c0.4-1.16,0.83-1.48,1.96-1.48
                                c1.36,0,1.97,0.36,2.36,1.53c0.55,1.65,1.06,3.32,1.59,4.98c0.12,0.38,0.23,0.77,0.36,1.15c0.33,0.93,1.16,1.36,2.14,1.34
                                c2.26-0.05,4.52-0.04,6.78,0c1.1,0.02,1.61,0.91,2.15,1.71C95.78,78.05,95.78,78.59,95.78,79.12z" />
                                        <path d="M40.64,63.23c1.16,0,2.32,0.02,3.48-0.01c0.85-0.02,1.38-0.48,1.65-1.3c0.67-2.03,1.37-4.06,2.07-6.08
                                c0.42-1.21,0.87-1.54,2.01-1.54c1.35,0,1.96,0.37,2.35,1.55c0.55,1.65,1.06,3.32,1.59,4.99c0.12,0.38,0.23,0.77,0.36,1.15
                                c0.33,0.91,1.17,1.34,2.16,1.32c2.26-0.05,4.53-0.04,6.79,0.01c0.93,0.02,1.75,0.81,1.95,1.66c0.3,1.26,0.01,2.08-0.9,2.73
                                c-1.78,1.26-3.54,2.53-5.31,3.81c-0.68,0.49-0.96,1.72-0.65,2.66c0.68,2.06,1.39,4.12,2.02,6.2c0.32,1.04-0.07,1.9-0.96,2.46
                                c-0.91,0.58-1.84,0.5-2.73-0.15c-1.61-1.19-3.25-2.34-4.85-3.54c-0.99-0.75-2.42-0.86-3.89,0.34c-1.42,1.16-2.95,2.17-4.45,3.22
                                c-0.89,0.62-2.14,0.57-2.95-0.09c-0.67-0.54-0.87-1.8-0.53-2.75c0.73-2.04,1.43-4.1,2.1-6.16c0.27-0.83-0.26-1.76-1.2-2.43
                                c-1.68-1.19-3.33-2.41-4.98-3.64c-0.87-0.65-1.2-1.75-0.88-2.72c0.31-0.97,1.11-1.57,2.27-1.61c1.16-0.04,2.32-0.01,3.48-0.01
                                C40.64,63.26,40.64,63.25,40.64,63.23z" />
                                        <path
                                            d="M10.1,75.74c1.13,0,2.26,0.02,3.39-0.01c0.88-0.02,1.45-0.46,1.73-1.32c0.67-2.03,1.38-4.06,2.08-6.08
                                c0.42-1.21,0.86-1.53,2.02-1.53c1.33,0,1.95,0.38,2.34,1.55c0.61,1.82,1.18,3.65,1.77,5.47c0.55,1.72,0.94,1.98,2.71,1.96
                                c1.99-0.02,3.99-0.01,5.98,0c1.11,0.01,2.1,0.65,2.37,1.52c0.4,1.29,0.1,2.16-0.98,2.93c-1.75,1.25-3.49,2.52-5.22,3.78
                                c-0.68,0.49-0.94,1.72-0.63,2.66c0.68,2.06,1.38,4.12,2.02,6.2c0.31,1.03-0.08,1.9-0.97,2.45c-0.93,0.58-1.83,0.5-2.73-0.17
                                c-1.61-1.19-3.22-2.38-4.86-3.52c-1.3-0.9-2.36-0.78-3.8,0.29c-1.49,1.11-3,2.19-4.52,3.26c-0.89,0.62-2.14,0.56-2.95-0.1
                                c-0.67-0.54-0.86-1.8-0.53-2.75c0.73-2.04,1.43-4.1,2.1-6.16c0.27-0.83-0.27-1.74-1.21-2.42c-1.65-1.17-3.29-2.37-4.91-3.59
                                c-0.92-0.69-1.26-1.8-0.94-2.78c0.33-1.01,1.14-1.57,2.36-1.6c1.13-0.03,2.26-0.01,3.39-0.01C10.1,75.77,10.1,75.75,10.1,75.74z" />
                                    </g>
                                </svg>
                            </span>
                            <span class="ml-4">Dealer Ratings & Reviews</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/dealer/change-password" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-7 currentColor">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path d="M94.75,73.24c-0.17,0.32-0.32,0.65-0.51,0.96c-0.86,1.42-2.17,1.93-3.77,1.93
                                c-8.33-0.01-16.66-0.01-24.98,0.01c-1.05,0-2.04-0.1-2.86-0.79c-0.8-0.66-1.42-1.47-1.42-2.57c-0.01-6.44-0.01-12.87-0.01-19.31
                                c0-1.36,1.06-3.25,3.45-3.51c0.25-1.84,0.28-3.71,0.78-5.44c1.24-4.26,3.96-7.31,8.25-8.69c4.82-1.55,9.2-0.67,13.04,2.72
                                c2.36,2.08,3.66,4.73,4.16,7.77c0.17,1.02,0,2.09,0.06,3.14c0.01,0.25,0.32,0.67,0.51,0.68c1.82,0.11,2.68,1.26,3.23,2.8
                                c0.01,0.02,0.05,0.02,0.08,0.04C94.75,59.73,94.75,66.48,94.75,73.24z M83.57,49.98c-0.03-2.45-0.17-4.71-2.37-6.32
                                c-1.56-1.15-4.49-1.37-6.09-0.31c-2.42,1.6-3.08,3.82-2.62,6.64C76.15,49.98,79.81,49.98,83.57,49.98z M76.05,65.48
                                c0,0.46-0.04,0.79,0.01,1.1c0.18,1.22,0.66,2.09,2.12,1.95c1.01-0.1,1.55-0.79,1.55-2.07c0-1.08-0.34-2.29,1-2.92
                                c0.02-0.01,0.02-0.05,0.03-0.08c1.75-2.4,0.83-5.03-2.04-5.83c-1.62-0.45-3.16,0.23-4.04,1.75c-0.68,1.18-0.65,3.11,0.56,4.19
                                C75.87,64.14,76.25,64.75,76.05,65.48z" />
                                        <path
                                            d="M46.15,23.87c10.95,0,21.9,0,32.85,0c1.52,0,3.03,0.2,4.37,0.97c2.08,1.2,3.48,2.91,3.69,5.41
                                c0.04,0.47,0.19,0.98,0.05,1.41c-0.36,1.18-1.38,1.59-2.72,1.07c-2.43-0.95-4.92-1.38-7.53-1.27c-3.18,0.14-6.03,1.17-8.6,2.99
                                c-3.09,2.19-5.08,5.2-6.28,8.74c-0.33,0.99-0.36,2.07-0.64,3.08c-0.14,0.52-0.41,1.13-0.82,1.45c-1.75,1.35-2.78,3.07-3,5.27
                                c-0.09,0.86-0.14,1.72-0.17,2.58c-0.03,1.08-0.78,1.89-1.86,1.92c-0.7,0.02-1.4,0.01-2.1,0.01c-13.34,0-26.68-0.01-40.01,0.01
                                c-1.66,0-3.25-0.24-4.63-1.15c-2.06-1.37-3.44-3.26-3.46-5.81c-0.06-6.52-0.05-13.05-0.01-19.57c0.02-3.19,2.38-6.04,5.47-6.81
                                c0.88-0.22,1.82-0.28,2.73-0.28C24.37,23.86,35.26,23.87,46.15,23.87z M50.08,43.96c1.13,0.68,1.99,0.58,2.71-0.33
                                c0.67-0.85,0.46-1.6-0.83-2.99c1.2-0.95,1.51-1.88,0.96-2.82c-0.36-0.62-1.61-1.12-2.85-0.46c-0.08-0.27-0.16-0.54-0.22-0.81
                                c-0.19-0.85-0.86-1.39-1.77-1.42c-0.79-0.02-1.55,0.54-1.72,1.35c-0.12,0.61-0.24,0.91-1,0.64c-0.94-0.34-1.75,0.08-2.14,0.91
                                c-0.42,0.9-0.2,1.67,0.65,2.27c0.13,0.1,0.27,0.19,0.41,0.28c-1.47,1.43-1.56,1.81-0.94,2.84c0.2,0.34,0.55,0.77,0.89,0.83
                                c0.6,0.11,1.24-0.03,1.89-0.07c0.45,1.56,0.89,1.99,2.05,1.96C49.24,46.12,49.71,45.58,50.08,43.96z M22.04,40.63
                                c1.12-0.72,1.56-1.56,1.17-2.46c-0.61-1.43-1.82-1.2-2.99-0.9c-0.32-1.5-0.98-2.17-2.05-2.14c-1.04,0.03-1.59,0.72-1.8,2.25
                                c-1.64-0.58-2.42-0.39-3.01,0.74c-0.45,0.86-0.09,1.67,1.12,2.56c-1.29,0.86-1.66,1.62-1.07,2.64c0.26,0.46,0.84,0.9,1.35,1.02
                                c0.5,0.12,1.1-0.19,1.66-0.3c0.1,1.35,0.59,2.05,1.51,2.11c1.17,0.07,1.87-0.06,2.3-2.09c1.75,0.4,2.34,0.28,2.82-0.6
                                C23.56,42.54,23.33,41.87,22.04,40.63z M31.18,44.06c0.7,1.97,0.94,2.19,2.31,2.09c0.9-0.06,1.36-0.67,1.58-2.09
                                c1.82,0.42,2.5,0.26,2.94-0.68c0.49-1.06,0.2-1.81-1.08-2.72c1.32-0.86,1.52-1.77,1.17-2.58c-0.45-1.02-1.45-1.23-3.06-0.71
                                c-0.21-1.56-0.67-2.18-1.8-2.22c-0.37-0.01-0.87,0.09-1.08,0.34c-0.45,0.53-0.74,1.18-1.12,1.82c-1.36-0.44-2.32-0.28-2.67,0.59
                                c-0.18,0.43-0.32,1.01-0.18,1.41c0.19,0.54,0.68,0.96,1.04,1.45c-1.11,1-1.39,1.74-0.92,2.6C28.87,44.36,29.39,44.49,31.18,44.06z" />
                                    </g>
                                </svg>
                            </span>
                            <span class="ml-4">Change Password</span>
                        </a>
                    </li>
                    <li >
                        <!-- *ngIf="!user?.parentUserId" -->
                        <a routerLink="/dealer/sms-email" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 currentColor">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path d="M13.51,73.25c-2.23,0-4.33-0.04-6.44,0.01c-1.53,0.04-2.63-0.6-3.26-1.93c-0.62-1.31-0.34-2.6,0.51-3.73
                            c2.98-3.97,5.98-7.91,8.95-11.88c0.18-0.24,0.21-0.63,0.22-0.96c0.02-1.76,0-3.53,0.02-5.29c0.04-3.03,2.85-6.09,6.15-6.1
                            c14.52-0.04,29.05-0.06,43.57,0.01c3.93,0.02,6.66,2.74,6.7,6.72c0.08,8.78,0.06,17.56,0.01,26.35c-0.02,3.89-2.92,6.73-6.72,6.74
                            c-14.52,0.02-29.05,0-43.57,0c-3.09,0-6.13-3.06-6.14-6.11C13.51,75.85,13.51,74.61,13.51,73.25z M41.67,66.6
                            c1.69,0.07,3.45-1.56,3.4-3.29c-0.05-1.89-1.6-3.42-3.57-3.32c-1.9,0.1-3.02,1.64-3.07,3.28C38.37,65.03,39.93,66.59,41.67,66.6z
                            M48.38,63.26c-0.01,1.78,1.5,3.31,3.24,3.35c1.69,0.05,3.46-1.56,3.42-3.29c-0.04-1.9-1.57-3.42-3.56-3.33
                            C49.61,60.08,48.51,61.54,48.38,63.26z M35.1,63.25c-0.01-1.85-1.5-3.3-3.5-3.26c-1.89,0.03-3.1,1.62-3.13,3.32
                            c-0.03,1.71,1.59,3.29,3.31,3.3C33.45,66.62,35.24,64.99,35.1,63.25z" />
                                        <path d="M86.57,26.76c2.14,0,4.18,0,6.21,0c2.01,0,3.21,0.86,3.66,2.76c0.3,1.25-0.36,2.3-1.07,3.24
                            c-2.76,3.71-5.57,7.38-8.34,11.09c-0.26,0.35-0.43,0.86-0.45,1.3c-0.05,1.55,0,3.11-0.02,4.67c-0.06,3.94-2.93,6.84-6.85,6.83
                            c-1,0-2,0-3.1,0c0-0.59,0-1.13,0-1.67c0-3.63,0.04-7.26-0.02-10.89c-0.03-2.15-0.49-4.2-2.32-5.62c-0.75-0.58-1.63-1-2.57-1.57
                            c-0.39-2.3-1.46-3.5-3.46-3.46c-1.61,0.03-2.9,0.84-3.37,3.29c-0.82,0-1.65-0.04-2.47,0.01c-0.6,0.04-0.84-0.22-0.95-0.76
                            c-0.35-1.84-2-2.59-3.38-2.54c-1.72,0.07-2.99,1.46-3.08,3.23c-1.15,0-2.3,0-3.46,0c0.06-2.1-1.64-3.14-3.3-3.25
                            c-1.24-0.08-3.1,1.08-3.25,3.27c-4.9,0-9.81,0-14.8,0c-0.02-0.24-0.06-0.5-0.06-0.76c0-4.25,0.05-8.51-0.02-12.76
                            c-0.05-3.16,2.98-6.39,6.45-6.38c14.42,0.03,28.85-0.03,43.27,0.04c3.96,0.02,6.57,2.74,6.75,6.7
                            C86.61,24.56,86.57,25.6,86.57,26.76z" />
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                            </span>
                            <span class="ml-4">SMS and Email</span>
                        </a>
                    </li>
                    <li *ngIf="user?.id == user?.parentUserId">
                        <a routerLink="/dealer/user-management" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6 currentColor">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                    style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="currentColor">
                                    <g>
                                        <path
                                            d="M4.44,87.36c0.53-1.48,0.95-3,1.62-4.41c0.91-1.92,2.31-3.48,4.13-4.63c6.13,6.11,15.43,6.66,22.22-0.04
                                    c2.49,1.85,4.38,4.15,5.23,7.18c0.31,1.11,0.5,2.27,0.59,3.41c0.1,1.3,0.04,2.61,0.02,3.91c-0.02,1.58-1.24,2.77-2.83,2.77
                                    c-9.16,0-18.32-0.03-27.48,0.03c-1.59,0.01-2.76-0.39-3.5-1.81C4.44,91.63,4.44,89.49,4.44,87.36z" />
                                        <path d="M51.43,4.42c1.31,0.54,2.73,0.9,3.9,1.65c2.44,1.55,3.84,3.81,4.31,6.73c0.83,5.21-2.78,10.03-7.74,11.01
                                    c-3,0.59-5.69-0.05-8.11-1.99c-1.96-1.57-3.16-3.62-3.51-6c-0.42-2.81,0.18-5.48,2.08-7.77c1.54-1.85,3.43-3.02,5.78-3.48
                                    c0.03-0.01,0.05-0.09,0.08-0.14C49.29,4.42,50.36,4.42,51.43,4.42z" />
                                        <path
                                            d="M61.01,24.51c2.58,1.87,4.42,4.21,5.3,7.22c0.3,1.05,0.49,2.15,0.57,3.24c0.1,1.36,0.05,2.73,0.02,4.09
                                    c-0.03,1.54-1.25,2.73-2.77,2.73c-3.79,0.01-7.58,0-11.46,0c0,1.43,0,2.82,0,4.2c0,2.22,0.01,4.45-0.01,6.67
                                    c0,0.47,0.12,0.68,0.58,0.89c2.68,1.26,4.3,3.32,4.6,6.33c0.05,0.47,0.29,0.72,0.83,0.88c3.45,1.01,6.87,2.08,10.29,3.17
                                    c0.49,0.16,0.66,0.05,0.87-0.37c1.55-3.01,4.05-4.84,7.34-5.34c2.5-0.38,4.95,0.2,7.05,1.69c2.06,1.46,3.4,3.46,3.97,5.95
                                    c0.81,3.51-0.66,7.6-3.57,9.78c-2.22,1.66-4.67,2.32-7.35,2.07c-1.68-0.16-3.2-0.89-4.56-1.91c-1.82-1.37-2.99-3.2-3.61-5.38
                                    c-0.07-0.25-0.08-0.52-0.12-0.83c-4.21-1.31-8.44-2.63-12.58-3.92c-1.51,1.79-3.35,2.9-5.64,3.15c-2.78,0.3-5.06-0.69-6.9-2.79
                                    c-0.15-0.17-0.55-0.28-0.78-0.21c-3.82,1.16-7.63,2.37-11.45,3.54c-0.51,0.16-0.77,0.39-0.88,0.91c-0.43,2.21-1.64,3.94-3.38,5.33
                                    c-2.15,1.71-4.63,2.38-7.32,2.11c-1.73-0.17-3.3-0.88-4.71-1.94c-2.07-1.57-3.34-3.7-3.74-6.18c-0.45-2.76,0.17-5.39,1.94-7.66
                                    c1.37-1.75,3.11-2.94,5.25-3.46c2.97-0.72,5.73-0.18,8.25,1.59c1.45,1.01,2.45,2.38,3.29,4.05c1.66-0.52,3.34-1.06,5.03-1.58
                                    c2.03-0.63,4.06-1.23,6.08-1.88c0.21-0.07,0.49-0.34,0.52-0.54c0.33-3.12,1.98-5.26,4.75-6.63c0.27-0.13,0.57-0.53,0.58-0.81
                                    c0.04-3.59,0.03-7.17,0.03-10.83c-0.28-0.02-0.53-0.04-0.79-0.04c-3.62,0-7.24,0-10.85,0c-1.49,0-2.59-1.09-2.59-2.56
                                    c-0.01-1.75-0.14-3.51,0.03-5.25c0.24-2.42,1.16-4.65,2.7-6.55c0.87-1.08,1.92-2,2.9-3C45.52,31.16,54.85,30.75,61.01,24.51z" />
                                        <path d="M89.69,78.26c2.05,1.56,3.7,3.36,4.72,5.69c0.72,1.65,1.09,3.37,1.13,5.14c0.04,1.52,0.02,3.07-0.2,4.57
                                    c-0.16,1.14-1.33,1.88-2.48,1.89c-9.55,0-19.09,0-28.64,0c-1.31,0-2.46-1.12-2.47-2.41c-0.02-1.81-0.15-3.63,0.03-5.42
                                    c0.23-2.39,1.16-4.59,2.66-6.48c0.76-0.95,1.71-1.75,2.58-2.61c0.27-0.27,0.52-0.33,0.86,0c2.2,2.15,4.81,3.59,7.81,4.23
                                    c2.35,0.5,4.7,0.33,7-0.36C84.55,81.93,86.72,81.15,89.69,78.26z" />
                                </g>
                            </svg>
                            </span>
                            <span class="ml-4">User Management</span>
                        </a>
                    </li>
                    <li *ngIf="user?.parentUserId != user?.id">
                        <a routerLink="/dealer/profile" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-6 h-6 currentColor">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1.25rem" width="1.25rem"  viewBox="0 0 448 512">
                                    <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" fill="currentColor" />
                                </svg>
                            </span>
                            <span class="ml-4">Profile Management</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li class="mt-0">
                <a routerLink="/faq" routerLinkActive="text_primary_color font-medium bg-gray-100"
                    class="flex items-center p-4  text-base  text-[#3A3F4D] hover:text-[#004AAD] hover:bg-[#EDEDED] group border-l-4 border-transparent	hover:border-[#004AAD] hover:font-semibold">
                    <span class="w-7 h-7 currentColor">
                        <svg version="1.1" id="faq" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                            style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="#3a3f4d"
                            fill="currentColor">
                            <g>
                                <path d="M93.9,65.1c-0.3,1.5-0.5,3-0.9,4.4c-1.1,4.1-3.2,7.7-6.1,10.8c-0.4,0.4-0.4,0.7-0.2,1.2c1.2,2.3,2.3,4.6,3.5,7
                        c-0.4,0-0.7,0-1,0c-7,0-14,0-21,0c-12.8,0-23.5-9.2-25.5-21.8c-0.1-0.5,0-0.7,0.5-1c10-4.2,16.3-11.5,18.9-22
                        c0.4-1.8,0.5-3.6,0.7-5.4c0.1-0.6,0.3-0.8,0.8-0.9c13.1-2.5,26.7,6.4,29.6,19.4c0.3,1.2,0.5,2.5,0.7,3.8
                        C93.9,62.1,93.9,63.6,93.9,65.1z M80.8,55.2c-6.9,0-13.7,0-20.5,0c0,1.7,0,3.4,0,5c6.9,0,13.6,0,20.5,0
                        C80.8,58.6,80.8,56.9,80.8,55.2z M80.8,65.5c-6.9,0-13.7,0-20.5,0c0,1.7,0,3.4,0,5c6.9,0,13.6,0,20.5,0
                        C80.8,68.9,80.8,67.2,80.8,65.5z" />
                                <path d="M9.8,62.9c1.3-2.6,2.6-5.1,3.7-7.5c-1.4-1.9-2.8-3.7-4-5.7C1.3,35.6,8.4,18.1,23.4,12.9C37.8,7.9,53,16,56.9,30.7
                        c3.9,14.6-5.9,29.6-20.8,31.9c-1.2,0.2-2.3,0.3-3.5,0.3c-7.4,0-14.7,0-22.1,0C10.3,62.9,10.1,62.9,9.8,62.9z M26.8,32
                        c0-0.2,0-0.4,0-0.5c0.3-2.4,2.2-4.3,4.5-4.6c2.5-0.2,4.8,1.2,5.5,3.5c0.8,2.4-0.2,4.8-2.5,6.1c-2.1,1.2-3.6,2.8-4.4,5
                        c-0.3,1-0.5,2.1-0.7,3.2c1.9,0,3.6,0,5.2,0c0.1-1.8,1-3,2.5-3.9c3.6-2.1,5.4-5.3,5.2-9.4C42,24,34,19.5,27.3,22.9
                        c-3.4,1.7-5.7,5.6-5.5,9.1C23.4,32,25.1,32,26.8,32z M29.5,47.5c0,1.7,0,3.4,0,5.1c1.7,0,3.4,0,5,0c0-1.7,0-3.4,0-5.1
                        C32.8,47.5,31.2,47.5,29.5,47.5z" />
                            </g>
                        </svg>
                    </span>
                    <span class="ml-4">FAQ</span>
                </a>
            </li>
        </ul>
    </div>
    <!-- <div class="py-2 overflow-y-auto bg-gray-50 rounded-lg mt-4" *ngIf="!role">
        <ul class="text-base">
                        <li>
                        <a routerLink="/dealer/saved-search"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <span class="w-5 h-5 inline-block">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"  x="0px" y="0px" style="enable-background:new 0 0 100 100;" xml:space="preserve"  fill="currentColor">
                                    <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/>
                                </svg>
                            </span>
                            <span class="ml-4">Saved Search </span>
                        </a>
                    </li>
            </ul>
            </div> -->

    <div class="p-1 overflow-y-auto bg-gray-50 rounded-lg" *ngIf="role">
        <ul class="">
            <li>
                <a routerLink="/seller/car-list-new" routerLinkActive="text_primary_color font-medium bg-gray-100"
                    class="relative flex items-center p-4 pb-6  text-base  text-[#3A3F4D] hover:text-[#004AAD] hover:bg-[#EDEDED] group border-l-4 border-transparent	hover:border-[#004AAD] hover:font-semibold" (click)="udateLastViewDate()">
                    <img alt="car image" src="assets/img/car-listing-icon.png">
                    <!-- <span class="ml-4">Vehicle Listing & Offers</span> -->
                    <span class="flex-1 ml-4 text-left whitespace-nowrap"
                    >Vehicle Listing & Offer</span>
                <span _ngcontent-wlm-c10=""
                    class="bg_primary absolute bottom-0.5 left-14 text-[10px] font-medium px-2.5 py-0.25 rounded-full text-white" *ngIf="notification>0">{{notification}}
                    New Updates</span>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m1 1 4 4 4-4" />
                </svg>
                </a>
            </li>
            <li>
                <button type="button"
                    class="w-full  flex items-center p-4  text-base  text-[#3A3F4D] hover:text-[#004AAD] hover:bg-[#EDEDED] group border-l-4 border-transparent hover:font-semibold hover:border-[#004AAD]"
                    aria-controls="dropdown-example" data-collapse-toggle="dropdown-example" (click)="toggleDropdown('dropdown-example')">
                    <img alt="car image" src="assets/img/setting-icon.png">
                    <span class="flex-1 ml-4 text-left whitespace-nowrap"
                        >Setting</span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <ul id="dropdown-example" class="p-2 hidden">
                    <li>
                        <a routerLink="/seller/seller-profile" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  font-medium text_primary_color">
                            <img alt="car image" src="assets/img/user-icon.png">
                            <span class="ml-4">Personal Details </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/seller/change-password" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <img alt="car image" src="assets/img/ch-pass-icon.png">
                            <span class="ml-4">Change Password </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/seller/sms-email" routerLinkActive="text_primary_color font-medium bg-gray-100"
                            class="flex items-center w-full p-4  transition duration-75 rounded-lg  group hover:bg-gray-100  hover:font-medium text-[#3A3F4D] hover:text-[#004AAD]">
                            <img alt="car image" src="assets/img/notification-icon.png">
                            <span class="ml-4">Notification </span>
                        </a>
                    </li>
                </ul>
            </li>
            <li class="mt-0">
                <a routerLink="/seller/account-history" routerLinkActive="text_primary_color font-medium bg-gray-100"
                    class="flex items-center p-4  text-base  text-[#3A3F4D] hover:text-[#004AAD] hover:bg-[#EDEDED] group border-l-4 border-transparent	hover:border-[#004AAD] hover:font-semibold">
                    <img alt="car image" src="assets/img/account-history-icon.png">
                    <span class="ml-4">Account History</span>
                </a>
            </li>
        </ul>
    </div>
</aside>
