import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-account-history',
  templateUrl: './account-history.component.html',
  styleUrls: ['./account-history.component.scss']
})
export class AccountHistoryComponent {
public logs:any=[];
public user:any;
public total_items = 0;
public item_per_page = 10;
public current_page = 1;
public isDealer:boolean = false;
public carId:number = 0;

constructor( private service: CommonService,private router:Router,private activateRoute:ActivatedRoute) {

  this.activateRoute.params.subscribe((res:any)=>{
    this.carId = res.id
  })

  let currentPath =  this.router.url.split('/')[1]
  if(currentPath == 'dealer'){
    this.isDealer = true;
  }
  if(this.service.isBrowserPlateformId()){

    this.user = JSON.parse(`${localStorage.getItem('user')}`)
  }
  if(this.user){
    this.getLogs();
  }
}

//get all logs
getLogs(){
  this.service.showSpinner();
  let data={
    userId:this.user.id,
    carId : this.carId,
    isDealer : this.isDealer,
    dealerId: this.user.id,
    parentUserId: this.user.parentUserId ? this.user.parentUserId : 0
  }
  this.service.postApi('logs/getLogs',data).subscribe((res:any)=>{
    this.logs = res.logs;
    this.total_items = res.totalItems;
    this.service.hideSpinner();

  },(error)=>{
    this.service.hideSpinner()
  })
}

  // manage pagination
  pageChanged(event:any) {
    this.current_page = event;
    this.getLogs();
  }

}
