import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import * as moment from 'moment';
@Component({
  selector: 'app-schedule-pick-drop',
  templateUrl: './schedule-pick-drop.component.html',
  styleUrls: ['./schedule-pick-drop.component.scss']
})
export class SchedulePickDropComponent implements OnInit {

  // public  today = new Date();
  public today = new Date().toISOString().slice(0, 10);

  public calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    selectable: true,
    selectAllow: function (select) {
      const today = moment().startOf('day');
      const selectedStartDate = moment(select.start).startOf('day');
      return today.diff(selectedStartDate, 'days') <= 0;
    },
    plugins: [dayGridPlugin, interactionPlugin],
    dateClick: this.handleDateClick.bind(this),
    dayHeaderFormat: { weekday: 'short' },
    // validRange: {
    //   start: this.today, // Set the start date to today it hide  the past date 
    // },
  };
  public user: any;
  public showModal: string = 'none'
  public selectedCar: any;
  public showLocationModal: string = 'none'
  public selectedOption: string = '0';
  public showDropOffModal: string = 'none';
  public modalId = 'schedule-way'
  public showCalendar: boolean = true
  public increaseZ: boolean = false;
  public scheduleDate: any = new Date();
  public scheduleTime: any;
  public currentDate = new Date();
  public selectedTimeSlot: number | null = null;
  public locationAddress: string = '';
  timeSlots: any = [];


  constructor(private service: CommonService) {
   
    this.user  = this.service.isBrowserPlateformId()? JSON.parse(`${localStorage.getItem('user')}`) : null
    this.generateTimeSlots();
  }

  ngOnInit(): void {
    this.service.pickDrop.subscribe((res: any) => {
      this.selectedCar = res;
      if (res && res.userId) {
        this.clickOutSideModal();
        this.showModal = 'flex'
      }
    })

  }

  generateTimeSlots(): void {
    this.timeSlots = []
    const now = this.currentDate;
    const startHour = 7;
    const endHour = 21;
    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 30) {
        const time: any = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minutes);
        if (time >= now) {
          this.timeSlots.push(time);
        }
      }
    }
  }


  handleDateClick(date: any) {
    if (moment(date.date).startOf('day').isSameOrAfter(moment().startOf('day'))) {
      this.scheduleDate = date.dateStr
      this.currentDate = date.date;
      if (moment(date.date).startOf('day').isSame(moment().startOf('day'))) {
        this.currentDate = new Date();
      }
      this.generateTimeSlots();
    }
  }

  clickOutSideModal() {
    if(this.service.isBrowserPlateformId()){

      window.addEventListener('click', (event) => {
        if (event.target === document.getElementById(this.modalId)) {
          this.showModal = 'none'
          this.showLocationModal = 'none'
          this.showDropOffModal = 'none'
          this.showCalendar = false
        }
      });
    }
  }

  showpickDropModal() {
    this.showModal = 'none'
    this.modalId = 'calendar-modal';
    this.showCalendar = true
    this.increaseZ = true;
    this.clickOutSideModal();
  }

  showLocation() {
    if(!this.scheduleTime){
      this.service.tempErrormessage('Please select time')
      return
    }
    this.showCalendar = false
    this.modalId = 'autolocation-pickdrop'
    this.clickOutSideModal();
    if (this.selectedOption == '0') {
      this.showLocationModal = 'flex';
      this.locationAddress = `${this.selectedCar.city}, ${this.selectedCar.state}, United State, ${this.selectedCar.zipCode}`

    }
    else {
      this.locationAddress = `${this.user.city}, ${this.user.state}, United State, ${this.user.zipCode}`
      this.showDropOffModal = 'flex';
    }
  }

  selectTimeSlot(index: number, item: string) {
    this.selectedTimeSlot = index;
    this.scheduleTime = item;
  }
  hideModal() {
    this.showModal = 'none'
    this.showDropOffModal = 'none';
    this.showLocationModal = 'none'
    this.showCalendar = false;
  }

  onSubmit() {
    if(!this.scheduleTime){
      this.service.showError('Please select time')
      return
    }
    
    const datePart = new Date(this.scheduleDate);
    const timePart = this.scheduleTime; 
    console.log("this.scheduleDate", this.scheduleDate, "this.scheduleTime;", this.scheduleTime.toString().split(' ')[4], "datePart: ")


    const year = datePart.getFullYear();
    const month = datePart.getMonth();
    const day = datePart.getDate();

    // Set the time from timePart to datePart
    const dateObject = new Date(year, month, day);
    dateObject.setHours(timePart.getHours(), timePart.getMinutes(), timePart.getSeconds());
    let data = {
      carId: this.selectedCar.id,
      dealerId: this.user.id,
      pickup: this.selectedOption == '0' ? 1 : 0,
      dropOff: this.selectedOption == '0' ? 0 : 1,
      scheduleDate: `${moment(this.scheduleDate).format('YYYY-MM-DD').toString().concat(` ${this.scheduleTime.toString().split(' ')[4]}`)}`,
      carName: this.selectedCar.carFullName ? this.selectedCar.carFullName : `${this.selectedCar.carName} ${this.selectedCar.modelName} ${this.selectedCar.modelYear}`,
      location: this.locationAddress,
      dealerName: this.user.username,
      isAdminLoggedIn:this.user.isAdminLoggedIn
    }
    this.service.showSpinner();
    this.service.postApi('cars/schedule-car', data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.CarData.next(this.selectedCar.id)
        this.showModal = 'none'
        this.showLocationModal = 'none'
        this.showDropOffModal = 'none'
        this.showCalendar = false;

        this.service.hideSpinner();
        this.service.showSuccess(res.message);
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message)

      }
    })

  }
}
