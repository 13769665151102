import { AfterViewInit, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-profile-management',
  templateUrl: './profile-management.component.html',
  styleUrls: ['./profile-management.component.scss']
})
export class ProfileManagementComponent implements AfterViewInit{
  public userForm!: FormGroup;
  public user: any = '';
  public imagePreview: any;
  public isAdminLoggedIn: number=0;
  constructor(private service: CommonService, private fb: FormBuilder) {
    if(this.service.isBrowserPlateformId()){
      this.service.scrollToTop()
      this.user = JSON.parse(`${localStorage.getItem('user')}`);
    }
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.isAdminLoggedIn = this.user ? this.user.isAdminLoggedIn : 0
    this.inilizeForm();
    this.getUserById();
  }
  inilizeForm() {
    this.userForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]+$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]+$/)]],
      empId: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      userImage: [''],
      role: [0]
    }
      , {
        updateOn: 'blur'
      }
    )
  }

  ngAfterViewInit() {
    // Code to be executed after the view has been initialized
    // this.triggerFileInput();
  }

  triggerFileInput() {
    if(this.service.isBrowserPlateformId()){
    const fileInput = document.getElementById('dropzone-file');
    if (fileInput) {
      fileInput.click();
    }
  }
  }
  getUserById() {
    this.service.showSpinner();
    this.service.getApi(`users/get-user/${this.user.id}`).subscribe((res: any) => {
      if(res.status ==200){
        this.user = res.user;
        this.userForm.patchValue(res.user);
        this.imagePreview = res.user.userImage;
        this.service.hideSpinner()
      }
      else{
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }

  // upload user profile pic 
  handleImageUpload(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = inputElement.files;

    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.userForm.get('userImage')?.setValue(fileList[0]);
        this.imagePreview = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  submitForm() {
    
    const formData = new FormData()
    const formGroup: FormGroup = this.userForm as FormGroup;
    for (const field in formGroup.controls) {
      if (Object.prototype.hasOwnProperty.call(formGroup.controls, field)) {
        const control = formGroup.get(field);
        if (control && control.value !== undefined) {
          formData.append(field, control.value);
        }
      }
    }
    this.service.showSpinner();
    this.service.postApi(`users/update-user/${this.user.id}`, formData).subscribe((res: any) => {
      this.service.user.next(res.userToUpdate)
      let localUserData = {
        id: res.userToUpdate.id,
        parentUserId:res.userToUpdate.parentUserId,
        username: res.userToUpdate.username,
        email:res.userToUpdate.email,
        phone:res.userToUpdate.phone,
        zipCode: res.userToUpdate.zipCode,
        city: res.userToUpdate.city,
        state: res.userToUpdate.state,
        country: res.userToUpdate.country,
        role: res.userToUpdate.role,
        isAdminLoggedIn : this.isAdminLoggedIn,
      }
      if(this.service.isBrowserPlateformId()){
      
        localStorage.setItem('user',`${JSON.stringify(localUserData)}`)
      }
      this.service.showSuccess(res.message);
      this.getUserById();
      this.service.hideSpinner();
    },(error)=>{
      this.service.hideSpinner();
    })
}


}
