<div id="agency-popup-deal" tabindex="-1" aria-hidden="true" tabindex="-1" aria-hidden="true" class="justify-center items-center	hidden  fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75" [ngStyle]="{display: displayStyle}">
    <div class="relative w-full max-w-[36rem] max-h-full">
        <!-- Modal content -->
        <div  class="relative bg-white rounded-lg shadow">   
            <div class="flex items-start justify-center p-4 rounded-t-lg bg_primary relative">
                <!-- <button type="button" class="absolute -right-4 -top-4  text-black bg-white rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="agency-popup-deal" (click)="hideModal()">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button> -->
                <h3 class="text-2xl font-medium text-white text-center secondary-font ">
                    {{userData?.role == 1 ?  'Customer' : 'Dealer' }} Info
                </h3>
            </div>  
            <button type="button" class="absolute top-3 end-2.5 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" (click)="hideModal()">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>      
            <!-- Modal body -->
            <div class="flex flex-wrap">
                <div class="w-full">
                    <div class="dealer-details-box px-6 py-8">
                        <div class="flex flex-wrap items-start " >
                           <div class="dealer-info-data-box flex-1  md:px-6">
                                <h3 class="xl:text-4xl lg:text-3xl text-2xl font-bold">{{userData?.firstName}} {{userData?.lastName}}</h3>
                                <div class="contact-info-dealer my-6 ">
                                    <ul class="space-y-3 grey_text_500 font-semibold">
                                        <li class="flex lg:text-lg text-base"><img src="assets/img/call-icon.svg" alt="call" class="w-5 h-5 mr-4"> <span>{{userData?.phone}}</span></li>
                                        <li class="flex"><img src="assets/img/location-icon.svg" alt="call" class="w-5 h-5 mr-4">{{userAddress}}</li>
                                        <li class="flex"><img src="assets/img/email-icon.svg" alt="call" class="w-5 h-5 mr-4"><span><a href="mailto:demoemail@.com" class="grey_text_500 hover:text_primary_color break-words break-all	">{{userData?.email}}</a></span></li>
                                    </ul>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


