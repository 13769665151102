import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-dealer-preference',
  templateUrl: './dealer-preference.component.html',
  styleUrls: ['./dealer-preference.component.scss']
})
export class DealerPreferenceComponent implements OnDestroy {
  public dealerPreferenceForm!: FormGroup;
  public allMakes: any;
  public modelList: any = [];
  public tempUserId: any;
  public userId: number = 0;
  public userPreference: any;
  public rangeValue = 0;
  public isSubmit:boolean = false;
  public priceRange = [
    { id: 1, price: '1-10000' },
    { id: 2, price: '10001-20000' },
    { id: 3, price: '20001-30000' },
    { id: 4, price: '30001-40000' },
    { id: 5, price: '40001-50000' },
    { id: 6, price: 'Above 50000' }
  ]
  public mileage = [
    { id: 1, mileage: '1-20000', },
    { id: 2, mileage: '20001-40000' },
    { id: 3, mileage: '40001-60000' },
    { id: 4, mileage: '60001-80000' },
    { id: 5, mileage: '80001-100000' },
    { id: 6, mileage: 'Above 100001' }
  ]
  public isLoading: boolean = true
  public modelYear: any[] = []
  public isSubDealer: any;
  public user:any;
  constructor(private formBuilder: FormBuilder, private service: CommonService, private router: Router, private activateRaoute: ActivatedRoute) {
    
    if(this.service.isBrowserPlateformId()){
      this.service.scrollToTop()
      this.tempUserId = localStorage.getItem('tempUserId');
      this.isSubDealer = sessionStorage.getItem('userPreferred');
      const localUser = localStorage.getItem('user')
      if(localUser){
        this.user = JSON.parse(localUser)
      }
    }

   
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1960; i--) {
      this.modelYear.push({ id: i, year: i });
    }
    this.activateRaoute.params.subscribe((res: any) => {
      this.userId = res.id;
      if (this.userId) {
        this.getPreference();
      }
    })
    this.initializeForm();
    this.getCarMake();
  }

  ngOnDestroy(): void {
    // sessionStorage.removeItem('userPreferred');
  }
  initializeForm() {
    this.dealerPreferenceForm = this.formBuilder.group({
      carLocation: [,Validators.required], // Car Pick At Customer's Location
      sameDayPayment: [], // Same Day Payment
      financeSettlement: [,Validators.required], // Finance Settlement
      leaseSettlement: [,Validators.required], // Lease Settlement
      carMake: [''], // Car Make
      carModel: [''], // Car Model
      mileRange: [''], // Mile Range
      carAge: [[]] // Car Age
    });
  }

  getPreference() {
    this.service.showSpinner();
    this.service.getApi(`dealer-preferences/user-prefrence/${this.userId}`).subscribe((res: any) => {
      if (res.status == 200) {
        this.userPreference = res.dealerPreferences[0];
        this.dealerPreferenceForm.patchValue({
          carLocation: this.userPreference.carLocation,
          sameDayPayment: this.userPreference.sameDayPayment ? (this.userPreference.sameDayPayment).split(',') : [],
          financeSettlement: this.userPreference.financeSettlement,
          leaseSettlement: this.userPreference.leaseSettlement,
          carMake: this.userPreference.carMake ? (this.userPreference.carMake).split(',') : [],
          carModel: this.userPreference.carModel ? (this.userPreference.carModel).split(',') : [],
          mileRange: this.userPreference.mileRange ? (this.userPreference.mileRange).split(',') : [],
          carAge: this.userPreference.carAge ? (this.userPreference.carAge).split(',') : []
        })
        this.rangeValue = this.userPreference.pinCodeRadius
        this.getModel()
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      console.log(error);
      this.service.hideSpinner();
    })
  }

  // Getter function to simplify form control access
  get f() {
    return this.dealerPreferenceForm.controls;
  }

  submitForm() {
    if (this.dealerPreferenceForm.invalid ) {   
      console.log(this.dealerPreferenceForm.controls);
         
      this.isSubmit = true;
      return;
    }
    this.service.showSpinner();
    let data = {
      carAge: this.dealerPreferenceForm.value.carAge ? (this.dealerPreferenceForm.value.carAge).join() : '',
      carLocation: this.dealerPreferenceForm.value.carLocation ? this.dealerPreferenceForm.value.carLocation : '',
      carMake: this.dealerPreferenceForm.value.carMake ?  (this.dealerPreferenceForm.value.carMake).join() : '',
      carModel: this.dealerPreferenceForm.value.carModel ? (this.dealerPreferenceForm.value.carModel).join(): '',
      financeSettlement: this.dealerPreferenceForm.value.financeSettlement,
      leaseSettlement: this.dealerPreferenceForm.value.leaseSettlement,
      mileRange: this.dealerPreferenceForm.value.mileRange ?  (this.dealerPreferenceForm.value.mileRange).join() : '',
      pinCodeRadius: this.rangeValue,
      sameDayPayment: this.dealerPreferenceForm.value.sameDayPayment ?  (this.dealerPreferenceForm.value.sameDayPayment).join(): '',
      userId: this.tempUserId ? this.tempUserId : this.userId
    }
    console.log(data);
    // return
    this.service.postApi('dealer-preferences/create-prefrence', data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();

        if(this.service.isBrowserPlateformId()){
      
          localStorage.removeItem('tempUserId');
          sessionStorage.removeItem('userPreferred');
        }
        this.service.showSuccess(res.message);
        this.getPreference();
        // if (!this.userId) {
        //   this.router.navigate([`/verification/${res.dealerPreference.userId}`]);
        // }
        if (!this.userId) {
          this.router.navigate([`/verification/${res.dealerPreference.userId}`]);
        }
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  getCarMake() {
    this.allMakes = [];
    this.service.showSpinner();
    this.service.getAllMakeApi().subscribe(
      (res: any) => {
        if (res && res.data) {
          this.allMakes = res.data;
          this.service.hideSpinner();
          this.isLoading = false;
        }
      },
      (error) => {
        console.log(error);
        this.service.hideSpinner();
      }
    );
  }
 
  clearMake() {
    this.modelList = [];
    this.dealerPreferenceForm.get('carModel')?.setValue('')
    // this.dealerPreferenceForm.get('carMake')?.updateValueAndValidity
    
  }

  // getModel() {
  //   let event = this.dealerPreferenceForm.value.carMake
  //   const tempModelList: any[] = [];
  //   if (Array.isArray(event) && event?.length > 0) {
  //     this.service.showSpinner();
  //     event.forEach((element: any) => {
  //       this.service.getModelByMakeName(`${element}`).subscribe((res: any) => {
  //         if (res && res.Results) {
  //           res.Results.forEach((element: any) => {
  //             tempModelList.push(element)
  //           })
  //           tempModelList.sort((a: any, b: any) => {
  //             const modelA = (a.Model_Name || '').toLowerCase();
  //             const modelB = (b.Model_Name || '').toLowerCase();
  //             return modelA.localeCompare(modelB);
  //           });
  //           this.modelList = [...tempModelList];
  //           this.service.hideSpinner();
  //         }
  //       }, (error) => {
  //         this.modelList = []
  //         this.service.hideSpinner();
  //       })
  //     });
  //   }
  // }

  getModel() {
    let event = this.dealerPreferenceForm.value.carMake
    console.log(event);
    
    const tempModelList: any[] = [];
    if (Array.isArray(event) && event?.length > 0) {
      this.service.showSpinner();
      event.forEach((makeName: any) => {
        this.service.getModelByMakeName(`${makeName}`).subscribe((res: any) => {
          if (res && res.data) {
            res.data.forEach((element: any) => {
              element.make_name = makeName
              tempModelList.push(element)
            })
            this.modelList = [...tempModelList];
            this.service.hideSpinner();
          }
        }, (error) => {
          this.modelList = []
          this.service.hideSpinner();
        })
      });
    }
  }
}
