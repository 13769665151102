import { Component, Inject, Input, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { WalletService } from '../kargone-wallet/wallet.service';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { isPlatformBrowser } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-invoice-account-statement',
  templateUrl: './invoice-account-statement.component.html',
  styleUrls: ['./invoice-account-statement.component.scss']
})

export class InvoiceAccountStatementComponent implements OnInit {
  public creditLogs: any;
  public userId: any;
  public total_items = 0;
  public item_per_page = 15;
  public current_page = 1;
  public wallet: any;
  public range!: FormGroup;

  public accountStatement: boolean = false;
  public isBillingInvoice: boolean = false;
  public isCreditLogs: boolean = false;
  public startDate: any;
  public endDate: any;
  public user: any;
  constructor(private http: HttpClient, private service: CommonService, private activateRoute: ActivatedRoute, private router: Router, public walletService: WalletService, @Inject(PLATFORM_ID) private platformId: any, private renderer: Renderer2) {
    this.getCalendar();
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.service.scrollToTop()
    this.user = JSON.parse(`${localStorage.getItem('user')}`);

    this.range = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
    let currentPath = this.router.url.split('/')[2]

    if (currentPath == 'account-statement-invoice') {
      this.accountStatement = true;
    }
    if (currentPath == 'billing-invoice') {
      this.isBillingInvoice = true;
    }
    if (currentPath == 'credit-reciept') {
      this.isCreditLogs = true;
    }
    this.activateRoute.params.subscribe((res: any) => {
      this.userId = res.id;
      let data = {
        userId: this.userId,
        creditLogs: this.isCreditLogs,
        billingInvoice: this.isBillingInvoice,
        accountStatement: this.accountStatement
      }
      this.getAllCreditLogs(data);
      this.getWallet();
    })
  }

  ngOnInit(): void {
    if(this.service.isBrowserPlateformId()){
   
      // Dynamically load CSS
      const link = this.renderer.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css';
      this.renderer.appendChild(document.head, link);
  
      // Dynamically load JS
      const script = this.renderer.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js';
      script.onload = () => {
        console.log('Swiper script loaded');
      };
      this.renderer.appendChild(document.body, script);
    }
  }

  getWallet() {
    let data = {
      userId: this.userId,
    }
    this.service.showSpinner()
    this.walletService.postApi(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.wallet = res.wallet[0];
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }

  getAllCreditLogs(data: any) {
    this.creditLogs = []
    this.service.postApi(`credit-logs/getlogs`, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.creditLogs = res.creditLogs;
        this.total_items = res.totalItems;
        this.getCalendar();
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }
  getCalendar() {
    $(() => {
      $('input[name="daterange"]').daterangepicker({
        opens: 'center',
        // autoUpdateInput: false,
        alwaysShowCalendars:true,
        showCustomRangeLabel:false,
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
          // 'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]

       },
        locale: {
          format: 'DD MMM YYYY'
        }
      }, (start: any, end: any, label: any) => {
        this.startDate = start.format('YYYY-MM-DD');
        this.endDate = end.format('YYYY-MM-DD');
        let data = {
          startDate: start.format('YYYY-MM-DD'),
          endDate: end.format('YYYY-MM-DD'),
          userId: this.userId,
          parentUserId: this.user.parentUserId ? this.user.parentUserId : 0,
          creditLogs: this.isCreditLogs,
          billingInvoice: this.isBillingInvoice,
          accountStatement: this.accountStatement
        }
        this.getAllCreditLogs(data)
      });

      $('input[name="daterange"]').on('apply.daterangepicker', (ev: any, picker:any) => {

        let data = {
          startDate: picker.startDate.format('YYYY-MM-DD'),
          endDate: picker.endDate.format('YYYY-MM-DD'),
          userId: this.userId,
          parentUserId: this.user.parentUserId ? this.user.parentUserId : 0,
          creditLogs: this.isCreditLogs,
          billingInvoice: this.isBillingInvoice,
          accountStatement: this.accountStatement
        }
        this.service.showSpinner();
        this.getAllCreditLogs(data)
        $('input[name="daterange"]').val(picker.startDate.format('DD-MMM-YYYY') + ' - ' + picker.endDate.format('DD-MMM-YYYY'));
    });
  
      $('input[name="daterange"]').on('cancel.daterangepicker', function(ev:any, picker:any) {
          $('input[name="daterange"]').val('');
      });
    });

  }

  resetCarModelData(){
    let data = {
      userId: this.userId,
      parentUserId: this.user.parentUserId ? this.user.parentUserId : 0,
      creditLogs: this.isCreditLogs,
      billingInvoice: this.isBillingInvoice,
      accountStatement: this.accountStatement
    }
    this.getAllCreditLogs(data)
    const $input = $('input[name="daterange"]');
    $input.val('')
    const daterangepickerInstance = $input.data('daterangepicker');
    if (daterangepickerInstance) {
      daterangepickerInstance.setStartDate(moment());
      daterangepickerInstance.setEndDate(moment());
    }
  }

  //generate Pdf 
  // generatePdf() {
  //   this.service.showSpinner();

  //   this.service.postApi('invoices/generate-invoice-pdf', { userId: this.userId }).subscribe(
  //     (res: any) => {
  //       console.log('=======================> asdasd', res);

  //       // Save the received PDF as a file
  //       const blob = new Blob([res.data],{ type: 'application/pdf' } );
  //       saveAs(blob, 'invoice.pdf');

  //       this.service.hideSpinner();
  //     },
  //     (error) => {
  //       console.error(error);
  //       this.service.hideSpinner();
  //     }
  //   );
  // }
  async generatePdf(id: any) {
    this.service.showSpinner();
    let data = {
      userId: this.userId,
      creditId: id,
      creditReciept: this.isBillingInvoice ? false : true,
      billingInvoice: this.isBillingInvoice ? true : false,
      startDate: this.startDate,
      endDate: this.endDate
    }
    const token = this.service.isBrowserPlateformId() ? localStorage.getItem('token') : '';

    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  
    const options = {
      headers: httpHeaders,
      responseType: 'blob' as 'json'
    };
    await this.http.post(`${this.service.webUrl}api/invoices/generate-invoice-pdf`, data, options).subscribe(
      (res: any) => {
        /*=============== DO NOT REMOVE THIS CODE (ANOTHER WAY TO DOWNLOAD THE PDF FILE) ===============*/
        // let blob = new Blob([res], { type: 'application/pdf' });
        // let pdfUrl = window.URL.createObjectURL(blob);
        // var PDF_link = document.createElement('a');
        // PDF_link.href = pdfUrl;
        // window.open(pdfUrl, '_blank');
        // PDF_link.download = "TestFile.pdf";
        // PDF_link.click();
        /*=============== DO NOT REMOVE THIS CODE (ANOTHER WAY TO DOWNLOAD THE PDF FILE) ===============*/
        // if (this.creditLogs.length>0) {
          
          if (isPlatformBrowser(this.platformId)) {
            const blob = new Blob([res], { type: 'application/pdf' });
            saveAs(blob, 'invoice.pdf');
          }
       

        this.service.hideSpinner();
      // }
      // else {
      //   this.service.hideSpinner();
      //   this.service.showError('No invoice found');
      // }
      },
      (error) => {
        console.error(error);
        this.service.hideSpinner();
      }
    );
  }

  pageChanged(event: any) {
    this.current_page = event;
    let data = {
      userId: this.userId,
      creditLogs: this.isCreditLogs,
      billingInvoice: this.isBillingInvoice,
      accountStatement: this.accountStatement
    }
    this.getAllCreditLogs(data);
  }

}
