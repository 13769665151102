import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-search-car',
  templateUrl: './search-car.component.html',
  styleUrls: ['./search-car.component.scss']
})
export class SearchCarComponent implements OnInit {
  public vin_number = '';
  public licenseNumber = '';
  public stateData: any[] = [];
  public selectedState = '';
  public selectedValue = '1';

  constructor(private service: CommonService, private router: Router) {}

  ngOnInit(): void {
    this.getStates();
  }

  private getStates(): void {
    this.service.apiFunction('https://api.countrystatecity.in/v1/countries/US/states').subscribe(
      (res: any) => {
        this.stateData = res.sort((a: any, b: any) => a.name.localeCompare(b.name));
      },
      (error) => console.error('API Error:', error)
    );
  }

  public listYourCar(): void {
    if (this.selectedValue === '1') {
      this.searchByLicensePlate();
    } else {
      this.checkCar();
    }
  }

  private searchByLicensePlate(): void {
    if (!this.licenseNumber || !this.selectedState) {
      this.service.showError('Please enter all required fields.');
      return;
    }

    const cleanedLicenseNumber = this.licenseNumber.trim().replace(/\s/g, '');
    this.service.showSpinner();
    
    this.service.licenseApi(`https://apibroker-license-plate-search-v1.p.rapidapi.com/license-plate-search?format=json&state=${this.selectedState}&plate=${cleanedLicenseNumber}`).subscribe(
      (res: any) => {
        if (res.status !== 'error') {
          const carData = {
            carName: res.content.make,
            modelYear: res.content.year,
            modelName: res.content.model,
            fuelType: res.content.vin_data.FuelTypePrimary,
            licenseNumber: res.content.plate,
            vinNumber: res.content.vin
          };
          this.vin_number = res.content.vin;
          this.checkCar();
          if (this.service.isBrowserPlateformId()) {
            localStorage.setItem('carData', JSON.stringify(carData));
          }
          this.router.navigate(['seller/add-car']);
        } else {
          this.router.navigate(['/find-your-car']);
        }
        this.service.hideSpinner();
      },
      (error) => {
        this.router.navigate(['/find-your-car']);
        this.service.hideSpinner();
      }
    );
  }

  private searchVinNumber(): void {
    if (!this.vin_number) {
      this.service.showError('Please enter VIN number.');
      return;
    }

    this.service.showSpinner();
    this.service.vinApi(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValues/${this.vin_number}?format=json`).subscribe(
      (res: any) => {
        const carDetails = res.Results[0];
        if (carDetails.Make && carDetails.ModelYear && carDetails.Model && carDetails.FuelTypePrimary) {
          const carData = {
            carName: carDetails.Make,
            modelYear: carDetails.ModelYear,
            modelName: carDetails.Model,
            fuelType: carDetails.FuelTypePrimary,
            vinNumber: this.vin_number
          };
          if (this.service.isBrowserPlateformId()) {
            localStorage.setItem('carData', JSON.stringify(carData));
          }
          this.router.navigate(['seller/add-car']);
        } else {
          this.router.navigate(['/find-your-car']);
        }
        this.service.hideSpinner();
      },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }

  private checkCar(): void {
    const data = {
      vinNumber: this.vin_number ? this.vin_number.trim() : null
    };

    if (this.selectedValue === '1') {
      if (!this.licenseNumber || !this.selectedState) {
        this.service.showError('Please enter all required fields.');
        return;
      }
    } else {
      if (!this.vin_number) {
        this.service.showError('Please enter VIN number.');
        return;
      }
    }

    this.service.showSpinner();
    this.service.postApi('cars/check-car', data).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.count > 0) {
          this.service.showError('Car already exists.');
        } else {
          if (this.selectedValue === '1') {
            this.router.navigate(['seller/add-car']);
          } else {
            this.searchVinNumber();
          }
        }
      },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }
}
