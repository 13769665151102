import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TextInputComponent } from './text-input/text-input.component';
import { HeaderComponent } from './header/header.component';
import { PopupComponent } from './popup/popup.component';
import { RouterModule } from '@angular/router';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { SectionsModule } from '../sections/sections.module';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { DataListComponent } from './data-list/data-list.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VerificationComponent } from './verification/verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SearchCarComponent } from './search-car/search-car.component';
import { ValidateTokenComponent } from './validate-token/validate-token.component';
import { SharedModule } from '../shared.module';
import { BidComponent } from './bid/bid.component';
import { SchedulePickDropComponent } from './schedule-pick-drop/schedule-pick-drop.component';
import { AccountHistoryComponent } from './account-history/account-history.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { UserInfoComponent } from './user-info/user-info.component';
import { UpdateCarStatusComponent } from './update-car-status/update-car-status.component';
import { ComfirmModalComponent } from './comfirm-modal/comfirm-modal.component';
import { ResponsiveSidebarComponent } from './responsive-sidebar/responsive-sidebar.component';

@NgModule({
  declarations: [
    TextInputComponent,
    FooterComponent,
    NavbarComponent,
    HeaderComponent,
    PopupComponent,
    PersonalDetailsComponent,
    SubHeaderComponent,
    DataListComponent,
    DataGridComponent,
    ChangePasswordComponent,
    SidebarComponent,
    VerificationComponent,
    ResetPasswordComponent,
    SearchCarComponent,
    ValidateTokenComponent,
    BidComponent,
    SchedulePickDropComponent,
    AccountHistoryComponent,
    UserInfoComponent,
    UpdateCarStatusComponent,
    ComfirmModalComponent,
    ResponsiveSidebarComponent,
  ],
  imports: [
    SharedModule,
    RouterModule,
    SectionsModule,
    FullCalendarModule

  ],
  exports: [
    TextInputComponent,
    FooterComponent,
    NavbarComponent,
    HeaderComponent,
    SubHeaderComponent,
    ChangePasswordComponent,
    DataGridComponent,
    DataListComponent,
    SidebarComponent,
    ResetPasswordComponent,
    SearchCarComponent,
    AccountHistoryComponent,
    BidComponent,
    ComfirmModalComponent,
    ResponsiveSidebarComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class ComponentsModule { }
