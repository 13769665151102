import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { interval } from 'rxjs';
import { CommonService } from 'src/app/common.service';
declare var $: any

@Component({
  selector: 'app-car-list-new',
  templateUrl: './car-list-new.component.html',
  styleUrls: ['./car-list-new.component.scss']
})
export class CarListNewComponent implements OnInit {
  public cars: any[] = [];
  public filterForm!: FormGroup;
  public modelYear: any[] = [];
  public listView = true
  public user: any;
  public allMakes: any = []
  public filterData: any = []
  public modelList: any = []
  public isModelDisabled: boolean = true;
  public dealerPreference: boolean = false;
  public isLoading: boolean = false;
  public isFilterPage: boolean = false
  public dealerId: any;
  public wonOffers: boolean = false;
  public isCarListPage: boolean = false;
  public dealerList: any;
  public stateData: any;
  public cityData: any;

  public selectedFilters: any = {
    carName: [],
    make: [],
    model: [],
    year: [],
    distance: [],
    price: [],
    mileage: [],
    city: [],
    state: []
  };
  public priceRange = [
    { id: 1, price: '1-10000', label: '$1-$10000' },
    { id: 2, price: '10001-20000', label: '$10001-$20000' },
    { id: 3, price: '20001-30000', label: '$20001-$30000' },
    { id: 4, price: '30001-40000', label: '$30001-$40000' },
    { id: 5, price: '40001-50000', label: '$40001-$50000' },
    { id: 6, price: 'Above 50000', label: 'Above $50000' }
  ]
  public mileage = [
    { id: 1, mileage: '1-20000', },
    { id: 2, mileage: '20001-40000' },
    { id: 3, mileage: '40001-60000' },
    { id: 4, mileage: '60001-80000' },
    { id: 5, mileage: '80001-100000' },
    { id: 6, mileage: 'Above 100001' }
  ]

  public distance = [
    { id: 1, distance: '1-10' },
    { id: 2, distance: '11-20' },
    { id: 3, distance: '21-30' },
    { id: 6, distance: 'Above 30' }
  ]
  public displayStyle = 'none'
  public pickup = 0;
  public rejected = 0;
  public myOffer: boolean = false;
  public schedulePickPage: boolean = false;
  public scheduleDropPage: boolean = false;
  public carOverView: boolean = false;
  public purchasedCar: boolean = false;
  public rejectedCar: boolean = false;
  public dataSubcribed: any;
  public isFilterApplied:boolean=false;
  public isCheckboxApplied:boolean=false;

  constructor(private service: CommonService, private fb: FormBuilder, private router: Router, private renderer: Renderer2) {
    
    this.subscribeLiveData()
   
    
    this.service.CarData.subscribe(res => {
      if (res) {
        this.getAllCars();
      }
    })

    this.service.scrollToTop()
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    if(this.service.isBrowserPlateformId()){
      const localUser = localStorage.getItem('user')
      if (localUser) {
        this.user = JSON.parse(localUser);
        this.service.user.next(this.user)
      }
    }
    let currentRoute = (this.router.url).split('/')[2];

    if (currentRoute == 'dealer-preferred-list' || currentRoute == 'saved-search') {
      this.isFilterPage = true
    }
    if (currentRoute == 'dealer-preferred-list') {
      this.dealerPreference = true
      this.isCarListPage = true
    }
    if (currentRoute == 'car-list-new' && this.user && !this.user?.role) {
      this.isCarListPage = true
    }
    if (this.user) {
      this.dealerId = this.user.id;
    }
    if (currentRoute == 'offers') {
      this.myOffer = true
    }
    if (currentRoute == 'won-offers') {
      this.wonOffers = true
    }
    if (currentRoute == 'schedule-car-pickup') {
      this.schedulePickPage = true;
    } else if (currentRoute == 'schedule-car-dropoff') {
      this.scheduleDropPage = true;
    } else if (currentRoute == 'car-overview') {
      this.carOverView = true;
    } else if (currentRoute == 'car-purchased') {
      this.purchasedCar = true;
    } else if (currentRoute == 'car-rejected') {
      this.rejectedCar = true;
    }

    this.intiliazeFilterForm();
    this.getFilters();
    this.getAllMake();
    this.getAllCars();
    this.getStates();
    this.getAllSubDealer();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1960; i--) {
      this.modelYear.push({ id: i, year: i });
    }
  }
  ngOnInit(): void {

    if(this.service.isBrowserPlateformId()){
   
      // Dynamically load CSS
      const link = this.renderer.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css';
      this.renderer.appendChild(document.head, link);
  
      // Dynamically load JS
      const script = this.renderer.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js';
      script.onload = () => {
        console.log('Swiper script loaded');
        this.getCalendar();
      };
      this.renderer.appendChild(document.body, script);
    }

   
  }

  getCalendar() {
    $(() => {
      $('input[name="daterange"]').daterangepicker({
        opens: 'center',
        // singleDatePicker: true,
        alwaysShowCalendars: true,
        showCustomRangeLabel: false,
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
          'All': [moment('2000-01-01'), moment()]
        },
        locale: {
          format: 'DD MMM YYYY'
        }
      }, (start: any, end: any, label: any) => {
        console.log(start,"start",start._isValid, typeof start)

        let data = {
          startDate: start.format('YYYY-MM-DD HH:mm:ss'),
          endDate: end.format('YYYY-MM-DD HH:mm:ss'),
          dealerId: this.dealerId,
          parentUserId: this.user.parentUserId ? this.user.parentUserId : 0,
          pickup: this.schedulePickPage,
          dropOff: this.scheduleDropPage,
          purchasedCar: this.purchasedCar,
          rejectedCar: this.rejectedCar
        }
        // this.getCounts(data)
        this.searchCar(data);
     

      });
    });
  }

  resetCarModelData(){
    let data = {
      dealerId: this.dealerId,
      parentUserId: this.user.parentUserId ? this.user.parentUserId : 0,
      pickup: this.schedulePickPage,
      dropOff: this.scheduleDropPage,
      purchasedCar: this.purchasedCar,
      rejectedCar: this.rejectedCar
    }
    this.searchCar(data);
    const $input = $('input[name="daterange"]');
    const daterangepickerInstance = $input.data('daterangepicker');
    if (daterangepickerInstance) {
      daterangepickerInstance.setStartDate(moment());
      daterangepickerInstance.setEndDate(moment());
    }
  }

  intiliazeFilterForm() {
    this.filterForm = this.fb.group({
      empId: [''],
      carName: [''],
      make: [''],
      model: [''],
      year: [''],
      price: [''],
      distance: [''],
      mileage: [''],
      state: [''],
      city: [''],
      filterName: ['']
    });
  }

  get f() {
    return this.filterForm.controls
  }
  
  private subscribeLiveData(): void {    
    this.dataSubcribed = interval(60000).subscribe(() => this.getRefershData());
  }

  getRefershData(){
    if(this.isFilterApplied){
      this.filterDataObj()
    }
    else if(this.isCheckboxApplied){
      this.applySelectedFilters();
    }
    else{
      this.getAllCars()
    }
  }

  ngOnDestroy(): void {
    this.dataSubcribed.unsubscribe();
  }
  getAllCars(): void {
    // this.service.showSpinner();
    let data = {
      id: null,
      dealerPreference: this.dealerPreference,
      dealerId: this.dealerId,
      myOffer: this.myOffer,
      wonOffers: this.wonOffers,
      isCarListPage: this.isCarListPage,
      pickup: this.schedulePickPage,
      dropOff: this.scheduleDropPage,
      carOverView: this.carOverView,
      purchasedCar: this.purchasedCar,
      rejectedCar: this.rejectedCar,
      parentUserId: (this.user.parentUserId && this.user.role == 0) > 0 ? this.user.parentUserId : 0,
      userTimeZone :'Asia/Calcutta'
    };
    if (this.user.role || this.dealerPreference) {
      data.id = this.user.id
    }
   
    if(this.service.isBrowserPlateformId()){
      data.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ;
      console.log("userTimeZone", data.userTimeZone)
    } 

    this.service.postApi(`cars/get-cars`, data).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.cars = [];
          this.isLoading = true
          this.cars = res.cars;
          this.service.hideSpinner();
        }
        else {
          this.isLoading = true
          this.service.hideSpinner();
        }
      },
      error => {
        console.error('Error fetching cars:', error);
        this.service.hideSpinner();
      }
    );
  }

  filterDataObj(){
    const formValues = this.filterForm.value;
    this.isCheckboxApplied = false;
    const isAnyValueNotEmpty = Object.values(formValues).some(value => !!value);
    if (isAnyValueNotEmpty) {
      this.isFilterApplied = true;
      const filters = {
        carName: formValues.carName,
        empId: formValues.empId,
        make: formValues.make,
        modelName: formValues.model,
        modelYear: formValues.year,
        price: formValues.price,
        distance: formValues.distance,
        mileage: formValues.mileage,
        state: formValues.state,
        city: formValues.city,
        zipCode: this.user.zipCode,
        dealerId: this.dealerId,
        myOffer: this.myOffer,
        wonOffers: this.wonOffers,
        isCarListPage: this.isCarListPage,
        pickup: this.schedulePickPage,
        dropOff: this.scheduleDropPage,
        carOverView: this.carOverView,
        purchasedCar: this.purchasedCar,
        rejectedCar: this.rejectedCar,
        parentUserId: (this.user.parentUserId && this.user.role == 0) > 0 ? this.user.parentUserId : 0
      };
      this.searchCar(filters);
    }
    else {
      this.service.hideSpinner()
    }
  }
  filterCar() {
    this.service.showSpinner();
    this.cars = [];
    this.filterDataObj()
  }
  searchCar(data: any) {

    data.userTimeZone = 'Asia/Calcutta'
    if(this.service.isBrowserPlateformId()){
      data.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ;
      console.log("userTimeZone", data.userTimeZone)
    } 

    this.service.postApi('cars/filter-cars', data).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.cars = res.cars;
          this.service.hideSpinner();
        }
        else {
          this.isLoading = true
          this.service.showError(res.message);
          this.service.hideSpinner();
        }
      },
      (error) => {
        console.error('Error fetching filtered cars:', error);
        this.service.hideSpinner();
      }
    );
  }


  getAllSubDealer() {
    this.service.showSpinner();
    let data = {
      filterPage: true,
      userId: this.user.id
    }
    this.service.postApi(`users/list`, data).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.dealerList = res.users;
          this.service.hideSpinner();
        }
        else {
          this.isLoading = true
          this.service.hideSpinner();
        }
      },
      error => {
        console.error('Error fetching cars:', error);
        this.service.hideSpinner();
      }
    );
  }
  resetFilter() {
    this.isFilterApplied=false;
    this.filterForm.reset();
    this.getAllCars();
  }

  saveSearch() {
    const formValues = this.filterForm.value;
    if (!formValues.filterName) {
      this.service.showError('Please enter filter name.');
      return
    }
    this.service.showSpinner();
    const isAnyValueNotEmpty = Object.values(formValues).some(value => !!value);

    if (isAnyValueNotEmpty) {
      const filterObj = {
        carName: formValues.carName,
        make: formValues.make ? (formValues.make).join(',') : '',
        model: formValues.model ? (formValues.model).join(',') : '',
        year: formValues.year ? (formValues.year).join(',') : '',
        price: formValues.price ? (formValues.price).join(',') : '',
        distance: formValues.distance ? (formValues.distance).join(',') : '',
        mileage: formValues.mileage ? (formValues.mileage).join(',') : '',
        state: formValues.state ? (formValues.state).join(',') : '',
        city: formValues.city ? (formValues.city).join(',') : '',
        zipCode: this.user.zipCode,
        userId: this.user.id,
        filterName: formValues.filterName
      };

      this.service.postApi('filters/create-filter', filterObj).subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.getFilters();
            this.service.showSuccess(res.message);
            this.service.hideSpinner();
          }
          else {
            this.service.showError(res.message);
            this.service.hideSpinner();
          }
        },
        (error) => {
          console.error('Error fetching filtered cars:', error);
          this.service.hideSpinner();
        }
      );
    }
    else {
      this.service.hideSpinner()
    }

  }

  // update filter when remove/clear any option from dropdown filter
  removeMake() {
    this.filterCar();
    this.getModel();
  }
  clearMake() {
    this.modelList = [];
    this.filterCar();
  }
  updateFilter() {
    this.filterCar();
  }
  removeState() {
    this.filterCar();
    this.getCities();
  }
  clearState() {
    this.cityData = [];
    this.filterCar();
  }


  getModel() {
    let event = this.filterForm.value.make
    const tempModelList: any[] = [];
    if (Array.isArray(event) && event?.length > 0) {
      this.service.showSpinner();
      event.forEach((makeName: any) => {
        this.service.getModelByMakeName(`${makeName}`).subscribe((res: any) => {
          if (res && res.data) {
            res.data.forEach((element: any) => {
              element.make_name = makeName
              tempModelList.push(element)
            })
            this.modelList = [...tempModelList];
            this.service.hideSpinner();
          }
        }, (error) => {
          this.modelList = []
          this.service.hideSpinner();
        })
      });
    }
  }



  // get all car brand
  getAllMake() {
    this.allMakes = [];
    this.service.showSpinner();
    this.service.getAllMakeApi().subscribe(
      (res: any) => {
        if (res && res.data) {
          this.allMakes = [...res.data];;
          this.service.hideSpinner();
        }
      },
      (error) => {
        console.log(error);
        this.service.hideSpinner();
      }
    );
  }


  // get state list according country selected
  getStates() {
    this.service.apiFunction(`https://api.countrystatecity.in/v1/countries/us/states`).subscribe(
      (res: any) => {
        this.stateData = res;
        this.stateData = this.stateData.sort((a: any, b: any) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }

  // get city list according state selected
  getCities() {
    let event = this.filterForm.value.state
    const tempModelList: any[] = [];
    if (Array.isArray(event) && event?.length > 0) {
      this.service.showSpinner();
      event.forEach((element: any) => {
        this.service.apiFunction(`https://api.countrystatecity.in/v1/countries/us/states/${element}/cities`).subscribe(
          (res: any) => {
            // this.cityData = res
            res.forEach((element: any) => {
              tempModelList.push(element)
            })
            this.cityData = [...tempModelList];
            this.service.hideSpinner();
          },
          (error) => {
            this.service.hideSpinner();
            console.error('API Error:', error);
          }
        );
      })
    }
  }

  getFilters() {
    this.service.showSpinner();
    this.service.getApi(`filters/get-filter/${this.user.id}`).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.filterData = res.filters;
        }

        this.service.hideSpinner();
      },
      (error) => {
        console.error('Error fetching filtered cars:', error);
        this.service.hideSpinner();
      }
    );
  }

  filterByCheckbox(item: any) {
    const filterCategories = ['carName', 'empId', 'make', 'model', 'year', 'price', 'distance', 'mileage', 'state', 'city'];
    if (item.checked) {
      for (const category of filterCategories) {
        if (item[category]) {
          const valuesToAdd = item[category].split(',');
          for (const value of valuesToAdd) {
            this.selectedFilters[category].push(value);
          }
        }
      }
    } else {
      for (const category of filterCategories) {
        if (item[category]) {
          const valuesToRemove = item[category].split(',');
          for (const value of valuesToRemove) {
            const index = this.selectedFilters[category].indexOf(value);
            if (index !== -1) {
              this.selectedFilters[category].splice(index, 1);
            }
          }
        }
      }
    }    
    // Check if any selectedFilters property has values
    const isAnyValueNotEmpty = filterCategories.some(category => this.selectedFilters[category]?.length > 0);

    if (!isAnyValueNotEmpty) {
      this.isCheckboxApplied = false;
      this.getAllCars();
    } else {
      this.isFilterApplied = false;
      this.isCheckboxApplied = true;
      this.service.showSpinner();
      this.cars = [];
      this.applySelectedFilters();
    }
  }


  applySelectedFilters() {
    const filters = {
      carName: this.selectedFilters.carName,
      empId: this.selectedFilters.empId ? this.selectedFilters.empId : [],
      make: this.selectedFilters.make ? this.selectedFilters.make : [],
      modelName: this.selectedFilters.model ? this.selectedFilters.model : [],
      modelYear: this.selectedFilters.year ? this.selectedFilters.year : [],
      price: this.selectedFilters.price ? this.selectedFilters.price : [],
      distance: this.selectedFilters.distance ? this.selectedFilters.distance : [],
      mileage: this.selectedFilters.mileage ? this.selectedFilters.mileage : [],
      state: this.selectedFilters.state ? this.selectedFilters.state : [],
      city: this.selectedFilters.city ? this.selectedFilters.city : [],
      zipCode: this.user.zipCode,
      savedFilter: true,
      isCarListPage:true,
      parentUserId: (this.user.parentUserId && this.user.role == 0) > 0 ? this.user.parentUserId : 0,
      dealerId: this.dealerId,
    }
    this.searchCar(filters);
  }
  showModal() {
    if(this.service.isBrowserPlateformId()){
    this.displayStyle = 'flex'
    document.body.style.overflow = 'hidden';
    }
  }
  hideModal() {
    if(this.service.isBrowserPlateformId()){
    this.displayStyle = 'none'
    document.body.style.overflow = 'initial';
    // document.body.style.overflow = 'auto';
    }

  }
}