<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name"
            class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center">
        <div class="container mx-auto  ">
            <div class="max-w-screen-sm	mx-auto z-50  flex-1 px-6 ">
                <div class="block text-center">
                    <h1 class="md:text-5xl text-4xl text-white font-bold ">Dashboard</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4">
            <app-responsive-sidebar></app-responsive-sidebar>
            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="bg-white md:p-10 p-6 rounded-md">
                    <div class="title-heading pb-1 lg-3 leading-none text-center mb-14 lg:text-center">
                        <h2 class="lg:text-5xl md:text-4xl text-3xl  font-bold leading-none text_primary_color">Browse
                            by Brand</h2>
                        <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                        <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                        <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    </div>
                    <div class="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 md:gap-x-6 gap-x-4 md:gap-y-10 gap-y-6">

                        <div class="brand-box text-center" *ngFor="let item of brands">
                            <a class="block" routerLink="/dealer/vehicle-insights/{{item?.brandName}}">
                                <div class="img-block border border-[#E3E3E3] rounded-md">
                                    <img [src]="item?.brandLogo" alt="BMW" class="inline-block">
                                </div>
                                <div class="md:text-base text-sm text-black uppercase font-medium mt-3">
                                    {{item?.brandName}}</div>
                            </a>
                        </div>
                    </div>
                    <!-- <div class="text-center block mt-16 mb-4">
                        <a  class="px-8 py-3 rounded-full bg_primary border border-primary-color text-white md:text-lg text-base font-normal hover:bg-white hover:text-[#004AAD] cursor-pointer"> Show all brands</a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>