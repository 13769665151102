<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name" class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center" >
        <div class="container mx-auto  ">
            <div class="max-w-screen-sm	mx-auto z-50  flex-1 px-6 ">
                <div class="block text-center">
                    <h1 class="md:text-5xl text-4xl text-white font-bold ">My Account</h1>       
                </div>
            </div>
        </div>
    </div>
</div>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4">          
            <app-sidebar class="z-2 xl:w-3/12 md:w-5/12 w-full px-4 mb-10 md:mb-0 hidden md:block"></app-sidebar>
            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center">
                    <h2 class="lg:text-5xl text-4xl  font-bold leading-none text_primary_color">Notification</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div> 
                <div class="block bg-white p-8 rounded-lg">
                    <!-- dealer message -->
                    <p *ngIf="!user?.role" class="text-black">Buying Smart, Customized Alerts! 🚙📬 Stay ahead in the market without information overload. With us, you have control over the email and SMS alerts you receive as a car dealer. Personalize your notifications for efficient sourcing!</p>

                    <!-- seller message -->
                    <p *ngIf="user?.role" class="text-black">Smart Selling, Tailored Alerts! 🚙📬 Stay in the loop without the overload. With us, you decide the alerts you receive via email and SMS as you sell your car. Personalize your notifications and streamline your selling process!</p>
                    <form [formGroup]="userForm" class="mt-6">
                        <label class="relative flex items-center mb-4 cursor-pointer">
                          <input type="checkbox" formControlName="emailNotification" class="sr-only peer" (change)="updateNotification()">
                          <div
                            class="w-11 h-6 bg-[#605C5C] rounded-[0.175rem] peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-[0.175rem] after:h-5 after:w-5 after:transition-all peer-checked:bg-[#175BB5]"
                          ></div>
                          <span class="ml-4 text-lg leading-none font-medium text-[#3A3F4D] uppercase">Email</span>
                        </label>
                      
                        <label class="relative flex items-center mb-4 cursor-pointer">
                          <input type="checkbox" formControlName="smsNotification" class="sr-only peer" (change)="updateNotification()">
                          <div
                            class="w-11 h-6 bg-[#605C5C] rounded-[0.175rem] peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-[0.175rem] after:h-5 after:w-5 after:transition-all peer-checked:bg-[#175BB5]"
                          ></div>
                          <span class="ml-4 text-lg leading-none font-medium text-[#3A3F4D] uppercase">SMS</span>
                        </label>
                      </form>
                      
                </div>
            </div>           
        </div>
    </div>
</section>