<app-sub-header></app-sub-header>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4">
            <!-- <input type="checkbox"  class="openSidebarMenu" id="openSidebarMenu">
            <label for="openSidebarMenu" class="sidebarIconToggle">
                <div class="spinner diagonal part-1"></div>
                <div class="spinner horizontal"></div>
                <div class="spinner diagonal part-2"></div>
            </label>
            <div class="z-2 xl:w-3/12 min-[993px]:w-5/12 w-full px-4 mb-10 min-[992px]:mb-0 hidden lg:block" id="sidebar_menu">
            <app-sidebar></app-sidebar>
            </div> -->
            <app-responsive-sidebar></app-responsive-sidebar>

            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center">
                    <h2 class="lg:text-5xl text-4xl leading-none text_primary_color">Manage User</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>

                <div class="text-right mb-3">
                    <button
                        class="bg-[#004AAD] text-white border-slate-300 border-2 px-8 py-2 rounded-full font-medium text-xl"
                        (click)="showModal()">Add New User</button>
                </div>
                <div class="relative overflow-x-auto sm:rounded-lg mb-5 custom-scrollbar ">
                    <table class="w-full text-sm font-medium text-left grey_text_500">
                        <thead class="text-base font-medium bg_primary text-white">
                            <tr>
                                <th scope="col" class="px-8 py-8">
                                    S.No.
                                </th>
                                <th scope="col" class="px-6 py-6">
                                    Name
                                </th>
                                <th scope="col" class="px-6 py-6">
                                    Email
                                </th>
                                <th scope="col" class="px-6 py-6">
                                    Delete
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white"
                                *ngFor="let item of userData | paginate : {itemsPerPage: item_per_page,currentPage: current_page,totalItems: total_items}; let i=index">
                                <td scope="row" class="px-6 py-6 font-medium whitespace-nowrap ">
                                    {{(i+((current_page-1)*item_per_page))+1}}
                                </td>
                                <td class="px-8 py-6">
                                    {{ item?.firstName }} {{ item?.lastName }}
                                </td>
                                <td class="px-8 py-6">
                                    {{ item?.email}}
                                </td>
                                <td class="px-8 py-6 cursor-pointer" (click)="showDeleteModal(item?.id)">
                                    <i class="fa fa-trash text-[#004AAD]" aria-hidden="true"
                                        data-modal-target="popup-modal" data-modal-toggle="popup-modal"></i>
                                </td>
                            </tr>
                            <tr class="bg-white text-center text-black" *ngIf="total_items == 0">
                                <td class="px-8 py-6" colspan="4">
                                     Sub Dealer Not Found
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <pagination-controls class="pagination-align text-right" max="10" [autoHide]="true" [responsive]="true"
                    (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>
    </div>
</section>

<!-- Add new user modal -->
<div id="car-rejected" tabindex="-1" aria-hidden="true"
    class="justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75 "
    [ngStyle]="{display: displayStyle}">
    <div class="relative w-full max-w-[610px] max-h-full">
        <!-- Modal content -->
        <div class="relative agency-popup-bg bg-white rounded-lg shadow  bg-popup secondary-font overflow-hidden">
            <!-- Modal body -->
            <div class="flex flex-wrap">
                <div class="w-full bg-[url(assets/img/popup-head-bg.png)] bg-no-repeat bg-cover bg-blend-multiply p-10">
                    <div class="model-bidding">
                        <div class="w-full">
                            <div class="text-white text-2xl font-medium uppercase text-center">Add New User</div>
                        </div>
                    </div>
                </div>
                <div class="w-full p-8 bg-white">
                    <form [formGroup]="userForm" class="form">
                        <!-- <div class="mx-auto w-full text-center  mb-4 grid justify-center relative">
                            <div class="w-44 h-44 relative">
                                <img *ngIf="imagePreview" [src]="imagePreview" class="w-44 h-44 rounded-full absolute"
                                    alt="Uploaded Image">
                                <img *ngIf="!imagePreview" src="../../../assets/img/avatar-profile.png"
                                    class="w-44 h-44 rounded-full absolute" alt="Uploaded Image">
                                <label for="dropzone-file"
                                    class="w-44 h-44 group hover:bg-gray-200 opacity-60  rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500">
                                    <img src="https://www.svgrepo.com/show/33565/upload.svg" alt="Upload Icon"
                                        class="hidden w-12 group-hover:block" *ngIf="!imagePreview">
                                    <input id="dropzone-file" type="file" class="hidden"
                                        (change)="handleImageUpload($event)">
                                </label>
                            </div>
                        </div> -->
                        <label class="block mb-6 md:px-4">
                            <input type="text" formControlName="firstName"
                                class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 mr-2 text-black"
                                placeholder="First Name" />
                            <div *ngIf="f['firstName'].invalid && (f['firstName'].dirty || f['firstName'].touched || isSubmit)"
                                class="error-message">
                                <div *ngIf="f['firstName'].errors?.['required']">*First Name is required</div>
                                <div *ngIf="f['firstName'].errors?.['pattern']">*Please enter valid first name</div>
                            </div>
                        </label>
                        <label class="block mb-6 md:px-4">
                            <input type="text" formControlName="lastName"
                                class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-black"
                                placeholder="Last Name" />
                            <div *ngIf="f['lastName'].invalid && (f['lastName'].dirty || f['lastName'].touched || isSubmit)"
                                class="error-message">
                                <div *ngIf="f['lastName'].errors?.['required']">*Last Name is required</div>
                                <div *ngIf="f['lastName'].errors?.['pattern']">*Please enter valid last name</div>
                            </div>
                        </label>
                        <label class="block mb-6 md:px-4">
                            <input type="text" formControlName="email"
                                class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-black"
                                placeholder="Email" />
                            <div *ngIf="f['email'].invalid && (f['email'].dirty || f['email'].touched || isSubmit)"
                                class="error-message">
                                <div *ngIf="f['email'].errors?.['required']">*Email is required</div>
                                <div *ngIf="f['email'].errors?.['email']">*Invalid email format</div>
                            </div>
                        </label>
                        <label class="block mb-6 md:px-4">
                            <input type="text" formControlName="phone"
                                class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-black"
                                placeholder="Phone Number" (keypress)="numberOnly($event)"
                                (input)="onInputChange($event)" minlength="10" />
                            <div *ngIf="f['phone'].invalid && (f['phone'].dirty || f['phone'].touched || isSubmit)"
                                class="error-message">
                                <div *ngIf="f['phone'].errors?.['required']">*Phone number is required</div>
                            </div>
                        </label>
                        <!-- <div class="flex-wrap gap-4 w-full h-32 block mb-6 md:px-4">
                            <label for="dropzone-file"
                                class="flex h-full flex-col items-center justify-center w-full border-dashed border-2 border-primary-color bg-[#E4EFFF] rounded-lg cursor-pointer hover:bg-gray-100 hover:border-black">

                                <div class="flex flex-col items-center justify-center pt-5 pb-6" *ngIf="!imagePreview">
                                    <svg class="w-14 h-14 text-[#004AAD]" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="1"
                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                    </svg>
                                    <div class="text-lg font-semibold group-hover:opacity-0 text-[#004AAD] mt-2 z-30"
                                        style="width: max-content;">Upload the photo</div>
                                </div>
                                <img *ngIf="imagePreview" [src]="imagePreview" alt="Uploaded Image"
                                    class="w-32 h-32 object-cover rounded-lg">
                                <input id="dropzone-file" type="file" class="hidden"
                                    (change)="handleImageUpload($event)" />
                            </label>
         
                        </div> -->
                        <div class="text-center mt-2">
                            <div class="text-lg bg_primary rounded-full text-white font-medium px-10 py-2 inline-block border-primary-color border hover:bg-white hover:text-[#004AAD] cursor-pointer mr-1"
                                (click)="hideModal()">Cancel</div>
                            <div class="text-lg bg_primary rounded-full text-white font-medium px-10 py-2 inline-block border-primary-color border hover:bg-white hover:text-[#004AAD] cursor-pointer"
                                (click)="submitForm()">Add</div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- delete user modal -->
<div id="popup-modal" tabindex="-1"
    class="hidden justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75"
    [ngStyle]="{display: showDeleteUserModal}">
    <div class="relative w-full max-w-md max-h-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="popup-modal" (click)="hideDeleteModal()">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
                <span class="sr-only" >Close modal</span>
            </button>
            <div class="p-6 text-center">
                <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete
                    this user?</h3>
                <button data-modal-hide="popup-modal" type="button"
                    class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                    (click)="deleteUser()">
                    Yes, I'm sure
                </button>
                <button data-modal-hide="popup-modal" type="button"
                    class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    (click)="hideDeleteModal()">No, cancel</button>
            </div>
        </div>
    </div>
</div>