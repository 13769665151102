<div id="your-option" tabindex="-1" aria-hidden="true"
    class="justify-center items-center	hidden  fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75"
    [ngStyle]="{display: showModal}">
    <div class="relative w-full max-w-md max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow">
            <!-- Modal header -->
            <div class="flex items-start justify-center p-4 rounded-t-lg bg_primary">
                <h3 class="text-2xl font-medium text-white text-center secondary-font ">
                    Select Your Option
                </h3>
                <button type="button" class="absolute top-3 end-2.5 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" (click)="hideModal()">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <!-- Modal body -->
            <div class="p-10 flex items-center justify-center">
                <div class="flex items-center custom-radio-blue mr-8">
                    <input id="default-radio-21" type="radio" value="0" name="default-radio1"
                        [(ngModel)]="selectedOption">
                    <label for="default-radio-21"
                        class="ml-2 text-lg font-medium text-black custom-label cursor-pointer">Car Purchased</label>
                </div>
                <div class="flex items-center custom-radio-blue">
                    <input id="default-radio-11" type="radio" value="1" name="default-radio1"
                        [(ngModel)]="selectedOption">
                    <label for="default-radio-11"
                        class="ml-2 text-lg font-medium text-black custom-label cursor-pointer">Car Rejected</label>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="flex justify-center  pb-8 ">
                <button type="button"
                    class="text-white bg_primary font-medium rounded-full  text-xl px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD]"
                    (click)="showpickDropModal()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div id="car-purchased" tabindex="-1" aria-hidden="true"
    class=" justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75 hidden"
    [ngStyle]="{display: showPurchasedModal}">
    <div class="relative w-full max-w-[610px] max-h-full">
        <!-- Modal content -->
        <div class="relative agency-popup-bg bg-white rounded-lg shadow  bg-popup secondary-font overflow-hidden">
            <!-- Modal body -->
            <div class="flex flex-wrap">
                <div
                    class="w-full bg-[url(assets/img/popup-head-bg.png)] bg-no-repeat bg-cover bg-blend-multiply px-10 py-12">
                    <div class="model-bidding">
                        <div class="w-full">
                            <div class="text-white text-2xl font-medium uppercase text-center">Car Purchased</div>
                        </div>
                    </div>
                </div>
                <div class="w-full p-8 bg-white">
                    <form class="form">
                        <label class="block mb-6 md:px-4">
                            <input type="text" placeholder="Amount" [(ngModel)]="purchaseAmount"
                            (focusout)="formatAmount()" (keypress)="numberOnly($event)"  [ngModelOptions]="{standalone: true}"
                                class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md grey_text_500 placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0">
                        </label>

                        <div class="text-center my-2">
                            <button
                            class="text-lg  rounded-full font-medium px-10 py-2 inline-block mr-4 border-primary-color border hover-bg-white hover-text-[#004AAD] " (click)="hideModal()">
                            Close</button>
                            <button
                                class="text-lg bg_primary rounded-full text-white font-medium px-10 py-2 inline-block border-primary-color border hover:bg-white hover:text-[#004AAD] " [disabled]="!purchaseAmount" (click)="onSubmit()">
                                Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Car Rajected -->
<div id="car-rejected" tabindex="-1" aria-hidden="true"
    class="justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75 hidden"
    [ngStyle]="{display: showRejectModal}">
    <div class="relative w-full max-w-[610px] max-h-full">
        <div class="relative agency-popup-bg bg-white rounded-lg shadow bg-popup secondary-font overflow-hidden">
            <div class="flex flex-wrap">
                <div
                    class="w-full bg-[url(assets/img/popup-head-bg.png)] bg-no-repeat bg-cover bg-blend-multiply px-10 py-12">
                    <div class="model-bidding">
                        <div class="w-full">
                            <div class="text-white text-2xl font-medium uppercase text-center">Car Rejected</div>
                            <button type="button" class="absolute top-3 end-2.5 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" (click)="hideModal()">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="w-full p-8 bg-white">
                    <form class="form" [formGroup]="carRejectedForm">
                        <label class="block mb-6 md:px-4">
                            <select formControlName="reason"
                                class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md grey_text_500 placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0">
                                <option value="" disabled>Select the reason</option>
                                <option value="Wrong Vehicle Information">Wrong Vehicle Information</option>
                                <option value="Stolen Vehicle">Stolen Vehicle</option>
                                <option value="Legally Cannot Sell">Legally Cannot Sell</option>
                                <option value="Title Issue">Title Issue</option>
                            </select>
                        </label>
                        <label class="block mb-6 md:px-4">
                            <textarea formControlName="reasonDescription" placeholder="Describe the reason in details"
                                class="h-32 mt-1 block w-full bg-white rounded-lg placeholder-[#777777] input-shadow md:px-8 px-4 py-4 md:text-base text-sm outline-0 border-0 border-[#CDD3D9] focus:border-[#CDD3D9] focus:shadow-none"></textarea>
                        </label>
                        <div class="flex-wrap gap-4 w-full flex mb-6 md:px-4">
                            <label class="w-full grey_text_500 text-base">Upload Proof</label>
                            <label 
                            class="flex flex-col items-center justify-center h-24 w-24 border border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 hover:border-black"
                            *ngFor="let preview of uploadedImagePreviews; let i = index" >
                            <div class="flex flex-col items-center justify-center pt-5 pb-6" >
                              <svg class="w-8 h-8 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 20 16" *ngIf="!preview">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                              </svg>
                              <img *ngIf="preview" [src]="preview" alt="Image Preview" class="w-full h-full object-cover">
                            </div>
                            <input [id]="'dropzone-file' + i" type="file" (change)="handleFileChange($event, i)" accept="image/*" style="display: none;">
                          </label>
                        </div>
                        <div class="text-center my-2">
                            <button
                                class="text-lg  rounded-full font-medium px-10 py-2 inline-block mr-4 border-primary-color border hover-bg-white hover-text-[#004AAD] " (click)="hideModal()">
                                Close</button>
                                <button
                                class="text-lg bg_primary rounded-full text-white font-medium px-10 py-2 inline-block border-primary-color border hover-bg-white hover-text-[#004AAD] " [disabled]="!carRejectedForm.valid" (click)="onSubmit()">
                                Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="car-rejected-details" tabindex="-1" aria-hidden="true"
    class="justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75"
    [ngClass]="{'flex':showRejectCarDetails,'hidden' : !showRejectCarDetails }">
    <div class="relative w-full max-w-[610px] max-h-full">
        <div class="relative agency-popup-bg bg-white rounded-lg shadow bg-popup secondary-font overflow-hidden">
            <div class="flex flex-wrap">
                <div
                    class="w-full bg-[url(assets/img/popup-head-bg.png)] bg-no-repeat bg-cover bg-blend-multiply px-10 py-12">
                    <div class="model-bidding">
                        <div class="w-full">
                            <div class="text-white text-2xl font-medium uppercase text-center">Car Rejection Summary</div>
                            <button type="button" class="absolute top-3 end-2.5 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" (click)="hideModal()">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="w-full p-8 bg-white">
                        <label class="block mb-1 md:px-4 font-semibold">
                            Reason
                        </label>
                        <p class="block mb-6 md:px-4">{{selectedCar?.reason}}</p>
                        <label class="block mb-2 md:px-4 font-semibold">Description
                        </label>
                        <p class="block mb-6 md:px-4">{{selectedCar?.reasonDescription}}</p>
                        <div *ngIf="selectedCar?.rejectionStatus">
                            <label class="block mb-2 md:px-4 font-semibold">Disapproved Reason</label>
                            <p class="block mb-6 md:px-4">{{selectedCar?.disapprovedReason}}</p>
                        </div>
                        <div class="flex-wrap gap-4 w-full flex mb-6 md:px-4">
                            <label class="w-full grey_text_500 text-base font-semibold">Proof</label>
                            <label 
                            class="flex flex-col items-center justify-center h-24 w-24 border border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 hover:border-black"
                            *ngFor="let preview of selectedCar?.proof; let i = index" >
                            <div class="flex flex-col items-center justify-center pt-5 pb-6" >
                              <img *ngIf="preview" [src]="preview.fileUrl" alt="Image Preview" class="w-full h-full object-cover" (click)="showImages(i)">
                            </div>
                          </label>
                        </div>
                        <!-- <div class="text-center my-2">
                            <button
                                class="text-lg bg_primary rounded-full text-white font-medium px-10 py-2 inline-block border-primary-color border hover-bg-white hover-text-[#004AAD] " [disabled]="!carRejectedForm.valid" (click)="onSubmit()">
                                Submit</button>
                        </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<dialog id="my_modal_5" class="modal bg-black p-0" [open]="displayModal">
    <div class="modal-box w-[77%] !p-0">
        <h3 class="font-bold text-lg p-2 bg-slate-100 absolute top-0 w-full text-center text-black z-50">Proof Images</h3>

        <button class="btn btn-sm btn-circle align-middle pt-[3px] bg-white mt-1 z-50 btn-ghost absolute right-8 top-0"
            (click)="closeModal()">
            <i class="fa-solid fa-xmark"></i>
        </button>
        <swiper-container style="--swiper-navigation-color: black; --swiper-pagination-color: black;" class="mySwiper"
            zoom="true" centeredSlides="true" onlyInViewport="true" data-swiper-zoom="2" navigation="true"
            pagination="true" pagination-clickable="true">
            <swiper-slide style="max-height: 100vh; height: 90vh !important;" *ngFor="let item of orderedImages; let i = index">
                <div class="swiper-zoom-container pt-11 flex justify-center items-center h-full">
                    <div class="w-full">
                        <img alt="car image" class="flex mx-auto items-center bg-contain" [src]="item?.fileUrl" />
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</dialog>