import { CanActivateFn, Router } from '@angular/router';
import { CommonService } from '../common.service';
import { inject } from '@angular/core';

export const addCarGuardGuard: CanActivateFn = (route, state) => {

  const service: CommonService = inject(CommonService)
  const router: Router = inject(Router);

      let isAuthenticated = false
      if(service.isBrowserPlateformId()){
        let cardata = localStorage.getItem('carData') || null;
        if(cardata != undefined && cardata != null){
          isAuthenticated = true
        }else{
          isAuthenticated = false
        }
      }
    
    if (isAuthenticated) {
      return true;
    } else {
      return router.createUrlTree(['/']);
    }
};
