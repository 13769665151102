import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HowWeWorkComponent } from './how-we-work/how-we-work.component';
import { FaqComponent } from './faq/faq.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FindYourCarComponent } from './find-your-car/find-your-car.component';
import { SignupComponent } from './signup/signup.component';
import { SectionsModule } from '../sections/sections.module';
import { LoginComponent } from './login/login.component';
import {  RouterModule } from '@angular/router';
import { SharedModule } from '../shared.module';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { ComponentsModule } from '../components/components.module';
import { NotFoundComponentComponent } from './not-found-component/not-found-component.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    HowWeWorkComponent,
    FaqComponent,
    ContactUsComponent,
    FindYourCarComponent,
    SignupComponent,
    LoginComponent,
    TermConditionComponent,
    PrivacyPolicyComponent,
    NotFoundComponentComponent
  ],
  imports: [
    SharedModule,
    SectionsModule,
    BrowserModule,
    ComponentsModule,
    RouterModule
  ],
  exports: [
    HowWeWorkComponent,
    FaqComponent,
    ContactUsComponent,
    FindYourCarComponent,
    SignupComponent,
    LoginComponent,
    NotFoundComponentComponent
  ]
})
export class PagesModule {}
