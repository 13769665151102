import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { HowWeWorkComponent } from './pages/how-we-work/how-we-work.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FindYourCarComponent } from './pages/find-your-car/find-your-car.component';
import { SignupComponent } from './pages/signup/signup.component';
import { VerificationComponent } from './components/verification/verification.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ValidateTokenComponent } from './components/validate-token/validate-token.component';
import { TermConditionComponent } from './pages/term-condition/term-condition.component';
import { AccountHistoryComponent } from './components/account-history/account-history.component';
import { AuthGuard } from './auth/auth.guard';
import { NotFoundComponentComponent } from './pages/not-found-component/not-found-component.component';
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  {path: 'dealer/car-history/:id',component: AccountHistoryComponent,canActivate:[AuthGuard]},
  {path: 'seller/account-history',component: AccountHistoryComponent,canActivate:[AuthGuard]},
  {
    path: 'forgot-password',
    component: ForgetPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'reset-password/:user_id/:token',
    component: ValidateTokenComponent,
    pathMatch: 'full',
  },
  {
    path: 'reset-password/:user_id',
    component: ResetPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'signup',
    component: SignupComponent,
    pathMatch: 'full',
  },
  {
    path: 'seller/signup',
    component: SignupComponent,
    pathMatch: 'full',
  },
  {
    path: 'dealer/signup',
    component: SignupComponent,
    pathMatch: 'full',
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    pathMatch: 'full',
  },
  {
    path: 'term-condition',
    component: TermConditionComponent,
    pathMatch: 'full',
  },
  {
    path: 'how-we-work',
    component: HowWeWorkComponent,
    pathMatch: 'full',
  },
  {
    path: 'faq',
    component: FaqComponent,
    pathMatch: 'full'
  },
  { path: 'dealer', loadChildren: () => import('./dealeraccount/dealeraccount.module').then(m => m.DealerAccountModule) },
  { path: 'seller', loadChildren: () => import('./myaccount/myaccount.module').then(m => m.MyaccountModule)},

  {
    path: 'contact-us',
    component: ContactUsComponent,
    pathMatch: 'full',
  },

  {
    path: 'find-your-car',
    component: FindYourCarComponent,
    pathMatch: 'full',
  },
  {
    path: 'verification/:id',
    component: VerificationComponent,
  },

  { path: '**', component:NotFoundComponentComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
