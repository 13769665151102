import { NgModule } from '@angular/core';
import { DealerRegistrationComponent } from './dealer-registration/dealer-registration.component';
import { SellerRegistrationComponent } from './seller-registration/seller-registration.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared.module';


@NgModule({
  declarations: [
    DealerRegistrationComponent,
    SellerRegistrationComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    HttpClientModule
  ],
  exports: [
    DealerRegistrationComponent,
    SellerRegistrationComponent
  ]
})
export class SectionsModule { }
