import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import * as moment from 'moment';
import { interval } from 'rxjs';

declare var $: any
@Component({
  selector: 'app-dealer-performance',
  templateUrl: './dealer-performance.component.html',
  styleUrls: ['./dealer-performance.component.scss']
})
export class DealerPerformanceComponent implements OnInit, OnDestroy {
  public user: any;
  public starCounts: any;
  public dashboardCount: any;
  public totalRatings: number = 0;
  public dataSubcribed: any
  constructor(private service: CommonService, private renderer: Renderer2 ) {

    if(this.service.isBrowserPlateformId()){
     
      // Dynamically load CSS
      const link = this.renderer.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css';
      this.renderer.appendChild(document.head, link);
  
      // Dynamically load JS
      const script = this.renderer.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js';
      script.onload = () => {
      
      };
      

      this.renderer.appendChild(document.body, script);
    }
   
    if(this.service.isBrowserPlateformId()){
      this.service.scrollToTop()
      this.user = JSON.parse(`${localStorage.getItem('user')}`);
    }
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  ngOnInit(): void {
    this.getRatings();
    this.getCalendar()
    // this.subscribeLiveData();

    
    
    
  }

  private subscribeLiveData(): void {    
    this.dataSubcribed = interval(60000)
      .subscribe(() => this.getRatings());
  }

  ngOnDestroy(): void {
    // this.dataSubcribed.unsubscribe();
  }

  getRatings() {
    let data = {
      userId: this.user.id,
      parentUserId: this.user.parentUserId ? this.user.parentUserId : 0
    }
    this.service.showSpinner();
    this.service.postApi('ratings/get-total-ratings', data).subscribe((res: any) => {
      this.starCounts = res;
      let data = {
        userId: this.user.id,
        parentUserId: this.user.parentUserId ? this.user.parentUserId : 0
      }
      this.getCounts(data);
      this.totalRatings = res.totalRatings
      this.service.hideSpinner();
    }, (error) => {
      this.service.hideSpinner();
    })
  }



  getCounts(data: any) {
    this.dashboardCount = []
    this.service.showSpinner();
    this.service.postApi('cars/dashboard', data).subscribe((res: any) => {
      this.dashboardCount = res
      this.service.hideSpinner();
      this.getCalendar()
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  getCalendar() {
    $(() => {
      $('input[name="daterange"]').daterangepicker({
        opens: 'center',
        // singleDatePicker: true,
        // autoUpdateInput: false,
        alwaysShowCalendars: true,
        showCustomRangeLabel: false,
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        locale: {
          format: 'DD MMM YYYY'
        }
      }, (start: any, end: any, label: any) => {
        
      });

      $('input[name="daterange"]').on('apply.daterangepicker', (ev: any, picker:any) => {

        let data = {
          startDate: picker.startDate.format('YYYY-MM-DD'),
          endDate: picker.endDate.format('YYYY-MM-DD'),
          userId: this.user.id,
          parentUserId : this.user.parentUserId ? this.user.parentUserId : 0
        }
        this.service.showSpinner();
        this.getCounts(data)
        $('input[name="daterange"]').val(picker.startDate.format('DD-MMM-YYYY') + ' - ' + picker.endDate.format('DD-MMM-YYYY'));
      });
  
      $('input[name="daterange"]').on('cancel.daterangepicker', function(ev:any, picker:any) {
          $('input[name="daterange"]').val('');
      });
    });
  }

  resetDateFunc() {
    const $input = $('input[name="daterange"]');
    const daterangepickerInstance = $input.data('daterangepicker');

    $input.val('YYYY-MM-DD - YYYY-MM-DD ');
    if (daterangepickerInstance) {
      daterangepickerInstance.setStartDate(moment());
      daterangepickerInstance.setEndDate(moment());
    }
  }

  resetCarModelData(){
    let data = {
      userId: this.user.id,
      parentUserId: this.user.parentUserId ? this.user.parentUserId : 0
    }
    console.log("data",data);
    
    this.getCounts(data)
    const $input = $('input[name="daterange"]');
    const daterangepickerInstance = $input.data('daterangepicker');
    if (daterangepickerInstance) {
      daterangepickerInstance.setStartDate(moment());
      daterangepickerInstance.setEndDate(moment());
    }
  }


  calculatePercentage(rating: any): number {
    if (rating.total > 0) {
      return (rating.total / this.totalRatings) * 100;
    }
    else
      return 0
  }

  calculateStarClass(i: number): string {
    const integerPart = Math.floor(this.starCounts?.averageRating || 0);
    const fractionalPart = this.starCounts?.averageRating % 1;

    if (i + 1 <= integerPart) {
      return 'fa fa-star active text-xl';
    } else if (i + 0.5 === this.starCounts?.averageRating || (i + 1 === integerPart + 1 && fractionalPart > 0)) {
      return 'fas fa-star-half-alt active text-xl';
    } else {
      return 'inactive fa fa-star text-xl';
    }
  }

}
