<app-sub-header></app-sub-header>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4">          
            <app-sidebar class="z-2 xl:w-3/12 md:w-5/12 w-full px-4 mb-10 md:mb-0 hidden md:block"></app-sidebar>
            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center">
                    <h2 class="lg:text-5xl text-4xl  font-bold leading-none text_primary_color">{{isDealer ? 'History' : 'Account History'}}</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>                 
                <div class="relative overflow-x-auto sm:rounded-lg mb-5 custom-scrollbar">
                    <table class="w-full text-sm font-medium text-left grey_text_500">
                        <thead class="text-base font-medium bg_primary text-white">
                            <tr>
                                <th scope="col" class="px-8 py-8">
                                    S.No.
                                </th>
                                <th scope="col" class="px-6 py-6">
                                    Date
                                </th>
                                <th scope="col" class="px-6 py-6">
                                    Time
                                </th>
                                <th scope="col" class="px-6 py-6">
                                    Acitvity
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white" *ngFor="let item of logs | paginate : {itemsPerPage: item_per_page,currentPage: current_page,totalItems: total_items}; let i=index">
                                <td scope="row" class="px-6 py-6 font-medium whitespace-nowrap ">
                                    {{(i+((current_page-1)*item_per_page))+1}}
                                </td>
                                <td class="px-8 py-6">
                                    {{ item?.createdAt | date:'d MMMM y'}}
                                </td>
                                <td class="px-8 py-6">
                                    {{ item?.createdAt | date:'shortTime'}}
                                </td>
                                <td class="px-8 py-6">
                                    {{item?.actions}}
                                </td>
                            </tr>
                            <tr class="bg-white text-center text-black" *ngIf="total_items == 0">
                                <td class="px-8 py-6" colspan="4">
                                    No History Found
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                </div>
                <pagination-controls class="pagination-align text-right" max="10" [autoHide]="true" [responsive]="true" (pageChange)="pageChanged($event)"></pagination-controls>
 
            </div>           
        </div>
    </div>
</section>