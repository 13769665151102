<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name"
            class="max-w-full sm:inline-block hidden lg:h-[550px] h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center">
        <div class="container mx-auto  ">
            <div class="w-full mx-auto z-50  flex-1 px-6 ">
                <div class="block text-center">
                    <div class="inline-block px-10 py-2 rounded-full bg-white mb-6 text-[#004AAD] font-semibold xl:text-xl text-base"
                        *ngIf="car?.carPlateNo && car?.carPlateNo!=' '">{{car?.carPlateNo}}</div>
                    <h1 class="xl:text-5xl text-4xl text-white font-bold "> {{car?.carName}} {{car?.modelName}} </h1>
                    <div class="text-center text-white xl:text-2xl text-base mt-4 " >
                        <div
                            class="relative inline-block sm:px-10 px-4 after:absolute after:xl:w-[8px] after:xl:h-[8px] after:w-[5px] after:h-[5px] after:rounded-full after:bg-white after:right-0 after:xl:top-[13px] after:top-[9px]">
                            {{car?.modelYear}}</div>
                        <div
                            class="relative inline-block sm:px-10 px-4 after:absolute after:xl:w-[8px] after:xl:h-[8px] after:w-[5px] after:h-[5px] after:rounded-full after:bg-white after:-right-[0px] after:md:top-[13px] after:top-[9px]">
                            {{car?.modelName}}</div>
                        <div class="inline-block sm:px-10 px-4"> {{car?.fuelType}}</div>
                    </div>
                    <div class="inline-block mt-4 px-10 py-2 rounded-full bg-white mb-6 text-[#004AAD] font-semibold xl:text-xl text-base"
                    *ngIf="car?.vinNumber && car?.vinNumber!='null'">
                    {{car?.vinNumber}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4">
            <input type="checkbox"  class="openSidebarMenu" id="openSidebarMenu">
            <label for="openSidebarMenu" class="sidebarIconToggle">
                <div class="spinner diagonal part-1"></div>
                <div class="spinner horizontal"></div>
                <div class="spinner diagonal part-2"></div>
            </label>
            <div class="z-2 xl:w-3/12 min-[993px]:w-5/12 w-full px-4 mb-10 min-[992px]:mb-0 hidden lg:block" id="sidebar_menu">
        <app-sidebar></app-sidebar>
    </div>
            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="bg-white p-4">
                    <div class="w-full mx-auto mb-10">
                        <!-- <div class=" bg-white b-10 md:flex md:flex-wrap justify-between px-4 lg:px-0 py-10 lg:py-0" *ngIf="mileageAndColor?.length>0">
                            <div class="xl:py-10  pt-6 xl:w-6/12 w-full bg-white" >
                                <div
                                    class="title-heading pb-1 lg-3 leading-none text-center md:mb-8 mb-6 lg:text-center">
                                    <h2 class="xl:text-2xl text-xl  font-bold leading-none text_primary_color">{{mileageAndColor[0]?.question}}</h2>
                                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                                </div>
                                <div class="w-full mb-6 text-center">
                                    <div
                                        class="md:text-lg text-base font-medium text-white rounded-full md:px-10 px-6 py-2 bg_primary inline-block">
                                        {{car?.mileage}}</div>
                                </div>
                            </div>
                            <div class="xl:py-10 xl:w-6/12 w-full bg-white">
                                <div
                                    class="title-heading pb-1 lg-3 leading-none text-center md:mb-8 mb-6 lg:text-center">
                                    <h2 class="xl:text-2xl text-xl  font-bold leading-none text_primary_color">{{mileageAndColor[1]?.question}}</h2>
                                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                                </div>
                                <div class="w-full mb-6 text-center">
                                       <div
                                        class="md:text-lg text-base font-medium text-white rounded-full md:px-10 px-6 py-2 bg_primary inline-block">
                                        {{car?.color}}</div>
                                </div>
                            </div>
                        </div> -->
                        <div class="bg-white b-10 md:flex md:flex-wrap justify-between px-4 lg:px-0 py-10 lg:py-0" *ngIf="mileageAndColor?.length > 0">
                            <ng-container *ngFor="let item of mileageAndColor; let i = index">
                                <div class="xl:py-10 pt-6 xl:w-6/12 w-full bg-white">
                                    <div class="title-heading pb-1 lg-3 leading-none text-center md:mb-8 mb-6 lg:text-center">
                                        <h2 class="xl:text-2xl text-xl font-bold leading-none text_primary_color">{{ item.question }}</h2>
                                        <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                                        <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                                        <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                                    </div>
                                    <div class="w-full mb-6 text-center">
                                        <div class="md:text-lg text-base font-medium text-white rounded-full md:px-10 px-6 py-2 bg_primary inline-block">
                                            {{ car ? (i === 0 ? car.mileage : car.color) : '' }}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
           
                        <div *ngFor="let item of carQuestions;let k=index">
                            <div *ngIf="(item.category.specialCategoryName != 'Mileage and Color' && item.category.specialCategoryName !== 'Referral Sources') || (item.category.specialCategoryName === 'Referral Sources' && user.role === 1)">
                                <div class="text-center w-full">
                                    <div class="inline-block mb-10  bg-white w-48" [ngClass]="{'lg:-mt-10 mt-0': k==0 && mileageAndColor}">
                                        <img alt="car image" [src]="item?.category?.image">
                                        
                                    </div>
                                </div>
                                <div class="title-heading pb-1 leading-none text-center mb-8 lg:text-center">
                                    <h2 class="xl:text-4xl text-3xl  font-bold leading-none text_primary_color">{{item.category.categoryName }}</h2>
                                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                                </div>
                                <div class="submission-detail mb-12 px-4 md:px-0 text-center space-y-20" *ngFor="let question of item.questions; let i=index">
                                    <div class="detail-row">
                                        <h3
                                            class="xl:text-2xl text-xl font-bold text_primary_color inline-flex xl:items-center mb-4 relative pl-10 md:pl-0 leading-none">
                                            <span
                                                class="border border-dashed border-primary-color rounded-full md:w-9 md:h-9 w-6 h-6 inline-flex items-center justify-center !leading-normal	xl:mr-6 mr-4  text-[#373737] md:relative absolute left-0 top-1 md:top-0 md:text-xl text-base">{{i+1}}</span>
                                            <span class="flex-1"> {{ question.question }}</span>
                                        </h3>
                                        <div class="w-full mb-6 text-center">
                                            <div
                                                class="md:text-lg text-base font-medium text-white rounded-full md:px-10 px-6 py-2 bg_primary inline-block" *ngIf="question.type!='checkbox'">
                                                {{carAnswers[question.id]}}
                                            </div>
                                            <div *ngIf="question.type=='checkbox'">
                                                <div class="md:text-lg text-base font-medium text-white rounded-full md:px-10 px-6 py-2 bg_primary inline-block mr-1 mb-2"
                                                *ngFor="let item of carAnswers[question.id]">{{item}}</div>
                                            </div>
                                            <div *ngIf="question.isSubOption ==1" class="mt-4">
                                                <div
                                                class="md:text-lg text-base font-medium text-white rounded-full md:px-10 px-6 py-2 bg_primary inline-block" *ngIf="question.subType!='checkbox'">
                                                {{carAnswers['subOption-'+question.id]}}
                                            </div>
                                            <div *ngIf="question.subType=='checkbox'">
                                                <div class="md:text-lg text-base font-medium text-white rounded-full md:px-10 px-6 py-2 bg_primary inline-block mr-1 mb-2"
                                                *ngFor="let item of carAnswers['subOption-'+question.id]">{{item}}</div>
                                            </div>
                                            </div>
                                            <div class="my-6 pl-10 md:pl-0" *ngIf="question.descriptionField === 1 && carAnswers['description-'+question.id] != ''">
                                                <div [innerHTML]="carAnswers['description-'+question.id]"
                                                    class="mt-1 h-auto items-center justify-center w-10/12 lg:w-9/12 sm:w-11/12 inline-block bg-white rounded-lg md:px-8 px-4  py-4 md:text-base text-sm input-shadow outline-0 border-0 text-center text-black">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
             
                      <div *ngIf="car?.carImages.length>0">
                        <div class="title-heading pb-1 leading-none text-center mb-8 lg:text-center">
                            <h2 class="xl:text-4xl text-3xl  font-bold leading-none text_primary_color">Vehicle Photos
                            </h2>
                            <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                            <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                            <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                        </div>
                        <div class="example-car-section px-4">
                            <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mb-10 mt-8">
                                <div class="rounded-lg border-2 border-primary-color bg-[#E4EFFF] flex items-center justify-center overflow-hidden cursor-pointer h-[208px]"
                                    *ngFor="let item of car?.carImages; let i = index">
                                    <div class="flex flex-wrap items-center justify-center">
                                        <div class="h-48 relative rounded-lg shadow-xl cursor-pointer transform transition-transform flex items-center justify-center"
                                            (click)="showModal(i)">
                                            <img src="{{item?.fileUrl}}" alt="front img"
                                                class="max-h-[208px] max-w-[208px]">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div class="title-heading pb-1 leading-none text-center mb-8 lg:text-center" *ngIf="!car?.bidWinnerId">
                            <h2 class="xl:text-4xl text-3xl  font-bold leading-none text_primary_color">Place Your Offer
                            </h2>
                            <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                            <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                            <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                        </div>
                        <div class="flex justify-center  pb-8 px-4 md:px-0" *ngIf="!car?.bidWinnerId">
                            <button type="button"
                                class="text-white bg_primary rounded-full  text-lg px-12 py-2 text-center border border-primary-color outline-0 hover:bg-white hover:text-[#004AAD]"
                                (click)="showBidModel()">Offer
                                Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-bid></app-bid>

<dialog id="my_modal_5" class="modal bg-black p-0" [open]="displayModal">
    <div class="modal-box w-[77%] !p-0">
        <button class="btn btn-sm btn-circle align-middle pt-[3px] bg-white mt-1 z-10 btn-ghost absolute right-8 top-0"
            (click)="closeModal()">
            <i class="fa-solid fa-xmark"></i>
        </button>
        <swiper-container style="--swiper-navigation-color: black; --swiper-pagination-color: black;" class="mySwiper"
            zoom="true" centeredSlides="true" onlyInViewport="true" data-swiper-zoom="2" navigation="true"
            pagination="true" pagination-clickable="true">
            <swiper-slide class="h-[80vh]" *ngFor="let item of orderedImages; let i = index">
                <h3 class="font-bold text-lg p-2 bg-slate-100 absolute top-0 w-full text-center text-black">{{ item?.label }}</h3>
                <div class="swiper-zoom-container">
                    <div class="w-full flex items-center justify-center h-full">
                        <div class="w-full mx-auto p-4 h-full flex items-center justify-center">
                            <img alt="car image" class="bg-contain max-w-full max-h-full" [src]="item?.fileUrl" />
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</dialog>
