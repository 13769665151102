<div class="main-section container flex flex-wrap bg-cover min-h-screen align-middle lg:items-center justify-center min-w-full" style="background-image: url('../../../assets/img/home-banner1.avif');">
  <div class=" z-50 flex-1 px-6 mt-20 pad:mt-10 xl:mt-10 md:mb-0 mb-14 flex ">
        <div class="max-w-screen-lg	mx-auto z-50 flex-1  px-6   mt-20 xl:mt-10 md:mb-0 mb-14 ">
            <div class="block text-center">
                <h1 class="md:text-5xl text-3xl text-white font-bold mb-3">List Your Vehicle </h1>
                <!-- <p class="md:text-lg text-sm text-white font-medium">If your make or model is not available, <a href=""
                        class="text-white underline hover:no-underline">contact</a> the administrator.
                </p> -->
                <div class="form mt-10">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="md:p-6 p-4 bg-white shadow-2xl rounded-2xl relative grid md:grid-cols-4 grid-cols-1 gap-6 after after:absolute after:w-8 after:h-8 after:bg-white after:-top-4 after:left-0 after:right-0 after:mx-auto after:-z-10 after:origin-center after:rotate-45 z-10">
                          <div class="relative">
                            <select formControlName="makeName"
                                    class="rounded-lg border h-14 px-6 w-full border-[#ABB7C3] md:text-lg text-base text-black focus:ring-0 focus:border-[#ABB7C3] custom-select cursor-pointer"
                                    (change)="getModel($event)">
                              <option selected value="" disabled>Make</option>
                              <option *ngFor="let item of allMakes" value="{{item.name}}" class="text-black cursor-pointer">{{item?.name | uppercase}}</option>
                            </select>
                            <div *ngIf="form.get('makeName')?.hasError('required') && (form.get('makeName')?.touched || isSubmit)" class="text-red-600 text-left">*Make name is required</div>
                          </div>
                     
                          <div class="relative">
                            <select formControlName="modelName"
                                    class="rounded-lg border h-14 px-6 w-full border-[#ABB7C3] md:text-lg text-base text-black focus:ring-0 focus:border-[#ABB7C3] custom-select">
                              <option selected value="" disabled>Model</option>
                              <option *ngFor="let item of modelList" value="{{item.name}}" class="text-black">{{item?.name | uppercase}}</option>
                            </select>
                            <div *ngIf="form.get('modelName')?.hasError('required') && (form.get('modelName')?.touched || isSubmit)" class="text-red-600 text-left">*Model name is required</div>
                          </div>
                          <div class="relative">
                            <select formControlName="year"
                                    class="rounded-lg border h-14 px-6 w-full border-[#ABB7C3] md:text-lg text-base text-black focus:ring-0 focus:border-[#ABB7C3] custom-select"
                                    >
                              <option selected value="" disabled>Year</option>
                              <option *ngFor="let item of yearList" value="{{item}}" class="text-black">{{item}}</option>
                            </select>
                            <div *ngIf="form.get('year')?.hasError('required') && (form.get('year')?.touched || isSubmit)" class="text-red-600 text-left">*Year is required</div>
                          </div>
                          <div class="relative">
                            <input formControlName="vinNumber"
                                   class="rounded-lg border h-14 px-6 w-full border-[#ABB7C3] md:text-lg text-base text-black focus:ring-0 focus:border-[#ABB7C3] outline-0 placeholder:text-black"
                                   placeholder="VIN Number">
                            <div *ngIf="form.get('vinNumber')?.hasError('required') && (form.get('vinNumber')?.touched || isSubmit)" class="text-red-600 text-left">*VIN Number is required</div>
                          </div>
                        </div>
                        <div class="block text-center md:mt-12 mt-8">
                          <button type="submit"
                                  class="rounded-full bg-white md:py-3 py-2 md:px-20 px-12 font-semibold md:text-xl taxt-base text_primary_color cursor-pointer border border-transparent hover:border-white hover:bg-transparent hover:text-white">Submit</button>
                          <p class="md:mt-8 mt-6 md:text-base text-xs text-white">Lets the dealers start fighting to
                            pay you more</p>
                        </div>
                      </form>
                      
                      
                </div>
            </div>
        </div>
    </div>
</div>