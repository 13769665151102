import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DealerPerformanceComponent } from './dealer-performance/dealer-performance.component';
import { EmployePerformanceComponent } from './employe-performance/employe-performance.component';
import { FilterTopbarComponent } from './filter-topbar/filter-topbar.component';
import { BrandsComponent } from './brands/brands.component';
import { BrandCarListComponent } from './brand-car-list/brand-car-list.component';
import { CarListNewComponent } from './car-list-new/car-list-new.component';
import { DealerPreferredListingComponent } from './dealer-preferred-listing/dealer-preferred-listing.component';
import { DealerOfferComponent } from './dealer-offer/dealer-offer.component';
import { ViewcarInfoComponent } from './viewcar-info/viewcar-info.component';
import { ScheduleCarPickupComponent } from './schedule-car-pickup/schedule-car-pickup.component';
import { CarOverviewComponent } from './car-overview/car-overview.component';
import { KargoneWalletComponent } from './kargone-wallet/kargone-wallet.component';
import { InvoiceAccountStatementComponent } from './invoice-account-statement/invoice-account-statement.component';
import { BillingInvoiceComponent } from './billing-invoice/billing-invoice.component';
import { DealershipRegistrationComponent } from './dealership-registration/dealership-registration.component';
import { ProfileManagementComponent } from './profile-management/profile-management.component';
import { DealerAccountRoutingModule } from './dealeraccount-routing.module';
import { ComponentsModule } from '../components/components.module';
import { DealerPreferenceComponent } from './dealer-preference/dealer-preference.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { SharedModule } from '../shared.module';
import { PaymentComponent } from './payment/payment.component';
import { CreditReceiptComponent } from './credit-receipt/credit-receipt.component';

@NgModule({
  declarations: [
    DealerPerformanceComponent,
    EmployePerformanceComponent,
    FilterTopbarComponent,
    BrandsComponent,
    BrandCarListComponent,
    CarListNewComponent,
    DealerPreferredListingComponent,
    DealerOfferComponent,
    ViewcarInfoComponent,
    ScheduleCarPickupComponent,
    CarOverviewComponent,
    KargoneWalletComponent,
    InvoiceAccountStatementComponent,
    BillingInvoiceComponent,
    DealershipRegistrationComponent,
    ProfileManagementComponent,
    DealerPreferenceComponent,
    UserManagementComponent,
    PaymentComponent,
    CreditReceiptComponent,
  ],
  imports: [
    SharedModule,
    DealerAccountRoutingModule,
    ComponentsModule,
  ],
  exports: [CarListNewComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class DealerAccountModule { }
