<div class="inner-banner bg-cover z-0 relative">
  <div class="inner-banner-images text-center">
      <img src="assets/img/inner-banner.png" alt="Page Name" class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
      <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
  </div>
  <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center" >
      <div class="container mx-auto  ">
          <div class="max-w-screen-sm	mx-auto z-50  flex-1 px-6 ">
              <div class="block text-center">
                  <h1 class="md:text-6xl text-4xl text-white font-bold ">How Kargone works</h1>       
              </div>
          </div>
      </div>
  </div>
</div>

<div *ngFor="let item of howWeWork; let i = index" [class]="(i) % 2 == 0 ? 'bg-section-center' : ''">
          <section class="relative xl:py-24 lg:py-20 py-0" *ngIf="(i % 2 == 0)">
            <div class="container max-w-screen-xl flex flex-wrap items-center mx-auto">
              <div class="flex flex-wrap items-center lg:order-1 order-2 md:min-h-[354px]">
                <div class="block-content lg:w-3/6 w-full px-4 lg:text-left ">
                  <div class="title-heading pb-1 lg-3 leading-none text-left mb-4">
                    <div class="z-10 relative">
                      <h2 class="md:text-4xl text-2xl heading_color font-bold leading-none">{{ item.heading }}</h2>
                      <span class="md:w-28 w-24 h-1 bg_primary mr-2 inline-block"></span>
                      <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                      <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    </div>
                    <span class="absolute left-0 md:-top-14 -top-10 z-0 md:text-9xl text-8xl leading-none exo-font font-black text-[#ADCDF7] opacity-30">{{ i + 1 }}</span>
                  </div>
                  <p class="md:text-lg text-base content_color text-[#555] text-justify" [innerHTML]="item.content"></p>
                </div>
              </div>
              <div class="block-video lg:w-3/6 w-full lg:absolute right-0 lg:pl-20 lg-8 lg:mb-0 mb-16  lg:top-20 lg:bottom-10 lg:order-2 order-1 h-[72%]">
                <div *ngIf="item.fileType == 1"  style="aspect-ratio: 744/438;" class="w-full lg:rounded-l-[70px] overflow-hidden">
                  <iframe class="w-full h-full" [src]="item.fileUrl"  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <img *ngIf="item.fileType == 0" class="w-full lg:rounded-r-[70px] overflow-hidden" [src]="item.fileUrl" alt="Image">
                <!-- <img class="w-full lg:rounded-l-[70px] overflow-hidden "  [src]="item.fileUrl"> -->
              </div>
            </div>
          </section>
          <section class="relative xl:py-36 lg:py-24 md:pb-0 pb-14" *ngIf="(i % 2 != 0)">
            <div class="container max-w-screen-xl flex flex-wrap items-center mx-auto">
              <div class="flex flex-wrap items-center justify-end lg:order-1 order-2">
                <div class="block-content lg:w-3/6 w-full px-4 text-left">
                  <div class="title-heading pb-1 lg-3 leading-none text-left mb-4">
                    <div class="z-10 relative">
                      <h2 class="md:text-4xl text-2xl heading_color font-bold leading-none">{{ item.heading }}</h2>
                      <span class="md:w-28 w-24 h-1 bg_primary mr-2 inline-block"></span>
                      <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                      <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    </div>
                    <span class="absolute left-0 md:-top-14 -top-10 z-0 md:text-9xl text-8xl leading-none exo-font font-black text-[#ADCDF7] opacity-30">{{ i + 1 }}</span>
                  </div>
                  <p class="md:text-lg text-base content_color text-[#555] text-justify" [innerHTML]="item.content"></p>
                </div>
              </div>
              <div class="block-video lg:w-3/6 w-full lg:absolute left-0 lg:pr-20 mb-16 mt-10 lg:mt-0 lg:mb-0  lg:order-2 order-1 ">  
                  <img *ngIf="item.fileType == 0" class="w-full lg:rounded-r-[70px] overflow-hidden" [src]="item.fileUrl" alt="Image">
                  <div *ngIf="item.fileType == 1" style="aspect-ratio: 744/438;" class="w-full lg:rounded-r-[70px] overflow-hidden">
                    <iframe   class="w-full h-full" [src]="item.fileUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
              </div>
            </div>
          </section>      
 
</div>
     <!-- <iframe class="w-full lg:rounded-l-[70px] overflow-hidden h-full"  src="https://www.youtube.com/embed/UPNjp14SekU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->