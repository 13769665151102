import { Component, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-update-car-status',
  templateUrl: './update-car-status.component.html',
  styleUrls: ['./update-car-status.component.scss']
})
export class UpdateCarStatusComponent {
  public user: any;
  public showModal: string = 'none'
  public selectedCar: any;
  public showPurchasedModal: string = 'none'
  public selectedOption = '0';
  public showRejectModal: string = 'none';
  public modalId = 'your-option'
  public carRejectedForm: FormGroup;
  public uploadedFiles: File[] = [];
  public purchaseAmount: any;
  public firstImage:any;
  public secondImage:any;
  public thirdImage:any;
  public showRejectCarDetails = false
  public uploadedImagePreviews: (string | undefined)[] = [undefined, undefined, undefined];
  public displayModal = false;
  public orderedImages:any;

  constructor(private service: CommonService, private fb: FormBuilder, private renderer : Renderer2) {
    if(this.service.isBrowserPlateformId()){
      this.user = JSON.parse(`${localStorage.getItem('user')}`)
    }
    this.carRejectedForm = this.fb.group({
      reason: ['', Validators.required],
      reasonDescription: ['', [Validators.required]],
      proof: ['', [Validators.required]]
    });

  }
  ngOnInit(): void {
    if(this.service.isBrowserPlateformId()){
   
      // Dynamically load CSS
      const link = this.renderer.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css';
      this.renderer.appendChild(document.head, link);
  
      // Dynamically load JS
      const script = this.renderer.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-element-bundle.min.js';
      script.onload = () => {
        console.log('Swiper script loaded');
      };
      this.renderer.appendChild(document.body, script);
    }
    

    this.service.carStatus.subscribe((res: any) => {
      this.selectedCar = res;
      if(this.selectedCar.isRejectDetails){
        this.modalId = 'car-rejected-details'
        this.showRejectCarDetails = true
      }
      else{
        
        this.showModal = 'flex'
      }
      this.clickOutSideModal()
    })


  }

  handleFileChange(event: any, index: number) {
    if (event.target.files && event.target.files.length > 0) {
      this.uploadedFiles.push(event.target.files[0]);
      if(index == 0){
        this.firstImage = event.target.files[0]
      }
      if(index == 1){
        this.secondImage = event.target.files[0]
      }
      if(index == 2){
        this.thirdImage = event.target.files[0]
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.uploadedImagePreviews[index] = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.carRejectedForm.get('proof')?.setValue(this.uploadedFiles);
    }
  }


  clickOutSideModal() {
    if(this.service.isBrowserPlateformId()){
      window.addEventListener('click', (event) => {
        if (event.target === document.getElementById(this.modalId)) {
          console.log(this.modalId);
          
          this.showModal = 'none'
          this.showPurchasedModal = 'none'
          this.showRejectModal = 'none'
          this.showRejectCarDetails = false
        }
      });
    }
  }

  showpickDropModal() {
    this.showModal = 'none'
    if (this.selectedOption == '0') {
      this.modalId = 'car-purchased'
      this.showPurchasedModal = 'flex';
    }
    else {
      this.modalId = 'car-rejected'
      this.showRejectModal = 'flex';
    }
    this.clickOutSideModal();
  }
  hideModal(){
    this.showModal = 'none';
    this.showPurchasedModal = 'none';
    this.showRejectModal = 'none';
    this.showRejectCarDetails = false;

  }

  onSubmit() {
    this.service.showSpinner();
    const currentValue: string = this.purchaseAmount;
    // Remove the dollar sign ($) and comma (,) from the value
    let intValue = 0
    if (currentValue && typeof currentValue === 'string') {
      // Remove the dollar sign ($) and comma (,) from the value
      const cleanedValue: string = currentValue.replace(/\$|,/g, '');
      intValue = parseInt(cleanedValue);
    }
    this.purchaseAmount = intValue;

    const formDataToSend = new FormData();

    if (this.carRejectedForm.valid) {
      if(this.firstImage){
        formDataToSend.append('firstImage', this.firstImage, this.firstImage.name);
      }
      if(this.secondImage){
        formDataToSend.append('secondImage', this.secondImage, this.secondImage.name);
      }
      if(this.thirdImage){
        formDataToSend.append('thirdImage', this.thirdImage, this.thirdImage.name);
      }
    }
    let data = {
      carId: this.selectedCar.id,
      dealerId: this.user.id,
      purchashed: this.selectedOption == '0' ? '1' : '0',
      rejected: this.selectedOption == '0' ? '0' : '1',
      reasonDescription: this.carRejectedForm.value.reasonDescription ? this.carRejectedForm.value.reasonDescription : '',
      reason: this.carRejectedForm.value.reason ? this.carRejectedForm.value.reason : '',
      carName: this.selectedCar.carFullName ? this.selectedCar.carFullName : `${this.selectedCar.carName} ${this.selectedCar.modelName} ${this.selectedCar.modelYear}`,
      dealerName: this.user.username,
      isAdminLoggedIn:this.user.isAdminLoggedIn
    }
    formDataToSend.append('carId', data.carId);
    formDataToSend.append('purchaseAmount', this.purchaseAmount);
    formDataToSend.append('dealerId', data.dealerId);
    formDataToSend.append('purchased', data.purchashed);
    formDataToSend.append('rejected', data.rejected);
    formDataToSend.append('reasonDescription', data.reasonDescription);
    formDataToSend.append('reason', data.reason);
    formDataToSend.append('carName', data.carName);
    formDataToSend.append('dealerName', data.dealerName);
    formDataToSend.append('vinNumber', this.selectedCar.vinNumber);
    formDataToSend.append('bidId',this.selectedCar.bidId)
    formDataToSend.append('bidWinnerId',this.selectedCar.bidWinnerId)
    formDataToSend.append('customerName',this.selectedCar.username)

    this.service.postApi('cars/update-status', formDataToSend).subscribe((res: any) => {
      if (res.status == 200) {
        this.showModal = 'none'
        this.showPurchasedModal = 'none'
        this.showRejectModal = 'none';
        this.service.CarData.next(this.selectedCar.id)
        this.service.hideSpinner();
        this.service.showSuccess(res.message);
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message)
      }
    })

  }

  formatAmount() {
    let value = this.purchaseAmount
    if (value !== null) {
      value = value.toString().replaceAll('$', '');
      value = value.toString().replaceAll(',', '');
      const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD', // Use USD as the currency symbol (United States)
      }).format(Number(value));
      this.purchaseAmount = formattedValue
    }
    else {
      this.purchaseAmount = ''
    }
  }
  //accept only numbers in input
  numberOnly(event: any) {
    console.log("🚀 ~ UpdateCarStatusComponent ~ numberOnly ~ event:", event)
    return this.service.numberOnly(event);
  }
 
  showImages(index: number) {
    this.orderedImages = this.selectedCar.proof.slice(index).concat(this.selectedCar.proof.slice(0, index));
    this.displayModal = true;
  }
  closeModal() {
    this.displayModal = false;
  }
}
