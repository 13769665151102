import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-filter-topbar',
  templateUrl: './filter-topbar.component.html',
  styleUrls: ['./filter-topbar.component.scss']
})
export class FilterTopbarComponent {
  public yearList: number[] = [];
  public filterForm!: FormGroup;


  constructor(private service: CommonService, private fb: FormBuilder) {
    this.intiliazeFilterForm();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 9; i <= currentYear; i++) {
      this.yearList.push(i);
    }
  }

  intiliazeFilterForm() {
    this.filterForm = this.fb.group({
      carName: [''],
      modelYear: [''],
      modelName: [''],
      mileage: [''],
      sellingPrice: ['']
    });
  }
  
  filterCar() {
  }
}
