<div id="car-rejected" tabindex="-1" aria-hidden="true"
    class="justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75 "
    [ngStyle]="{display: displayStyle}">
    <div class="relative w-full max-w-[564px] max-h-full">
        <div class="relative agency-popup-bg bg-white rounded-lg shadow  bg-popup secondary-font overflow-hidden">
            <div class="flex flex-wrap">
                <div class="w-full bg-[url(assets/img/popup-head-bg.png)] bg-no-repeat bg-cover bg-blend-multiply p-10">
                    <div class="model-bidding">
                        <div class="w-full">
                            <div class="text-white text-2xl font-medium uppercase text-center">Pay Now</div>
                        </div>
                    </div>
                </div>
                <div class="w-full p-8 bg-white">
                    <div id="container" class="h-auto w-full"></div>
                    <div class="flex justify-center items-center gap-4 flex-wrap mt-4">
                        <div class="text-lg bg_primary rounded-full text-white font-medium px-10 py-2 inline-block border-primary-color border hover:bg-white hover:text-[#004AAD] cursor-pointer mr-1"
                            (click)="displayStyle = 'none'">Cancel</div>
                        <div class="text-lg bg_primary rounded-full text-white font-medium px-10 py-2 inline-block border-primary-color border hover:bg-white hover:text-[#004AAD] cursor-pointer"
                            (click)="submitPayment()">Pay</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>