import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl } from '../global.variables';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, private service: CommonService) { }


  isAuthenticated() {
    let user = null;
    let token = '';
    // Check if there are any cookies
    const cookiesString = this.service.isBrowserPlateformId() ? document.cookie.trim() : '';
    if (cookiesString !== "") {
      const cookies = cookiesString.split(';');
      let cookieFound = false;
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('user' + '=')) {
          let userData = cookie.split('=')[1];
          user = userData ? JSON.parse(userData) : {};
          
          let localUserData = {
            id: user.id,
            parentUserId: user.parentUserId,
            username: user.username,
            email: user.email,
            phone:user.phone,
            city: user.city,
            state: user.state,
            country: user.country,
            zipCode: user.zipCode,
            role: user.role,
            isAdminLoggedIn: 1
          };

          if(this.service.isBrowserPlateformId()){
            localStorage.setItem('user', JSON.stringify(localUserData));
            localStorage.setItem('token', user.adminToken);
            
          }
         
          break;
        }
      }
      // If no user data found in cookies, check localStorage
      if(this.service.isBrowserPlateformId()){
          token =  localStorage.getItem('token') || ''
          if (!cookieFound && localStorage.getItem('user')) {
            user = JSON.parse(`${localStorage.getItem('user')}`);
    
          }
      }
      
      const data = {
        token: localStorage.getItem('token'),
        userId: user?.id,
        role: user?.role,
        isAdminLoggedIn: user?.isAdminLoggedIn ? user?.isAdminLoggedIn : 0
      };
      return this.http.post(apiUrl + 'auths/validate-token', data);
    }
    else{
      if(this.service.isBrowserPlateformId()){
        user = JSON.parse(`${localStorage.getItem('user')}`);
        token = localStorage.getItem('token') || ''
      }
     
      const data = {
        token: token,
        userId: user?.id,
        role: user?.role,
        isAdminLoggedIn: user?.isAdminLoggedIn ? user?.isAdminLoggedIn : 0
      };
      return this.http.post(apiUrl + 'auths/validate-token', data);
    }
   
  }

}
