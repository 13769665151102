import { Component } from '@angular/core';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})

export class PopupComponent {
  public pickupData:any;
  public showSummaryModal = false
  public modalId= 'schedule-pickdrop1'
  scheduleTime: string = ''
  constructor(private service: CommonService) {
    this.service.summary.subscribe((res:any) => {
       this.pickupData = res;
       this.scheduleTime = this.formatTime(this.pickupData?.scheduleDate)
        	// if(res.type){
              this.showSummaryModal = true;
              this.clickOutSideModal();
        	// } 
    })
  }

  ngOnInit(): void {    
    
  }
  hideModal(){
    this.showSummaryModal = false

  }

  formatTime(timeString: string): string {
    // Create a Date object from the time string
    // const date = new Date(timeString);
    // Extract hours and minutes
    const time = timeString.split('T')[1].split('.')[0]

    console.log('Hours:', time);
    const hoursArr = time.split(':')
    let hours = parseInt(hoursArr[0])
    let minutes = parseInt(hoursArr[1])
        
    // Determine AM or PM
    const amPm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Hour '0' should be '12'

    // Ensure minutes is a number and format it with leading zero if needed
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();

    // Return formatted time
    return `${hours}:${formattedMinutes} ${amPm}`;
    
}
  clickOutSideModal() {    
    if(this.service.isBrowserPlateformId()){
      window.addEventListener('click', (event) => {
        if (event.target === document.getElementById(this.modalId)) {
          this.showSummaryModal = false
        }
      });
    }
   
  }
}
