import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { WalletService } from './wallet.service';

@Component({
  selector: 'app-kargone-wallet',
  templateUrl: './kargone-wallet.component.html',
  styleUrls: ['./kargone-wallet.component.scss']
})
export class KargoneWalletComponent implements OnInit {
  public user:any;
  public wallet: any;
  public alertAmount=0;
  public alertWarning:boolean=false;
  public alertModal :string = 'none';
  public creditModal:string = 'none';
  public creditForm !:FormGroup;
  public isSubmit:boolean = false;
  public amountPerCredit:number=0;
  public totalPayableAmount=0
  constructor(private service: CommonService, private fb: FormBuilder, private router: Router,private walletService:WalletService) {
    if(this.service.isBrowserPlateformId()){
      const localUser = localStorage.getItem('user')
      if(localUser){
        this.user = JSON.parse(localUser)
      }
    }
    this.initializeForm();
    this.getWallet();
    this.getCreditPrice();
  }

  initializeForm(){
    this.creditForm = this.fb.group({
      credits:['',Validators.required],
      paymentType:['card']
    })
  }
get f(){
  return this.creditForm.controls
}
  ngOnInit(): void {
    if(this.service.isBrowserPlateformId()){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      window.addEventListener('click', (event) => {
        if (event.target === document.getElementById('payment-way') || event.target === document.getElementById('alertAmount-model') ) {
          this.creditModal = 'none'
          this.alertModal = 'none';
        }
      });
    }
    this.service.walletData.subscribe(res=>{
      if(res){
        this.getWallet();
      }
    })
  }
  getCreditPrice() {

    this.service.getApi(`wallets/get-creditprice`).subscribe((res: any) => {
      if (res.status == 200) {
        this.amountPerCredit = res.kargoneCredit.creditValue
        // this.service.showSuccess(res.message)
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
    
  }
  getWallet(){
    let data = {
      userId:this.user.id,
      parentUserId: this.user.parentUserId ? this.user.parentUserId : 0
    }
    this.walletService.postApi(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.wallet = res.wallet[0];
        this.alertAmount = this.wallet.alertAmount
        this.alertWarning = this.wallet.amount<=this.wallet.alertAmount ? true : false
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }

  showAlertModal(){
    this.alertModal = 'flex';
  }

  showCreditModal(){    
    this.creditModal = 'flex';
  }

  updateWallet(){
    let data ={
      userId: this.user.parentUserId >0 ?this.user.parentUserId : this.user.id,
      alertAmount : this.alertAmount
    }
    this.service.postApi(`wallets/update-wallet`,data).subscribe((res: any) => {
      if (res.status == 200) {
        this.alertModal = 'none';
        // this.wallet = res.wallet[0]
        this.getWallet()
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }

  numberOnly(event:any){
    return this.service.numberOnly(event)
  }
  hideModal(){
    this.alertWarning=false;
    this.alertModal  = 'none';
    this.creditModal = 'none';
  }
  calculateAmount(){
    const calculatedAmount =
    ((this.f.credits.value !== '' ? Number(this.f.credits.value) : 0) * 6) +
    this.amountPerCredit * (this.f.credits.value !== '' ? Number(this.f.credits.value) : 0);
    this.totalPayableAmount = Math.ceil(calculatedAmount)
  }

  selectPaymentType(){  
    // use this percentage amount instead of 6 
    // (0.02 * amountPerCredit)
    const calculatedAmount = ((this.f.credits.value !== '' ? Number(this.f.credits.value) : 0) * 6) +
    this.amountPerCredit * (this.f.credits.value !== '' ? Number(this.f.credits.value) : 0);
    if(this.creditForm.invalid){
      this.isSubmit = true;
      return 
    }     
    this.creditModal = 'none';
    const data = {
      type: this.f.paymentType.value,
      amount: Math.ceil(calculatedAmount),
      credit:Number(this.f.credits.value),
      user: this.user,
      amountPerCredit:this.amountPerCredit
    }
    this.service.paymentType.next(data);
  }
}
