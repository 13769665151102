import { NgModule } from '@angular/core';
import { NotificationComponent } from './notification/notification.component';
// import { AccountHistoryComponent } from './account-history/account-history.component';
import { CarSubmissionComponent } from './car-submission/car-submission.component';
import { DealerListComponent } from './dealer-list/dealer-list.component';
import { RatingComponent } from './rating/rating.component';
import { MyaccountRoutingModule } from './myaccount-routing.module';
import { SellerSidebarComponent } from './seller-sidebar/seller-sidebar.component';
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared.module';
import { DealerAccountModule } from '../dealeraccount/dealeraccount.module';

@NgModule({
  declarations: [
    NotificationComponent,
    // AccountHistoryComponent,
    CarSubmissionComponent,
    DealerListComponent,
    RatingComponent,
    SellerSidebarComponent,
  ],
  imports: [
    MyaccountRoutingModule,
    SharedModule,
    ComponentsModule ,
    DealerAccountModule
  ],
  exports: [
    SellerSidebarComponent,
    NotificationComponent,
    // AccountHistoryComponent,
    CarSubmissionComponent,
    DealerListComponent,
    RatingComponent,
  ],
})
export class MyaccountModule { }
