import { Component } from '@angular/core';

@Component({
  selector: 'app-dealer-offer',
  templateUrl: './dealer-offer.component.html',
  styleUrls: ['./dealer-offer.component.scss']
})
export class DealerOfferComponent {

}
