<div 
  class="main-section container flex flex-wrap bg-cover min-h-screen items-center justify-center min-w-full"
  [ngStyle]="{
    'background-color': '#000',
    'background-image':  'url(../../../assets/img/home-banner1.avif)'
  }">
  <div class="z-50 flex-1 px-6 mt-20 xl:mt-10 md:mb-0 mb-14 flex">
    <div class="block text-center m-auto">
      <ng-container *ngIf="sell_car_promo_message !== ''; else loading">
        <h2 class="max-w-screen-md mx-auto xl:text-4xl md:text-3xl sm:text-2xl text-white font-bold" [innerHtml]="sell_car_promo_message"></h2>
        <p class="text-white xl:text-xl text-lg font-semibold" [innerHtml]="trusted_sellers_count"></p>
      </ng-container>
      <ng-template #loading>
        <h2 class="max-w-screen-md mx-auto xl:text-4xl text-3xl text-transparent font-bold bg-transparent animate-pulse h-8 mb-4"></h2>
        <p class="text-transparent xl:text-xl text-lg font-semibold bg-transparent animate-pulse h-6"></p>
      </ng-template>
      <div class="form xl:mt-10 mt-6">
        <app-search-car></app-search-car>
      </div>
    </div>
  </div>
</div>
