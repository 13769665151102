<app-sub-header></app-sub-header>

<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4">
                <input type="checkbox"  class="openSidebarMenu" id="openSidebarMenu">
                <label for="openSidebarMenu" class="sidebarIconToggle">
                    <div class="spinner diagonal part-1"></div>
                    <div class="spinner horizontal"></div>
                    <div class="spinner diagonal part-2"></div>
                </label>
                <div class="z-2 xl:w-3/12 min-[993px]:w-5/12 w-full px-4 mb-10 min-[992px]:mb-0 hidden lg:block" id="sidebar_menu">
            <app-sidebar></app-sidebar>
        </div>

            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
                    <div class="block-area border bg-white border-[#E3E3E3] rounded-lg overflow-hidden">
                        <div
                            class="bg_primary px-4 py-4 text-white font-bold text-2xl text-center codec-font leading-none">
                            Low Credit alert</div>
                        <div class="relative text-center pt-10 pb-6 space-y-8">
                            <div class="w-full text_primary_color font-bold md:text-6xl text-5xl text-center ">
                                {{wallet?.alertAmount}}</div>
                            <div class="w-[90%] py-2 px-2 bg_primary rounded-full md:text-xl text-lg font-medium inline-block text-center text-white cursor-pointer"
                                data-modal-target="alertAmount-model" data-modal-toggle="alertAmount-model"
                                (click)="showAlertModal()">Update Alert</div>
                        </div>

                    </div>
                    <div class="block-area border bg-white border-[#E3E3E3] rounded-lg overflow-hidden">
                        <div
                            class="bg_primary px-4 py-4 text-white font-bold text-2xl text-center codec-font leading-none">
                            Balance in your account</div>
                        <div class="relative text-center pt-10 pb-6 space-y-8">
                            <div class="w-full  font-bold md:text-6xl text-5xl text-center "
                                [ngClass]="{'text-red': alertWarning,'text_primary_color': !alertWarning}">
                                {{wallet?.amount}}</div>
                            <div class="w-[90%] py-2 px-2 bg_primary rounded-full md:text-xl text-lg font-medium inline-block text-center text-white cursor-pointer"
                                data-modal-target="payment-way" data-modal-toggle="payment-way"
                                (click)="showCreditModal()">Purchase Kargone Credits</div>
                        </div>
                    </div>
                </div>
            </div>
      
        </div>
      </div>
</section>



<div id="alertAmount-model" tabindex="-1" aria-hidden="true"
    class="justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75 hidden"
    [ngStyle]="{display: alertModal}">
    <div class="relative w-full max-w-[610px] max-h-full">
        <!-- Modal content -->
        <div class="relative agency-popup-bg bg-white rounded-lg shadow  bg-popup secondary-font overflow-hidden">
            <!-- Modal body -->
            <div class="flex flex-wrap">
                <div class="w-full bg-[url(assets/img/popup-head-bg.png)] bg-no-repeat bg-cover bg-blend-multiply p-10">
                    <div class="model-bidding">
                        <div class="w-full">
                            <div class="text-white text-2xl font-medium uppercase text-center">Confirm Minimum Balance
                            </div>
                            <button type="button" class="absolute top-3 end-2.5 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" (click)="hideModal()">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button> 
                        </div>
                    </div>
                </div>
                <div class="w-full p-8 bg-white ">
                    <label class="block mb-6 md:px-4">
                        <input type="text"
                            class="block w-full p-3.5 text-sm input-shadow border-0 outline-0 rounded-full mb-4 md:mb-0 text-center"
                            required [(ngModel)]="alertAmount">
                    </label>


                    <div class="text-center my-2">
                        <!-- <div class="text-lg bg_primary rounded-full text-white font-medium px-10 py-2 inline-block border-primary-color border hover:bg-white hover:text-[#004AAD] cursor-pointer mr-1"
                                >Cancel</div> -->
                        <div class="text-lg bg_primary rounded-full text-white font-medium px-10 py-2 inline-block border-primary-color border hover:bg-white hover:text-[#004AAD] cursor-pointer"
                            (click)="updateWallet()" data-modal-hide="alertAmount-model">Submit</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div id="payment-way" tabindex="-1" aria-hidden="true"
    class="justify-center items-center  fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75 "
    [ngStyle]="{display: creditModal}">
    <div class="relative w-full max-w-md max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow">
            <!-- Modal header -->
            <div class="flex items-start justify-center p-4 rounded-t-lg bg_primary">
                <h3 class="text-2xl font-medium text-white text-center secondary-font ">
                    Select Your Payment Way
                </h3>
                <button type="button" class="absolute top-3 end-2.5 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" (click)="hideModal()">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button> 
            </div>
            <!-- Modal body -->

            <div class="w-full p-8 bg-white">
                <label class="flex justify-between mb-2">
                    <!-- <div class="">Total Amount : {{amountPerCredit * (f['credits'].value != '' ? f['credits'].value: 0 ) }} </div> -->
                    <div>1 Credit = ${{amountPerCredit}}</div>
                </label>
                <form [formGroup]="creditForm">

                    <div class="mb-6 flex items-center justify-center">
                        <div class="w-1/2 mr-2">

                            <input type="text" formControlName="credits"
                                class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md  placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-black"
                                placeholder="No of credits" (keypress)="numberOnly($event)" (input)="calculateAmount()" />
                            <div *ngIf="f['credits'].invalid && (f['credits'].dirty || f['credits'].touched || isSubmit)"
                                class="error-message">
                                <div *ngIf="f['credits'].errors?.['required']">*Please enter no of credit.</div>
                            </div>
                        </div>
                        <div class="mt-1 md:h-14 h-12 block w-1/2 bg-white rounded-md placeholder-[#777777] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0  m-auto  pt-4 text-center text-black">
                            ${{amountPerCredit * (f['credits'].value != '' ? f['credits'].value: 0 ) }}
                        </div>
                    </div>
                        
                        <div class="mb-6 flex text-black">
                            <div >Initial cost</div>
                            <div class="ml-auto">${{amountPerCredit * (f['credits'].value != '' ? f['credits'].value: 0 ) }}</div>
                        </div>
                        <div class="mb-6 flex text-black">
                            <div>{{f['paymentType'].value == 'ach' ? 'Transaction' :'Processing'}} Fees($6 Per Credit)</div>
                            <div class="ml-auto">${{((f['credits'].value != '' ? f['credits'].value: 0 )*6)}}</div>
                        </div>
                        <hr class="mb-3">
                        <div class="mb-4 flex text-black">
                            <div class="font-medium"> Total Fees</div>
                            <div class="ml-auto font-medium">${{totalPayableAmount}}</div>
                        </div>
                        <hr>
                    <div class="flex items-center justify-center mt-4">

                        <div class="flex items-center custom-radio-blue mr-8">
                            <input id="default-radio-2" type="radio" value="card" name="paymentType"
                                formControlName="paymentType">
                            <label for="default-radio-2"
                                class="ml-2 text-lg font-medium text-black custom-label cursor-pointer">Credit
                                Card</label>
                        </div>
                        <div class="flex items-center custom-radio-blue" *ngIf="wallet?.dealerType=='group' && wallet?.parentUserId == wallet?.userId">
                            <input id="default-radio-1" type="radio" value="ach" name="paymentType"
                                formControlName="paymentType">
                            <label for="default-radio-1"
                                class="ml-2 text-lg font-medium text-black custom-label cursor-pointer">ACH</label>
                        </div>

                    </div>

                </form>
            </div>
            <!-- Modal footer -->
            <div class="flex justify-center  pb-8 ">
                <button type="button"
                    class="text-white bg_primary font-medium rounded-full  text-xl px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD]"
                    (click)="selectPaymentType()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div>
    <app-payment></app-payment>
</div>