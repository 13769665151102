<div id="bidding-popup-deal" tabindex="-1" aria-hidden="true"
    class="justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75 hidden"
    [ngStyle]="{display: showModel}">
    <div class="relative w-full max-w-[610px] max-h-full">
        <!-- Modal content -->
        <div class="relative agency-popup-bg bg-white rounded-lg shadow  bg-popup secondary-font overflow-hidden">
            <!-- Modal body -->
            <div class="flex flex-wrap">
                <div
                    class="w-full bg-[url(assets/img/popup-head-bg.png)] bg-no-repeat bg-cover bg-blend-multiply px-10 py-8">
                    <div class="model-bidding flex flex-wrap">
                        <div class="sm:w-7/12 w-full space-y-1">
                            <div class="text-white text-xl font-medium uppercase">{{selectedCar?.modelYear}}
                                {{selectedCar?.carName}} {{selectedCar?.modelName}}</div>
                            <div class="text-white font-medium text-base">
                                <span class="w-4 h-4 inline-block mr-1">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                                        style="enable-background:new 0 0 100 100;" xml:space="preserve"
                                        fill="currentColor">
                                        <g>
                                            <path d="M49.3,96.2c-1.5-1.3-3-2.4-4.4-3.8c-7.9-7.8-15-16.3-20.7-26c-3.6-6.2-6.5-12.7-8-19.8c-3.6-16.7,4.6-33.2,20.2-40.1
                                            C50.5,0.4,67.5,4.7,77.2,17c5.5,7,8,15.1,7.5,24c-0.4,6.5-2.4,12.6-5.1,18.5C75.1,68.9,69.1,77.2,62.2,85c-3,3.3-6.2,6.5-9.3,9.7
                                            c-0.6,0.6-1.4,1.1-2.1,1.6C50.3,96.2,49.8,96.2,49.3,96.2z M69.2,38.4c0-10.6-8.6-19.2-19.2-19.2c-10.6,0-19.3,8.7-19.2,19.3
                                            c0.1,10.6,8.7,19.2,19.3,19.2C60.7,57.6,69.3,49,69.2,38.4z" />
                                        </g>
                                    </svg>
                                </span> {{selectedCar?.city}} , {{selectedCar?.state}}
                            </div>
                            <div class="model-info text-white">
                                <span
                                    class="relative pl-0 px-4 after:absolute after:w-[5px] after:h-[5px] after:rounded-full after:bg-white after:right-0 after:top-[9px]">{{selectedCar?.color}}
                                </span>
                                <span class="relative px-4"> {{selectedCar?.mileage}} miles</span>

                            </div>
                        </div>
                        <div class="sm:w-5/12 w-full sm:text-right mt-4 sm:mt-0">
                            <div
                                class="inline-block bg-white rounded-full px-10 py-2 text_primary_color text-xl font-medium">
                                Offer Now</div>
                        </div>
                    </div>
                </div>
                <div class="w-full sm:pt-10 sm:pb-8 py-8 sm:px-10 px-10 bg-white">
                    <!-- <form class="form"> -->
                        <!-- <div class="relative text-center">
                            <p class="text-red font-bold mb-4" *ngIf="isWarning">Your credit amount is low. Please add amount.</p>
                        </div> -->

                    <div class="relative text-center">
                        <input type="search" id="search"
                            class="block w-full p-3.5 pl-10 md:pr-44 text-sm input-shadow border-0 outline-0 rounded-full text-black mb-4 md:mb-0"
                            placeholder="Enter your offer amount" required [(ngModel)]="bidAmount" (keypress)="numberOnly($event)" (input)="formatBidAmount1()">
                        <button type="submit"
                            class="text-white sm:absolute right-0 bottom-0 bg_primary focus:ring-0 focus:outline-none  font-medium rounded-full md:text-xl text-lg px-10 py-2 h-full"
                            (click)="offerBid()" data-modal-hide="bidding-popup-deal" [disabled]="bidAmount<0 || !bidAmount">Offer Now</button>
                    </div>
                    <!-- </form> -->
                    <div class="flex justify-center mt-5">
    
                        <button type="button" class="text-black  font-medium rounded-full  md:text-xl text-lg px-12 py-2 text-center outline-0 border border-primary-color hover:bg-white hover:text-[#004AAD]" (click)="hideModal()" >Cancel</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>