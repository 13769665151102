<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name" class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center" >
        <div class="container mx-auto  ">
            <div class="max-w-screen-sm	mx-auto z-50  flex-1 px-6 ">
                <div class="block text-center">
                    <h1 class="md:text-5xl text-4xl text-white font-bold ">My Account</h1>       
                </div>
            </div>
        </div>
    </div>
</div>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <app-filter-topbar></app-filter-topbar>
        <div class="md:flex md:flex-wrap -mx-4">          
            <app-sidebar class="z-2 xl:w-3/12 md:w-5/12 w-full px-4 mb-10 md:mb-0 hidden md:block"></app-sidebar>
            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="grid grid-cols-1 gap-x-8 gap-y-10">
                    <div class="car-data-box bg-white shadow-md p-4 flex flex-wrap rounded-md items-start relative">
                        <div class="close-deal-badge bg-[url('assets/img/red-ribbon.png')] bg-no-repeat w-48 h-14 bg-contain absolute  top-10 -left-[7px] z-10 text-lg text-white font-medium px-6 py-1.5">
                            About to close
                        </div>
                        <div class="xl:w-4/12 w-full data-img text-center rounded-md overflow-hidden relative after after:absolute after:w-full after:h-full after:top-0 after:left-0 after:opacity-20 after:bg-black ">
                            <img src="assets/img/car-list5.png" alt="Car Name" class="inline-block w-full">
                            
                        </div>
                        <div class="relative z-10 xl:w-8/12 w-full xl:px-4 px-0 py-4 xl:py-0">
                            <div class="car-data-head md:flex justify-end mb-4">
                                <div class="left-block">
                                    <div class="model-car-year text-xl font-medium mb-1">DODGE VIPER 2015</div>
                                    <div class="model-car-location grey_text_500 text-base font-medium"><span class="w-4 h-4 inline-block"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="#00459f">
                                   <g>
                                       <path d="M49.3,96.2c-1.5-1.3-3-2.4-4.4-3.8c-7.9-7.8-15-16.3-20.7-26c-3.6-6.2-6.5-12.7-8-19.8c-3.6-16.7,4.6-33.2,20.2-40.1
                                           C50.5,0.4,67.5,4.7,77.2,17c5.5,7,8,15.1,7.5,24c-0.4,6.5-2.4,12.6-5.1,18.5C75.1,68.9,69.1,77.2,62.2,85c-3,3.3-6.2,6.5-9.3,9.7
                                           c-0.6,0.6-1.4,1.1-2.1,1.6C50.3,96.2,49.8,96.2,49.3,96.2z M69.2,38.4c0-10.6-8.6-19.2-19.2-19.2c-10.6,0-19.3,8.7-19.2,19.3
                                           c0.1,10.6,8.7,19.2,19.3,19.2C60.7,57.6,69.3,49,69.2,38.4z"/>
                                   </g>
                                   </svg>
                                   </span> Raleigh , NC</div>
                                </div>
                               <div class="session-time ml-auto text-red text-base font-semibold">59 minutes left</div>
                            </div>
                            <div class="model-car-info font-medium text-base space-y-1.5 p-0">
                                <div class="flex flex-wrap gap-y-1 ">
                                    <div class="text-base grey_text_500 lg:w-6/12 w-full">
                                        <span class="text-black mr-2">Dealership Name:   </span>Car dealer
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Highest Offer:   </span><span class="text-green font-semibold"> $30,000</span>
                                    </div>                                
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Employee Name: </span> Johnson
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">My Offer :   </span><span class="text-green font-semibold"> $20,000</span>
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Mileage: </span> 35,000 KM
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Expected Offer :   </span><span class=""> $20,000</span>
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Color: </span> White
                                    </div>
                                </div>
                            </div>
                            <div class="model-action md:flex flex-wrap gap-4 justify-end text-base sm:text-lg font-medium mt-4 text-center space-y-4 md:space-y-0">
                                <a href="" class="bg-orange rounded-full px-10 py-2 text-white block">History</a> 
                                <a routerLink="/dealer/view-car-info" class="bg-green rounded-full px-6 py-2 text-white block">View Car Info</a> 
                                <a href="" class="bg_primary rounded-full px-6 py-2 text-white block">Update Bid</a> 
                            </div>
                        </div>
                    </div>
                    <div class="car-data-box bg-white shadow-md p-4 flex flex-wrap rounded-md items-start relative">
                        <div class="close-deal-badge bg-[url('assets/img/yellow-ribbon.png')] bg-no-repeat w-48 h-14 bg-contain absolute  top-10 -left-[10px] z-10 text-lg text-black font-medium px-6 py-1.5">
                            Losing your Deal
                        </div>
                        <div class="xl:w-4/12 w-full data-img text-center rounded-md overflow-hidden relative after after:absolute after:w-full after:h-full after:top-0 after:left-0 after:opacity-20 after:bg-black ">
                            <img src="assets/img/car-list5.png" alt="Car Name" class="inline-block w-full">
                        </div>
                        <div class="relative z-10 xl:w-8/12 w-full xl:px-4 px-0 py-4 xl:py-0">
                            <div class="car-data-head md:flex justify-end mb-4">
                                <div class="left-block">
                                    <div class="model-car-year text-xl font-medium mb-1">DODGE VIPER 2015</div>
                                    <div class="model-car-location grey_text_500 text-base font-medium"><span class="w-4 h-4 inline-block"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="#00459f">
                                   <g>
                                       <path d="M49.3,96.2c-1.5-1.3-3-2.4-4.4-3.8c-7.9-7.8-15-16.3-20.7-26c-3.6-6.2-6.5-12.7-8-19.8c-3.6-16.7,4.6-33.2,20.2-40.1
                                           C50.5,0.4,67.5,4.7,77.2,17c5.5,7,8,15.1,7.5,24c-0.4,6.5-2.4,12.6-5.1,18.5C75.1,68.9,69.1,77.2,62.2,85c-3,3.3-6.2,6.5-9.3,9.7
                                           c-0.6,0.6-1.4,1.1-2.1,1.6C50.3,96.2,49.8,96.2,49.3,96.2z M69.2,38.4c0-10.6-8.6-19.2-19.2-19.2c-10.6,0-19.3,8.7-19.2,19.3
                                           c0.1,10.6,8.7,19.2,19.3,19.2C60.7,57.6,69.3,49,69.2,38.4z"/>
                                   </g>
                                   </svg>
                                   </span> Raleigh , NC</div>
                                </div>
                               <div class="session-time ml-auto text-green text-base font-semibold">1 Day Left</div>
                            </div>
                            <div class="model-car-info font-medium text-base space-y-1.5 p-0">
                                <div class="flex flex-wrap gap-y-1 ">
                                    <div class="text-base grey_text_500 lg:w-6/12 w-full">
                                        <span class="text-black mr-2">Dealership Name:   </span>Car dealer
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Highest Offer:   </span><span class="text-green font-semibold"> $30,000</span>
                                    </div>                                
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Employee Name: </span> Johnson
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">My Offer :   </span><span class="text-orange font-semibold"> $20,000</span>
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Mileage: </span> 35,000 KM
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Expected Offer :   </span><span class=""> $20,000</span>
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Color: </span> White
                                    </div>
                                </div>
                            </div>
                            <div class="model-action md:flex flex-wrap gap-4 justify-end text-base sm:text-lg font-medium mt-4 text-center space-y-4 md:space-y-0">
                                <a href="" class="bg-orange rounded-full px-10 py-2 text-white block">History</a> 
                                <a routerLink="/dealer/view-car-info" class="bg-green rounded-full px-6 py-2 text-white block">View Car Info</a> 
                                <a href="" class="bg_primary rounded-full px-6 py-2 text-white block">Update Bid</a> 
                            </div>
                        </div>
                    </div>
                    <div class="car-data-box bg-white shadow-md p-4 flex flex-wrap rounded-md items-start relative">
                        
                        <div class="xl:w-4/12 w-full data-img text-center rounded-md overflow-hidden relative after after:absolute after:w-full after:h-full after:top-0 after:left-0 after:opacity-20 after:bg-black ">
                            <img src="assets/img/car-list5.png" alt="Car Name" class="inline-block w-full">
                            
                        </div>
                        <div class="relative z-10 xl:w-8/12 w-full xl:px-4 px-0 py-4 xl:py-0">
                            <div class="car-data-head md:flex justify-end mb-4">
                                <div class="left-block">
                                    <div class="model-car-year text-xl font-medium mb-1">DODGE VIPER 2015</div>
                                    <div class="model-car-location grey_text_500 text-base font-medium"><span class="w-4 h-4 inline-block"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="#00459f">
                                   <g>
                                       <path d="M49.3,96.2c-1.5-1.3-3-2.4-4.4-3.8c-7.9-7.8-15-16.3-20.7-26c-3.6-6.2-6.5-12.7-8-19.8c-3.6-16.7,4.6-33.2,20.2-40.1
                                           C50.5,0.4,67.5,4.7,77.2,17c5.5,7,8,15.1,7.5,24c-0.4,6.5-2.4,12.6-5.1,18.5C75.1,68.9,69.1,77.2,62.2,85c-3,3.3-6.2,6.5-9.3,9.7
                                           c-0.6,0.6-1.4,1.1-2.1,1.6C50.3,96.2,49.8,96.2,49.3,96.2z M69.2,38.4c0-10.6-8.6-19.2-19.2-19.2c-10.6,0-19.3,8.7-19.2,19.3
                                           c0.1,10.6,8.7,19.2,19.3,19.2C60.7,57.6,69.3,49,69.2,38.4z"/>
                                   </g>
                                   </svg>
                                   </span> Raleigh , NC</div>
                                </div>
                               <div class="session-time ml-auto text-red text-base font-semibold">59 minutes left</div>
                            </div>
                            <div class="model-car-info font-medium text-base space-y-1.5 p-0">
                                <div class="flex flex-wrap gap-y-1 ">
                                    <div class="text-base grey_text_500 lg:w-6/12 w-full">
                                        <span class="text-black mr-2">Dealership Name:   </span>Car dealer
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Highest Offer:   </span><span class="text-green font-semibold"> $30,000</span>
                                    </div>                                
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Employee Name: </span> Johnson
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">My Offer :   </span><span class="text-green font-semibold"> $20,000</span>
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Mileage: </span> 35,000 KM
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Expected Offer :   </span><span class=""> $20,000</span>
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Color: </span> White
                                    </div>
                                </div>
                            </div>
                            <div class="model-action md:flex flex-wrap gap-4 justify-end text-base sm:text-lg font-medium mt-4 text-center space-y-4 md:space-y-0">
                                <a href="" class="bg-orange rounded-full px-10 py-2 text-white block">History</a> 
                                <a routerLink="/dealer/view-car-info" class="bg-green rounded-full px-6 py-2 text-white block">View Car Info</a> 
                                <a href="" class="bg_primary rounded-full px-6 py-2 text-white block">Update Bid</a> 
                            </div>
                        </div>
                    </div>
                    <div class="car-data-box bg-white shadow-md p-4 flex flex-wrap rounded-md items-start relative">
                        
                        <div class="xl:w-4/12 w-full data-img text-center rounded-md overflow-hidden relative after after:absolute after:w-full after:h-full after:top-0 after:left-0 after:opacity-20 after:bg-black ">
                            <img src="assets/img/car-list5.png" alt="Car Name" class="inline-block w-full">
                        </div>
                        <div class="relative z-10 xl:w-8/12 w-full xl:px-4 px-0 py-4 xl:py-0">
                            <div class="car-data-head md:flex justify-end mb-4">
                                <div class="left-block">
                                    <div class="model-car-year text-xl font-medium mb-1">DODGE VIPER 2015</div>
                                    <div class="model-car-location grey_text_500 text-base font-medium"><span class="w-4 h-4 inline-block"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="#00459f">
                                   <g>
                                       <path d="M49.3,96.2c-1.5-1.3-3-2.4-4.4-3.8c-7.9-7.8-15-16.3-20.7-26c-3.6-6.2-6.5-12.7-8-19.8c-3.6-16.7,4.6-33.2,20.2-40.1
                                           C50.5,0.4,67.5,4.7,77.2,17c5.5,7,8,15.1,7.5,24c-0.4,6.5-2.4,12.6-5.1,18.5C75.1,68.9,69.1,77.2,62.2,85c-3,3.3-6.2,6.5-9.3,9.7
                                           c-0.6,0.6-1.4,1.1-2.1,1.6C50.3,96.2,49.8,96.2,49.3,96.2z M69.2,38.4c0-10.6-8.6-19.2-19.2-19.2c-10.6,0-19.3,8.7-19.2,19.3
                                           c0.1,10.6,8.7,19.2,19.3,19.2C60.7,57.6,69.3,49,69.2,38.4z"/>
                                   </g>
                                   </svg>
                                   </span> Raleigh , NC</div>
                                </div>
                               <div class="session-time ml-auto text-green text-base font-semibold">1 Day Left</div>
                            </div>
                            <div class="model-car-info font-medium text-base space-y-1.5 p-0">
                                <div class="flex flex-wrap gap-y-1 ">
                                    <div class="text-base grey_text_500 lg:w-6/12 w-full">
                                        <span class="text-black mr-2">Dealership Name:   </span>Car dealer
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Highest Offer:   </span><span class="text-green font-semibold"> $30,000</span>
                                    </div>                                
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Employee Name: </span> Johnson
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">My Offer :   </span><span class="text-orange font-semibold"> $20,000</span>
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Mileage: </span> 35,000 KM
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Expected Offer :   </span><span class=""> $20,000</span>
                                    </div>
                                    <div class="text-base grey_text_500 lg:w-6/12  w-full">
                                        <span class="text-black mr-2">Color: </span> White
                                    </div>
                                </div>
                            </div>
                            <div class="model-action md:flex flex-wrap gap-4 justify-end text-base sm:text-lg font-medium mt-4 text-center space-y-4 md:space-y-0">
                                <a href="" class="bg-orange rounded-full px-10 py-2 text-white block">History</a> 
                                <a routerLink="/dealer/view-car-info" class="bg-green rounded-full px-6 py-2 text-white block">View Car Info</a> 
                                <a href="" class="bg_primary rounded-full px-6 py-2 text-white block">Update Bid</a> 
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>           
        </div>
    </div>
</section>