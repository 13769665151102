import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-comfirm-modal',
  templateUrl: './comfirm-modal.component.html',
  styleUrls: ['./comfirm-modal.component.scss']
})
export class ComfirmModalComponent implements OnInit {
  public messageData :any;
  public displayModal :boolean = false
  constructor(private service:CommonService){

  }
  ngOnInit(): void {    
    this.service.showMessage.subscribe((res:any)=>{
      this.displayModal = true;
      this.messageData = res;
      // setTimeout(()=>{
      //   this.displayModal = false;
      // },1500)
    })
  }

  closeModal(){
    this.displayModal = false
  }
}
