import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
// import heic2any from 'heic2any';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-car-submission',
  templateUrl: './car-submission.component.html',
  styleUrls: ['./car-submission.component.scss']
})
export class CarSubmissionComponent implements OnInit {

  public carForm!: FormGroup;
  public user: any;
  public images: Record<string, string> = {}; // Store image URLs here
  public corners: { isUploaded: boolean, isEdited: boolean, name: string, label: string, imageUrl: File[] }[] = [
    { isUploaded: false, isEdited: false, name: 'frontImage', label: 'Front Image*', imageUrl: [] },
    { isUploaded: false, isEdited: false, name: 'leftFrontCorner', label: 'Left Front Corner*', imageUrl: [] },
    { isUploaded: false, isEdited: false, name: 'rightFrontCorner', label: 'Right Front Corner*', imageUrl: [] },
    { isUploaded: false, isEdited: false, name: 'rearImage', label: 'Rear*', imageUrl: [] },
    { isUploaded: false, isEdited: false, name: 'leftRearCorner', label: 'Left Rear Corner*', imageUrl: [] },
    { isUploaded: false, isEdited: false, name: 'rightRearCorner', label: 'Right Rear Corner*', imageUrl: [] },
    { isUploaded: false, isEdited: false, name: 'odometerImage', label: 'Odometer*', imageUrl: [] },
    { isUploaded: false, isEdited: false, name: 'frontInterior', label: 'Front Interior*', imageUrl: [] },
    { isUploaded: false, isEdited: false, name: 'addOne', label: 'CarMax instore offer picture', imageUrl: [] },
    { isUploaded: false, isEdited: false, name: 'addTwo', label: 'Add On(optional)', imageUrl: [] },
    { isUploaded: false, isEdited: false, name: 'addThree', label: 'Add On(optional)', imageUrl: [] },
    { isUploaded: false, isEdited: false, name: 'addFour', label: 'Add On(optional)', imageUrl: [] }
  ];
  public isDropdownOpen = false;
  public color: string = '';
  public mileage: any;
  public carPlateNo: string = ''
  public carName: string = ''
  public modelYear: string = ''
  public modelName: string = ''
  public fuelType: string = ''
  public vinNumber: string = ''
  public carId: any;
  public isSubmit: boolean = false;
  public mileageAndColor: any;
  public carQuestions: any;
  public lastIndex: number = 0;
  public isOthersChecked: boolean = false; 

  constructor(private service: CommonService, private router: Router, private formBuilder: FormBuilder, private activateRaoute: ActivatedRoute,@Inject(PLATFORM_ID) private platformId: any) {
    
    if(this.service.isBrowserPlateformId()){
      this.user = JSON.parse(`${localStorage.getItem('user')}`)
    }
    this.activateRaoute.params.subscribe((res: any) => {
      this.carId = res.id;
      if (this.carId) {
        this.getCar();
      }
    })
    if (!this.carId) {
      this.getQuestionCategories();
    }
  }

  selectColor(item: any) {
    this.isDropdownOpen = false;
    this.color = item;
    this.carForm.get('color')?.setValue(item)
  }
  toggleDropDown() {
    this.isDropdownOpen = true;
  }
  ngOnInit(): void {
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.service.scrollToTop()
    this.corners.forEach(element => {
      switch (element.name) {
        case 'frontImage':
          this.images[element.name] = 'assets/carImages/e04.jpg';
          break;
        case 'leftFrontCorner':
          this.images[element.name] = 'assets/carImages/e06.jpg';
          break;
        case 'rightFrontCorner':
          this.images[element.name] = 'assets/carImages/e02.jpg';
          break;
        case 'rearImage':
          this.images[element.name] = 'assets/carImages/e03.jpg';
          break;
        case 'leftRearCorner':
          this.images[element.name] = 'assets/carImages/e05.jpg';
          break;
        case 'rightRearCorner':
          this.images[element.name] = 'assets/carImages/e01.jpg';
          break;
        case 'odometerImage':
          this.images[element.name] = 'assets/carImages/e08.jpg';
          break;
        case 'frontInterior':
          this.images[element.name] = 'assets/carImages/e07.jpg';
          break;
        case 'addOne':
            this.images[element.name] = 'assets/carImages/e04.jpg';
            break; 
        default:
          this.images[element.name] = 'assets/img/img-bg.png';
          break;
      }
    });
  }
  //initialize car form
  initializeForm(): void {
    const formControls: any = {};
    this.carQuestions.forEach((item: any, index: number) => {
      if (item.category.specialCategoryName != 'Referral Sources' && item.category.specialCategoryName != 'Mileage and Color') {
        this.lastIndex = 0;
      }
      if (item.category.specialCategoryName != 'Mileage and Color') {
        item.questions.forEach((question: any, qIndex: number) => {
          const questionControlName = `${question.id}`;
          if (question.descriptionField === 1) {
            const descriptionControlName = `description-${question.id}`;
            formControls[descriptionControlName] = question.descriptionRequired === 1 ? ['', Validators.required] : [''];
          }
          if (question.type === 'checkbox') {
            const checkboxControlName = `${question.id}`;
            formControls[checkboxControlName] = question.questionRequired === 1 ? [[], Validators.required] : [[]];
          } else {
            formControls[questionControlName] = question.questionRequired === 1 ? ['', Validators.required] : [''];
            if (question.type === 'radio' && question.isSubOption == 1) {
              const checkboxControlName = `subOption-${question.id}`;
              formControls[checkboxControlName] = [[]];
            }
          }
          this.lastIndex += 1;
        });
      }
    });
    if(this.mileageAndColor){
      this.mileageAndColor.forEach((element:any,index:Number)=>{
        if(index == 0){
          formControls['mileage'] = element.questionRequired === 1 ? [[], Validators.required] : [''];
        }
        if(index==1){
          formControls['color'] = element.questionRequired === 1 ? [[], Validators.required] : [''];
        }
      })
    }
    else{
    formControls['color'] = [''];
    formControls['mileage'] = [''];
    }
    formControls['carImages'] = new FormArray([]);
    formControls['carFullName'] = [''];
   
    formControls['carName'] = ['']
    formControls['modelYear'] = ['']
    formControls['modelName'] = ['']
    formControls['fuelType'] = ['']
    formControls['userId'] = ['']
    formControls['fuelType'] = ['']
    formControls['vinNumber'] = ['']
    formControls['sellingPrice'] = ['']
    formControls['carPlateNo'] = ['']

    this.carForm = this.formBuilder.group(formControls);
    if(this.service.isBrowserPlateformId()){
      
      let carData = JSON.parse(`${localStorage.getItem('carData')}`)
      if (carData) {
        this.carName = carData.carName
        this.modelYear = carData.modelYear
        this.modelName = carData.modelName
        this.fuelType = carData.fuelType
        this.carPlateNo = carData.licenseNumber;
        this.vinNumber = carData.vinNumber
        this.carForm.patchValue({
          carName: this.carName,
          modelYear: this.modelYear,
          modelName: this.modelName,
          fuelType: this.fuelType,
          carPlateNo: this.carPlateNo,
          vinNumber: this.vinNumber
        })
      }
    }

    this.listenToFormChanges();
    if(this.service.isBrowserPlateformId()){
      let isCarFormData = localStorage.getItem('carFormData')
      if (isCarFormData) {
        this.patchFormDataFromLocalStorage()
      }
    }
  }

  getQuestionCategories() {
    this.service.showSpinner();
    this.service.postApi('question-categories/category-with-question', {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.carQuestions = res.categories
        this.carQuestions.forEach((element: any) => {
          if (element.category.specialCategoryName == 'Mileage and Color') {
            this.mileageAndColor = element.questions
          }
          element.questions.forEach((question: any) => {
            question.options = JSON.parse(question.options)
            if (question.isSubOption == 1) {
              question.subOptions = JSON.parse(question.subOptions)
            }
          })
        })
        this.initializeForm();
        this.service.hideSpinner();
      }
      else {
        this.service.showError(res.message)
        this.service.hideSpinner();
      }

    }, (error:any) => {
      this.service.hideSpinner();
    })
  }
  formatSellingPrice(value: number | null | string, questionId: number) {
    if (value !== null) {
      value = value.toString().replaceAll('$', '');
      value = value.toString().replaceAll(',', '');
      const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD', // Use USD as the currency symbol (United States)
      }).format(Number(value));
      this.carForm.get(`${questionId}`)?.setValue(formattedValue, { emitEvent: false });
      this.carForm.get('sellingPrice')?.setValue(formattedValue, { emitEvent: false });
    }
    else {
      this.carForm.get(`${questionId}`)?.setValue('', { emitEvent: false });
      this.carForm.get('sellingPrice')?.setValue('', { emitEvent: false });
    }
  }


  get f() {
    return this.carForm.controls
  }
  selectImage(event: any, cornerName: string) {
    // Implement your logic to trigger the file input here
    if(this.service.isBrowserPlateformId()){
    const fileInput = document.getElementById(cornerName) as HTMLInputElement;
    if (fileInput) {
      fileInput.click(); // This simulates a click on the hidden file input
    }
    }
  }

  updateCheckboxType(value: string, name: string) {

    const formArrayValue = this.carForm.get(name)?.value;

    if (value === "None") {
      // If "None" checkbox is selected, uncheck all other checkboxes
      this.carForm.get(name)?.patchValue([value]);
    } else {
      // If a checkbox other than "None" is selected, toggle its value
      const index = formArrayValue.indexOf(value);
      if (index !== -1) {
        formArrayValue.splice(index, 1);
      } else {
        formArrayValue.push(value);
        // If any checkbox other than "None" is selected, uncheck "None"
        const noneIndex = formArrayValue.indexOf("None");
        if (noneIndex !== -1) {
          formArrayValue.splice(noneIndex, 1);
        }
      }
      this.carForm.get(name)?.patchValue([...formArrayValue]);
    }
    if (formArrayValue.includes('Others') || formArrayValue.includes('Other')) {
      this.carForm.get(`description-${name}`)?.setValidators([Validators.required]);
      this.isOthersChecked = true;
    } else {
      this.carForm.get(`description-${name}`)?.clearValidators();
      this.isOthersChecked = false;
    }
    this.carForm.get(`description-${name}`)?.updateValueAndValidity();
  }

  // image selector
  // onImageSelected(event: Event, imageKey: string, index: number) {
  //   const inputElement = event.target as HTMLInputElement;
  //   if (inputElement.files && inputElement.files.length > 0) {
  //     const file = inputElement.files[0];
  //     // Check if the file type is an image
  //     if (file.type.startsWith('image/')) {
  //       this.corners[index].imageUrl = [];
  //       this.corners[index].imageUrl.push(file);
  //       if (!this.corners[index].isUploaded) {
  //         const formArray = this.carForm.get('carImages') as FormArray;
  //         formArray.push(new FormControl(this.corners[index]));
  //       }
  //       this.corners[index].isEdited = false;
  //       this.corners[index].isUploaded = true;
  //       console.log(this.corners[index]);
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         this.images[imageKey] = reader.result as string;
  //       };
  //       reader.readAsDataURL(file);
  //     } else {
  //       // Display an error message or take appropriate action for non-image files
  //       this.service.showError('Please select an image file.')
  //     }
  //   }
  // }

  async onImageSelected(event: Event, imageKey: string, index: number) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      // Check if the file type is an image or HEIC
      if (file.type.startsWith('image/') || file.name.endsWith('.heic')) {
        if (isPlatformBrowser(this.platformId)) {
          const heic2any = (await import("heic2any")).default;
        this.corners[index].imageUrl = [];
        let convertedFile = file;

        // Convert HEIC to JPEG if necessary
        if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
          try {

            const convertedBlob: any = await heic2any({ blob: file, toType: 'image/jpeg' });
            if(typeof convertedBlob != 'undefined'){
              convertedFile = new File([convertedBlob], file.name.replace('.heic', '.jpg'), { type: 'image/jpeg' });
            }
          } catch (error) {
            console.error('Error converting HEIC to JPEG:', error);
            this.service.showError('Failed to convert HEIC image. Please select a different file.');
            return;
          }
        }

     

          
         

        this.corners[index].imageUrl.push(convertedFile);
        if (!this.corners[index].isUploaded) {
          const formArray = this.carForm.get('carImages') as FormArray;
          formArray.push(new FormControl(this.corners[index]));
        }
        this.corners[index].isEdited = false;
        this.corners[index].isUploaded = true;
        console.log(this.corners[index]);

        const reader = new FileReader();
        reader.onload = () => {
          this.images[imageKey] = reader.result as string;
        };
        reader.readAsDataURL(convertedFile);
      
        }
      } else {
        // Display an error message or take appropriate action for non-image files
        this.service.showError('Please select an image file.');
      }
      
    }
  }
  convertToString(str: string) {
    return str.toString();
  }

  getCar(): void {
    this.service.showSpinner();
    let data = {
      carId: this.carId,
      userTimeZone: 'Asia/Calcutta'
    }

    if(this.service.isBrowserPlateformId()){
      data.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ;
      console.log("userTimeZone", data.userTimeZone)
    } 

    this.service.postApi(`cars/get-cars`, data).subscribe(
      (data: any) => {
        let car = data.cars[0];
        this.vinNumber = car.vinNumber;
        this.color = car.color;
        this.carPlateNo = car.carPlateNo != null ? car.carPlateNo : ' ',
          this.carName = car.carName,
          this.modelName = car.modelName
        this.modelYear = car.modelYear
        this.fuelType = car.fuelType;
        this.mileage = car.mileage;
        let carAnswers = JSON.parse(car.carAnswers);
        this.carQuestions = car.carQuestions ? JSON.parse(car.carQuestions) : [];
        this.initializeForm();
        this.carQuestions.forEach((category: any) => {
          if (category.category.specialCategoryName == 'Mileage and Color') {
            this.mileageAndColor = category.questions
          }
          category.questions.forEach((element: any) => {
            Object.keys(carAnswers).forEach(key => {
              if (key == element.id) {
                if (element.specialQuestionName == 'sellingPrice' && element.type == 'number') {
                  this.formatSellingPrice(carAnswers[key], element.id)
                }
                else {
                  this.carForm.get(`${element.id}`)?.setValue(carAnswers[key])
                }
              }
              if ((element.descriptionField == 1 && key == `description-${element.id}`)) {
                this.carForm.get(`description-${element.id}`)?.setValue(carAnswers[key])
              }
              if ((element.isSubOption == 1 && key == `subOption-${element.id}`)) {
                this.carForm.get(`subOption-${element.id}`)?.setValue(carAnswers[key])
              }
            })
          })

        })
        this.carForm.get('mileage')?.setValue(car.mileage);
        this.carForm.get('color')?.setValue(car.color);

        if (car.carImages) {
          const formArray = this.carForm.get('carImages') as FormArray;
          formArray.clear();
          car.carImages.forEach((element: any) => {
            this.images[element.type] = element.fileUrl;
            const cornerIndex = this.corners.findIndex((corner: any) => corner.name === element.type);
            if (cornerIndex !== -1) {
              const parts = element.fileUrl.split('/');
              const filename = parts[parts.length - 1];
              this.corners[cornerIndex].isEdited = true;
              this.corners[cornerIndex].isUploaded = true;
              this.corners[cornerIndex].imageUrl = filename;
              formArray.push(new FormControl(this.corners[cornerIndex]));
            }
          });
        }
        this.service.hideSpinner();
      },
      error => {
        console.error('Error fetching cars:', error);
        this.service.hideSpinner();
      }
    );
  }

  listenToFormChanges(): void {
    this.carForm.valueChanges.subscribe(() => {
      this.updateLocalStorage();
    });
  }

  updateLocalStorage(): void {
    const formData = JSON.stringify(this.carForm.value);
    if(this.service.isBrowserPlateformId()){
      localStorage.setItem('carFormData', formData);
    }
  }

  patchFormDataFromLocalStorage(): void {
    // Retrieve data from local storage and patch it into the form
    if(this.service.isBrowserPlateformId()){
      const storedData = localStorage.getItem('carFormData');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        this.carForm.patchValue(parsedData);
        Object.keys(parsedData).forEach((element:any) => {
          if(Array.isArray(parsedData[element]) && parsedData[element].includes('Others')){
            this.carForm.get(`description-${element}`)?.setValidators([Validators.required]);
            this.carForm.get(`description-${element}`)?.updateValueAndValidity();
          }       
        });
        this.carForm.patchValue({
          carName: this.carName,
          modelYear: this.modelYear,
          modelName: this.modelName,
          fuelType: this.fuelType,
          carPlateNo: this.carPlateNo,
          vinNumber: this.vinNumber
        })
        if (parsedData.color && parsedData.color != '') {
          this.color = parsedData.color;
        }
      }
    }

   
  }

  onSubmit() {
    if (this.carForm.invalid) {
      this.isSubmit = true;
      this.service.showError('Please answer all the required questions.');
      return
    }

    const formData = new FormData();
    let fullName = `${this.carName} ${this.modelName} ${this.modelYear}`
    this.carForm.get('carFullName')?.setValue(fullName);
    formData.append('carQuestions', `${JSON.stringify(this.carQuestions)}`);
    formData.append('carAnswers', `${JSON.stringify(this.carForm.value)}`);
    let imageTypes = [
      'frontImage',
      'leftFrontCorner',
      'rightFrontCorner',
      'rearImage',
      'leftRearCorner',
      'rightRearCorner',
      'odometerImage',
      'frontInterior',
    ];
    const carImagesFormArray = this.carForm.get('carImages') as FormArray;
    const uploadedImageTypes = carImagesFormArray.controls.map((image: any) => image.value.name);
    const allImageTypesExist: any = imageTypes.every((imageType: string) => uploadedImageTypes.includes(imageType));
    
    this.carForm.patchValue({
      carName: this.carName,
      modelYear: this.modelYear,
      modelName: this.modelName,
      fuelType: this.fuelType,
      carPlateNo: this.carPlateNo,
      vinNumber: this.vinNumber
    })
    
    if (this.user) {
      this.carForm.get('userId')?.setValue(this.user.id)
      formData.append('sellerPhone', this.user.phone)
    }
    const currentValue: string = this.carForm.value.sellingPrice;
    let intValue = this.carForm.value.sellingPrice
    if (currentValue && typeof currentValue === 'string') {
      const cleanedValue: string = currentValue.replace(/\$|,/g, '');
      intValue = parseInt(cleanedValue);
    }
    this.carForm.get('sellingPrice')?.setValue(intValue);

    // console.log(this.carForm.value, this.carQuestions);
    // return;
    const formGroup: FormGroup = this.carForm as FormGroup;
    for (const field in formGroup.controls) {
      if (Object.prototype.hasOwnProperty.call(formGroup.controls, field)) {
        const control = formGroup.get(field);
        if (control && field === 'carImages' && control.value !== undefined) {
          control.value.forEach((element: any) => {
            // console.log(element.name, element.isEdited , element.imageUrl);
            formData.append(element.name, element.isEdited ? element.imageUrl : element.imageUrl[0]);
          });
        }
        else if (control && control.value !== undefined) {
          formData.append(field, control.value);
        }
      }
    }
    if (!allImageTypesExist) {
      formData.append('isRelist', '1');
    }

    formData.append('allImageTypesExist', allImageTypesExist)

    if (this.carId) {
      formData.append('carId', this.carId);
      formData.append('sellerEmail', this.user.email);
      formData.append('sellerName', this.user.username);
    }
    this.service.showSpinner();
    if (!this.service.isLoggedIn()) {
      this.service.postApi('tempcars/create-temp-car', formData).subscribe((res: any) => {
        if(this.service.isBrowserPlateformId()){

          localStorage.setItem('tempCarid', res.cars.id);
        }
        // this.carForm.reset();
        this.service.hideSpinner();
      }, (error) => {
        this.service.hideSpinner();
        this.service.showError('Something went wrong')
      })
      this.router.navigate(['/seller/signup']);
      return
    }
    else {
      this.service.postApi('cars/add-car', formData).subscribe((res) => {
        if(this.service.isBrowserPlateformId()){

          localStorage.removeItem('carData');
          localStorage.removeItem('carFormData');
        }
        // this.carForm.reset();
        this.service.hideSpinner();
        this.router.navigate(['seller/car-list-new'])
      }, (error) => {
        this.service.showError('Something went wrong')
        this.service.hideSpinner();
      })
    }
  }


  //accept only numbers in input
  numberOnly(event: any) {
    return this.service.numberOnly(event);
  }
}
