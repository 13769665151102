import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  public passwordForm: FormGroup;
  public user: any;
  public passwordPattern = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[\w#?!@$%^&*-]{7,12}$/;
  public showEye:boolean=false;

  constructor(private fb: FormBuilder, private service: CommonService) {
    if(this.service.isBrowserPlateformId()){

      this.user = JSON.parse(`${localStorage.getItem('user')}`)
    }
    this.passwordForm = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['',[Validators.required,
      Validators.compose([
        this.lowercaseValidator,
        this.uppercaseValidator,
        this.digitValidator,
        this.specialCharacterValidator,
        this.lengthValidator,
      ])]
    ],
      confirmPassword: ['', [Validators.required]],
    },
      {
        validator: this.ConfirmedValidator('newPassword', 'confirmPassword')
      }
    );
  }

  get f() {
    return this.passwordForm.controls;
  }


  showHidePassword(){
    this.showEye = !this.showEye
  }

  //confirm password matching validators
  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  //change password function
  onSubmit() {
    let data = {
      currentPassword: this.passwordForm.value.currentPassword,
      newPassword: this.passwordForm.value.newPassword,
      userId: this.user.id
    }
    this.service.showSpinner();
    this.service.postApi('users/change-password', data).subscribe((res: any) => {
      if (res.status==200){
        this.service.showSuccess(res.message);
      }
      else{
        this.service.showError(res.message);
      }
      this.service.hideSpinner();
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })

  }

  // Custom validator for at least one lowercase letter
 lowercaseValidator(control: AbstractControl): { [key: string]: boolean } | null {  
  const value = control.value;
  if (value && !/[a-z]/.test(value)) {
    return { 'lowercase': true };
  }
  return null;
}

// Custom validator for at least one uppercase letter
 uppercaseValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const value = control.value;
  if (value && !/[A-Z]/.test(value)) {
    return { 'uppercase': true };
  }
  return null;
}

// Custom validator for at least one digit
 digitValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const value = control.value;
  if (value && !/[0-9]/.test(value)) {
    return { 'digit': true };
  }
  return null;
}

// Custom validator for at least one special character
 specialCharacterValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const value = control.value;
  if (value && !/[#?!@$%^&*-]/.test(value)) {
    return { 'special': true };
  }
  return null;
}

// Custom validator for password length between 7 and 16 characters
 lengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const value = control.value;
  if (value && (value.length < 7 || value.length > 16)) {
    return { 'length': true };
  }
  return null;
}


}
