<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name" class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center" >
        <div class="container mx-auto  ">
            <div class="max-w-screen-sm	mx-auto z-50  flex-1 px-6 ">
                <div class="block text-center">
                    <h1 class="md:text-6xl text-4xl text-white font-bold ">About Us</h1>       
                </div>
            </div>
        </div>
    </div>
</div>
<section class="relative lg:py-36 bg-white z-20">
    <div class="container max-w-screen-xl flex flex-wrap items-center mx-auto">
        <div class="flex flex-wrap items-center lg:order-1 order-2">
            <div class="block-content lg:w-3/6 w-full px-4 lg:text-left text-center">
                <div class="title-heading pb-1 lg-3 leading-none text-center mb-4 lg:text-left">
                    <h2 class="md:text-4xl text-3xl  heading_color font-bold leading-none">{{aboutusData?.firstSectionHeading}}</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>                
                <p class="md:text-lg text-base content_color mb-8 text-justify" [innerHtml]="aboutusData?.firstSectionContent">
                </p>
            </div>
        </div>        
        <div class="block-video lg:w-3/6 w-full lg:absolute right-0 lg:pl-20 lg-8 lg:mb-0 mb-8 lg:top-10 lg:bottom-10 h-full lg:order-2 order-1">
            <iframe *ngIf="aboutusData?.firstSectionFileType==1" class="w-full lg:rounded-l-[70px] overflow-hidden lg:aspect-auto aspect-video h-full" width="560" height="" [src]="firstSectionVideoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <img alt="car image" *ngIf="aboutusData?.firstSectionFileType==0" class="w-full lg:rounded-l-[70px] overflow-hidden lg:aspect-auto aspect-video h-full" width="560" height=""  [src]="aboutusData?.firstSectionVideoUrl">
        </div>
    </div>
</section>
<section class="relative md:py-24 py-10 bg-section-center bg-white">
    <div class="container max-w-screen-xl mx-auto">
        <div class="flex flex-wrap items-center justify-center px-6 md:px-0">
            <div class="block-content w-full text-center">     
            <div class="title-heading pb-1 md:mb-20 mb-10 leading-none text-center">
                <h2 class="md:text-4xl text-3xl heading_color font-bold leading-none">{{aboutusData?.mainMiddleHeading}}</h2>
                <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
            </div>  
            </div>
            <div class="block-content md:w-4/12 w-full  text-center md:border-r-2 md:border-b-0  border-b-2 border-indigo-[#d9d9d9] md:mb-0 mb-8 pb-6 px-4"> 
                <div class="icon text-center mb-4"><img src="assets/img/icon-2.png" alt="head1" class="inline-block"></div>                             
                <h3 class="md:text-2xl text-xl content_color mb-2">{{aboutusData?.firstMiddleHeading}}</h3>
                <p class="md:text-lg text-base content_color text-[#555]" [innerHtml]="aboutusData?.firstMiddleContent"></p>
            </div>
            <div class="block-content md:w-4/12 w-full  text-center md:border-r-2 md:border-b-0  border-b-2 border-indigo-[#d9d9d9] md:mb-0 mb-8 pb-6 px-4"> 
                <div class="icon text-center mb-4"><img src="assets/img/icon-1.png" alt="head1" class="inline-block"></div>                             
                <h3 class="md:text-2xl text-xl content_color mb-2">{{aboutusData?.secondMiddleHeading}}</h3>
                <p class="md:text-lg text-base content_color text-[#555]" [innerHtml]="aboutusData?.secondMiddleContent"></p>
            </div>
            <div class="block-content md:w-4/12 w-full  text-center  md:mb-0 pb-6 px-4"> 
                <div class="icon text-center mb-4"><img src="assets/img/icon-3.png" alt="head1" class="inline-block"></div>                             
                <h3 class="md:text-2xl text-xl content_color mb-2">{{aboutusData?.thirdMiddleHeading}}</h3>
                <p class="md:text-lg text-base content_color text-[#555]" [innerHtml]="aboutusData?.thirdMiddleContent"></p>
            </div>
        </div>  
    </div>
</section>
<section class="relative lg:py-36 bg-white">
    <div class="container max-w-screen-xl flex flex-wrap items-center mx-auto">
        <div class="flex flex-wrap items-center justify-end  lg:order-1 order-2">
            <div class="block-content lg:w-3/6 w-full  px-4 text-center lg:text-left">
                <div class="title-heading pb-1 mb-3 leading-none">
                    <h2 class="md:text-4xl text-3xl heading_color font-bold leading-none">{{aboutusData?.thirdSectionHeading}}</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>                
                <p class="md:text-lg text-base content_color mb-8 text-justify" [innerHtml]="aboutusData?.thirdSectionContent">
                </p>
            </div>
        </div>        
        <div class="block-video lg:w-3/6 w-full lg:absolute left-0 lg:pr-20 mb-8 lg:mb-0  lg:order-2 order-1 lg:-top-10 lg:bottom-10 h-full">
            <iframe *ngIf="aboutusData?.thirdSectionFileType==1" class="w-full lg:rounded-r-[70px] overflow-hidden lg:aspect-auto aspect-video h-full" width="560" height="" [src]="thirdSectionVideoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <img alt="car image" *ngIf="aboutusData?.thirdSectionFileType==0" class="w-full lg:rounded-r-[70px] overflow-hidden lg:aspect-auto aspect-video h-full" width="560" height=""  [src]="aboutusData?.thirdSectionVideoUrl">
        </div>
    </div>
</section>