import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { WalletService } from 'src/app/dealeraccount/kargone-wallet/wallet.service';

@Component({
  selector: 'app-bid',
  templateUrl: './bid.component.html',
  styleUrls: ['./bid.component.scss']
})
export class BidComponent implements OnInit{
  public bidAmount:any;
  public user:any;
  public walletAmount: number=0;
  public selectedCar : any;
  public showModel :string = 'none';

  constructor(private service: CommonService,private walletService:WalletService,private router:Router) {
    if(this.service.isBrowserPlateformId()){

      this.user = JSON.parse(`${localStorage.getItem('user')}`)
    }
  } 

  ngOnInit(): void {
    //close modal when click on outside of modal
    if(this.service.isBrowserPlateformId()){
      window.addEventListener('click', (event) => {
        if (event.target === document.getElementById('bidding-popup-deal')) {
          this.showModel = 'none'
        }
      });
    }

    this.service.bidData.subscribe((res:any) => {
       this.selectedCar = res;
      //  this.bidAmount = res.bidAmount>0 ? res.bidAmount : ''
      //  if(this.bidAmount>0){
      //   this.formatBidAmount()
      //  }
       if(res && res.userId ){
         this.showModel = 'flex'
         this.getWallet();
       }
    })
  }
  getWallet(){
    let data = {
      userId:this.user.id,
      parentUserId: this.user.parentUserId ? this.user.parentUserId : 0
    }
    this.walletService.postApi(data).subscribe((res: any) => {
      if (res.status == 200) {
        this.walletAmount = res.wallet[0].amount
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
        // this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }
  formatBidAmount1() {
    // let value = this.bidAmount
    // this.bidAmount = '$' + value.replace('$', '');
    if(this.bidAmount === '$'){
      this.bidAmount = ''
    }else{
      let value = parseFloat(this.bidAmount.replace(/[^0-9.]/g, ''));
    // Format the number with commas and dollar sign
    this.bidAmount = '$' + value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    } 
    
  }
  // formatBidAmount() {
  //   let value = this.bidAmount
  //   if (value !== null) {
  //     value = value.toString().replaceAll('$', '');
  //     value = value.toString().replaceAll(',', '');
  //     const formattedValue = new Intl.NumberFormat('en-US', {
  //       style: 'currency',
  //       currency: 'USD', // Use USD as the currency symbol (United States)
  //     }).format(Number(value));
  //     this.bidAmount = formattedValue

  //   }
  //   else {
  //     this.bidAmount = ''
  //   }
  // }

    //accept only numbers in input
    numberOnly(event: any) {
      return this.service.numberOnly(event);
    }
public isWarning :boolean = false;
  offerBid(){   
    const currentValue: string = this.bidAmount;

    // Remove the dollar sign ($) and comma (,) from the value
    let intValue = 0
    if (currentValue && typeof currentValue === 'string') {
      // Remove the dollar sign ($) and comma (,) from the value
      const cleanedValue: string = currentValue.replace(/\$|,/g, '');
      intValue = parseInt(cleanedValue);
    } 
    
    if(intValue<=0){
      this.service.showError('Offer amount should be greater than 0');
      return
    }
    if(this.walletAmount < 1 && !this.selectedCar.bidId){
      // this.service.showError('Your credit amount is low');
      this.router.navigate(['/dealer/kargone-wallet'])
      this.isWarning = true
      return
    }      
   
    this.bidAmount = intValue;
    let carFullName = this.selectedCar.carFullName ? this.selectedCar.carFullName : `${this.selectedCar.carName} ${this.selectedCar.modelName} ${this.selectedCar.modelYear}`
    let data = {
      dealerId: this.user.id,
      sellerId : this.selectedCar.userId,
      carId: this.selectedCar.id,
      bidAmount : Number(this.bidAmount),
      bidId: (this.selectedCar.bidId && this.selectedCar.bidParentUserId == this.user.parentUserId) ? this.selectedCar.bidId : null,
      bidCompleted: 0,
      carName : carFullName,
      parentUserId: this.user.parentUserId,
      customerName: this.selectedCar.username.replace('-', ' '),
      userName: this.user.username.replace('-', ' '),
      carNumber:  this.selectedCar.vinNumber,
      vinNumber : this.selectedCar.vinNumber,
      email : this.user.email,
      username:this.user.username,
      isAdminLoggedIn:this.user.isAdminLoggedIn
    }
    this.service.showSpinner();
    this.service.postApi('bids/create-bid',data).subscribe((res: any) => {
      if (res.status == 200) {
        this.showModel = 'none'
        this.service.showSuccess(res.message);
        this.service.CarData.next(this.selectedCar.id)
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }

  hideModal(){
    this.showModel = 'none'
  }
}
