import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CommonService } from 'src/app/common.service';
import { interval } from 'rxjs';
declare var $:any;
@Component({
  selector: 'app-employe-performance',
  templateUrl: './employe-performance.component.html',
  styleUrls: ['./employe-performance.component.scss']
})
export class EmployePerformanceComponent implements OnInit, OnDestroy {

  public subDealer:any;
  public totalItems:number = 0;
  public user:any;
  public dataSubcribed: any;
  public startDate:any;
  public endDate:any;
  
  constructor( private service: CommonService,  private activateRaoute: ActivatedRoute, private renderer: Renderer2) {    
    this.service.scrollToTop()
    // this.getCalendar();
    if(this.service.isBrowserPlateformId()){
      this.user = JSON.parse(`${localStorage.getItem('user')}`);
    }
    let data = {
      userId:this.user.id,
      parentUserId :this.user.parentUserId > 0 ? this.user.parentUserId:0
    }
    this.service.showSpinner();
    this.resetCarModelData()
  }

  getCalendar(){
    $(() => {
      $('input[name="daterange"]').daterangepicker({
        opens: 'center',
        // singleDatePicker: true,
        // showDropdowns:true,
        // autoUpdateInput: false,
        alwaysShowCalendars:true,
        showCustomRangeLabel:false,
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
       },
        locale: {
          format: 'DD MMM YYYY'
        }
      }, (start:any, end:any, label:any) => {
        this.startDate = start.format('YYYY-MM-DD')
        this.endDate = end.format('YYYY-MM-DD');
      });

      $('input[name="daterange"]').on('apply.daterangepicker', (ev: any, picker:any) => {

        let data = {
          startDate: picker.startDate.format('YYYY-MM-DD'),
          endDate: picker.endDate.format('YYYY-MM-DD'),
          userId: this.user.id,
          parentUserId : this.user.parentUserId ? this.user.parentUserId : 0
        }
        this.service.showSpinner();
        this.getSubDealers(data)
        $('input[name="daterange"]').val(picker.startDate.format('DD-MMM-YYYY') + ' - ' + picker.endDate.format('DD-MMM-YYYY'));
      });
  
      $('input[name="daterange"]').on('cancel.daterangepicker', function(ev:any, picker:any) {
          $('input[name="daterange"]').val('');
      });
    });
  }

  resetCarModelData(){
    let data = {
      userId: this.user.id,
      parentUserId : this.user.parentUserId ? this.user.parentUserId : 0
    }
    this.service.showSpinner();
    this.getSubDealers(data)
    const $input = $('input[name="daterange"]');
    $input.val('')
    const daterangepickerInstance = $input.data('daterangepicker');
    if (daterangepickerInstance) {
      daterangepickerInstance.setStartDate(moment());
      daterangepickerInstance.setEndDate(moment());
    }
  }

  ngOnInit(): void {
    this.subscribeLiveData();
  
      if(this.service.isBrowserPlateformId()){
     
        // Dynamically load CSS
        const link = this.renderer.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css';
        this.renderer.appendChild(document.head, link);
    
        // Dynamically load JS
        const script = this.renderer.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js';
        script.onload = () => {
          console.log('Swiper script loaded');
        };
        this.renderer.appendChild(document.body, script);
      }
    
  }

  private subscribeLiveData(): void {    
    this.dataSubcribed = interval(60000)
      .subscribe(() => this.getRefershData());
  }
  getRefershData(){
    let data = {
      startDate: this.startDate ?  this.startDate: null ,
      endDate: this.endDate ? this.endDate:null,
      userId: this.user.id,
      parentUserId : this.user.parentUserId ? this.user.parentUserId : 0
    }
    this.getSubDealers(data)
  }

  ngOnDestroy(): void {
    this.dataSubcribed.unsubscribe();
  }
  getSubDealers(data:any) {
    // this.service.showSpinner();
 
    this.service.postApi(`users/user-performance`,data).subscribe((res: any) => {
      if(res.status ==200){
        this.subDealer = res.user;
        this.getCalendar();
        this.service.hideSpinner();
      }
      else{
        this.totalItems =0;
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }

  calculateStarClass(i:number,averageRating:number): string {
    const integerPart = Math.floor(averageRating || 0);
    const fractionalPart = averageRating % 1;
  
    if (i + 1 <= integerPart) {
      return 'fa fa-star active text-xl';
    } else if (i + 0.5 === averageRating || (i + 1 === integerPart + 1 && fractionalPart > 0)) {
      return 'fas fa-star-half-alt active text-xl';
    } else {
      return 'inactive fa fa-star text-xl';
    }
  }
}
