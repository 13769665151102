import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  public user:any;
  public userForm!: FormGroup;

  constructor(private service:CommonService,private fb: FormBuilder){
    if(this.service.isBrowserPlateformId()){

      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.user = JSON.parse(`${localStorage.getItem('user')}`)
    }
    this.inilizeForm();
    this.getUserById();
  }
  getUserById() {
    this.service.showSpinner();
    this.service.getApi(`users/get-user/${this.user.id}`).subscribe((res: any) => {
      if(res.status ==200){
        this.user = res.user;
        this.userForm.patchValue(res.user);
        this.service.hideSpinner()
      }
      else{
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }

  inilizeForm() {
    this.userForm = this.fb.group({
      emailNotification: [],
      smsNotification: []
    })
  }
  updateNotification(){
    this.service.showSpinner();
    let data = {
      userId:this.user.id,
      emailNotification: this.userForm.value.emailNotification,
      smsNotification: this.userForm.value.smsNotification
    }
    this.service.postApi(`users/update-notification`, data).subscribe((res: any) => {
      if(res.status == 200){
        this.getUserById();
        this.service.hideSpinner();
        this.service.showSuccess('Notification settings updated successfully');
      }
      else{
        this.service.hideSpinner();
        this.service.showSuccess('Notification settings did not updated.');
      }
    },(error)=>{
      this.service.hideSpinner();
    })
  }

}
