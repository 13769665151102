import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})

export class SignupComponent {

  public is_dealer = false
  public formData!: FormGroup;
  public isTermConditon = false;
  public licenseName: any = '';
  public registrationForm!: FormGroup;
  public countryData: any;
  public stateData: any = [];
  public cityData: any;
  public isSubmit: boolean = false;
  public imagePreview: any;
  public showEye=false;
  public isSeller:boolean = false
  public emailOptIn:boolean = false;
  public smsOptIn:boolean = false
  public selectedState:string =''
  public isoCode:string = ''
  public isMinLength:boolean=false;
  public isMaxLength:boolean=false;

  private regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-.\/:;\\<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-.\/:;\\<=>?@[\]^_`{|}~]{8,}$/

  emailRegx = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/


  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router, private service: CommonService) {
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.service.scrollToTop()
    let currentPath =  this.router.url.split('/')[1]
    if (currentPath == 'seller') {
      this.isSeller = true
    }else{
      this.is_dealer = true
      this.isSeller = false
    }
    this.intializeForm()
    // this.getStates();
    this.listenToFormChanges();

    if(this.service.isBrowserPlateformId()){
      let isSignUpData = localStorage.getItem('signupData')
      if(isSignUpData){
        this.patchFormDataFromLocalStorage()
      }
    }

  }

  formatPhoneNumber(input: string) {
    // Remove non-numeric characters
    const numericValue = input.replace(/\D/g, '');
    if(numericValue.length ==0){
      this.registrationForm.get('phone')?.setValue('')
    }
    // Format the numeric value as (XXX) XXX-XXXX
    let formattedValue = '';
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 0) {
        formattedValue += '(' + numericValue[i];
      } else if (i === 3) {
        formattedValue += ') ' + numericValue[i];
      } else if (i === 6) {
        formattedValue += '-' + numericValue[i];
      } else {
        formattedValue += numericValue[i];
      }
    }

    return formattedValue;
  }

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const newValue = inputElement.value;
    const formattedValue = this.formatPhoneNumber(newValue);
    // Update the input value with the formatted value
    inputElement.value = formattedValue;
  }


  // doucument of license
  uploadLicense(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = inputElement.files;
    if (fileList && fileList.length > 0) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const fileBuffer = e.target.result; // This will be a buffer
        this.licenseName = fileList[0].name;
        this.registrationForm.get('license')?.setValue(fileList[0]);
      };
      reader.readAsArrayBuffer(fileList[0]);
    }
  }

  // upload user profile pic 
  handleImageUpload(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = inputElement.files;

    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.registrationForm.get('userImage')?.setValue(fileList[0]);
        this.imagePreview = reader.result as string;
        if(this.service.isBrowserPlateformId()){

          localStorage.setItem('userPic',this.imagePreview);
        }
      };
      reader.readAsDataURL(file);
    }
  
  }


  // initiazing registration form
  intializeForm() {
    this.registrationForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
      dealershipName: [''],
      companyName: [''],
      license: [''],
      licenseNumber: [''],
      carBuyMonthly: [''],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      country: ["United States", Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: ['', Validators.required],
      regNumber: [''],
      email: ['', [Validators.required, Validators.pattern(this.emailRegx)]],
      phone: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(this.regx), Validators.compose([
        this.lowercaseValidator,
        this.uppercaseValidator,
        this.digitValidator,
        this.specialCharacterValidator,
        this.lengthValidator,
      ])]],
      confirmPassword: ['', Validators.required,
      ],
      dealerType: [''],
      dealerGroup: [''],
      userImage: [''],
      role: ['1'],
    },
      {
        validator: this.ConfirmedValidator('password', 'confirmPassword'),
        updateOn: 'blur'
      }
    );

    if (this.is_dealer) {
      this.registrationForm.get('dealershipName')?.setValidators([
        Validators.required
      ]);      
      this.registrationForm.get('regNumber')?.setValidators([
        Validators.required
      ]); 
      this.registrationForm.get('companyName')?.setValidators([
        Validators.required
      ]);
      this.registrationForm.get('license')?.setValidators([Validators.required]);
      this.registrationForm.get('licenseNumber')?.setValidators([Validators.required]);
    } else {
      this.registrationForm.get('regNumber')?.clearValidators();
      this.registrationForm.get('dealershipName')?.clearValidators();
      this.registrationForm.get('companyName')?.clearValidators();
      this.registrationForm.get('license')?.clearValidators();
    }
    // Update the form controls to reflect the new validators
    this.registrationForm.get('regNumber')?.updateValueAndValidity();
    this.registrationForm.get('dealershipName')?.updateValueAndValidity();
    this.registrationForm.get('companyName')?.updateValueAndValidity();
    this.registrationForm.get('license')?.updateValueAndValidity();
    this.registrationForm.get('licenseNumber')?.updateValueAndValidity();

  }

  // get form controls
  get f() {
    return this.registrationForm.controls;
  }


  showHidePassword(){
    this.showEye = !this.showEye
  }


  listenToFormChanges(): void {
    this.registrationForm.valueChanges.subscribe(() => {
        this.updateLocalStorage();
    });
  }

  updateLocalStorage(): void {
    const formData = JSON.stringify(this.registrationForm.value);
    if(this.service.isBrowserPlateformId()){

      if(this.is_dealer){
        localStorage.setItem('signupData1', formData);
      }
      else{
        localStorage.setItem('signupData', formData);
      }
    }
  }

  patchFormDataFromLocalStorage(): void {    
    // Retrieve data from local storage and patch it into the form
    if(this.service.isBrowserPlateformId()){
      const state = localStorage.getItem('state');
      const storedData =this.is_dealer ? localStorage.getItem('signupData1'): localStorage.getItem('signupData');
      this.imagePreview = localStorage.getItem('userPic') ? localStorage.getItem('userPic') : ''
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        if(this.is_dealer && parsedData.dealerType == 'group'){
          this.isGroupSelected = true;
        }
        this.registrationForm.patchValue(parsedData);
        this.selectedState = state ? state : '';
      }
    }
  }

  // get state list according country selected
  getStates(isoCode:any,city:string) {
    this.service.apiFunction(`https://api.countrystatecity.in/v1/countries/us/states`).subscribe(
      (res: any) => {
        this.stateData = res;
        this.stateData.forEach((element:any)=>{
          if(element.iso2 == isoCode){
            this.registrationForm.get('state')?.setValue(isoCode);
            this.registrationForm.get('city')?.setValue(city)
            this.selectedState = element.name;
            if(this.service.isBrowserPlateformId()){

              localStorage.setItem('state',element.name);
            }
          }
        })
        this.stateData = this.stateData.sort((a: any, b: any) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }

  // get city list according state selected
  getCities(event: any) {
    this.service.showSpinner();
    this.service.apiFunction(`https://api.countrystatecity.in/v1/countries/us/states/${event.target.value}/cities`).subscribe(
      (res: any) => {
        this.cityData = res
        this.service.hideSpinner();
      },
      (error) => {
        this.service.hideSpinner();
        console.error('API Error:', error);
      }
    );
  }


  //accept only numbers in input
  numberOnly(event: any) {
    return this.service.numberOnly(event);
  }

  //confirm password matching validators
  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }


  toggle(is_seller: any) {
    this.is_dealer = is_seller ? false : true;
    this.router.navigateByUrl(is_seller ? 'seller/signup': 'dealer/signup')
    if(!this.is_dealer){
      this.isGroupSelected = false;
    }
    this.intializeForm()
    this.listenToFormChanges()
    if(this.service.isBrowserPlateformId()){
      let isSignUpData = this.is_dealer ?  localStorage.getItem('signupData1') :localStorage.getItem('signupData')
      if(isSignUpData){
        this.patchFormDataFromLocalStorage()
      }
    }
  }


  // submit  data
  submitForm() {  
    this.isMaxLength = false;
    this.isMinLength = false;  
    if (this.registrationForm.invalid || !this.isTermConditon) {   
      console.log(this.registrationForm.controls);
         
      this.isSubmit = true;
      return;
    }
    this.registrationForm.get('role')?.setValue(this.is_dealer ? '0' : '1');
    if (this.registrationForm.get('dealerType')?.value != 'group') {
      this.registrationForm.get('dealerGroup')?.setValue('');
    }
    //dealerGroup
    const phoneNumber = this.registrationForm.get('phone')?.value;
    const cleanedPhoneNumber = phoneNumber.replace(/\s/g, "").replace(/[()\-+]/g, "");
    this.registrationForm.get('phone')?.setValue(cleanedPhoneNumber);
    if(cleanedPhoneNumber.length<10){
      this.isMinLength = true;
      return
    }
    if(cleanedPhoneNumber.length>12){
      this.isMaxLength = true;
      return
    }

    this.formData = this.registrationForm
    if (this.formData && this.formData.valid) {
      const formData = new FormData()
      const formGroup: FormGroup = this.formData as FormGroup;
      formData.append('smsOptIn', this.smsOptIn.toString());
      formData.append('emailOptIn', this.emailOptIn.toString());
      for (const field in formGroup.controls) {
        if (Object.prototype.hasOwnProperty.call(formGroup.controls, field)) {
          const control = formGroup.get(field);
          if (control && control.value !== undefined) {
            formData.append(field, control.value);
          }
        }
      }
      if(this.is_dealer){
        formData.append('isTempUser', '1');
      }
      else{
        formData.append('isTempUser', '0');
      }
      this.service.showSpinner();
      this.service.postApi('auths/register', formData).subscribe((res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          let data = { email: res.newUser.email, phone: res.newUser.phone, isSeller : !this.is_dealer }
          localStorage.setItem('verificationData', JSON.stringify(data))          
          if (res.newUser.role == 0) {

            localStorage.setItem('tempUserId', `${res.newUser.id}`)
            this.router.navigate([`/dealer/dealer-preferences`])
          }
          else {
            this.service.showSuccess(res.message);
            this.router.navigate([`/verification/${res.newUser.id}`]);
          }
          // localStorage.removeItem('state');
          // localStorage.removeItem('signupData')
          // localStorage.removeItem('signupData1')

        }
        else if (res.status == 202) {
          this.service.showError(res.message)
          // this.router.navigate(['/login'])
        }
        else {
          this.service.showError(res.message);
        }
      }, (error) => {
        this.service.hideSpinner()
      })
    }

  }
public isGroupSelected:boolean = false;
  selectGroup(event:any){
    this.isGroupSelected = event.target.value == 'group' ? true: false;
  }
  //click  check box term & conditions
  handleCheckboxClick(event: any) {
    const isChecked = event.target.checked;
    this.isTermConditon = isChecked
  }

  checkSmsOptIn(event:any){
    this.smsOptIn = event.target.checked;

  }
  checkEmailOptIn(event:any){
    this.emailOptIn = event.target.checked;
  }

  // Custom validator for at least one lowercase letter
  lowercaseValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !/[a-z]/.test(value)) {
      return { 'lowercase': true };
    }
    return null;
  }

  // Custom validator for at least one uppercase letter
  uppercaseValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !/[A-Z]/.test(value)) {
      return { 'uppercase': true };
    }
    return null;
  }

  // Custom validator for at least one digit
  digitValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !/[0-9]/.test(value)) {
      return { 'digit': true };
    }
    return null;
  }

  // Custom validator for at least one special character
  specialCharacterValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !/[#?!@$%^&*-]/.test(value)) {
      return { 'special': true };
    }
    return null;
  }

  // Custom validator for password length between 7 and 16 characters
  lengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && (value.length < 7 || value.length > 16)) {
      return { 'length': true };
    }
    return null;
  }

  public zipCode: any;
  // getZipCode(event: any) {
  //   this.service.showSpinner();
  //   // let stringWithoutSpaces = event.target.value.replace(/\s/g, "");

  //   this.service.zipCodeApi(event.target.value, this.registrationForm.get('state')?.value).subscribe((res: any) => {
  //     this.zipCode = res.zip_codes;
  //     this.service.hideSpinner();
  //   }, (error) => {
  //     this.service.hideSpinner();
  //   })
  // }
  getStateCity(event:any){
  if(event.target.value.length == 5){
    this.service.showSpinner();
    this.service.getStateCityByZipCode(event.target.value).subscribe((res: any) => {
      // this.registrationForm.patchValue({
      //   city:  res.city,
      //   // state: res.state
      // })
      this.isoCode = res.state;
      this.getStates(res.state,res.city);
      this.service.hideSpinner();
   
  },(error)=>{
    // if(res.error_code = 404){
      this.service.hideSpinner();
      this.service.showError('Enter Valid Zip Code')
    // }
  })
}
else{
  this.registrationForm.patchValue({
    city:  '',
    state: ''
  })
  this.selectedState = '';
}
  }
  
}
