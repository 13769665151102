<app-sub-header></app-sub-header>
<section class="xl:py-20 py-10 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="md:flex md:flex-wrap -mx-4">          
            <!-- <app-sidebar class="z-2 xl:w-3/12 md:w-5/12 w-full px-4 mb-10 md:mb-0 hidden md:block"></app-sidebar> -->
            <app-responsive-sidebar></app-responsive-sidebar>
            
            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">
                <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center">
                    <h2 class="lg:text-5xl text-4xl  font-bold leading-none text_primary_color">Rating</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>
                <div class="w-full flex-wrap flex items-center justify-end gap-4 mb-6" *ngIf="user?.role == 0">
                        <div class="pad:min-w-[auto] lg:w-[310px] w-full">
                              <input type="text" name="daterange" class="w-full px-4 md:pl-14 pl-10 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/date-icon-black.png)] bg-no-repeat	bg-[center_left_1rem] md:bg-[length:24px] bg-[length:18px]	!shadow-none outline-none cursor-pointer focus:ring-0 focus:ring-offset-0" placeholder="1 Jan 2023 - 30 Jan 2023">
                        </div>
                        <div class="pad:min-w-[220px] lg:w-auto w-full">
                            <select class="w-full px-4 pr-14 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/down-arrow-black.png)] bg-no-repeat	bg-[center_right_1rem] appearance-none" (change)="filterByEmployee($event)">
                                <option value="" disabled selected>Employee</option>
                                <option *ngFor="let item of dealerList" [value]="item?.id" >{{item.username}}</option>
                            </select>
                        </div>
                        <div class="pad:min-w-[220px] lg:w-auto w-full">
                          <select class="w-full px-4 pr-14 pad:h-14 h-10 md:text-base text-sm rounded-md border border-[#004AAD] text-[#3A3F4D] placeholder:text-[#3A3F4D] bg-[url(assets/img/down-arrow-black.png)] bg-no-repeat	bg-[center_right_1rem] appearance-none" (change)="filterByStar($event)">
                              <option value="" selected disabled>Rating</option>
                              <option value="1">1 Star</option>
                              <option value="2">2 Star</option>
                              <option value="3">3 Star</option>
                              <option value="4">4 Star</option>
                              <option value="5">5 Star</option>
                          </select>
                      </div>
                </div>
                <div class="relative overflow-x-auto sm:rounded-lg">
                    <div class="space-y-6">
                        <div class="bg-white p-6 rounded-lg" *ngFor="let item of ratingData">
                            <div class="customer-name">
                                <div class="md:text-2xl text-xl font-semibold text_primary_color leading-none">{{item?.employeeName}}</div>
                                <div class="date-post text-xs text-[#070808] my-1">{{item?.createdAt | date:'d MMMM y'}}</div>
                                <div class="user-rating space-x-1">
                                    <span class="w-5 h-5 inline-block" *ngFor="let _ of [].constructor(5); let i = index">
                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                        <style type="text/css">
                                            .st0{fill:#CCCCCB;}
                                        </style>
                                        <path class="st0 " [ngClass]="{'star-active': i<item?.star}" d="M4.6,40.6c0-0.5,0-0.9,0-1.4c0.5-1.1,1.3-1.9,2.6-2.1c9-1.4,18-2.7,26.9-4.1c0.6-0.1,0.9-0.3,1.2-0.9
                                            c3.9-8.3,7.8-16.6,11.7-24.9c0.6-1.3,1.3-2.2,2.7-2.5c0.2,0,0.5,0,0.7,0C51.7,5,52.5,5.9,53,7.2c3.9,8.3,7.8,16.6,11.7,24.9
                                            c0.2,0.5,0.5,0.8,1.1,0.9c1.7,0.2,3.4,0.5,5.1,0.7c7.4,1.1,14.7,2.2,22,3.4c1.3,0.2,2.3,1.2,2.4,2.5c0.1,1.1-0.4,1.9-1.1,2.7
                                            c-6.5,6.6-12.9,13.2-19.4,19.9c-0.3,0.3-0.5,0.8-0.5,1.1c1.1,7.3,2.3,14.5,3.5,21.8c0.4,2.3,0.8,4.5,1.1,6.8c0.3,1.7-0.5,3.1-2,3.5
                                            c-1,0.3-1.8-0.1-2.6-0.5c-7.8-4.3-15.6-8.6-23.4-13c-0.7-0.4-1.3-0.5-2.1,0c-7.8,4.4-15.7,8.7-23.5,13c-1.2,0.6-2.3,0.8-3.4,0
                                            c-1.1-0.8-1.3-2-1.1-3.2c1.5-9.4,3.1-18.8,4.6-28.2c0.1-0.6-0.1-0.9-0.4-1.2c-6.3-6.5-12.7-13-19-19.5C5.6,42,5.1,41.3,4.6,40.6z"/>
                                        </svg>
                                    </span>
                                </div>
                                <div class="customer-comments md:text-lg text-base  text-[#3A3F4D] mt-3">
                                    <p class="md:text-lg text-base">{{item?.comment}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="bg-white p-6 rounded-lg text-center mt-8" *ngIf="ratingData?.length == 0">
                            Rating not found
                        </div> -->
                    </div>
                    <!--Write A Review-->
                    <div class="form-review mt-20" *ngIf="user.role==1">
                        <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center">
                            <h2 class="lg:text-5xl text-4xl  font-bold leading-none text_primary_color">Write a Review</h2>
                            <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                            <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                            <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                        </div> 
                        <form [formGroup]="ratingForm" class="space-y-6 px-1">
                            <label class="block">
                              <input formControlName="dealershipName" type="text" placeholder="Dealership Name" class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#000000] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0">
                            </label>
                            <label class="block">
                              <!-- <select formControlName="employeeName" class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#000000] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0">
                                <option selected value="" disabled>Employee Name</option>
                                <option selected value="Thomas Messi">Thomas Messi</option>
                              </select> -->
                              <input formControlName="employeeName" type="text" placeholder="Employee Name" class="mt-1 md:h-14 h-12 block w-full bg-white rounded-md placeholder-[#000000] md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0">
                            </label>
                            <label class="block">
                              <span  class="mt-1 pt-2 md:h-14 h-12 block w-full bg-white rounded-md md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0">
                                <span class="md:mr-4 mr-2"> Please rate the dealer : </span>
                                <div class="inline-block lg:mr-2 mr-1" *ngFor="let item of star;let i=index">
                                  <i class="fa fa-star pad:text-2xl sm:text-base text-sm cursor-pointer text-[#CCCCCB]" aria-hidden="true" [ngClass]="{'active': i<=rating}" (click)="updateRating(i)"></i>
                                </div>
                              </span>
                            </label>
                            <label class="block pb-4">
                              <textarea formControlName="comment" placeholder="Add a comment..." class="h-32 mt-1 block w-full bg-white rounded-lg placeholder-[#000000] md:px-4 px-4 py-3 md:text-base text-sm input-shadow outline-0 border-0"></textarea>
                            </label>
                            <div class="block text-center mt-4 md:w-full">
                              <button class="rounded-full bg_primary pt-3 pb-3 pl-14 pr-14 font-medium text-xl text-white border border-transparent hover:border-[#004AAD] hover:bg-white hover:text-[#004AAD]" (click)="onSubmit()" [disabled]="!ratingForm.valid || rating<0">Submit</button>
                            </div>
                          </form>
                          
                    </div>
                </div>  
 
            </div>           
        </div>
    </div>
</section>