import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import {isPlatformBrowser } from '@angular/common';
// import heic2any from 'heic2any';

@Component({
  selector: 'app-viewcar-info',
  templateUrl: './viewcar-info.component.html',
  styleUrls: ['./viewcar-info.component.scss']
})
export class ViewcarInfoComponent implements OnInit {
  public user: any;
  public car: any
  public carId: Number = 0

  public displayModal = false;
  public selectedImageIndex: number = 0;
  public orderedImages:any;
  public carAnswers :any;
  public carQuestions :any;
  public mileageAndColor :any;
  
  constructor(private service: CommonService, private activateRoute: ActivatedRoute, private el: ElementRef, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: any) {
    if(this.service.isBrowserPlateformId()){
      this.service.scrollToTop()   
      this.user = JSON.parse(`${localStorage.getItem('user')}`)
    }
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.activateRoute.params.subscribe((res: any) => {
      this.carId = res.id;
      this.getCar();
    })

  }

  ngOnInit(): void {
    // Dynamically load CSS
    if(this.service.isBrowserPlateformId()){
    const link = this.renderer.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css';
    this.renderer.appendChild(document.head, link);

    // Dynamically load JS
    const script = this.renderer.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-element-bundle.min.js';
    script.onload = () => {
      console.log('Swiper script loaded');
    };
    this.renderer.appendChild(document.body, script);
  }
  }
 
  getCar(): void {
    this.service.showSpinner();
    let data = {
      id: null,
      carId: this.carId,
      userTimeZone :'Asia/Calcutta'
    }
    if (this.user.role) {
      data.id = this.user.id
    }

 
    if(this.service.isBrowserPlateformId()){
      data.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ;
      console.log("userTimeZone", data.userTimeZone)
    } 

    this.service.postApi(`cars/get-cars`, data).subscribe(
      async (data: any) => {
        try {
          // Await the result of processImageUrls
          let newCarsImages = await this.processImageUrls(data.cars[0].carImages || []);
    
          // Update the car object with the new images
          this.car = { ...data.cars[0], carImages: newCarsImages || [] };
    
          // Parsing carQuestions and carAnswers
          this.carQuestions = JSON.parse(this.car.carQuestions);
          console.log("this.carQuestions",this.carQuestions)
          this.carAnswers = JSON.parse(this.car.carAnswers);
    
          // Processing the carQuestions to find Mileage and Color
          this.carQuestions.forEach((category: any) => {
            if (category.category.categoryName == 'Mileage and Color') {
              this.mileageAndColor = category.questions;
            }
          });
    
          this.service.hideSpinner();
        } catch (error: any) {
          console.error('Error processing cars:', error);
          this.service.hideSpinner();
        }
      },
      (error: any) => {
        console.error('Error fetching cars:', error);
        this.service.hideSpinner();
      }
    );
    
  }


  async processImageUrls(urls: {type: string, fileUrl: string, label: string}[]): Promise<{type: string, fileUrl: string, label: string}[]> {
    const processedUrls: {type: string, fileUrl: string, label: string}[] = [];
    console.log(urls,`urls`,processedUrls);
  
    for (const url of urls) {
  
      if (url.fileUrl.toLowerCase().endsWith('.heic')) {
        // const jpegUrl = await this.convertHeicUrlToJpeg(url.fileUrl);
        // processedUrls.push({...url, fileUrl:jpegUrl});
      } else {
        processedUrls.push(url);
      }
    }

    console.log('processedUrls',processedUrls)
    return processedUrls;
  }

  
  showModal(index: number) {
    this.selectedImageIndex = index;
    this.orderedImages = this.car?.carImages.slice(index).concat(this.car?.carImages.slice(0, index));
    this.displayModal = true;
  }

  showBidModel(){    
    this.service.bidData.next(this.car)
  }

  closeModal() {
    this.selectedImageIndex = 0;
    this.displayModal = false;
  }
  prevImage() {
    this.selectedImageIndex = (this.selectedImageIndex - 1 + this.car.carImages.length) % this.car.carImages.length;
  }

  nextImage() {
    this.selectedImageIndex = (this.selectedImageIndex + 1) % this.car.carImages.length;
  }
}
