import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit{
  public userData :any;
  public displayStyle = 'none'
  public userId:number=0;
  public parentUserId:number =0;
  public userAddress = ''
  public modalId ='agency-popup-deal';
  constructor(private service: CommonService) {

  }

  ngOnInit(): void {    
    this.service.customerData.subscribe((res:any)=>{
      this.userId = res.id;
      this.parentUserId = res.parentUserId
      if(this.userId>0){
        
        this.getDealerDeatails();
        this.clickOutSideModal();
      }
    })
  }

  getDealerDeatails() {
    this.service.showSpinner();
    let data = {
      userId:this.userId,
      parentUserId :this.parentUserId > 0 ? this.parentUserId:null
    }
    this.service.postApi(`users/dealer-details`,data).subscribe((res: any) => {
    // this.service.getApi(`users/get-user/${this.userId}`).subscribe((res: any) => {
      if(res.status ==200){
        this.userData = res.user;
        this.userAddress = `${this.userData?.addressLine1}, ${this.userData?.addressLine2 ? this.userData?.addressLine2 + ", " : ''} ${this.userData?.city} , ${this.userData?.state}, United State, ${this.userData?.zipCode}`
        this.displayStyle = 'flex'
        this.service.hideSpinner();
      }
      else{
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }
  clickOutSideModal() {    
    if(this.service.isBrowserPlateformId()){

      window.addEventListener('click', (event) => {
        if (event.target === document.getElementById(this.modalId)) {
          this.displayStyle = 'none'
        }
      });
    }
  }

  hideModal() {
    this.displayStyle = 'none'
  }
}
