<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name"
            class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center">
        <div class="container mx-auto  ">
            <div class="max-w-screen-sm	mx-auto z-50 lg:mt-0 flex-1 px-6 md:mb-0 mb-20">
                <div class="block text-center">
                    <h1 class="md:text-5xl  text-4xl text-white font-bold ">Privacy Policy</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="relative lg:py-14 lg:-mt-44 md:-mt-20 -mt-28">
    <div class="container  max-w-screen-xl  mx-auto px-4 xl:px-0">
        <div class class="content bg-white md:px-16 px-6 py-10 rounded-2xl shadow-xl ">

            <div *ngFor="let item of privacyPolicyData;let i= index">

                <div class="title-heading pb-1 mb-3 mt-8 leading-none" [ngClass]="{'mt-8': i!=0}">
                    <h2 class="md:text-4xl text-2xl	 text_primary_color font-bold leading-none">{{item.heading}}
                    </h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>
                <p class="md:text-lg text-base content_color" [innerHtml]="item.content">
                </p>
            </div>
        </div>
    </div>
</section>