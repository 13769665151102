// meta.service.ts
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {

  constructor(private meta: Meta, private titleService: Title) {}

  updateTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  updateMetaTag(name: string, content: string): void {
    // this.meta.updateTag({ name, content });
    this.meta.updateTag({ property: name, content });
    // this.meta.updateTag({ property: 'twitter:description', content });

  }
  // constructor(private meta: Meta) {}

  // updateMetaTags(title: string, description: string): void {
  //   this.meta.updateTag({ name: 'title', content: title });
  //   this.meta.updateTag({ name: 'description', content: description });

  //   // Update Open Graph (OG) meta tags
  //   this.meta.updateTag({ property: 'og:title', content: title });
  //   this.meta.updateTag({ property: 'og:description', content: description });
  // }
}
