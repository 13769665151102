import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(private service: CommonService,  private router: Router) {
  }

  routeToAddCar(){
    if (this.service.isLoggedIn()) {
        this.router.navigate(['dealer/car-list-new'])
      }
  }
}
