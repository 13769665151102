<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name" class="max-w-full sm:inline-block hidden lg:h-[550px] h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center">
        <div class="container mx-auto  ">
            <div class="w-full mx-auto z-50  flex-1 px-6 ">
                <div class="block text-center">
                    <div class="inline-block px-10 py-2 rounded-full bg-white mb-6 text-[#004AAD] font-semibold xl:text-xl text-base"
                        *ngIf="car?.carPlateNo && car?.carPlateNo!=' '">{{car?.carPlateNo}}</div>
                    <h1 class="xl:text-5xl text-4xl text-white font-bold "> {{car?.carName}} {{car?.modelName}} </h1>
                    <div class="text-center text-white xl:text-2xl text-base mt-4 ">
                        <div
                            class="relative inline-block sm:px-10 px-4 after:absolute after:xl:w-[8px] after:xl:h-[8px] after:w-[5px] after:h-[5px] after:rounded-full after:bg-white after:right-0 after:xl:top-[13px] after:top-[9px]">
                            {{car?.modelYear}}</div>
                        <div
                            class="relative inline-block sm:px-10 px-4 after:absolute after:xl:w-[8px] after:xl:h-[8px] after:w-[5px] after:h-[5px] after:rounded-full after:bg-white after:-right-[0px] after:md:top-[13px] after:top-[9px]">
                            {{car?.modelName}}</div>
                        <div class="inline-block sm:px-10 px-4"> {{car?.fuelType}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class=" py-20 px-4 lg:px-0 bg-[#EBEBEB]">
    <div class="max-w-screen-xl	 mx-auto lg:px-4">
        <div class="text-center">
        </div>
        <div class="md:flex md:flex-wrap -mx-4">          
            <app-sidebar class="z-2 xl:w-3/12 md:w-5/12 w-full px-4 mb-10 md:mb-0 hidden md:block"></app-sidebar>
            <div class="px-4 xl:w-9/12 md:w-7/12 w-full">     
                <div class="mb-4 text-center lg:text-lg text-base font-medium text-red -mt-10"><sup>*</sup> You can select only 1 offer from list of offers for your Vehicle.</div>                           
                <div class="relative overflow-x-auto rounded-lg custom-scrollbar custom-scrollbar ">
                    <table class="w-full text-sm text-left grey_text_500">
                        <thead class="text-base  bg_primary text-white font-normal text-center">
                            <tr>
                                <th scope="col" class="px-4 py-6 font-semibold text-left">
                                    Dealer
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Car Pick up
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Financial Settlement 
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold whitespace-nowrap	">
                                    Lease Settlement
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold">
                                    Distance
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold">
                                    Offer 
                                </th>
                                <th scope="col" class="px-4 py-6 font-semibold">
                                    Status   
                                </th>
                            </tr>
                        </thead>
                        <tbody class="text-center  font-medium">
                            <tr class="bg-white active-bedding z-10 relative text-black text-base " *ngFor="let item of bidOffers; let i=index">
                                <th scope="row" class="px-4 py-4 text-left" *ngIf="item?.bidWinnerId">
                                    <span class="underline text-sm font-medium ">{{item?.firstName}} {{item?.lastName}}</span>
                                </th>
                                <th scope="row" class="px-4 py-4 text-left" *ngIf="!item?.bidWinnerId">
                                    <span class="text-sm font-medium ">Dealer {{i+1}}</span>
                                </th>
                                <td class="px-4 py-6 ">
                                    {{item?.carLocation  ? 'Yes' : 'No'}}
                                </td>
                                <td class="px-4 py-6">
                                    {{item?.financeSettlement  ? 'Yes' : 'No'}}
                                </td>
                                <td class="px-4 py-6">
                                    {{item?.leaseSettlement ? 'Yes' : 'No'}}
                                </td>
                                <td class="px-4 py-6">
                                    {{(item?.distance).toFixed()}} miles
                                </td>
                                <td class="px-4 py-6 bidding-price active-biding relative">
                                    <span class="bg-green text-white text-xs font-medium px-2.5 py-0.5 rounded-full inline-block absolute  w-auto -translate-y-full -translate-x-1/2" *ngIf="i==0">Highest</span>
                                    <p class="m-0 font-semibold text-lg">${{item?.bidAmount}}</p> 
                                </td>
                                <td class="px-4 py-6 action" *ngIf="item.bidWinnerId">
                                    <a  class="text-sm text-white bg_primary rounded-full px-4 py-2 whitespace-nowrap cursor-pointer" (click)="getDealerDeatails(item?.dealerId,item?.bidAmount,item?.parentUserId)">Dealer Detail</a>
                                </td>
                                <td class="px-4 py-6 action text-center" *ngIf="!item.bidWinnerId">
                                    <a  class="text-sm text-white bg-green rounded-full px-4 py-1.5 whitespace-nowrap cursor-pointer" (click)="acceptBid(item?.id,item?.dealerId,item?.parentUserId)" *ngIf="!isBidAccepted">Accept</a>
                                </td>
                            </tr>
                            <tr class="bg-white active-bedding z-10 relative text-black text-base " *ngIf="totalItems==0">
                                <td class="px-4 py-6" colspan="7">
                                  Offer not found
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
 
            </div>           
        </div>
    </div>
</section>

<!-- Dealer Agency Popup Auto -->
<div id="agency-popup-deal" tabindex="-1" aria-hidden="true" class="justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75 hidden " [ngStyle]="{display: displayStyle}">
    <div class="relative w-full max-w-5xl max-h-full">
        <!-- Modal content -->
        <div class="flex items-center bg-[#004AAD] justify-between p-4 md:p-5 !pb-8 border-b rounded-t dark:border-gray-600">

            <button type="button" class="absolute top-3 end-2.5 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" (click)="hideModal()">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <!-- <h2 class="text-white !p-0 !pb-0 text-center text-xl">Dealer Details</h2> -->
            </div>
            

        <div class="relative agency-popup-bg bg-white rounded-b-lg shadow bg-popup secondary-font overflow-hidden">           
            <!-- Modal body -->
            <div class="flex flex-wrap">
                <div class="md:w-6/12 w-full bg_primary px-10 md:py-16 py-12">
                    <div class="info-message">
                        <div class="px-4 text-center">
                            <div class="inline-block agency-name  border-2 border-white rounded-lg md:p-6 p-4 lg:text-3xl md:text-2xl text-xl font-medium text-white text-center">{{dealer?.dealershipName}}</div>
                            
                        </div>
                        <div class="w-full  mt-4 text-base text-white font-medium text-center  leading-8">
                            <p>Congratulations you have picked the perfect dealer to sell your car. </p>
                        </div>
                    </div>

                </div>
                <div class="md:w-6/12  w-full">
                    <div class="dealer-details-box px-6 py-8">
                        <div class="flex flex-wrap items-start justify-center ">
                            <div class="dealer-img rounded-full overflow-hidden shadow-xl md:mb-0 mb-4 w-28	h-28">
                                <img src="{{dealer?.userImage ? dealer?.userImage  : 'assets/img/user_image_placeholder.png'}}" alt="user name" title="user name" class="h-28 object-cover">
                            </div>
                           <div class="dealer-info-data-box md:flex-1 md:w-auto w-full md:px-6">
                                <h3 class="xl:text-4xl lg:text-3xl text-2xl font-bold">{{dealer?.firstName}} {{dealer?.lastName}}</h3>
                                <div class="rating flex my-2">
                                    <span class="w-5 h-5 inline-block" *ngFor="let _ of [].constructor(5); let i = index">
                                        <!-- <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                        <style type="text/css">
                                            .st0{fill:#CCCCCB;}
                                        </style>
                                        <path class="st0 " [ngClass]="{'star-active': i<dealer?.star}" d="M4.6,40.6c0-0.5,0-0.9,0-1.4c0.5-1.1,1.3-1.9,2.6-2.1c9-1.4,18-2.7,26.9-4.1c0.6-0.1,0.9-0.3,1.2-0.9
                                            c3.9-8.3,7.8-16.6,11.7-24.9c0.6-1.3,1.3-2.2,2.7-2.5c0.2,0,0.5,0,0.7,0C51.7,5,52.5,5.9,53,7.2c3.9,8.3,7.8,16.6,11.7,24.9
                                            c0.2,0.5,0.5,0.8,1.1,0.9c1.7,0.2,3.4,0.5,5.1,0.7c7.4,1.1,14.7,2.2,22,3.4c1.3,0.2,2.3,1.2,2.4,2.5c0.1,1.1-0.4,1.9-1.1,2.7
                                            c-6.5,6.6-12.9,13.2-19.4,19.9c-0.3,0.3-0.5,0.8-0.5,1.1c1.1,7.3,2.3,14.5,3.5,21.8c0.4,2.3,0.8,4.5,1.1,6.8c0.3,1.7-0.5,3.1-2,3.5
                                            c-1,0.3-1.8-0.1-2.6-0.5c-7.8-4.3-15.6-8.6-23.4-13c-0.7-0.4-1.3-0.5-2.1,0c-7.8,4.4-15.7,8.7-23.5,13c-1.2,0.6-2.3,0.8-3.4,0
                                            c-1.1-0.8-1.3-2-1.1-3.2c1.5-9.4,3.1-18.8,4.6-28.2c0.1-0.6-0.1-0.9-0.4-1.2c-6.3-6.5-12.7-13-19-19.5C5.6,42,5.1,41.3,4.6,40.6z"/>
                                        </svg> -->
                                        <i [class]="calculateStarClass(i,dealer?.star)" aria-hidden="true"></i>
                                    </span>

                                </div>
                                <div class="contact-info-dealer my-6 ">
                                    <ul class="space-y-3 grey_text_500 font-semibold">
                                        <li class="flex lg:text-lg text-base"><img src="assets/img/call-icon.svg" alt="call" class="w-5 h-5 mr-4"> <span>{{dealer?.phone}}</span></li>
                                        <li class="flex"><img src="assets/img/location-icon.svg" alt="call" class="w-5 h-5 mr-4"><span>{{userAddress}}</span></li>
                                        <li class="flex"><img src="assets/img/email-icon.svg" alt="call" class="w-5 h-5 mr-4"><span><a href="mailto:demoemail@.com" class="grey_text_500 hover:text_primary_color break-all break-words	">{{dealer?.email}}</a></span></li>
                                    </ul>
                                </div>
                                <div class="deal-price text-right">
                                    <span class="px-8 py-2 text-base font-medium bg_primary text-white rounded-full inline-block">${{dealerBidAmount}}</span>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

