// dealer-account-routing.module.ts
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DealerPerformanceComponent } from './dealer-performance/dealer-performance.component';
import { CarListNewComponent } from './car-list-new/car-list-new.component';
import { BrandCarListComponent } from './brand-car-list/brand-car-list.component';
import { EmployePerformanceComponent } from './employe-performance/employe-performance.component';
import { BrandsComponent } from './brands/brands.component';
import { DealerOfferComponent } from './dealer-offer/dealer-offer.component';
import { InvoiceAccountStatementComponent } from './invoice-account-statement/invoice-account-statement.component';
import { KargoneWalletComponent } from './kargone-wallet/kargone-wallet.component';
import { ProfileManagementComponent } from './profile-management/profile-management.component';
import { ViewcarInfoComponent } from './viewcar-info/viewcar-info.component';
import { DealerPreferenceComponent } from './dealer-preference/dealer-preference.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { AuthGuard } from '../auth/auth.guard';
import { PersonalDetailsComponent } from '../components/personal-details/personal-details.component';
import { RatingComponent } from '../myaccount/rating/rating.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { NotificationComponent } from '../myaccount/notification/notification.component';

// ... import other components ...

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: CarListNewComponent, pathMatch: 'full' },
      { path: 'car-list-new', component: CarListNewComponent ,canActivate:[AuthGuard] },
      { path: 'dealer-performance', component: DealerPerformanceComponent,canActivate:[AuthGuard]  },
      { path: 'dealer-preferred-list', component: CarListNewComponent ,canActivate:[AuthGuard] },
      { path: 'offers', component: CarListNewComponent ,canActivate:[AuthGuard] },
      { path: 'won-offers', component: CarListNewComponent,canActivate:[AuthGuard]  },
      {path: 'sms-email',component: NotificationComponent,canActivate:[AuthGuard] },
      {path:'dealer-profile',component:PersonalDetailsComponent,canActivate:[AuthGuard] },
      {path: 'rating',component: RatingComponent,canActivate:[AuthGuard] },
      {
        path: 'employee-performance',
        component: EmployePerformanceComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'brands',
        component: BrandsComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'vehicle-insights',
        component: BrandCarListComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'vehicle-insights/:id',
        component: BrandCarListComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'offer',
        component: DealerOfferComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'schedule-car-pickup',
        component: CarListNewComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'schedule-car-dropoff',
        component: CarListNewComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'car-overview',
        component: CarListNewComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'car-purchased',
        component: CarListNewComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'car-rejected',
        component: CarListNewComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'kargone-wallet',
        component: KargoneWalletComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'account-statement-invoice/:id',
        component: InvoiceAccountStatementComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'billing-invoice/:id',
        component: InvoiceAccountStatementComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'credit-reciept/:id',
        component: InvoiceAccountStatementComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'profile-management',
        component: ProfileManagementComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'view-car-info/:id',
        component: ViewcarInfoComponent,  
        canActivate:[AuthGuard]  
      },
      {
        path:'dealer-preferences',
        component:DealerPreferenceComponent,
      },
      {path: 'change-password',component: ChangePasswordComponent},

      {
        path:'dealer-preferences/:id',
        component:DealerPreferenceComponent,
        canActivate:[AuthGuard] 
      },
      {
        path:'saved-search',
        component:CarListNewComponent,
        canActivate:[AuthGuard] 
      },
      {
        path:'user-management',
        component:UserManagementComponent,
        canActivate:[AuthGuard] 
      },
      {
        path: 'profile',
        component : ProfileManagementComponent,
        canActivate:[AuthGuard] 
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DealerAccountRoutingModule { }
